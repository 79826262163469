/* tslint:disable */
/* eslint-disable */
/**
 * Clifton.API.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdjustmentAdminCreateModel
 */
export interface AdjustmentAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminCreateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminCreateModel
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminListViewModel
 */
export interface AdjustmentAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminListViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminListViewModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminListViewModel
     */
    'adjustmentTypeName': string;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminListViewModelListApplicationResult
 */
export interface AdjustmentAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AdjustmentAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AdjustmentAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<AdjustmentAdminListViewModel>}
     * @memberof AdjustmentAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<AdjustmentAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminListViewModelPaginatedList
 */
export interface AdjustmentAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<AdjustmentAdminListViewModel>}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'items': Array<AdjustmentAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminListViewModelPaginatedListApplicationResult
 */
export interface AdjustmentAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {AdjustmentAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: AdjustmentAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface AdjustmentAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<AdjustmentAdminListViewModel>}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<AdjustmentAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminUpdateModel
 */
export interface AdjustmentAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminUpdateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminUpdateModel
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminViewModel
 */
export interface AdjustmentAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModel
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminViewModelApplicationResult
 */
export interface AdjustmentAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AdjustmentAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AdjustmentAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {AdjustmentAdminViewModelApplicationResultResultData}
     * @memberof AdjustmentAdminViewModelApplicationResult
     */
    'resultData'?: AdjustmentAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface AdjustmentAdminViewModelApplicationResultResultData
 */
export interface AdjustmentAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModelApplicationResultResultData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentAdminViewModelApplicationResultResultData
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminCreateModel
 */
export interface AdjustmentTypeAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminCreateModel
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminListViewModel
 */
export interface AdjustmentTypeAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminListViewModel
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminListViewModelListApplicationResult
 */
export interface AdjustmentTypeAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AdjustmentTypeAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AdjustmentTypeAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<AdjustmentTypeAdminListViewModel>}
     * @memberof AdjustmentTypeAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<AdjustmentTypeAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminListViewModelPaginatedList
 */
export interface AdjustmentTypeAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<AdjustmentTypeAdminListViewModel>}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'items': Array<AdjustmentTypeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminListViewModelPaginatedListApplicationResult
 */
export interface AdjustmentTypeAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<AdjustmentTypeAdminListViewModel>}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<AdjustmentTypeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof AdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminUpdateModel
 */
export interface AdjustmentTypeAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminUpdateModel
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminViewModel
 */
export interface AdjustmentTypeAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminViewModel
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminViewModelApplicationResult
 */
export interface AdjustmentTypeAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AdjustmentTypeAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AdjustmentTypeAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AdjustmentTypeAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {AdjustmentTypeAdminViewModelApplicationResultResultData}
     * @memberof AdjustmentTypeAdminViewModelApplicationResult
     */
    'resultData'?: AdjustmentTypeAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface AdjustmentTypeAdminViewModelApplicationResultResultData
 */
export interface AdjustmentTypeAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdminAdjustmentTypesGetRequest
 */
export interface AdminAdjustmentTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentTypesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentTypesGetRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdminAdjustmentTypesIdDeleteRequest
 */
export interface AdminAdjustmentTypesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentTypesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentTypesIdDeleteRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface AdminAdjustmentsGetRequest
 */
export interface AdminAdjustmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentsGetRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentsGetRequest
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface AdminAdjustmentsIdDeleteRequest
 */
export interface AdminAdjustmentsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentsIdDeleteRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAdjustmentsIdDeleteRequest
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface AdminArtAssetCategoriesGetRequest
 */
export interface AdminArtAssetCategoriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetCategoriesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetCategoriesGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetCategoriesGetRequest
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminArtAssetCategoriesIdDeleteRequest
 */
export interface AdminArtAssetCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetCategoriesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetCategoriesIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetCategoriesIdDeleteRequest
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminArtAssetsGetRequest
 */
export interface AdminArtAssetsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetsGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetsGetRequest
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminArtAssetsGetRequest
     */
    'categoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminArtAssetsIdDeleteRequest
 */
export interface AdminArtAssetsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetsIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArtAssetsIdDeleteRequest
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminArtAssetsIdDeleteRequest
     */
    'categoryIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AdminBannerItemsGetRequest
 */
export interface AdminBannerItemsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsGetRequest
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminBannerItemsIdDeleteRequest
 */
export interface AdminBannerItemsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerItemsIdDeleteRequest
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminBannerMessagesGetRequest
 */
export interface AdminBannerMessagesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesGetRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesGetRequest
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesGetRequest
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminBannerMessagesGetRequest
     */
    'showOnQuotes': boolean;
}
/**
 * 
 * @export
 * @interface AdminBannerMessagesIdDeleteRequest
 */
export interface AdminBannerMessagesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesIdDeleteRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesIdDeleteRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesIdDeleteRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesIdDeleteRequest
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBannerMessagesIdDeleteRequest
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminBannerMessagesIdDeleteRequest
     */
    'showOnQuotes': boolean;
}
/**
 * 
 * @export
 * @interface AdminBlogCategoriesGetRequest
 */
export interface AdminBlogCategoriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBlogCategoriesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogCategoriesGetRequest
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface AdminBlogCategoriesIdDeleteRequest
 */
export interface AdminBlogCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBlogCategoriesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogCategoriesIdDeleteRequest
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface AdminBlogsGetRequest
 */
export interface AdminBlogsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminBlogsGetRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminBlogsGetRequest
     */
    'categoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsGetRequest
     */
    'publishedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminBlogsIdDeleteRequest
 */
export interface AdminBlogsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminBlogsIdDeleteRequest
     */
    'categoryIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AdminCampaignsBannerItemsGetRequest
 */
export interface AdminCampaignsBannerItemsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'campaignId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsGetRequest
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCampaignsBannerItemsIdDeleteRequest
 */
export interface AdminCampaignsBannerItemsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsBannerItemsIdDeleteRequest
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCampaignsClientsGetRequest
 */
export interface AdminCampaignsClientsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsClientsGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsClientsGetRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsClientsGetRequest
     */
    'campaignId': string;
}
/**
 * 
 * @export
 * @interface AdminCampaignsClientsIdDeleteRequest
 */
export interface AdminCampaignsClientsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsClientsIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsClientsIdDeleteRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCampaignsGetRequest
 */
export interface AdminCampaignsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsGetRequest
     */
    'bannerImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCampaignsIdDeleteRequest
 */
export interface AdminCampaignsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'customizedBannerItems': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'customizedClients': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaignsIdDeleteRequest
     */
    'bannerImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCarouselItemsGetRequest
 */
export interface AdminCarouselItemsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsGetRequest
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface AdminCarouselItemsIdDeleteRequest
 */
export interface AdminCarouselItemsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCarouselItemsIdDeleteRequest
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface AdminCategoriesGetRequest
 */
export interface AdminCategoriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCategoriesGetRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCategoriesGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'parentCategoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesGetRequest
     */
    'productCategoryMenuGroupId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCategoriesGetRequest
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CategoryImageAdminCreateModel>}
     * @memberof AdminCategoriesGetRequest
     */
    'thumbnailImages'?: Array<CategoryImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<CategoryImageAdminCreateModel>}
     * @memberof AdminCategoriesGetRequest
     */
    'bannerImages'?: Array<CategoryImageAdminCreateModel> | null;
}
/**
 * 
 * @export
 * @interface AdminCategoriesIdDeleteRequest
 */
export interface AdminCategoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'parentCategoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'productCategoryMenuGroupId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CategoryImageAdminUpdateModel>}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'thumbnailImages'?: Array<CategoryImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<CategoryImageAdminUpdateModel>}
     * @memberof AdminCategoriesIdDeleteRequest
     */
    'bannerImages'?: Array<CategoryImageAdminUpdateModel> | null;
}
/**
 * 
 * @export
 * @interface AdminClientsGetRequest
 */
export interface AdminClientsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminClientsGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminClientsGetRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminClientsIdDeleteRequest
 */
export interface AdminClientsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminClientsIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminClientsIdDeleteRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminColourGroupsGetRequest
 */
export interface AdminColourGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminColourGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColourGroupsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminColourGroupsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminColourGroupsGetRequest
     */
    'colourIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminColourGroupsIdDeleteRequest
 */
export interface AdminColourGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminColourGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColourGroupsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminColourGroupsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminColourGroupsIdDeleteRequest
     */
    'colourIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminColourPricesGetRequest
 */
export interface AdminColourPricesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminColourPricesGetRequest
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminColourPricesGetRequest
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminColourPricesGetRequest
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof AdminColourPricesGetRequest
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface AdminColourPricesIdDeleteRequest
 */
export interface AdminColourPricesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminColourPricesIdDeleteRequest
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminColourPricesIdDeleteRequest
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminColourPricesIdDeleteRequest
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof AdminColourPricesIdDeleteRequest
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface AdminColoursGetRequest
 */
export interface AdminColoursGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminColoursGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursGetRequest
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursGetRequest
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminColoursGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursGetRequest
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminColoursIdDeleteRequest
 */
export interface AdminColoursIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminColoursIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursIdDeleteRequest
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursIdDeleteRequest
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminColoursIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminColoursIdDeleteRequest
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCountriesGetRequest
 */
export interface AdminCountriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesGetRequest
     */
    'currencyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCountriesGetRequest
     */
    'isDefault': boolean;
    /**
     * 
     * @type {ImportDutyType}
     * @memberof AdminCountriesGetRequest
     */
    'importDutyTypeId': ImportDutyType;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesGetRequest
     */
    'countryGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCountriesGetRequest
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCountriesGetRequest
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesGetRequest
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCountriesIdDeleteRequest
 */
export interface AdminCountriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'currencyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'isDefault': boolean;
    /**
     * 
     * @type {ImportDutyType}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'importDutyTypeId': ImportDutyType;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'countryGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCountriesIdDeleteRequest
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCountryDeliveryTypesGetRequest
 */
export interface AdminCountryDeliveryTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCountryDeliveryTypesGetRequest
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof AdminCountryDeliveryTypesGetRequest
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof AdminCountryDeliveryTypesGetRequest
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCountryDeliveryTypesGetRequest
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCountryDeliveryTypesGetRequest
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface AdminCountryDeliveryTypesIdDeleteRequest
 */
export interface AdminCountryDeliveryTypesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCountryDeliveryTypesIdDeleteRequest
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof AdminCountryDeliveryTypesIdDeleteRequest
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof AdminCountryDeliveryTypesIdDeleteRequest
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCountryDeliveryTypesIdDeleteRequest
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCountryDeliveryTypesIdDeleteRequest
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface AdminCouriersGetRequest
 */
export interface AdminCouriersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCouriersGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCouriersGetRequest
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface AdminCouriersIdDeleteRequest
 */
export interface AdminCouriersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCouriersIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCouriersIdDeleteRequest
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface AdminCurrenciesGetRequest
 */
export interface AdminCurrenciesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCurrenciesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCurrenciesGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCurrenciesGetRequest
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof AdminCurrenciesGetRequest
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCurrenciesGetRequest
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface AdminCurrenciesIdDeleteRequest
 */
export interface AdminCurrenciesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCurrenciesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCurrenciesIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCurrenciesIdDeleteRequest
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof AdminCurrenciesIdDeleteRequest
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCurrenciesIdDeleteRequest
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface AdminCustomizationGroupsGetRequest
 */
export interface AdminCustomizationGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationGroupsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCustomizationGroupsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCustomizationGroupsGetRequest
     */
    'customizationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminCustomizationGroupsIdDeleteRequest
 */
export interface AdminCustomizationGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationGroupsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCustomizationGroupsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCustomizationGroupsIdDeleteRequest
     */
    'customizationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminCustomizationsGetRequest
 */
export interface AdminCustomizationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof AdminCustomizationsGetRequest
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCustomizationsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationsGetRequest
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminCustomizationsIdDeleteRequest
 */
export interface AdminCustomizationsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof AdminCustomizationsIdDeleteRequest
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCustomizationsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminCustomizationsIdDeleteRequest
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminDesignGroupsGetRequest
 */
export interface AdminDesignGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDesignGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDesignGroupsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDesignGroupsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDesignGroupsGetRequest
     */
    'designIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminDesignGroupsIdDeleteRequest
 */
export interface AdminDesignGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDesignGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDesignGroupsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDesignGroupsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDesignGroupsIdDeleteRequest
     */
    'designIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminDesignsGetRequest
 */
export interface AdminDesignsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDesignsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDesignsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDesignsGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDesignsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDesignsGetRequest
     */
    'customizationGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDesignsGetRequest
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminDesignsIdDeleteRequest
 */
export interface AdminDesignsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminDesignsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDesignsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDesignsIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDesignsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDesignsIdDeleteRequest
     */
    'customizationGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminDesignsIdDeleteRequest
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminEnquiriesIdDeleteRequest
 */
export interface AdminEnquiriesIdDeleteRequest {
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'enquiryStatusId': EnquiryStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {EnquiryType}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'enquiryTypeId': EnquiryType;
    /**
     * 
     * @type {string}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'internalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'confirmedDeadline'?: string | null;
    /**
     * 
     * @type {EnquiryCustomerType}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'enquiryCustomerTypeId'?: EnquiryCustomerType | null;
    /**
     * 
     * @type {number}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'qualityScore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminEnquiriesIdDeleteRequest
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
 */
export interface AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'borderColour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'borderStyle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'borderWidth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'frontPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {CustomizedProductExtraCreateUpdateModelFont}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'font'?: CustomizedProductExtraCreateUpdateModelFont | null;
    /**
     * 
     * @type {CustomizedProductItemCreateUpdateModelCustomization}
     * @memberof AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest
     */
    'customization': CustomizedProductItemCreateUpdateModelCustomization;
}
/**
 * 
 * @export
 * @interface AdminExtraGroupsGetRequest
 */
export interface AdminExtraGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminExtraGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraGroupsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExtraGroupsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminExtraGroupsGetRequest
     */
    'extraIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminExtraGroupsIdDeleteRequest
 */
export interface AdminExtraGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminExtraGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtraGroupsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExtraGroupsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminExtraGroupsIdDeleteRequest
     */
    'extraIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminExtrasGetRequest
 */
export interface AdminExtrasGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasGetRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasGetRequest
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasGetRequest
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExtrasGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExtrasGetRequest
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminExtrasGetRequest
     */
    'customizationTextMaximumLength'?: number | null;
}
/**
 * 
 * @export
 * @interface AdminExtrasIdDeleteRequest
 */
export interface AdminExtrasIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminExtrasIdDeleteRequest
     */
    'customizationTextMaximumLength'?: number | null;
}
/**
 * 
 * @export
 * @interface AdminFabricChannelsGetRequest
 */
export interface AdminFabricChannelsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFabricChannelsGetRequest
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricChannelsGetRequest
     */
    'externalName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminFabricChannelsGetRequest
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminFabricChannelsIdDeleteRequest
 */
export interface AdminFabricChannelsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFabricChannelsIdDeleteRequest
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricChannelsIdDeleteRequest
     */
    'externalName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminFabricChannelsIdDeleteRequest
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminFabricColoursGetRequest
 */
export interface AdminFabricColoursGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFabricColoursGetRequest
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricColoursGetRequest
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricColoursGetRequest
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @interface AdminFabricColoursIdDeleteRequest
 */
export interface AdminFabricColoursIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFabricColoursIdDeleteRequest
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricColoursIdDeleteRequest
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricColoursIdDeleteRequest
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @interface AdminFabricsGetRequest
 */
export interface AdminFabricsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFabricsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricsGetRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminFabricsIdDeleteRequest
 */
export interface AdminFabricsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFabricsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFabricsIdDeleteRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminFactoriesGetRequest
 */
export interface AdminFactoriesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminFactoriesGetRequest
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesGetRequest
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesGetRequest
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface AdminFactoriesIdDeleteRequest
 */
export interface AdminFactoriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminFactoriesIdDeleteRequest
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesIdDeleteRequest
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFactoriesIdDeleteRequest
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface AdminFontsGetRequest
 */
export interface AdminFontsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFontsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFontsGetRequest
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface AdminFontsIdDeleteRequest
 */
export interface AdminFontsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFontsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminFontsIdDeleteRequest
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface AdminLayerFabricsGetRequest
 */
export interface AdminLayerFabricsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminLayerFabricsGetRequest
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayerFabricsGetRequest
     */
    'fabricId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLayerFabricsGetRequest
     */
    'colourGroupIds': Array<string>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof AdminLayerFabricsGetRequest
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof AdminLayerFabricsGetRequest
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof AdminLayerFabricsGetRequest
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLayerFabricsGetRequest
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminLayerFabricsIdDeleteRequest
 */
export interface AdminLayerFabricsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'fabricId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'colourGroupIds': Array<string>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLayerFabricsIdDeleteRequest
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminLayersGetRequest
 */
export interface AdminLayersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminLayersGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersGetRequest
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersGetRequest
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminLayersGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersGetRequest
     */
    'fabricChannelId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminLayersIdDeleteRequest
 */
export interface AdminLayersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminLayersIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersIdDeleteRequest
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersIdDeleteRequest
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminLayersIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminLayersIdDeleteRequest
     */
    'fabricChannelId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminMediasGetRequest
 */
export interface AdminMediasGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMediasGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMediasGetRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminMediasIdDeleteRequest
 */
export interface AdminMediasIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMediasIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminMediasIdDeleteRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminOrderCostsGetRequest
 */
export interface AdminOrderCostsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsGetRequest
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderCostsGetRequest
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderCostsGetRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsGetRequest
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsGetRequest
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsGetRequest
     */
    'internalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminOrderCostsIdDeleteRequest
 */
export interface AdminOrderCostsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminOrderCostsIdDeleteRequest
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderCostsIdDeleteRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsIdDeleteRequest
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsIdDeleteRequest
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderCostsIdDeleteRequest
     */
    'internalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminOrderInvoicesGetRequest
 */
export interface AdminOrderInvoicesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrderInvoicesGetRequest
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderInvoicesGetRequest
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof AdminOrderInvoicesGetRequest
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderInvoicesGetRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface AdminOrderItemAssetsGetRequest
 */
export interface AdminOrderItemAssetsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrderItemAssetsGetRequest
     */
    'orderItemId': string;
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof AdminOrderItemAssetsGetRequest
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderItemAssetsGetRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderItemAssetsGetRequest
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderItemAssetsGetRequest
     */
    'fileUrl': string;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionaFileCreateUpdateModel>}
     * @memberof AdminOrderItemAssetsGetRequest
     */
    'additionalFiles': Array<OrderItemAssetAdditionaFileCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminOrderItemAssetsIdDeleteRequest
 */
export interface AdminOrderItemAssetsIdDeleteRequest {
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof AdminOrderItemAssetsIdDeleteRequest
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderItemAssetsIdDeleteRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderItemAssetsIdDeleteRequest
     */
    'fileUrl': string;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionaFileCreateUpdateModel>}
     * @memberof AdminOrderItemAssetsIdDeleteRequest
     */
    'additionalFiles': Array<OrderItemAssetAdditionaFileCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminOrderPaymentsGetRequest
 */
export interface AdminOrderPaymentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'currencyId': string;
    /**
     * 
     * @type {number}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'paymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsGetRequest
     */
    'externalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminOrderPaymentsIdDeleteRequest
 */
export interface AdminOrderPaymentsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsIdDeleteRequest
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsIdDeleteRequest
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrderPaymentsIdDeleteRequest
     */
    'externalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminOrdersCreateOrderQuotePostRequest
 */
export interface AdminOrdersCreateOrderQuotePostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersCreateOrderQuotePostRequest
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof AdminOrdersCreateOrderQuotePostRequest
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersCreateOrderQuotePostRequest
     */
    'enquiryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersCreateOrderQuotePostRequest
     */
    'useEnquiryProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersCreateOrderQuotePostRequest
     */
    'quoteExpiryDate'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminOrdersIdGetRequest
 */
export interface AdminOrdersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof AdminOrdersIdGetRequest
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {DeliveryType}
     * @memberof AdminOrdersIdGetRequest
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'courierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'courierReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {OrderAdminUpdateModelPrimaryBillingAddress}
     * @memberof AdminOrdersIdGetRequest
     */
    'primaryBillingAddress'?: OrderAdminUpdateModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminUpdateModelPrimaryBillingAddress}
     * @memberof AdminOrdersIdGetRequest
     */
    'primaryDeliveryAddress'?: OrderAdminUpdateModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {Array<OrderAddressAdminUpdateModel>}
     * @memberof AdminOrdersIdGetRequest
     */
    'additionalAddresses': Array<OrderAddressAdminUpdateModel>;
    /**
     * 
     * @type {OrderAdminUpdateModelPrimaryCustomerContact}
     * @memberof AdminOrdersIdGetRequest
     */
    'primaryCustomerContact'?: OrderAdminUpdateModelPrimaryCustomerContact | null;
    /**
     * 
     * @type {Array<OrderCustomerContactAdminUpdateModel>}
     * @memberof AdminOrdersIdGetRequest
     */
    'additionalCustomerContacts': Array<OrderCustomerContactAdminUpdateModel>;
    /**
     * 
     * @type {Array<OrderItemAdminUpdateModel>}
     * @memberof AdminOrdersIdGetRequest
     */
    'orderItems': Array<OrderItemAdminUpdateModel>;
    /**
     * 
     * @type {Array<OrderDocumentCreateUpdateModel>}
     * @memberof AdminOrdersIdGetRequest
     */
    'orderDocuments': Array<OrderDocumentCreateUpdateModel>;
    /**
     * 
     * @type {Array<FactoryAdminProductionNotesModel>}
     * @memberof AdminOrdersIdGetRequest
     */
    'factories': Array<FactoryAdminProductionNotesModel>;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'exWorksConfirmedByFactoryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'exWorksNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'exWorksApproved'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'groupedUK': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'groupedUSA': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'productionNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'dispatchNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'lostReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'cancellationReason'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'disableQuoteReminder': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'voucherId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'aspNetUserId'?: string | null;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof AdminOrdersIdGetRequest
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'quoteAlmostExpiredLastSendDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'disableAutoSendFactoryEmails': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminOrdersIdGetRequest
     */
    'specialTerms'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOrdersIdGetRequest
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface AdminOverlayGroupsGetRequest
 */
export interface AdminOverlayGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOverlayGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlayGroupsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOverlayGroupsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminOverlayGroupsGetRequest
     */
    'overlayIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminOverlayGroupsIdDeleteRequest
 */
export interface AdminOverlayGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOverlayGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlayGroupsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOverlayGroupsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminOverlayGroupsIdDeleteRequest
     */
    'overlayIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminOverlaysGetRequest
 */
export interface AdminOverlaysGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysGetRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysGetRequest
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysGetRequest
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOverlaysGetRequest
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface AdminOverlaysIdDeleteRequest
 */
export interface AdminOverlaysIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysIdDeleteRequest
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysIdDeleteRequest
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminOverlaysIdDeleteRequest
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminOverlaysIdDeleteRequest
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface AdminPageSeoSettingsDeleteRequest
 */
export interface AdminPageSeoSettingsDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPageSeoSettingsDeleteRequest
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPageSeoSettingsDeleteRequest
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface AdminPageSeoSettingsDeleteRequest1
 */
export interface AdminPageSeoSettingsDeleteRequest1 {
    /**
     * 
     * @type {string}
     * @memberof AdminPageSeoSettingsDeleteRequest1
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof AdminPageSeoSettingsDeleteRequest1
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPageSeoSettingsDeleteRequest1
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface AdminProductBundlesGetRequest
 */
export interface AdminProductBundlesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductBundlesGetRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductBundlesGetRequest
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductBundlesGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesGetRequest
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBundleImageAdminCreateModel>}
     * @memberof AdminProductBundlesGetRequest
     */
    'thumbnailImages'?: Array<ProductBundleImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleImageAdminCreateModel>}
     * @memberof AdminProductBundlesGetRequest
     */
    'galleryImages'?: Array<ProductBundleImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductBundleAdminCreateModel>}
     * @memberof AdminProductBundlesGetRequest
     */
    'relatedProducts'?: Array<RelatedProductBundleAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductBundleAdminCreateModel>}
     * @memberof AdminProductBundlesGetRequest
     */
    'productTags'?: Array<ProductTagProductBundleAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleProductAdminCreateModel>}
     * @memberof AdminProductBundlesGetRequest
     */
    'products'?: Array<ProductBundleProductAdminCreateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductBundleCategoryAdminCreateModel>}
     * @memberof AdminProductBundlesGetRequest
     */
    'categories': Array<ProductBundleCategoryAdminCreateModel>;
}
/**
 * 
 * @export
 * @interface AdminProductBundlesIdDeleteRequest
 */
export interface AdminProductBundlesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBundleImageAdminUpdateModel>}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'thumbnailImages'?: Array<ProductBundleImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleImageAdminUpdateModel>}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'galleryImages'?: Array<ProductBundleImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductBundleAdminUpdateModel>}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'relatedProducts'?: Array<RelatedProductBundleAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleProductAdminUpdateModel>}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'products'?: Array<ProductBundleProductAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductBundleAdminUpdateModel>}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'productTags'?: Array<ProductTagProductBundleAdminUpdateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductBundleCategoryAdminUpdateModel>}
     * @memberof AdminProductBundlesIdDeleteRequest
     */
    'categories': Array<ProductBundleCategoryAdminUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminProductCategoryMenuGroupsGetRequest
 */
export interface AdminProductCategoryMenuGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductCategoryMenuGroupsGetRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminProductCategoryMenuGroupsIdDeleteRequest
 */
export interface AdminProductCategoryMenuGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductCategoryMenuGroupsIdDeleteRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminProductProductionSpeedsGetRequest
 */
export interface AdminProductProductionSpeedsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductProductionSpeedsGetRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductProductionSpeedsGetRequest
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface AdminProductProductionSpeedsIdDeleteRequest
 */
export interface AdminProductProductionSpeedsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductProductionSpeedsIdDeleteRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductProductionSpeedsIdDeleteRequest
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface AdminProductTagsGetRequest
 */
export interface AdminProductTagsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductTagsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductTagsGetRequest
     */
    'parentProductTagId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminProductTagsIdDeleteRequest
 */
export interface AdminProductTagsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductTagsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductTagsIdDeleteRequest
     */
    'parentProductTagId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminProductionSpeedAdjustmentsGetRequest
 */
export interface AdminProductionSpeedAdjustmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductionSpeedAdjustmentsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductionSpeedAdjustmentsGetRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductionSpeedAdjustmentsGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductionSpeedAdjustmentsGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedAdjustmentsGetRequest
     */
    'deliveryDelay': number;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedAdjustmentsGetRequest
     */
    'productionDelay': number;
}
/**
 * 
 * @export
 * @interface AdminProductionSpeedsGetRequest
 */
export interface AdminProductionSpeedsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductionSpeedsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedsGetRequest
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedsGetRequest
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductionSpeedsGetRequest
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedsGetRequest
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof AdminProductionSpeedsGetRequest
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface AdminProductionSpeedsIdDeleteRequest
 */
export interface AdminProductionSpeedsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductionSpeedsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedsIdDeleteRequest
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedsIdDeleteRequest
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductionSpeedsIdDeleteRequest
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminProductionSpeedsIdDeleteRequest
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof AdminProductionSpeedsIdDeleteRequest
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface AdminProductsGetRequest
 */
export interface AdminProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductsGetRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductsGetRequest
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsGetRequest
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof AdminProductsGetRequest
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminProductsGetRequest
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {Array<ProductImageAdminCreateModel>}
     * @memberof AdminProductsGetRequest
     */
    'thumbnailImages'?: Array<ProductImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductImageAdminCreateModel>}
     * @memberof AdminProductsGetRequest
     */
    'galleryImages'?: Array<ProductImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductAdminCreateModel>}
     * @memberof AdminProductsGetRequest
     */
    'relatedProducts'?: Array<RelatedProductAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductAdminCreateModel>}
     * @memberof AdminProductsGetRequest
     */
    'productTags'?: Array<ProductTagProductAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductStyleGroupAdminCreateModel>}
     * @memberof AdminProductsGetRequest
     */
    'styleGroups'?: Array<ProductStyleGroupAdminCreateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductCategoryAdminCreateModel>}
     * @memberof AdminProductsGetRequest
     */
    'categories': Array<ProductCategoryAdminCreateModel>;
}
/**
 * 
 * @export
 * @interface AdminProductsIdDeleteRequest
 */
export interface AdminProductsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductsIdDeleteRequest
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductsIdDeleteRequest
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminProductsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminProductsIdDeleteRequest
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof AdminProductsIdDeleteRequest
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminProductsIdDeleteRequest
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {Array<ProductImageAdminUpdateModel>}
     * @memberof AdminProductsIdDeleteRequest
     */
    'thumbnailImages'?: Array<ProductImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductImageAdminUpdateModel>}
     * @memberof AdminProductsIdDeleteRequest
     */
    'galleryImages'?: Array<ProductImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductAdminUpdateModel>}
     * @memberof AdminProductsIdDeleteRequest
     */
    'relatedProducts'?: Array<RelatedProductAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductAdminUpdateModel>}
     * @memberof AdminProductsIdDeleteRequest
     */
    'productTags'?: Array<ProductTagProductAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductStyleGroupAdminUpdateModel>}
     * @memberof AdminProductsIdDeleteRequest
     */
    'styleGroups'?: Array<ProductStyleGroupAdminUpdateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductCategoryAdminUpdateModel>}
     * @memberof AdminProductsIdDeleteRequest
     */
    'categories': Array<ProductCategoryAdminUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminSizeGroupAttributeSizeValuesGetRequest
 */
export interface AdminSizeGroupAttributeSizeValuesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributeSizeValuesGetRequest
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributeSizeValuesGetRequest
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributeSizeValuesGetRequest
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>}
     * @memberof AdminSizeGroupAttributeSizeValuesGetRequest
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminSizeGroupAttributeSizeValuesIdDeleteRequest
 */
export interface AdminSizeGroupAttributeSizeValuesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributeSizeValuesIdDeleteRequest
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributeSizeValuesIdDeleteRequest
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributeSizeValuesIdDeleteRequest
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>}
     * @memberof AdminSizeGroupAttributeSizeValuesIdDeleteRequest
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminSizeGroupAttributesGetRequest
 */
export interface AdminSizeGroupAttributesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributesGetRequest
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface AdminSizeGroupAttributesIdDeleteRequest
 */
export interface AdminSizeGroupAttributesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupAttributesIdDeleteRequest
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface AdminSizeGroupsGetRequest
 */
export interface AdminSizeGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupsGetRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupsGetRequest
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminSizeGroupsIdDeleteRequest
 */
export interface AdminSizeGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupsIdDeleteRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizeGroupsIdDeleteRequest
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminSizesGetRequest
 */
export interface AdminSizesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizesGetRequest
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface AdminSizesIdDeleteRequest
 */
export interface AdminSizesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSizesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSizesIdDeleteRequest
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface AdminStoresIdDeleteRequest
 */
export interface AdminStoresIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'ownerAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'orderManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'salesManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof AdminStoresIdDeleteRequest
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreStatus}
     * @memberof AdminStoresIdDeleteRequest
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStoresIdDeleteRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminStyleAdjustmentTypesGetRequest
 */
export interface AdminStyleAdjustmentTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStyleAdjustmentTypesGetRequest
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStyleAdjustmentTypesGetRequest
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStyleAdjustmentTypesGetRequest
     */
    'adjustmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStyleAdjustmentTypesIdDeleteRequest
 */
export interface AdminStyleAdjustmentTypesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStyleAdjustmentTypesIdDeleteRequest
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStyleAdjustmentTypesIdDeleteRequest
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStyleAdjustmentTypesIdDeleteRequest
     */
    'adjustmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStyleGroupsGetRequest
 */
export interface AdminStyleGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStyleGroupsGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStyleGroupsGetRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminStyleGroupsGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStyleGroupsGetRequest
     */
    'styleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStyleGroupsIdDeleteRequest
 */
export interface AdminStyleGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStyleGroupsIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStyleGroupsIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminStyleGroupsIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStyleGroupsIdDeleteRequest
     */
    'styleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStyleSizeGroupsGetRequest
 */
export interface AdminStyleSizeGroupsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStyleSizeGroupsGetRequest
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStyleSizeGroupsGetRequest
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStyleSizeGroupsGetRequest
     */
    'sizeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStyleSizeGroupsIdDeleteRequest
 */
export interface AdminStyleSizeGroupsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStyleSizeGroupsIdDeleteRequest
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStyleSizeGroupsIdDeleteRequest
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStyleSizeGroupsIdDeleteRequest
     */
    'sizeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStylesGetRequest
 */
export interface AdminStylesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminStylesGetRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesGetRequest
     */
    'factoryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStylesGetRequest
     */
    'designGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStylesGetRequest
     */
    'extraGroupIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminStylesIdDeleteRequest
 */
export interface AdminStylesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminStylesIdDeleteRequest
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof AdminStylesIdDeleteRequest
     */
    'factoryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStylesIdDeleteRequest
     */
    'designGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminStylesIdDeleteRequest
     */
    'extraGroupIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AdminTaskMessagesGetRequest
 */
export interface AdminTaskMessagesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTaskMessagesGetRequest
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskMessagesGetRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface AdminTaskMessagesIdDeleteRequest
 */
export interface AdminTaskMessagesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTaskMessagesIdDeleteRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface AdminTasksGetRequest
 */
export interface AdminTasksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTasksGetRequest
     */
    'title': string;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof AdminTasksGetRequest
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof AdminTasksGetRequest
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminTasksGetRequest
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminTasksGetRequest
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminTasksGetRequest
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminCreateUpdateModel>}
     * @memberof AdminTasksGetRequest
     */
    'taskRecipients': Array<TaskRecipientAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminTasksIdDeleteRequest
 */
export interface AdminTasksIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTasksIdDeleteRequest
     */
    'title': string;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof AdminTasksIdDeleteRequest
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof AdminTasksIdDeleteRequest
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminTasksIdDeleteRequest
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminCreateUpdateModel>}
     * @memberof AdminTasksIdDeleteRequest
     */
    'taskRecipients': Array<TaskRecipientAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface AdminUsersGetRequest
 */
export interface AdminUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUsersGetRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUsersGetRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUsersGetRequest
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUsersGetRequest
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersGetRequest
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface AdminUsersIdGetRequest
 */
export interface AdminUsersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUsersIdGetRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUsersIdGetRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminUsersIdGetRequest
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUsersIdGetRequest
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUsersIdGetRequest
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface AdminVouchersGetRequest
 */
export interface AdminVouchersGetRequest {
    /**
     * 
     * @type {VoucherType}
     * @memberof AdminVouchersGetRequest
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof AdminVouchersGetRequest
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersGetRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersGetRequest
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersGetRequest
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVouchersGetRequest
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVouchersGetRequest
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersGetRequest
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVouchersGetRequest
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVouchersGetRequest
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVouchersGetRequest
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVouchersGetRequest
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVouchersGetRequest
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface AdminVouchersIdDeleteRequest
 */
export interface AdminVouchersIdDeleteRequest {
    /**
     * 
     * @type {VoucherType}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdminVouchersIdDeleteRequest
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ApplicationError
 */
export interface ApplicationError {
    /**
     * 
     * @type {string}
     * @memberof ApplicationError
     */
    'friendlyMessage': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationError
     */
    'propertyName': string;
}
/**
 * 
 * @export
 * @interface ApplicationResult
 */
export interface ApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationResultFailureType = {
    None: 'None',
    Authorization: 'Authorization',
    Validation: 'Validation',
    ExpectedException: 'ExpectedException',
    UnexpectedException: 'UnexpectedException',
    NotFound: 'NotFound'
} as const;

export type ApplicationResultFailureType = typeof ApplicationResultFailureType[keyof typeof ApplicationResultFailureType];


/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminCreateModel
 */
export interface ArtAssetCategoryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminCreateModel
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminListViewModel
 */
export interface ArtAssetCategoryAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminListViewModel
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminListViewModelPaginatedList
 */
export interface ArtAssetCategoryAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ArtAssetCategoryAdminListViewModel>}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'items': Array<ArtAssetCategoryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult
 */
export interface ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ArtAssetCategoryAdminListViewModel>}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ArtAssetCategoryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminUpdateModel
 */
export interface ArtAssetCategoryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminUpdateModel
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminViewModel
 */
export interface ArtAssetCategoryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModel
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminViewModelApplicationResult
 */
export interface ArtAssetCategoryAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ArtAssetCategoryAdminViewModelApplicationResultResultData}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResult
     */
    'resultData'?: ArtAssetCategoryAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ArtAssetCategoryAdminViewModelApplicationResultResultData
 */
export interface ArtAssetCategoryAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetCategoryAdminViewModelApplicationResultResultData
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminCreateModel
 */
export interface ArtAssetsAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminCreateModel
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtAssetsAdminCreateModel
     */
    'categoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminListViewModel
 */
export interface ArtAssetsAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminListViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtAssetsAdminListViewModel
     */
    'categoryNames': Array<string>;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminListViewModelPaginatedList
 */
export interface ArtAssetsAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ArtAssetsAdminListViewModel>}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'items': Array<ArtAssetsAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminListViewModelPaginatedListApplicationResult
 */
export interface ArtAssetsAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ArtAssetsAdminListViewModel>}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ArtAssetsAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ArtAssetsAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminUpdateModel
 */
export interface ArtAssetsAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminUpdateModel
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtAssetsAdminUpdateModel
     */
    'categoryIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminViewModel
 */
export interface ArtAssetsAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModel
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtAssetsAdminViewModel
     */
    'categoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminViewModelApplicationResult
 */
export interface ArtAssetsAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ArtAssetsAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ArtAssetsAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ArtAssetsAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ArtAssetsAdminViewModelApplicationResultResultData}
     * @memberof ArtAssetsAdminViewModelApplicationResult
     */
    'resultData'?: ArtAssetsAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ArtAssetsAdminViewModelApplicationResultResultData
 */
export interface ArtAssetsAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ArtAssetsAdminViewModelApplicationResultResultData
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtAssetsAdminViewModelApplicationResultResultData
     */
    'categoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AuthenticationResult
 */
export interface AuthenticationResult {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResult
     */
    'encodedToken': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResult
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResult
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResult
     */
    'refreshTokenExpiration': string;
}
/**
 * 
 * @export
 * @interface AuthenticationResultApplicationResult
 */
export interface AuthenticationResultApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AuthenticationResultApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AuthenticationResultApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationResultApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {AuthenticationResultApplicationResultResultData}
     * @memberof AuthenticationResultApplicationResult
     */
    'resultData'?: AuthenticationResultApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface AuthenticationResultApplicationResultResultData
 */
export interface AuthenticationResultApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResultApplicationResultResultData
     */
    'encodedToken': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResultApplicationResultResultData
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResultApplicationResultResultData
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResultApplicationResultResultData
     */
    'refreshTokenExpiration': string;
}
/**
 * 
 * @export
 * @interface AvailableOrderStatusViewModel
 */
export interface AvailableOrderStatusViewModel {
    /**
     * 
     * @type {OrderStatus}
     * @memberof AvailableOrderStatusViewModel
     */
    'id': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof AvailableOrderStatusViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableOrderStatusViewModel
     */
    'isPreviousStatus': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableOrderStatusViewModel
     */
    'isNextStatus': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableOrderStatusViewModel
     */
    'isCurrentStatus': boolean;
}
/**
 * 
 * @export
 * @interface AvailableOrderStatusViewModelListApplicationResult
 */
export interface AvailableOrderStatusViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof AvailableOrderStatusViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof AvailableOrderStatusViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableOrderStatusViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<AvailableOrderStatusViewModel>}
     * @memberof AvailableOrderStatusViewModelListApplicationResult
     */
    'resultData'?: Array<AvailableOrderStatusViewModel> | null;
}
/**
 * 
 * @export
 * @interface BannerItemAdminCreateModel
 */
export interface BannerItemAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminCreateModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface BannerItemAdminListViewModel
 */
export interface BannerItemAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'buttonLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminListViewModel
     */
    'backgroundImageUrl': string;
}
/**
 * 
 * @export
 * @interface BannerItemAdminListViewModelPaginatedList
 */
export interface BannerItemAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BannerItemAdminListViewModel>}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'items': Array<BannerItemAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BannerItemAdminListViewModelPaginatedListApplicationResult
 */
export interface BannerItemAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BannerItemAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: BannerItemAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BannerItemAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface BannerItemAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BannerItemAdminListViewModel>}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<BannerItemAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BannerItemAdminUpdateModel
 */
export interface BannerItemAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminUpdateModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface BannerItemAdminViewModel
 */
export interface BannerItemAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface BannerItemAdminViewModelApplicationResult
 */
export interface BannerItemAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BannerItemAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BannerItemAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BannerItemAdminViewModelApplicationResultResultData}
     * @memberof BannerItemAdminViewModelApplicationResult
     */
    'resultData'?: BannerItemAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BannerItemAdminViewModelApplicationResultResultData
 */
export interface BannerItemAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemAdminViewModelApplicationResultResultData
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface BannerItemListViewModel
 */
export interface BannerItemListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerItemListViewModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface BannerItemListViewModelListApplicationResult
 */
export interface BannerItemListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BannerItemListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BannerItemListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BannerItemListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<BannerItemListViewModel>}
     * @memberof BannerItemListViewModelListApplicationResult
     */
    'resultData'?: Array<BannerItemListViewModel> | null;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminCreateModel
 */
export interface BannerMessageAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminCreateModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminCreateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminCreateModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminCreateModel
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminCreateModel
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminCreateModel
     */
    'showOnQuotes': boolean;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminListViewModel
 */
export interface BannerMessageAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModel
     */
    'showOnQuotes': boolean;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminListViewModel
     */
    'countryName': string;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminListViewModelListApplicationResult
 */
export interface BannerMessageAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BannerMessageAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BannerMessageAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<BannerMessageAdminListViewModel>}
     * @memberof BannerMessageAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<BannerMessageAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminListViewModelPaginatedList
 */
export interface BannerMessageAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BannerMessageAdminListViewModel>}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'items': Array<BannerMessageAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminListViewModelPaginatedListApplicationResult
 */
export interface BannerMessageAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BannerMessageAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: BannerMessageAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface BannerMessageAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BannerMessageAdminListViewModel>}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<BannerMessageAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BannerMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminUpdateModel
 */
export interface BannerMessageAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminUpdateModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminUpdateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminUpdateModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminUpdateModel
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminUpdateModel
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminUpdateModel
     */
    'showOnQuotes': boolean;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminViewModel
 */
export interface BannerMessageAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModel
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModel
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminViewModel
     */
    'showOnQuotes': boolean;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminViewModelApplicationResult
 */
export interface BannerMessageAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BannerMessageAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BannerMessageAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BannerMessageAdminViewModelApplicationResultResultData}
     * @memberof BannerMessageAdminViewModelApplicationResult
     */
    'resultData'?: BannerMessageAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BannerMessageAdminViewModelApplicationResultResultData
 */
export interface BannerMessageAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof BannerMessageAdminViewModelApplicationResultResultData
     */
    'showOnQuotes': boolean;
}
/**
 * 
 * @export
 * @interface BlogAdminCreateModel
 */
export interface BlogAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminCreateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BlogAdminCreateModel
     */
    'categoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminCreateModel
     */
    'publishedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface BlogAdminListViewModel
 */
export interface BlogAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'authorName': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminListViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminListViewModel
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BlogAdminListViewModel
     */
    'categories': Array<string>;
}
/**
 * 
 * @export
 * @interface BlogAdminListViewModelPaginatedList
 */
export interface BlogAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BlogAdminListViewModel>}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'items': Array<BlogAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BlogAdminListViewModelPaginatedListApplicationResult
 */
export interface BlogAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BlogAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: BlogAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BlogAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface BlogAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BlogAdminListViewModel>}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<BlogAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BlogAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BlogAdminUpdateModel
 */
export interface BlogAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminUpdateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminUpdateModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BlogAdminUpdateModel
     */
    'categoryIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BlogAdminViewModel
 */
export interface BlogAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BlogAdminViewModel
     */
    'categoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BlogAdminViewModelApplicationResult
 */
export interface BlogAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BlogAdminViewModelApplicationResultResultData}
     * @memberof BlogAdminViewModelApplicationResult
     */
    'resultData'?: BlogAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BlogAdminViewModelApplicationResultResultData
 */
export interface BlogAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BlogAdminViewModelApplicationResultResultData
     */
    'categoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminCreateModel
 */
export interface BlogCategoryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminCreateModel
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminListViewModel
 */
export interface BlogCategoryAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminListViewModel
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminListViewModelPaginatedList
 */
export interface BlogCategoryAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BlogCategoryAdminListViewModel>}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'items': Array<BlogCategoryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminListViewModelPaginatedListApplicationResult
 */
export interface BlogCategoryAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BlogCategoryAdminListViewModel>}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<BlogCategoryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BlogCategoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminUpdateModel
 */
export interface BlogCategoryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminUpdateModel
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminViewModel
 */
export interface BlogCategoryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminViewModel
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminViewModelApplicationResult
 */
export interface BlogCategoryAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogCategoryAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogCategoryAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BlogCategoryAdminViewModelApplicationResultResultData}
     * @memberof BlogCategoryAdminViewModelApplicationResult
     */
    'resultData'?: BlogCategoryAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BlogCategoryAdminViewModelApplicationResultResultData
 */
export interface BlogCategoryAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryAdminViewModelApplicationResultResultData
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface BlogCategoryListViewModel
 */
export interface BlogCategoryListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogCategoryListViewModel
     */
    'hexValue': string;
}
/**
 * 
 * @export
 * @interface BlogCategoryListViewModelListApplicationResult
 */
export interface BlogCategoryListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogCategoryListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogCategoryListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogCategoryListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<BlogCategoryListViewModel>}
     * @memberof BlogCategoryListViewModelListApplicationResult
     */
    'resultData'?: Array<BlogCategoryListViewModel> | null;
}
/**
 * 
 * @export
 * @interface BlogListViewModel
 */
export interface BlogListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogListViewModel
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlogListViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogListViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {Array<BlogCategoryListViewModel>}
     * @memberof BlogListViewModel
     */
    'categories': Array<BlogCategoryListViewModel>;
}
/**
 * 
 * @export
 * @interface BlogListViewModelPaginatedList
 */
export interface BlogListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof BlogListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlogListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BlogListViewModel>}
     * @memberof BlogListViewModelPaginatedList
     */
    'items': Array<BlogListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BlogListViewModelPaginatedListApplicationResult
 */
export interface BlogListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BlogListViewModelPaginatedListApplicationResultResultData}
     * @memberof BlogListViewModelPaginatedListApplicationResult
     */
    'resultData'?: BlogListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BlogListViewModelPaginatedListApplicationResultResultData
 */
export interface BlogListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<BlogListViewModel>}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<BlogListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof BlogListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface BlogViewModel
 */
export interface BlogViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlogViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModel
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {Array<BlogCategoryListViewModel>}
     * @memberof BlogViewModel
     */
    'categories': Array<BlogCategoryListViewModel>;
}
/**
 * 
 * @export
 * @interface BlogViewModelApplicationResult
 */
export interface BlogViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof BlogViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof BlogViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof BlogViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {BlogViewModelApplicationResultResultData}
     * @memberof BlogViewModelApplicationResult
     */
    'resultData'?: BlogViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface BlogViewModelApplicationResultResultData
 */
export interface BlogViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'headerImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'authorImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {Array<BlogCategoryListViewModel>}
     * @memberof BlogViewModelApplicationResultResultData
     */
    'categories': Array<BlogCategoryListViewModel>;
}
/**
 * 
 * @export
 * @interface CampaignAdminCreateModel
 */
export interface CampaignAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminCreateModel
     */
    'bannerImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignAdminListViewModel
 */
export interface CampaignAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminListViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminListViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminListViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModel
     */
    'customizedBannerItems': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModel
     */
    'customizedClients': boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModel
     */
    'enquiriesCount': number;
}
/**
 * 
 * @export
 * @interface CampaignAdminListViewModelPaginatedList
 */
export interface CampaignAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CampaignAdminListViewModel>}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'items': Array<CampaignAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CampaignAdminListViewModelPaginatedListApplicationResult
 */
export interface CampaignAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CampaignAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CampaignAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CampaignAdminListViewModel>}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CampaignAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CampaignAdminUpdateModel
 */
export interface CampaignAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminUpdateModel
     */
    'customizedBannerItems': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminUpdateModel
     */
    'customizedClients': boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminUpdateModel
     */
    'bannerImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignAdminViewModel
 */
export interface CampaignAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminViewModel
     */
    'customizedBannerItems': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminViewModel
     */
    'customizedClients': boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModel
     */
    'bannerImageUrl'?: string | null;
    /**
     * 
     * @type {Array<CampaignEnquiriesListViewModel>}
     * @memberof CampaignAdminViewModel
     */
    'enquiries': Array<CampaignEnquiriesListViewModel>;
}
/**
 * 
 * @export
 * @interface CampaignAdminViewModelApplicationResult
 */
export interface CampaignAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignAdminViewModelApplicationResultResultData}
     * @memberof CampaignAdminViewModelApplicationResult
     */
    'resultData'?: CampaignAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignAdminViewModelApplicationResultResultData
 */
export interface CampaignAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'endDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'customizedBannerItems': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'customizedClients': boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'bannerImageUrl'?: string | null;
    /**
     * 
     * @type {Array<CampaignEnquiriesListViewModel>}
     * @memberof CampaignAdminViewModelApplicationResultResultData
     */
    'enquiries': Array<CampaignEnquiriesListViewModel>;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminCreateModel
 */
export interface CampaignBannerItemAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'campaignId': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminCreateModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminListViewModel
 */
export interface CampaignBannerItemAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'buttonLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminListViewModel
     */
    'backgroundImageUrl': string;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminListViewModelPaginatedList
 */
export interface CampaignBannerItemAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CampaignBannerItemAdminListViewModel>}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'items': Array<CampaignBannerItemAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminListViewModelPaginatedListApplicationResult
 */
export interface CampaignBannerItemAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CampaignBannerItemAdminListViewModel>}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CampaignBannerItemAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignBannerItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminUpdateModel
 */
export interface CampaignBannerItemAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminUpdateModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminViewModel
 */
export interface CampaignBannerItemAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'campaignId': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminViewModelApplicationResult
 */
export interface CampaignBannerItemAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignBannerItemAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignBannerItemAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBannerItemAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignBannerItemAdminViewModelApplicationResultResultData}
     * @memberof CampaignBannerItemAdminViewModelApplicationResult
     */
    'resultData'?: CampaignBannerItemAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemAdminViewModelApplicationResultResultData
 */
export interface CampaignBannerItemAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'campaignId': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemAdminViewModelApplicationResultResultData
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignBannerItemListViewModel
 */
export interface CampaignBannerItemListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'backgroundImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'boxBackgrounColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'boxTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'boxDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'boxPositionClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'boxTextAlignmentClass': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'boxTextColour': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignBannerItemListViewModel
     */
    'buttonLink'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminCreateModel
 */
export interface CampaignClientAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminCreateModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminCreateModel
     */
    'campaignId': string;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminListViewModel
 */
export interface CampaignClientAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminListViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminListViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminListViewModelPaginatedList
 */
export interface CampaignClientAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CampaignClientAdminListViewModel>}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'items': Array<CampaignClientAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminListViewModelPaginatedListApplicationResult
 */
export interface CampaignClientAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignClientAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CampaignClientAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CampaignClientAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CampaignClientAdminListViewModel>}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CampaignClientAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminUpdateModel
 */
export interface CampaignClientAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminUpdateModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminViewModel
 */
export interface CampaignClientAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModel
     */
    'campaignId': string;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminViewModelApplicationResult
 */
export interface CampaignClientAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignClientAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignClientAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignClientAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignClientAdminViewModelApplicationResultResultData}
     * @memberof CampaignClientAdminViewModelApplicationResult
     */
    'resultData'?: CampaignClientAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignClientAdminViewModelApplicationResultResultData
 */
export interface CampaignClientAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModelApplicationResultResultData
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientAdminViewModelApplicationResultResultData
     */
    'campaignId': string;
}
/**
 * 
 * @export
 * @interface CampaignClientViewModel
 */
export interface CampaignClientViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignClientViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignClientViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CampaignEnquiriesListViewModel
 */
export interface CampaignEnquiriesListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignEnquiriesListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignEnquiriesListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignEnquiriesListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof CampaignEnquiriesListViewModel
     */
    'enquiryStatusId': EnquiryStatus;
    /**
     * 
     * @type {number}
     * @memberof CampaignEnquiriesListViewModel
     */
    'qualityScore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignEnquiriesListViewModel
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignEnquiriesListViewModel
     */
    'preparingQuotesTotal': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignEnquiriesListViewModel
     */
    'providedQuotesTotal': number;
    /**
     * 
     * @type {number}
     * @memberof CampaignEnquiriesListViewModel
     */
    'lostQuotesTotal': number;
}
/**
 * 
 * @export
 * @interface CampaignViewModel
 */
export interface CampaignViewModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModel
     */
    'bannerImageUrl'?: string | null;
    /**
     * 
     * @type {Array<CampaignBannerItemListViewModel>}
     * @memberof CampaignViewModel
     */
    'campaignBannerItems': Array<CampaignBannerItemListViewModel>;
    /**
     * 
     * @type {Array<CampaignClientViewModel>}
     * @memberof CampaignViewModel
     */
    'campaignClients': Array<CampaignClientViewModel>;
}
/**
 * 
 * @export
 * @interface CampaignViewModelApplicationResult
 */
export interface CampaignViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CampaignViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CampaignViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CampaignViewModelApplicationResultResultData}
     * @memberof CampaignViewModelApplicationResult
     */
    'resultData'?: CampaignViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CampaignViewModelApplicationResultResultData
 */
export interface CampaignViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'customerBannerMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'quoteTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'bannerImageUrl'?: string | null;
    /**
     * 
     * @type {Array<CampaignBannerItemListViewModel>}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'campaignBannerItems': Array<CampaignBannerItemListViewModel>;
    /**
     * 
     * @type {Array<CampaignClientViewModel>}
     * @memberof CampaignViewModelApplicationResultResultData
     */
    'campaignClients': Array<CampaignClientViewModel>;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminCreateModel
 */
export interface CarouselItemAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminCreateModel
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminListViewModel
 */
export interface CarouselItemAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminListViewModel
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminListViewModelPaginatedList
 */
export interface CarouselItemAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CarouselItemAdminListViewModel>}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'items': Array<CarouselItemAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminListViewModelPaginatedListApplicationResult
 */
export interface CarouselItemAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CarouselItemAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CarouselItemAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CarouselItemAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CarouselItemAdminListViewModel>}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CarouselItemAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CarouselItemAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminUpdateModel
 */
export interface CarouselItemAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminUpdateModel
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminViewModel
 */
export interface CarouselItemAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModel
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminViewModelApplicationResult
 */
export interface CarouselItemAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CarouselItemAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CarouselItemAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselItemAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CarouselItemAdminViewModelApplicationResultResultData}
     * @memberof CarouselItemAdminViewModelApplicationResult
     */
    'resultData'?: CarouselItemAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CarouselItemAdminViewModelApplicationResultResultData
 */
export interface CarouselItemAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'titleFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'contentFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'buttonUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'buttonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'buttonFontColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'buttonBackgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'backgroundColour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'featuredImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarouselItemAdminViewModelApplicationResultResultData
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface CartItemsCreateCartItemPostRequest
 */
export interface CartItemsCreateCartItemPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CartItemsCreateCartItemPostRequest
     */
    'customizedProductId': string;
    /**
     * 
     * @type {string}
     * @memberof CartItemsCreateCartItemPostRequest
     */
    'orderId': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof CartItemsCreateCartItemPostRequest
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof CartItemsCreateCartItemPostRequest
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface CartItemsCreateStoreCartItemCartIdPostRequest
 */
export interface CartItemsCreateStoreCartItemCartIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CartItemsCreateStoreCartItemCartIdPostRequest
     */
    'storeCustomizedProductId': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof CartItemsCreateStoreCartItemCartIdPostRequest
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof CartItemsCreateStoreCartItemCartIdPostRequest
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface CartItemsUpdateCartItemIdPutRequest
 */
export interface CartItemsUpdateCartItemIdPutRequest {
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof CartItemsUpdateCartItemIdPutRequest
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof CartItemsUpdateCartItemIdPutRequest
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest
 */
export interface CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest {
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface CartsCreateCartPostRequest
 */
export interface CartsCreateCartPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CartsCreateCartPostRequest
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CartsCreateCartPostRequest
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {boolean}
     * @memberof CartsCreateCartPostRequest
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface CartsCreateStoreCartPostRequest
 */
export interface CartsCreateStoreCartPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CartsCreateStoreCartPostRequest
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof CartsCreateStoreCartPostRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CartsCreateStoreCartPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CartsCreateStoreCartPostRequest
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface CartsUpdateCartIdPutRequest
 */
export interface CartsUpdateCartIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {boolean}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderCartUpdateModelDeliveryAddress}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'deliveryAddress'?: OrderCartUpdateModelDeliveryAddress | null;
    /**
     * 
     * @type {OrderCartUpdateModelDeliveryAddress}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'billingAddress'?: OrderCartUpdateModelDeliveryAddress | null;
    /**
     * 
     * @type {OrderCartUpdateModelCustomerContact}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'customerContact'?: OrderCartUpdateModelCustomerContact | null;
    /**
     * 
     * @type {Array<OrderDocumentCreateUpdateModel>}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'orderDocuments': Array<OrderDocumentCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {boolean}
     * @memberof CartsUpdateCartIdPutRequest
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface CartsUpdateStoreCartIdPutRequest
 */
export interface CartsUpdateStoreCartIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CartsUpdateStoreCartIdPutRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CartsUpdateStoreCartIdPutRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CartsUpdateStoreCartIdPutRequest
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface CategoryAdminCreateModel
 */
export interface CategoryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminCreateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'parentCategoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminCreateModel
     */
    'productCategoryMenuGroupId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminCreateModel
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CategoryImageAdminCreateModel>}
     * @memberof CategoryAdminCreateModel
     */
    'thumbnailImages'?: Array<CategoryImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<CategoryImageAdminCreateModel>}
     * @memberof CategoryAdminCreateModel
     */
    'bannerImages'?: Array<CategoryImageAdminCreateModel> | null;
}
/**
 * 
 * @export
 * @interface CategoryAdminListViewModel
 */
export interface CategoryAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminListViewModel
     */
    'hasProducts': boolean;
    /**
     * 
     * @type {Array<CategoryAdminListViewModel>}
     * @memberof CategoryAdminListViewModel
     */
    'childCategories': Array<CategoryAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface CategoryAdminListViewModelListApplicationResult
 */
export interface CategoryAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CategoryAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CategoryAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<CategoryAdminListViewModel>}
     * @memberof CategoryAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<CategoryAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface CategoryAdminUpdateModel
 */
export interface CategoryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminUpdateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'parentCategoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminUpdateModel
     */
    'productCategoryMenuGroupId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminUpdateModel
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CategoryImageAdminUpdateModel>}
     * @memberof CategoryAdminUpdateModel
     */
    'thumbnailImages'?: Array<CategoryImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<CategoryImageAdminUpdateModel>}
     * @memberof CategoryAdminUpdateModel
     */
    'bannerImages'?: Array<CategoryImageAdminUpdateModel> | null;
}
/**
 * 
 * @export
 * @interface CategoryAdminViewModel
 */
export interface CategoryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'parentCategoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModel
     */
    'productCategoryMenuGroupId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModel
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryAdminViewModel
     */
    'thumbnailImages': Array<CategoryImageViewModel>;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryAdminViewModel
     */
    'bannerImages': Array<CategoryImageViewModel>;
}
/**
 * 
 * @export
 * @interface CategoryAdminViewModelApplicationResult
 */
export interface CategoryAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CategoryAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CategoryAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CategoryAdminViewModelApplicationResultResultData}
     * @memberof CategoryAdminViewModelApplicationResult
     */
    'resultData'?: CategoryAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CategoryAdminViewModelApplicationResultResultData
 */
export interface CategoryAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'parentCategoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'productCategoryMenuGroupId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'thumbnailImages': Array<CategoryImageViewModel>;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryAdminViewModelApplicationResultResultData
     */
    'bannerImages': Array<CategoryImageViewModel>;
}
/**
 * 
 * @export
 * @interface CategoryImageAdminCreateModel
 */
export interface CategoryImageAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryImageAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CategoryImageAdminCreateModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CategoryImageAdminUpdateModel
 */
export interface CategoryImageAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryImageAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CategoryImageAdminUpdateModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CategoryImageAdminUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface CategoryImageViewModel
 */
export interface CategoryImageViewModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryImageViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryImageViewModel
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface CategoryListViewModel
 */
export interface CategoryListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryListViewModel
     */
    'parentSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryListViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryListViewModel
     */
    'fullSlug': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModel
     */
    'hasProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryListViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryListViewModel
     */
    'thumbnailImages': Array<CategoryImageViewModel>;
}
/**
 * 
 * @export
 * @interface CategoryListViewModelPaginatedList
 */
export interface CategoryListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CategoryListViewModel>}
     * @memberof CategoryListViewModelPaginatedList
     */
    'items': Array<CategoryListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CategoryListViewModelPaginatedListApplicationResult
 */
export interface CategoryListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CategoryListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CategoryListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CategoryListViewModelPaginatedListApplicationResultResultData}
     * @memberof CategoryListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CategoryListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CategoryListViewModelPaginatedListApplicationResultResultData
 */
export interface CategoryListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CategoryListViewModel>}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CategoryListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CategoryListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CategoryViewModel
 */
export interface CategoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'parentSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'fullSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModel
     */
    'hasProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryViewModel
     */
    'thumbnailImages': Array<CategoryImageViewModel>;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryViewModel
     */
    'bannerImages': Array<CategoryImageViewModel>;
    /**
     * 
     * @type {Array<ParentCategoryViewModel>}
     * @memberof CategoryViewModel
     */
    'parentCategories': Array<ParentCategoryViewModel>;
}
/**
 * 
 * @export
 * @interface CategoryViewModelApplicationResult
 */
export interface CategoryViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CategoryViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CategoryViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CategoryViewModelApplicationResultResultData}
     * @memberof CategoryViewModelApplicationResult
     */
    'resultData'?: CategoryViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CategoryViewModelApplicationResultResultData
 */
export interface CategoryViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'parentSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'fullSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'seoTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'seoKeywords': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'seoDescription': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'hasProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'blurb': string;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'thumbnailImages': Array<CategoryImageViewModel>;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'bannerImages': Array<CategoryImageViewModel>;
    /**
     * 
     * @type {Array<ParentCategoryViewModel>}
     * @memberof CategoryViewModelApplicationResultResultData
     */
    'parentCategories': Array<ParentCategoryViewModel>;
}
/**
 * 
 * @export
 * @interface ClientAdminCreateModel
 */
export interface ClientAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ClientAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminCreateModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientAdminListViewModel
 */
export interface ClientAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ClientAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminListViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminListViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientAdminListViewModelPaginatedList
 */
export interface ClientAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ClientAdminListViewModel>}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'items': Array<ClientAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ClientAdminListViewModelPaginatedListApplicationResult
 */
export interface ClientAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ClientAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ClientAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ClientAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ClientAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ClientAdminListViewModel>}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ClientAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ClientAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ClientAdminUpdateModel
 */
export interface ClientAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ClientAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminUpdateModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientAdminViewModel
 */
export interface ClientAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ClientAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientAdminViewModelApplicationResult
 */
export interface ClientAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ClientAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ClientAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ClientAdminViewModelApplicationResultResultData}
     * @memberof ClientAdminViewModelApplicationResult
     */
    'resultData'?: ClientAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ClientAdminViewModelApplicationResultResultData
 */
export interface ClientAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ClientAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClientAdminViewModelApplicationResultResultData
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientViewModel
 */
export interface ClientViewModel {
    /**
     * 
     * @type {string}
     * @memberof ClientViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClientViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientViewModelListApplicationResult
 */
export interface ClientViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ClientViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ClientViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ClientViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<ClientViewModel>}
     * @memberof ClientViewModelListApplicationResult
     */
    'resultData'?: Array<ClientViewModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CliftonTaskPriority = {
    Standard: 'Standard',
    Urgent: 'Urgent'
} as const;

export type CliftonTaskPriority = typeof CliftonTaskPriority[keyof typeof CliftonTaskPriority];


/**
 * 
 * @export
 * @enum {string}
 */

export const CliftonTaskStatus = {
    Pending: 'Pending',
    Resolved: 'Resolved'
} as const;

export type CliftonTaskStatus = typeof CliftonTaskStatus[keyof typeof CliftonTaskStatus];


/**
 * 
 * @export
 * @interface ColourAdminCreateModel
 */
export interface ColourAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ColourAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminCreateModel
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminCreateModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminCreateModel
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ColourAdminListViewModel
 */
export interface ColourAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ColourAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminListViewModel
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ColourAdminListViewModelListApplicationResult
 */
export interface ColourAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ColourAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ColourAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<ColourAdminListViewModel>}
     * @memberof ColourAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<ColourAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface ColourAdminListViewModelPaginatedList
 */
export interface ColourAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ColourAdminListViewModel>}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'items': Array<ColourAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ColourAdminListViewModelPaginatedListApplicationResult
 */
export interface ColourAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ColourAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ColourAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ColourAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ColourAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ColourAdminListViewModel>}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ColourAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ColourAdminUpdateModel
 */
export interface ColourAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ColourAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminUpdateModel
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminUpdateModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminUpdateModel
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ColourAdminViewModel
 */
export interface ColourAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModel
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModel
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ColourAdminViewModelApplicationResult
 */
export interface ColourAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ColourAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ColourAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ColourAdminViewModelApplicationResultResultData}
     * @memberof ColourAdminViewModelApplicationResult
     */
    'resultData'?: ColourAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ColourAdminViewModelApplicationResultResultData
 */
export interface ColourAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ColourAdminViewModelApplicationResultResultData
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminCreateModel
 */
export interface ColourGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColourGroupAdminCreateModel
     */
    'colourIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminListViewModel
 */
export interface ColourGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColourGroupAdminListViewModel
     */
    'colourIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminListViewModelListApplicationResult
 */
export interface ColourGroupAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ColourGroupAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ColourGroupAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<ColourGroupAdminListViewModel>}
     * @memberof ColourGroupAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<ColourGroupAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminListViewModelPaginatedList
 */
export interface ColourGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ColourGroupAdminListViewModel>}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'items': Array<ColourGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface ColourGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ColourGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ColourGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ColourGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ColourGroupAdminListViewModel>}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ColourGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ColourGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminUpdateModel
 */
export interface ColourGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColourGroupAdminUpdateModel
     */
    'colourIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminViewModel
 */
export interface ColourGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColourGroupAdminViewModel
     */
    'colourIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof ColourGroupAdminViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminViewModelApplicationResult
 */
export interface ColourGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ColourGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ColourGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ColourGroupAdminViewModelApplicationResultResultData}
     * @memberof ColourGroupAdminViewModelApplicationResult
     */
    'resultData'?: ColourGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ColourGroupAdminViewModelApplicationResultResultData
 */
export interface ColourGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ColourGroupAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColourGroupAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColourGroupAdminViewModelApplicationResultResultData
     */
    'colourIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof ColourGroupAdminViewModelApplicationResultResultData
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConstructionType = {
    CutAndSew: 'CutAndSew',
    Sublimation: 'Sublimation'
} as const;

export type ConstructionType = typeof ConstructionType[keyof typeof ConstructionType];


/**
 * 
 * @export
 * @interface CountryAdminCreateModel
 */
export interface CountryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CountryAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminCreateModel
     */
    'currencyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminCreateModel
     */
    'isDefault': boolean;
    /**
     * 
     * @type {ImportDutyType}
     * @memberof CountryAdminCreateModel
     */
    'importDutyTypeId': ImportDutyType;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminCreateModel
     */
    'countryGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryAdminCreateModel
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminCreateModel
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminCreateModel
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryAdminListViewModel
 */
export interface CountryAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModel
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminListViewModel
     */
    'importDutyTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminListViewModel
     */
    'countryGroupName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryAdminListViewModel
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModel
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminListViewModel
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryAdminListViewModelPaginatedList
 */
export interface CountryAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CountryAdminListViewModel>}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'items': Array<CountryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CountryAdminListViewModelPaginatedListApplicationResult
 */
export interface CountryAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CountryAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CountryAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CountryAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CountryAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CountryAdminListViewModel>}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CountryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CountryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CountryAdminUpdateModel
 */
export interface CountryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CountryAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminUpdateModel
     */
    'currencyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminUpdateModel
     */
    'isDefault': boolean;
    /**
     * 
     * @type {ImportDutyType}
     * @memberof CountryAdminUpdateModel
     */
    'importDutyTypeId': ImportDutyType;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminUpdateModel
     */
    'countryGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryAdminUpdateModel
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminUpdateModel
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminUpdateModel
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryAdminViewModel
 */
export interface CountryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModel
     */
    'currencyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminViewModel
     */
    'isDefault': boolean;
    /**
     * 
     * @type {ImportDutyType}
     * @memberof CountryAdminViewModel
     */
    'importDutyTypeId': ImportDutyType;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModel
     */
    'countryGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryAdminViewModel
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminViewModel
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModel
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryAdminViewModelApplicationResult
 */
export interface CountryAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CountryAdminViewModelApplicationResultResultData}
     * @memberof CountryAdminViewModelApplicationResult
     */
    'resultData'?: CountryAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CountryAdminViewModelApplicationResultResultData
 */
export interface CountryAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'currencyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'isDefault': boolean;
    /**
     * 
     * @type {ImportDutyType}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'importDutyTypeId': ImportDutyType;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'countryGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'geolocationCodes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryAdminViewModelApplicationResultResultData
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminCreateModel
 */
export interface CountryDeliveryTypeAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminCreateModel
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CountryDeliveryTypeAdminCreateModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminCreateModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminCreateModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminCreateModel
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminListViewModel
 */
export interface CountryDeliveryTypeAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'deliveryTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModel
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminListViewModelPaginatedList
 */
export interface CountryDeliveryTypeAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CountryDeliveryTypeAdminListViewModel>}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'items': Array<CountryDeliveryTypeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult
 */
export interface CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CountryDeliveryTypeAdminListViewModel>}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CountryDeliveryTypeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminUpdateModel
 */
export interface CountryDeliveryTypeAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminUpdateModel
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CountryDeliveryTypeAdminUpdateModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminUpdateModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminUpdateModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminUpdateModel
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminViewModel
 */
export interface CountryDeliveryTypeAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CountryDeliveryTypeAdminViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminViewModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminViewModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminViewModel
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminViewModelApplicationResult
 */
export interface CountryDeliveryTypeAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CountryDeliveryTypeAdminViewModelApplicationResultResultData}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResult
     */
    'resultData'?: CountryDeliveryTypeAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeAdminViewModelApplicationResultResultData
 */
export interface CountryDeliveryTypeAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResultResultData
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResultResultData
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResultResultData
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeAdminViewModelApplicationResultResultData
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface CountryDeliveryTypeViewModel
 */
export interface CountryDeliveryTypeViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeViewModel
     */
    'id': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof CountryDeliveryTypeViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {string}
     * @memberof CountryDeliveryTypeViewModel
     */
    'deliveryTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeViewModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeViewModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof CountryDeliveryTypeViewModel
     */
    'deliveryDays': number;
}
/**
 * 
 * @export
 * @interface CountryGroupListViewModel
 */
export interface CountryGroupListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryGroupListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryGroupListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CountryGroupListViewModelPaginatedList
 */
export interface CountryGroupListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CountryGroupListViewModel>}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'items': Array<CountryGroupListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CountryGroupListViewModelPaginatedListApplicationResult
 */
export interface CountryGroupListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CountryGroupListViewModelPaginatedListApplicationResultResultData}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CountryGroupListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CountryGroupListViewModelPaginatedListApplicationResultResultData
 */
export interface CountryGroupListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CountryGroupListViewModel>}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CountryGroupListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CountryGroupListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CountryViewModel
 */
export interface CountryViewModel {
    /**
     * 
     * @type {string}
     * @memberof CountryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryViewModel
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModel
     */
    'code': string;
    /**
     * 
     * @type {Array<CountryDeliveryTypeViewModel>}
     * @memberof CountryViewModel
     */
    'deliveryTypes': Array<CountryDeliveryTypeViewModel>;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof CountryViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof CountryViewModel
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModel
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryViewModelApplicationResult
 */
export interface CountryViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CountryViewModelApplicationResultResultData}
     * @memberof CountryViewModelApplicationResult
     */
    'resultData'?: CountryViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CountryViewModelApplicationResultResultData
 */
export interface CountryViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {Array<CountryDeliveryTypeViewModel>}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'deliveryTypes': Array<CountryDeliveryTypeViewModel>;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelApplicationResultResultData
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryViewModelCurrency
 */
export interface CountryViewModelCurrency {
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelCurrency
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelCurrency
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelCurrency
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CountryViewModelCurrency
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CountryViewModelCurrency
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CountryViewModelCurrency
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CountryViewModelListApplicationResult
 */
export interface CountryViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CountryViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CountryViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CountryViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<CountryViewModel>}
     * @memberof CountryViewModelListApplicationResult
     */
    'resultData'?: Array<CountryViewModel> | null;
}
/**
 * 
 * @export
 * @interface CourierAdminCreateModel
 */
export interface CourierAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CourierAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminCreateModel
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface CourierAdminListViewModel
 */
export interface CourierAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CourierAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminListViewModel
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface CourierAdminListViewModelPaginatedList
 */
export interface CourierAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CourierAdminListViewModel>}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'items': Array<CourierAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CourierAdminListViewModelPaginatedListApplicationResult
 */
export interface CourierAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CourierAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CourierAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CourierAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CourierAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CourierAdminListViewModel>}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CourierAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CourierAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CourierAdminUpdateModel
 */
export interface CourierAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CourierAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminUpdateModel
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface CourierAdminViewModel
 */
export interface CourierAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CourierAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminViewModel
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface CourierAdminViewModelApplicationResult
 */
export interface CourierAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CourierAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CourierAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CourierAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CourierAdminViewModelApplicationResultResultData}
     * @memberof CourierAdminViewModelApplicationResult
     */
    'resultData'?: CourierAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CourierAdminViewModelApplicationResultResultData
 */
export interface CourierAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CourierAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourierAdminViewModelApplicationResultResultData
     */
    'trackingUrlFormat': string;
}
/**
 * 
 * @export
 * @interface CurrencyAdminCreateModel
 */
export interface CurrencyAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminCreateModel
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminCreateModel
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminCreateModel
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CurrencyAdminListViewModel
 */
export interface CurrencyAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminListViewModel
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModel
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModel
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CurrencyAdminListViewModelPaginatedList
 */
export interface CurrencyAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CurrencyAdminListViewModel>}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'items': Array<CurrencyAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CurrencyAdminListViewModelPaginatedListApplicationResult
 */
export interface CurrencyAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CurrencyAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CurrencyAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CurrencyAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CurrencyAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CurrencyAdminListViewModel>}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CurrencyAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CurrencyAdminUpdateModel
 */
export interface CurrencyAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminUpdateModel
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminUpdateModel
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminUpdateModel
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CurrencyAdminViewModel
 */
export interface CurrencyAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModel
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminViewModel
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminViewModel
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CurrencyAdminViewModelApplicationResult
 */
export interface CurrencyAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CurrencyAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CurrencyAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CurrencyAdminViewModelApplicationResultResultData}
     * @memberof CurrencyAdminViewModelApplicationResult
     */
    'resultData'?: CurrencyAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CurrencyAdminViewModelApplicationResultResultData
 */
export interface CurrencyAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAdminViewModelApplicationResultResultData
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminViewModelApplicationResultResultData
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyAdminViewModelApplicationResultResultData
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CurrencyViewModel
 */
export interface CurrencyViewModel {
    /**
     * 
     * @type {string}
     * @memberof CurrencyViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyViewModel
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyViewModel
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyViewModel
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface CustomizationAdminCreateModel
 */
export interface CustomizationAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizationAdminCreateModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminCreateModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizationAdminListViewModel
 */
export interface CustomizationAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizationAdminListViewModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminListViewModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizationAdminListViewModelPaginatedList
 */
export interface CustomizationAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CustomizationAdminListViewModel>}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'items': Array<CustomizationAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CustomizationAdminListViewModelPaginatedListApplicationResult
 */
export interface CustomizationAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizationAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CustomizationAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizationAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CustomizationAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CustomizationAdminListViewModel>}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CustomizationAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CustomizationAdminUpdateModel
 */
export interface CustomizationAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizationAdminUpdateModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminUpdateModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizationAdminViewModel
 */
export interface CustomizationAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizationAdminViewModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizationAdminViewModelApplicationResult
 */
export interface CustomizationAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizationAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizationAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizationAdminViewModelApplicationResultResultData}
     * @memberof CustomizationAdminViewModelApplicationResult
     */
    'resultData'?: CustomizationAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizationAdminViewModelApplicationResultResultData
 */
export interface CustomizationAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizationAdminViewModelApplicationResultResultData
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizationAdminViewModelApplicationResultResultData
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminCreateModel
 */
export interface CustomizationGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizationGroupAdminCreateModel
     */
    'customizationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminListViewModel
 */
export interface CustomizationGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizationGroupAdminListViewModel
     */
    'customizationIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminListViewModelPaginatedList
 */
export interface CustomizationGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CustomizationGroupAdminListViewModel>}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'items': Array<CustomizationGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface CustomizationGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CustomizationGroupAdminListViewModel>}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CustomizationGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizationGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminUpdateModel
 */
export interface CustomizationGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizationGroupAdminUpdateModel
     */
    'customizationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminViewModel
 */
export interface CustomizationGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizationGroupAdminViewModel
     */
    'customizationIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof CustomizationGroupAdminViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminViewModelApplicationResult
 */
export interface CustomizationGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizationGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizationGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizationGroupAdminViewModelApplicationResultResultData}
     * @memberof CustomizationGroupAdminViewModelApplicationResult
     */
    'resultData'?: CustomizationGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizationGroupAdminViewModelApplicationResultResultData
 */
export interface CustomizationGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizationGroupAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationGroupAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizationGroupAdminViewModelApplicationResultResultData
     */
    'customizationIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof CustomizationGroupAdminViewModelApplicationResultResultData
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomizationType = {
    Text: 'Text',
    Logo: 'Logo',
    Extra: 'Extra'
} as const;

export type CustomizationType = typeof CustomizationType[keyof typeof CustomizationType];


/**
 * 
 * @export
 * @interface CustomizationTypeListViewModel
 */
export interface CustomizationTypeListViewModel {
    /**
     * 
     * @type {number}
     * @memberof CustomizationTypeListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizationTypeListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CustomizationTypeListViewModelListApplicationResult
 */
export interface CustomizationTypeListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizationTypeListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizationTypeListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizationTypeListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<CustomizationTypeListViewModel>}
     * @memberof CustomizationTypeListViewModelListApplicationResult
     */
    'resultData'?: Array<CustomizationTypeListViewModel> | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductAdjustmentTypeViewModel
 */
export interface CustomizedProductAdjustmentTypeViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductAdjustmentTypeViewModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductAdjustmentTypeViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomizedProductAdjustmentViewModel>}
     * @memberof CustomizedProductAdjustmentTypeViewModel
     */
    'adjustments': Array<CustomizedProductAdjustmentViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductAdjustmentViewModel
 */
export interface CustomizedProductAdjustmentViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductAdjustmentViewModel
     */
    'adjustmentId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductAdjustmentViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductCreateUpdateModel
 */
export interface CustomizedProductCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'isForQuote': boolean;
    /**
     * 
     * @type {CustomizedProductCreateUpdateModelStyle}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'style': CustomizedProductCreateUpdateModelStyle;
    /**
     * 
     * @type {CustomizedProductCreateUpdateModelDesign}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'design': CustomizedProductCreateUpdateModelDesign;
    /**
     * 
     * @type {Array<CustomizedProductLayerCreateUpdateModel>}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'layers': Array<CustomizedProductLayerCreateUpdateModel>;
    /**
     * 
     * @type {Array<CustomizedProductItemCreateUpdateModel>}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'items': Array<CustomizedProductItemCreateUpdateModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraCreateUpdateModel>}
     * @memberof CustomizedProductCreateUpdateModel
     */
    'extras': Array<CustomizedProductExtraCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductCreateUpdateModelDesign
 */
export interface CustomizedProductCreateUpdateModelDesign {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCreateUpdateModelDesign
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductCreateUpdateModelStyle
 */
export interface CustomizedProductCreateUpdateModelStyle {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCreateUpdateModelStyle
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductCustomizationCreateUpdateModel
 */
export interface CustomizedProductCustomizationCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCustomizationCreateUpdateModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductCustomizationViewModel
 */
export interface CustomizedProductCustomizationViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCustomizationViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCustomizationViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCustomizationViewModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizedProductCustomizationViewModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductCustomizationViewModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductDesignCreateUpdateModel
 */
export interface CustomizedProductDesignCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductDesignCreateUpdateModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductDesignViewModel
 */
export interface CustomizedProductDesignViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductDesignViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductDesignViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductDesignViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductDesignViewModel
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraCreateUpdateModel
 */
export interface CustomizedProductExtraCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraCreateUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraCreateUpdateModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {CustomizedProductExtraCreateUpdateModelFont}
     * @memberof CustomizedProductExtraCreateUpdateModel
     */
    'font'?: CustomizedProductExtraCreateUpdateModelFont | null;
    /**
     * 
     * @type {CustomizedProductExtraCreateUpdateModelCustomization}
     * @memberof CustomizedProductExtraCreateUpdateModel
     */
    'customization'?: CustomizedProductExtraCreateUpdateModelCustomization | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraCreateUpdateModelCustomization
 */
export interface CustomizedProductExtraCreateUpdateModelCustomization {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraCreateUpdateModelCustomization
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraCreateUpdateModelFont
 */
export interface CustomizedProductExtraCreateUpdateModelFont {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraCreateUpdateModelFont
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraOptionViewModel
 */
export interface CustomizedProductExtraOptionViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraOptionViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraOptionViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductExtraOptionViewModel
     */
    'customizationTextMaximumLength'?: number | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraViewModel
 */
export interface CustomizedProductExtraViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductExtraViewModel
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductExtraViewModel
     */
    'customizationTextMaximumLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductExtraViewModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {CustomizedProductExtraViewModelFont}
     * @memberof CustomizedProductExtraViewModel
     */
    'font'?: CustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {CustomizedProductExtraViewModelCustomization}
     * @memberof CustomizedProductExtraViewModel
     */
    'customization'?: CustomizedProductExtraViewModelCustomization | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraViewModelCustomization
 */
export interface CustomizedProductExtraViewModelCustomization {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelCustomization
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelCustomization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelCustomization
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizedProductExtraViewModelCustomization
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelCustomization
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductExtraViewModelFont
 */
export interface CustomizedProductExtraViewModelFont {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelFont
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelFont
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductExtraViewModelFont
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductFontCreateUpdateModel
 */
export interface CustomizedProductFontCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductFontCreateUpdateModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductFontViewModel
 */
export interface CustomizedProductFontViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductFontViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductFontViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductFontViewModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemBorderCreateUpdateModel
 */
export interface CustomizedProductItemBorderCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemBorderCreateUpdateModel
     */
    'colour': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemBorderCreateUpdateModel
     */
    'style': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemBorderCreateUpdateModel
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemBorderViewModel
 */
export interface CustomizedProductItemBorderViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemBorderViewModel
     */
    'colour': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemBorderViewModel
     */
    'style': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemBorderViewModel
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemCreateUpdateModel
 */
export interface CustomizedProductItemCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'isFromLibrary': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'extraId'?: string | null;
    /**
     * 
     * @type {CustomizedProductItemCreateUpdateModelBorder}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'border'?: CustomizedProductItemCreateUpdateModelBorder | null;
    /**
     * 
     * @type {CustomizedProductExtraCreateUpdateModelFont}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'font'?: CustomizedProductExtraCreateUpdateModelFont | null;
    /**
     * 
     * @type {CustomizedProductItemCreateUpdateModelCustomization}
     * @memberof CustomizedProductItemCreateUpdateModel
     */
    'customization': CustomizedProductItemCreateUpdateModelCustomization;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemCreateUpdateModelBorder
 */
export interface CustomizedProductItemCreateUpdateModelBorder {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModelBorder
     */
    'colour': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModelBorder
     */
    'style': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemCreateUpdateModelBorder
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemCreateUpdateModelCustomization
 */
export interface CustomizedProductItemCreateUpdateModelCustomization {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemCreateUpdateModelCustomization
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemViewModel
 */
export interface CustomizedProductItemViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModel
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductItemViewModel
     */
    'isFromLibrary': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModel
     */
    'extraId'?: string | null;
    /**
     * 
     * @type {CustomizedProductItemViewModelBorder}
     * @memberof CustomizedProductItemViewModel
     */
    'border'?: CustomizedProductItemViewModelBorder | null;
    /**
     * 
     * @type {CustomizedProductExtraViewModelFont}
     * @memberof CustomizedProductItemViewModel
     */
    'font'?: CustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {CustomizedProductItemViewModelCustomization}
     * @memberof CustomizedProductItemViewModel
     */
    'customization': CustomizedProductItemViewModelCustomization;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemViewModelBorder
 */
export interface CustomizedProductItemViewModelBorder {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModelBorder
     */
    'colour': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModelBorder
     */
    'style': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductItemViewModelBorder
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductItemViewModelCustomization
 */
export interface CustomizedProductItemViewModelCustomization {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModelCustomization
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModelCustomization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModelCustomization
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof CustomizedProductItemViewModelCustomization
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductItemViewModelCustomization
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerCreateUpdateModel
 */
export interface CustomizedProductLayerCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerCreateUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {CustomizedProductLayerCreateUpdateModelFabric}
     * @memberof CustomizedProductLayerCreateUpdateModel
     */
    'fabric': CustomizedProductLayerCreateUpdateModelFabric;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerCreateUpdateModelFabric
 */
export interface CustomizedProductLayerCreateUpdateModelFabric {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerCreateUpdateModelFabric
     */
    'id': string;
    /**
     * 
     * @type {CustomizedProductLayerFabricCreateUpdateModelColour}
     * @memberof CustomizedProductLayerCreateUpdateModelFabric
     */
    'colour': CustomizedProductLayerFabricCreateUpdateModelColour;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricChannelViewModel
 */
export interface CustomizedProductLayerFabricChannelViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricChannelViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricChannelViewModel
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricChannelViewModel
     */
    'externalName': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerFabricChannelViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricColourCreateUpdateModel
 */
export interface CustomizedProductLayerFabricColourCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourCreateUpdateModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourCreateUpdateModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourCreateUpdateModel
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricColourViewModel
 */
export interface CustomizedProductLayerFabricColourViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'hexValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'highQualityPatternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricColourViewModel
     */
    'referenceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricCreateUpdateModel
 */
export interface CustomizedProductLayerFabricCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricCreateUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {CustomizedProductLayerFabricCreateUpdateModelColour}
     * @memberof CustomizedProductLayerFabricCreateUpdateModel
     */
    'colour': CustomizedProductLayerFabricCreateUpdateModelColour;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricCreateUpdateModelColour
 */
export interface CustomizedProductLayerFabricCreateUpdateModelColour {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricCreateUpdateModelColour
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricCreateUpdateModelColour
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricCreateUpdateModelColour
     */
    'highQualityPatternImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricViewModel
 */
export interface CustomizedProductLayerFabricViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {CustomizedProductLayerFabricViewModelColour}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'colour': CustomizedProductLayerFabricViewModelColour;
    /**
     * 
     * @type {ConstructionType}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerFabricViewModel
     */
    'customPatternPrice': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerFabricViewModelColour
 */
export interface CustomizedProductLayerFabricViewModelColour {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'hexValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'highQualityPatternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerFabricViewModelColour
     */
    'referenceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerViewModel
 */
export interface CustomizedProductLayerViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerViewModel
     */
    'order': number;
    /**
     * 
     * @type {CustomizedProductLayerViewModelFabric}
     * @memberof CustomizedProductLayerViewModel
     */
    'fabric': CustomizedProductLayerViewModelFabric;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {CustomizedProductLayerViewModelFabricChannel}
     * @memberof CustomizedProductLayerViewModel
     */
    'fabricChannel'?: CustomizedProductLayerViewModelFabricChannel | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerViewModelFabric
 */
export interface CustomizedProductLayerViewModelFabric {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'description'?: string | null;
    /**
     * 
     * @type {CustomizedProductLayerFabricViewModelColour}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'colour': CustomizedProductLayerFabricViewModelColour;
    /**
     * 
     * @type {ConstructionType}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerViewModelFabric
     */
    'customPatternPrice': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductLayerViewModelFabricChannel
 */
export interface CustomizedProductLayerViewModelFabricChannel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabricChannel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabricChannel
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductLayerViewModelFabricChannel
     */
    'externalName': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductLayerViewModelFabricChannel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductListViewModel
 */
export interface CustomizedProductListViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductListViewModel
     */
    'previewImage': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductListViewModel
     */
    'backPreviewImage': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductListViewModel
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductListViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductListViewModel
     */
    'canAddToStore': boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModel
     */
    'defaultQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductListViewModelPaginatedList
 */
export interface CustomizedProductListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CustomizedProductListViewModel>}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'items': Array<CustomizedProductListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductListViewModelPaginatedListApplicationResult
 */
export interface CustomizedProductListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizedProductListViewModelPaginatedListApplicationResultResultData}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResult
     */
    'resultData'?: CustomizedProductListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductListViewModelPaginatedListApplicationResultResultData
 */
export interface CustomizedProductListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<CustomizedProductListViewModel>}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<CustomizedProductListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductPriceViewModel
 */
export interface CustomizedProductPriceViewModel {
    /**
     * Per unit price when the price of one product was calculated.Total price when the price of multiple products were calculated
     * @type {number}
     * @memberof CustomizedProductPriceViewModel
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductPriceViewModel
     */
    'isTotalPrice': boolean;
    /**
     * 
     * @type {Array<OrderCartDeliveryOptionViewModel>}
     * @memberof CustomizedProductPriceViewModel
     */
    'deliveryOptions': Array<OrderCartDeliveryOptionViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductPriceViewModelApplicationResult
 */
export interface CustomizedProductPriceViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizedProductPriceViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizedProductPriceViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductPriceViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizedProductPriceViewModelApplicationResultResultData}
     * @memberof CustomizedProductPriceViewModelApplicationResult
     */
    'resultData'?: CustomizedProductPriceViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductPriceViewModelApplicationResultResultData
 */
export interface CustomizedProductPriceViewModelApplicationResultResultData {
    /**
     * Per unit price when the price of one product was calculated.Total price when the price of multiple products were calculated
     * @type {number}
     * @memberof CustomizedProductPriceViewModelApplicationResultResultData
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductPriceViewModelApplicationResultResultData
     */
    'isTotalPrice': boolean;
    /**
     * 
     * @type {Array<OrderCartDeliveryOptionViewModel>}
     * @memberof CustomizedProductPriceViewModelApplicationResultResultData
     */
    'deliveryOptions': Array<OrderCartDeliveryOptionViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductQuantityModel
 */
export interface CustomizedProductQuantityModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductQuantityModel
     */
    'customizedProductId': string;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductQuantityModel
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductSizeAttributeViewModel
 */
export interface CustomizedProductSizeAttributeViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeAttributeViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeAttributeViewModel
     */
    'value': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideListViewModel>}
     * @memberof CustomizedProductSizeAttributeViewModel
     */
    'overrides': Array<SizeGroupAttributeSizeValueOverrideListViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductSizeGroupViewModel
 */
export interface CustomizedProductSizeGroupViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeGroupViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeGroupViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeGroupViewModel
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
    /**
     * 
     * @type {Array<CustomizedProductSizeViewModel>}
     * @memberof CustomizedProductSizeGroupViewModel
     */
    'sizes': Array<CustomizedProductSizeViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductSizeGroupViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductSizeOptionsViewModel
 */
export interface CustomizedProductSizeOptionsViewModel {
    /**
     * 
     * @type {Array<CustomizedProductSizeGroupViewModel>}
     * @memberof CustomizedProductSizeOptionsViewModel
     */
    'sizeGroups': Array<CustomizedProductSizeGroupViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductAdjustmentTypeViewModel>}
     * @memberof CustomizedProductSizeOptionsViewModel
     */
    'adjustmentTypes': Array<CustomizedProductAdjustmentTypeViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraOptionViewModel>}
     * @memberof CustomizedProductSizeOptionsViewModel
     */
    'extras': Array<CustomizedProductExtraOptionViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductSizeOptionsViewModelApplicationResult
 */
export interface CustomizedProductSizeOptionsViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizedProductSizeOptionsViewModelApplicationResultResultData}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResult
     */
    'resultData'?: CustomizedProductSizeOptionsViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductSizeOptionsViewModelApplicationResultResultData
 */
export interface CustomizedProductSizeOptionsViewModelApplicationResultResultData {
    /**
     * 
     * @type {Array<CustomizedProductSizeGroupViewModel>}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResultResultData
     */
    'sizeGroups': Array<CustomizedProductSizeGroupViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductAdjustmentTypeViewModel>}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResultResultData
     */
    'adjustmentTypes': Array<CustomizedProductAdjustmentTypeViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraOptionViewModel>}
     * @memberof CustomizedProductSizeOptionsViewModelApplicationResultResultData
     */
    'extras': Array<CustomizedProductExtraOptionViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductSizeViewModel
 */
export interface CustomizedProductSizeViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeViewModel
     */
    'sizeId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductSizeViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomizedProductSizeAttributeViewModel>}
     * @memberof CustomizedProductSizeViewModel
     */
    'attributes': Array<CustomizedProductSizeAttributeViewModel>;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductSizeViewModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface CustomizedProductStyleCreateUpdateModel
 */
export interface CustomizedProductStyleCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleCreateUpdateModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductStyleViewModel
 */
export interface CustomizedProductStyleViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductStyleViewModel
     */
    'factoryId': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductViewModel
 */
export interface CustomizedProductViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'productSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {CustomizedProductViewModelStyle}
     * @memberof CustomizedProductViewModel
     */
    'style': CustomizedProductViewModelStyle;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModel
     */
    'customerCanUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModel
     */
    'adminCanUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModel
     */
    'canAddToStore': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof CustomizedProductViewModel
     */
    'orderStatusId'?: OrderStatus | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'orderReferenceNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizedProductViewModel
     */
    'enquiryReferenceNumbers': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductViewModel
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductViewModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModel
     */
    'storeProductName'?: string | null;
    /**
     * 
     * @type {CustomizedProductViewModelDesign}
     * @memberof CustomizedProductViewModel
     */
    'design': CustomizedProductViewModelDesign;
    /**
     * 
     * @type {Array<CustomizedProductLayerViewModel>}
     * @memberof CustomizedProductViewModel
     */
    'layers': Array<CustomizedProductLayerViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductItemViewModel>}
     * @memberof CustomizedProductViewModel
     */
    'items': Array<CustomizedProductItemViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraViewModel>}
     * @memberof CustomizedProductViewModel
     */
    'extras': Array<CustomizedProductExtraViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductViewModelApplicationResult
 */
export interface CustomizedProductViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof CustomizedProductViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof CustomizedProductViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {CustomizedProductViewModelApplicationResultResultData}
     * @memberof CustomizedProductViewModelApplicationResult
     */
    'resultData'?: CustomizedProductViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface CustomizedProductViewModelApplicationResultResultData
 */
export interface CustomizedProductViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'productSlug': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {CustomizedProductViewModelStyle}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'style': CustomizedProductViewModelStyle;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'customerCanUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'adminCanUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'canAddToStore': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'orderStatusId'?: OrderStatus | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'orderReferenceNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'enquiryReferenceNumbers': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'storeProductName'?: string | null;
    /**
     * 
     * @type {CustomizedProductViewModelDesign}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'design': CustomizedProductViewModelDesign;
    /**
     * 
     * @type {Array<CustomizedProductLayerViewModel>}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'layers': Array<CustomizedProductLayerViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductItemViewModel>}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'items': Array<CustomizedProductItemViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraViewModel>}
     * @memberof CustomizedProductViewModelApplicationResultResultData
     */
    'extras': Array<CustomizedProductExtraViewModel>;
}
/**
 * 
 * @export
 * @interface CustomizedProductViewModelDesign
 */
export interface CustomizedProductViewModelDesign {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelDesign
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelDesign
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelDesign
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelDesign
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductViewModelStyle
 */
export interface CustomizedProductViewModelStyle {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductViewModelStyle
     */
    'factoryId': string;
}
/**
 * 
 * @export
 * @interface CustomizedProductsIdDeleteRequest
 */
export interface CustomizedProductsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'isForQuote': boolean;
    /**
     * 
     * @type {CustomizedProductCreateUpdateModelStyle}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'style': CustomizedProductCreateUpdateModelStyle;
    /**
     * 
     * @type {CustomizedProductCreateUpdateModelDesign}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'design': CustomizedProductCreateUpdateModelDesign;
    /**
     * 
     * @type {Array<CustomizedProductLayerCreateUpdateModel>}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'layers': Array<CustomizedProductLayerCreateUpdateModel>;
    /**
     * 
     * @type {Array<CustomizedProductItemCreateUpdateModel>}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'items': Array<CustomizedProductItemCreateUpdateModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraCreateUpdateModel>}
     * @memberof CustomizedProductsIdDeleteRequest
     */
    'extras': Array<CustomizedProductExtraCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface DashboardItemViewModel
 */
export interface DashboardItemViewModel {
    /**
     * 
     * @type {string}
     * @memberof DashboardItemViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardItemViewModel
     */
    'relativeUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemViewModel
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DashboardItemViewModelListApplicationResult
 */
export interface DashboardItemViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DashboardItemViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DashboardItemViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardItemViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<DashboardItemViewModel>}
     * @memberof DashboardItemViewModelListApplicationResult
     */
    'resultData'?: Array<DashboardItemViewModel> | null;
}
/**
 * 
 * @export
 * @interface DecimalApplicationResult
 */
export interface DecimalApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DecimalApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DecimalApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DecimalApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {number}
     * @memberof DecimalApplicationResult
     */
    'resultData': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeliveryType = {
    Standard: 'Standard',
    Express: 'Express'
} as const;

export type DeliveryType = typeof DeliveryType[keyof typeof DeliveryType];


/**
 * 
 * @export
 * @interface DeliveryTypeListViewModel
 */
export interface DeliveryTypeListViewModel {
    /**
     * 
     * @type {number}
     * @memberof DeliveryTypeListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTypeListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DeliveryTypeListViewModelListApplicationResult
 */
export interface DeliveryTypeListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DeliveryTypeListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DeliveryTypeListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryTypeListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<DeliveryTypeListViewModel>}
     * @memberof DeliveryTypeListViewModelListApplicationResult
     */
    'resultData'?: Array<DeliveryTypeListViewModel> | null;
}
/**
 * 
 * @export
 * @interface DesignAdminCreateModel
 */
export interface DesignAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof DesignAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminCreateModel
     */
    'customizationGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminCreateModel
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DesignAdminListViewModel
 */
export interface DesignAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof DesignAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface DesignAdminListViewModelPaginatedList
 */
export interface DesignAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<DesignAdminListViewModel>}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'items': Array<DesignAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface DesignAdminListViewModelPaginatedListApplicationResult
 */
export interface DesignAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {DesignAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: DesignAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface DesignAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface DesignAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<DesignAdminListViewModel>}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<DesignAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof DesignAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface DesignAdminUpdateModel
 */
export interface DesignAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof DesignAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminUpdateModel
     */
    'customizationGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminUpdateModel
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DesignAdminViewModel
 */
export interface DesignAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminViewModel
     */
    'customizationGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminViewModel
     */
    'layerIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface DesignAdminViewModelApplicationResult
 */
export interface DesignAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DesignAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DesignAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {DesignAdminViewModelApplicationResultResultData}
     * @memberof DesignAdminViewModelApplicationResult
     */
    'resultData'?: DesignAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface DesignAdminViewModelApplicationResultResultData
 */
export interface DesignAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'customizationGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'layerIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DesignAdminViewModelApplicationResultResultData
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminCreateModel
 */
export interface DesignGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignGroupAdminCreateModel
     */
    'designIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminListViewModel
 */
export interface DesignGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignGroupAdminListViewModel
     */
    'designIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminListViewModelPaginatedList
 */
export interface DesignGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<DesignGroupAdminListViewModel>}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'items': Array<DesignGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface DesignGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {DesignGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: DesignGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface DesignGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<DesignGroupAdminListViewModel>}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<DesignGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof DesignGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminUpdateModel
 */
export interface DesignGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignGroupAdminUpdateModel
     */
    'designIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminViewModel
 */
export interface DesignGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignGroupAdminViewModel
     */
    'designIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof DesignGroupAdminViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminViewModelApplicationResult
 */
export interface DesignGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof DesignGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof DesignGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {DesignGroupAdminViewModelApplicationResultResultData}
     * @memberof DesignGroupAdminViewModelApplicationResult
     */
    'resultData'?: DesignGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface DesignGroupAdminViewModelApplicationResultResultData
 */
export interface DesignGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignGroupAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof DesignGroupAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DesignGroupAdminViewModelApplicationResultResultData
     */
    'designIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof DesignGroupAdminViewModelApplicationResultResultData
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface EftBankDetails
 */
export interface EftBankDetails {
    /**
     * 
     * @type {string}
     * @memberof EftBankDetails
     */
    'bankTransferDetails': string;
    /**
     * 
     * @type {string}
     * @memberof EftBankDetails
     */
    'reference': string;
}
/**
 * 
 * @export
 * @interface EftBankDetailsApplicationResult
 */
export interface EftBankDetailsApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof EftBankDetailsApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof EftBankDetailsApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof EftBankDetailsApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {EftBankDetailsApplicationResultResultData}
     * @memberof EftBankDetailsApplicationResult
     */
    'resultData'?: EftBankDetailsApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface EftBankDetailsApplicationResultResultData
 */
export interface EftBankDetailsApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof EftBankDetailsApplicationResultResultData
     */
    'bankTransferDetails': string;
    /**
     * 
     * @type {string}
     * @memberof EftBankDetailsApplicationResultResultData
     */
    'reference': string;
}
/**
 * 
 * @export
 * @interface EnquiriesCreateEnquiryPostRequest
 */
export interface EnquiriesCreateEnquiryPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'customerContactNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'leadIn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'ponumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'message': string;
    /**
     * 
     * @type {Array<EnquiryAttachmentCreateUpdateModel>}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'attachments': Array<EnquiryAttachmentCreateUpdateModel>;
    /**
     * 
     * @type {Array<EnquiryCustomizedProductCreateUpdateModel>}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'customizedProducts': Array<EnquiryCustomizedProductCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'googleRecaptchaResponse': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'cartId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiriesCreateEnquiryPostRequest
     */
    'campaignId'?: string | null;
}
/**
 * 
 * @export
 * @interface EnquiryAdminListViewModel
 */
export interface EnquiryAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof EnquiryAdminListViewModel
     */
    'enquiryStatusId': EnquiryStatus;
    /**
     * 
     * @type {EnquiryType}
     * @memberof EnquiryAdminListViewModel
     */
    'enquiryTypeId': EnquiryType;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModel
     */
    'hasCustomizedProducts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModel
     */
    'hasAttachments': boolean;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'internalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'confirmedDeadline'?: string | null;
    /**
     * 
     * @type {EnquiryCustomerType}
     * @memberof EnquiryAdminListViewModel
     */
    'enquiryCustomerTypeId'?: EnquiryCustomerType | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModel
     */
    'qualityScore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'cartId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'cartReferenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModel
     */
    'preparingQuotesTotal': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModel
     */
    'providedQuotesTotal': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModel
     */
    'lostQuotesTotal': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModel
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'orderManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'salesManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'campaignId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminListViewModel
     */
    'campaignName'?: string | null;
}
/**
 * 
 * @export
 * @interface EnquiryAdminListViewModelPaginatedList
 */
export interface EnquiryAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<EnquiryAdminListViewModel>}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'items': Array<EnquiryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface EnquiryAdminListViewModelPaginatedListApplicationResult
 */
export interface EnquiryAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {EnquiryAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: EnquiryAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface EnquiryAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface EnquiryAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<EnquiryAdminListViewModel>}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<EnquiryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface EnquiryAdminUpdateModel
 */
export interface EnquiryAdminUpdateModel {
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof EnquiryAdminUpdateModel
     */
    'enquiryStatusId': EnquiryStatus;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminUpdateModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminUpdateModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {EnquiryType}
     * @memberof EnquiryAdminUpdateModel
     */
    'enquiryTypeId': EnquiryType;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminUpdateModel
     */
    'internalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminUpdateModel
     */
    'confirmedDeadline'?: string | null;
    /**
     * 
     * @type {EnquiryCustomerType}
     * @memberof EnquiryAdminUpdateModel
     */
    'enquiryCustomerTypeId'?: EnquiryCustomerType | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminUpdateModel
     */
    'qualityScore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminUpdateModel
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminUpdateModel
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminUpdateModel
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface EnquiryAdminViewModel
 */
export interface EnquiryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'customerContactNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'leadIn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'ponumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'message': string;
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof EnquiryAdminViewModel
     */
    'enquiryStatusId': EnquiryStatus;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'orderManagerAspNetUserDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'orderManagerAspNetUserEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'salesManagerAspNetUserDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'salesManagerAspNetUserEmail': string;
    /**
     * 
     * @type {EnquiryType}
     * @memberof EnquiryAdminViewModel
     */
    'enquiryTypeId': EnquiryType;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {Array<EnquiryAttachmentViewModel>}
     * @memberof EnquiryAdminViewModel
     */
    'attachments': Array<EnquiryAttachmentViewModel>;
    /**
     * 
     * @type {Array<EnquiryCustomizedProductViewModel>}
     * @memberof EnquiryAdminViewModel
     */
    'customizedProducts': Array<EnquiryCustomizedProductViewModel>;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'internalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'confirmedDeadline'?: string | null;
    /**
     * 
     * @type {EnquiryCustomerType}
     * @memberof EnquiryAdminViewModel
     */
    'enquiryCustomerTypeId'?: EnquiryCustomerType | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminViewModel
     */
    'qualityScore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'cartId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'cartReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminViewModel
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'campaignId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'campaignName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModel
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface EnquiryAdminViewModelApplicationResult
 */
export interface EnquiryAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof EnquiryAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof EnquiryAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {EnquiryAdminViewModelApplicationResultResultData}
     * @memberof EnquiryAdminViewModelApplicationResult
     */
    'resultData'?: EnquiryAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface EnquiryAdminViewModelApplicationResultResultData
 */
export interface EnquiryAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'customerContactNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'leadIn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'ponumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'message': string;
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'enquiryStatusId': EnquiryStatus;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserEmail': string;
    /**
     * 
     * @type {EnquiryType}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'enquiryTypeId': EnquiryType;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {Array<EnquiryAttachmentViewModel>}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'attachments': Array<EnquiryAttachmentViewModel>;
    /**
     * 
     * @type {Array<EnquiryCustomizedProductViewModel>}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'customizedProducts': Array<EnquiryCustomizedProductViewModel>;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'internalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'confirmedDeadline'?: string | null;
    /**
     * 
     * @type {EnquiryCustomerType}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'enquiryCustomerTypeId'?: EnquiryCustomerType | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'qualityScore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'cartId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'cartReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'campaignId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'campaignName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAdminViewModelApplicationResultResultData
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface EnquiryAttachmentCreateUpdateModel
 */
export interface EnquiryAttachmentCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof EnquiryAttachmentCreateUpdateModel
     */
    'fileUrl': string;
}
/**
 * 
 * @export
 * @interface EnquiryAttachmentViewModel
 */
export interface EnquiryAttachmentViewModel {
    /**
     * 
     * @type {string}
     * @memberof EnquiryAttachmentViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryAttachmentViewModel
     */
    'fileUrl': string;
}
/**
 * 
 * @export
 * @interface EnquiryCreateModel
 */
export interface EnquiryCreateModel {
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'customerContactNumber': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'leadIn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'ponumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'message': string;
    /**
     * 
     * @type {Array<EnquiryAttachmentCreateUpdateModel>}
     * @memberof EnquiryCreateModel
     */
    'attachments': Array<EnquiryAttachmentCreateUpdateModel>;
    /**
     * 
     * @type {Array<EnquiryCustomizedProductCreateUpdateModel>}
     * @memberof EnquiryCreateModel
     */
    'customizedProducts': Array<EnquiryCustomizedProductCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'googleRecaptchaResponse': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'cartId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'targetDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCreateModel
     */
    'expectedQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCreateModel
     */
    'campaignId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EnquiryCustomerType = {
    Organisation: 'Organisation',
    GroupOrSocialClub: 'GroupOrSocialClub',
    TeamOrSportsClub: 'TeamOrSportsClub',
    TradeOrWholesale: 'TradeOrWholesale',
    Individual: 'Individual'
} as const;

export type EnquiryCustomerType = typeof EnquiryCustomerType[keyof typeof EnquiryCustomerType];


/**
 * 
 * @export
 * @interface EnquiryCustomerTypeListViewModel
 */
export interface EnquiryCustomerTypeListViewModel {
    /**
     * 
     * @type {EnquiryCustomerType}
     * @memberof EnquiryCustomerTypeListViewModel
     */
    'id': EnquiryCustomerType;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomerTypeListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EnquiryCustomerTypeListViewModelPaginatedList
 */
export interface EnquiryCustomerTypeListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<EnquiryCustomerTypeListViewModel>}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'items': Array<EnquiryCustomerTypeListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface EnquiryCustomerTypeListViewModelPaginatedListApplicationResult
 */
export interface EnquiryCustomerTypeListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResult
     */
    'resultData'?: EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
 */
export interface EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<EnquiryCustomerTypeListViewModel>}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<EnquiryCustomerTypeListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomerTypeListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface EnquiryCustomizedProductCreateUpdateModel
 */
export interface EnquiryCustomizedProductCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductCreateUpdateModel
     */
    'customizedProductId': string;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomizedProductCreateUpdateModel
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface EnquiryCustomizedProductViewModel
 */
export interface EnquiryCustomizedProductViewModel {
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof EnquiryCustomizedProductViewModel
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomizedProductViewModel
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface EnquiryCustomizedProductViewModelCustomizedProduct
 */
export interface EnquiryCustomizedProductViewModelCustomizedProduct {
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'productSlug': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {CustomizedProductViewModelStyle}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'style': CustomizedProductViewModelStyle;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'customerCanUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'adminCanUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'canAddToStore': boolean;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'orderStatusId'?: OrderStatus | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'orderReferenceNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'enquiryReferenceNumbers': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'storeProductName'?: string | null;
    /**
     * 
     * @type {CustomizedProductViewModelDesign}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'design': CustomizedProductViewModelDesign;
    /**
     * 
     * @type {Array<CustomizedProductLayerViewModel>}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'layers': Array<CustomizedProductLayerViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductItemViewModel>}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'items': Array<CustomizedProductItemViewModel>;
    /**
     * 
     * @type {Array<CustomizedProductExtraViewModel>}
     * @memberof EnquiryCustomizedProductViewModelCustomizedProduct
     */
    'extras': Array<CustomizedProductExtraViewModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EnquiryStatus = {
    New: 'New',
    Read: 'Read',
    AttentionRequired: 'AttentionRequired',
    Resolved: 'Resolved',
    OnHold: 'OnHold',
    AwaitingPq: 'AwaitingPQ',
    Quoting: 'Quoting',
    AwaitingOrder: 'AwaitingOrder',
    Lost: 'Lost',
    LostOrderedViaAnotherEnquiry: 'LostOrderedViaAnotherEnquiry'
} as const;

export type EnquiryStatus = typeof EnquiryStatus[keyof typeof EnquiryStatus];


/**
 * 
 * @export
 * @interface EnquiryStatusListViewModel
 */
export interface EnquiryStatusListViewModel {
    /**
     * 
     * @type {EnquiryStatus}
     * @memberof EnquiryStatusListViewModel
     */
    'id': EnquiryStatus;
    /**
     * 
     * @type {string}
     * @memberof EnquiryStatusListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EnquiryStatusListViewModelPaginatedList
 */
export interface EnquiryStatusListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<EnquiryStatusListViewModel>}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'items': Array<EnquiryStatusListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface EnquiryStatusListViewModelPaginatedListApplicationResult
 */
export interface EnquiryStatusListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {EnquiryStatusListViewModelPaginatedListApplicationResultResultData}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResult
     */
    'resultData'?: EnquiryStatusListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface EnquiryStatusListViewModelPaginatedListApplicationResultResultData
 */
export interface EnquiryStatusListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<EnquiryStatusListViewModel>}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<EnquiryStatusListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EnquiryStatusListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EnquiryType = {
    NotYetAssigned: 'NotYetAssigned',
    Sales: 'Sales',
    CustomerService: 'CustomerService',
    Other: 'Other',
    Store: 'Store'
} as const;

export type EnquiryType = typeof EnquiryType[keyof typeof EnquiryType];


/**
 * 
 * @export
 * @interface ExtraAdminCreateModel
 */
export interface ExtraAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminCreateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminCreateModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminCreateModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminCreateModel
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminCreateModel
     */
    'customizationTextMaximumLength'?: number | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminListViewModel
 */
export interface ExtraAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModel
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModel
     */
    'customizationTextMaximumLength'?: number | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminListViewModelPaginatedList
 */
export interface ExtraAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ExtraAdminListViewModel>}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'items': Array<ExtraAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ExtraAdminListViewModelPaginatedListApplicationResult
 */
export interface ExtraAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ExtraAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ExtraAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ExtraAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ExtraAdminListViewModel>}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ExtraAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ExtraAdminUpdateModel
 */
export interface ExtraAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminUpdateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminUpdateModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminUpdateModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminUpdateModel
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminUpdateModel
     */
    'customizationTextMaximumLength'?: number | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminViewModel
 */
export interface ExtraAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminViewModel
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModel
     */
    'customizationTextMaximumLength'?: number | null;
    /**
     * 
     * @type {ExtraAdminViewModelCustomizedProductItemTemplate}
     * @memberof ExtraAdminViewModel
     */
    'customizedProductItemTemplate'?: ExtraAdminViewModelCustomizedProductItemTemplate | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminViewModelApplicationResult
 */
export interface ExtraAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ExtraAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ExtraAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ExtraAdminViewModelApplicationResultResultData}
     * @memberof ExtraAdminViewModelApplicationResult
     */
    'resultData'?: ExtraAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminViewModelApplicationResultResultData
 */
export interface ExtraAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'customizationTextMaximumLength'?: number | null;
    /**
     * 
     * @type {ExtraAdminViewModelCustomizedProductItemTemplate}
     * @memberof ExtraAdminViewModelApplicationResultResultData
     */
    'customizedProductItemTemplate'?: ExtraAdminViewModelCustomizedProductItemTemplate | null;
}
/**
 * 
 * @export
 * @interface ExtraAdminViewModelCustomizedProductItemTemplate
 */
export interface ExtraAdminViewModelCustomizedProductItemTemplate {
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'borderColour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'borderStyle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'borderWidth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'frontPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {CustomizedProductExtraViewModelFont}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'font'?: CustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {CustomizedProductItemViewModelCustomization}
     * @memberof ExtraAdminViewModelCustomizedProductItemTemplate
     */
    'customization': CustomizedProductItemViewModelCustomization;
}
/**
 * 
 * @export
 * @interface ExtraCustomizationRowAdminViewModel
 */
export interface ExtraCustomizationRowAdminViewModel {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExtraCustomizationRowAdminViewModel
     */
    'values': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ExtraCustomizedProductItemTemplateCreateUpdateModel
 */
export interface ExtraCustomizedProductItemTemplateCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'borderColour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'borderStyle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'borderWidth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'frontPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {CustomizedProductExtraCreateUpdateModelFont}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'font'?: CustomizedProductExtraCreateUpdateModelFont | null;
    /**
     * 
     * @type {CustomizedProductItemCreateUpdateModelCustomization}
     * @memberof ExtraCustomizedProductItemTemplateCreateUpdateModel
     */
    'customization': CustomizedProductItemCreateUpdateModelCustomization;
}
/**
 * 
 * @export
 * @interface ExtraCustomizedProductItemTemplateViewModel
 */
export interface ExtraCustomizedProductItemTemplateViewModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'borderColour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'borderStyle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'borderWidth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'frontPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {CustomizedProductExtraViewModelFont}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'font'?: CustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {CustomizedProductItemViewModelCustomization}
     * @memberof ExtraCustomizedProductItemTemplateViewModel
     */
    'customization': CustomizedProductItemViewModelCustomization;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminCreateModel
 */
export interface ExtraGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtraGroupAdminCreateModel
     */
    'extraIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminListViewModel
 */
export interface ExtraGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtraGroupAdminListViewModel
     */
    'extraIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminListViewModelPaginatedList
 */
export interface ExtraGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ExtraGroupAdminListViewModel>}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'items': Array<ExtraGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface ExtraGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ExtraGroupAdminListViewModel>}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ExtraGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ExtraGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminUpdateModel
 */
export interface ExtraGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtraGroupAdminUpdateModel
     */
    'extraIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminViewModel
 */
export interface ExtraGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtraGroupAdminViewModel
     */
    'extraIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof ExtraGroupAdminViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminViewModelApplicationResult
 */
export interface ExtraGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ExtraGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ExtraGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ExtraGroupAdminViewModelApplicationResultResultData}
     * @memberof ExtraGroupAdminViewModelApplicationResult
     */
    'resultData'?: ExtraGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ExtraGroupAdminViewModelApplicationResultResultData
 */
export interface ExtraGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroupAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtraGroupAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtraGroupAdminViewModelApplicationResultResultData
     */
    'extraIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof ExtraGroupAdminViewModelApplicationResultResultData
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface FabricAdminCreateModel
 */
export interface FabricAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof FabricAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminCreateModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface FabricAdminListViewModel
 */
export interface FabricAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof FabricAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminListViewModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface FabricAdminListViewModelListApplicationResult
 */
export interface FabricAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<FabricAdminListViewModel>}
     * @memberof FabricAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<FabricAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface FabricAdminListViewModelPaginatedList
 */
export interface FabricAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FabricAdminListViewModel>}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'items': Array<FabricAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FabricAdminListViewModelPaginatedListApplicationResult
 */
export interface FabricAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FabricAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: FabricAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FabricAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface FabricAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FabricAdminListViewModel>}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<FabricAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FabricAdminUpdateModel
 */
export interface FabricAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof FabricAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminUpdateModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface FabricAdminViewModel
 */
export interface FabricAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface FabricAdminViewModelApplicationResult
 */
export interface FabricAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FabricAdminViewModelApplicationResultResultData}
     * @memberof FabricAdminViewModelApplicationResult
     */
    'resultData'?: FabricAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FabricAdminViewModelApplicationResultResultData
 */
export interface FabricAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FabricAdminViewModelApplicationResultResultData
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminCreateModel
 */
export interface FabricChannelAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminCreateModel
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminCreateModel
     */
    'externalName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FabricChannelAdminCreateModel
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminListViewModel
 */
export interface FabricChannelAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminListViewModel
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminListViewModel
     */
    'externalName': string;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminListViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminListViewModelPaginatedList
 */
export interface FabricChannelAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FabricChannelAdminListViewModel>}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'items': Array<FabricChannelAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminListViewModelPaginatedListApplicationResult
 */
export interface FabricChannelAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FabricChannelAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: FabricChannelAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface FabricChannelAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FabricChannelAdminListViewModel>}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<FabricChannelAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FabricChannelAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminUpdateModel
 */
export interface FabricChannelAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminUpdateModel
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminUpdateModel
     */
    'externalName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FabricChannelAdminUpdateModel
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminViewModel
 */
export interface FabricChannelAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminViewModel
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminViewModel
     */
    'externalName': string;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FabricChannelAdminViewModel
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminViewModelApplicationResult
 */
export interface FabricChannelAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricChannelAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricChannelAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FabricChannelAdminViewModelApplicationResultResultData}
     * @memberof FabricChannelAdminViewModelApplicationResult
     */
    'resultData'?: FabricChannelAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FabricChannelAdminViewModelApplicationResultResultData
 */
export interface FabricChannelAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminViewModelApplicationResultResultData
     */
    'internalName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricChannelAdminViewModelApplicationResultResultData
     */
    'externalName': string;
    /**
     * 
     * @type {boolean}
     * @memberof FabricChannelAdminViewModelApplicationResultResultData
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FabricChannelAdminViewModelApplicationResultResultData
     */
    'layerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FabricColourAdminCreateModel
 */
export interface FabricColourAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminCreateModel
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminCreateModel
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminCreateModel
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @interface FabricColourAdminListViewModel
 */
export interface FabricColourAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminListViewModel
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminListViewModel
     */
    'fabricName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminListViewModel
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminListViewModel
     */
    'colourName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminListViewModel
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @interface FabricColourAdminListViewModelPaginatedList
 */
export interface FabricColourAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FabricColourAdminListViewModel>}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'items': Array<FabricColourAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FabricColourAdminListViewModelPaginatedListApplicationResult
 */
export interface FabricColourAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FabricColourAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: FabricColourAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FabricColourAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface FabricColourAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FabricColourAdminListViewModel>}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<FabricColourAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FabricColourAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FabricColourAdminUpdateModel
 */
export interface FabricColourAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminUpdateModel
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminUpdateModel
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminUpdateModel
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @interface FabricColourAdminViewModel
 */
export interface FabricColourAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModel
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModel
     */
    'fabricName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModel
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModel
     */
    'colourName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModel
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @interface FabricColourAdminViewModelApplicationResult
 */
export interface FabricColourAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FabricColourAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FabricColourAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FabricColourAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FabricColourAdminViewModelApplicationResultResultData}
     * @memberof FabricColourAdminViewModelApplicationResult
     */
    'resultData'?: FabricColourAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FabricColourAdminViewModelApplicationResultResultData
 */
export interface FabricColourAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModelApplicationResultResultData
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModelApplicationResultResultData
     */
    'fabricName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModelApplicationResultResultData
     */
    'colourId': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModelApplicationResultResultData
     */
    'colourName': string;
    /**
     * 
     * @type {string}
     * @memberof FabricColourAdminViewModelApplicationResultResultData
     */
    'referenceCode': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FacebookConversionsEventTypes = {
    AddPaymentInfo: 'AddPaymentInfo',
    AddToCart: 'AddToCart',
    Lead: 'Lead',
    CustomizeProduct: 'CustomizeProduct',
    InitiateCheckout: 'InitiateCheckout',
    Purchase: 'Purchase',
    ViewContent: 'ViewContent'
} as const;

export type FacebookConversionsEventTypes = typeof FacebookConversionsEventTypes[keyof typeof FacebookConversionsEventTypes];


/**
 * 
 * @export
 * @interface FacebookSocialLoginModel
 */
export interface FacebookSocialLoginModel {
    /**
     * 
     * @type {string}
     * @memberof FacebookSocialLoginModel
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface FactoryAdminCreateModel
 */
export interface FactoryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactoryAdminCreateModel
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminCreateModel
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminCreateModel
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface FactoryAdminListViewModel
 */
export interface FactoryAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactoryAdminListViewModel
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminListViewModel
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminListViewModel
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface FactoryAdminListViewModelPaginatedList
 */
export interface FactoryAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FactoryAdminListViewModel>}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'items': Array<FactoryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FactoryAdminListViewModelPaginatedListApplicationResult
 */
export interface FactoryAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FactoryAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: FactoryAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FactoryAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface FactoryAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FactoryAdminListViewModel>}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<FactoryAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FactoryAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FactoryAdminProductionNotesModel
 */
export interface FactoryAdminProductionNotesModel {
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminProductionNotesModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminProductionNotesModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminProductionNotesModel
     */
    'productionNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminProductionNotesModel
     */
    'orderToken'?: string | null;
}
/**
 * 
 * @export
 * @interface FactoryAdminUpdateModel
 */
export interface FactoryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactoryAdminUpdateModel
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminUpdateModel
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminUpdateModel
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface FactoryAdminViewModel
 */
export interface FactoryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactoryAdminViewModel
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModel
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModel
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface FactoryAdminViewModelApplicationResult
 */
export interface FactoryAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FactoryAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FactoryAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FactoryAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FactoryAdminViewModelApplicationResultResultData}
     * @memberof FactoryAdminViewModelApplicationResult
     */
    'resultData'?: FactoryAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FactoryAdminViewModelApplicationResultResultData
 */
export interface FactoryAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactoryAdminViewModelApplicationResultResultData
     */
    'emailAddresses': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModelApplicationResultResultData
     */
    'contactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FactoryAdminViewModelApplicationResultResultData
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface FactorySelectModel
 */
export interface FactorySelectModel {
    /**
     * 
     * @type {string}
     * @memberof FactorySelectModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FactorySelectModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FactorySelectModelPaginatedList
 */
export interface FactorySelectModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof FactorySelectModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactorySelectModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FactorySelectModel>}
     * @memberof FactorySelectModelPaginatedList
     */
    'items': Array<FactorySelectModel>;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FactorySelectModelPaginatedListApplicationResult
 */
export interface FactorySelectModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FactorySelectModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FactorySelectModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FactorySelectModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FactorySelectModelPaginatedListApplicationResultResultData}
     * @memberof FactorySelectModelPaginatedListApplicationResult
     */
    'resultData'?: FactorySelectModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FactorySelectModelPaginatedListApplicationResultResultData
 */
export interface FactorySelectModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FactorySelectModel>}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'items': Array<FactorySelectModel>;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FactorySelectModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FilePath
 */
export interface FilePath {
    /**
     * 
     * @type {string}
     * @memberof FilePath
     */
    'relativePath'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FilePath
     */
    'absolutePath': boolean;
}
/**
 * 
 * @export
 * @interface FontAdminCreateModel
 */
export interface FontAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof FontAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminCreateModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface FontAdminListViewModel
 */
export interface FontAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof FontAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminListViewModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface FontAdminListViewModelPaginatedList
 */
export interface FontAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FontAdminListViewModel>}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'items': Array<FontAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FontAdminListViewModelPaginatedListApplicationResult
 */
export interface FontAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FontAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FontAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FontAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FontAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof FontAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: FontAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FontAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface FontAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<FontAdminListViewModel>}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<FontAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof FontAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface FontAdminUpdateModel
 */
export interface FontAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof FontAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminUpdateModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface FontAdminViewModel
 */
export interface FontAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof FontAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminViewModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface FontAdminViewModelApplicationResult
 */
export interface FontAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof FontAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof FontAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof FontAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {FontAdminViewModelApplicationResultResultData}
     * @memberof FontAdminViewModelApplicationResult
     */
    'resultData'?: FontAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface FontAdminViewModelApplicationResultResultData
 */
export interface FontAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof FontAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FontAdminViewModelApplicationResultResultData
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface GoogleSocialLoginModel
 */
export interface GoogleSocialLoginModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleSocialLoginModel
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface GuidApplicationResult
 */
export interface GuidApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof GuidApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof GuidApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof GuidApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {string}
     * @memberof GuidApplicationResult
     */
    'resultData': string;
}
/**
 * 
 * @export
 * @interface GuidNullableApplicationResult
 */
export interface GuidNullableApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof GuidNullableApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof GuidNullableApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof GuidNullableApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {string}
     * @memberof GuidNullableApplicationResult
     */
    'resultData'?: string | null;
}
/**
 * 
 * @export
 * @interface HistoricalCurrencyViewModel
 */
export interface HistoricalCurrencyViewModel {
    /**
     * 
     * @type {string}
     * @memberof HistoricalCurrencyViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalCurrencyViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalCurrencyViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalCurrencyViewModel
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof HistoricalCurrencyViewModel
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof HistoricalCurrencyViewModel
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface IdentityAuthenticationLoginFacebookLoginFacebookPostRequest
 */
export interface IdentityAuthenticationLoginFacebookLoginFacebookPostRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationLoginFacebookLoginFacebookPostRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface IdentityAuthenticationLoginGoogleLoginGooglePostRequest
 */
export interface IdentityAuthenticationLoginGoogleLoginGooglePostRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationLoginGoogleLoginGooglePostRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface IdentityAuthenticationLoginPostRequest
 */
export interface IdentityAuthenticationLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationLoginPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationLoginPostRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface IdentityAuthenticationRefreshTokenPostRequest
 */
export interface IdentityAuthenticationRefreshTokenPostRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationRefreshTokenPostRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface IdentityAuthenticationRegisterPostRequest
 */
export interface IdentityAuthenticationRegisterPostRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationRegisterPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationRegisterPostRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationRegisterPostRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityAuthenticationRegisterPostRequest
     */
    'googleRecaptchaResponse': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportDutyType = {
    Dap: 'DAP',
    Ddp: 'DDP'
} as const;

export type ImportDutyType = typeof ImportDutyType[keyof typeof ImportDutyType];


/**
 * 
 * @export
 * @interface ImportDutyTypeListViewModel
 */
export interface ImportDutyTypeListViewModel {
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ImportDutyTypeListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ImportDutyTypeListViewModelPaginatedList
 */
export interface ImportDutyTypeListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ImportDutyTypeListViewModel>}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'items': Array<ImportDutyTypeListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ImportDutyTypeListViewModelPaginatedListApplicationResult
 */
export interface ImportDutyTypeListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ImportDutyTypeListViewModelPaginatedListApplicationResultResultData}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ImportDutyTypeListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
 */
export interface ImportDutyTypeListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ImportDutyTypeListViewModel>}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ImportDutyTypeListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ImportDutyTypeListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface LayerAdminCreateModel
 */
export interface LayerAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof LayerAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminCreateModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminCreateModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminCreateModel
     */
    'fabricChannelId'?: string | null;
}
/**
 * 
 * @export
 * @interface LayerAdminListViewModel
 */
export interface LayerAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof LayerAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminListViewModel
     */
    'fabricChannelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminListViewModel
     */
    'fabricChannelInternalName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface LayerAdminListViewModelListApplicationResult
 */
export interface LayerAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof LayerAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof LayerAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<LayerAdminListViewModel>}
     * @memberof LayerAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<LayerAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface LayerAdminListViewModelPaginatedList
 */
export interface LayerAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<LayerAdminListViewModel>}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'items': Array<LayerAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface LayerAdminListViewModelPaginatedListApplicationResult
 */
export interface LayerAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {LayerAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: LayerAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface LayerAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface LayerAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<LayerAdminListViewModel>}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<LayerAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof LayerAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface LayerAdminUpdateModel
 */
export interface LayerAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof LayerAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminUpdateModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminUpdateModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminUpdateModel
     */
    'fabricChannelId'?: string | null;
}
/**
 * 
 * @export
 * @interface LayerAdminViewModel
 */
export interface LayerAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'fabricChannelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModel
     */
    'fabricChannelInternalName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface LayerAdminViewModelApplicationResult
 */
export interface LayerAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof LayerAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof LayerAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {LayerAdminViewModelApplicationResultResultData}
     * @memberof LayerAdminViewModelApplicationResult
     */
    'resultData'?: LayerAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface LayerAdminViewModelApplicationResultResultData
 */
export interface LayerAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'fabricChannelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'fabricChannelInternalName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LayerAdminViewModelApplicationResultResultData
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminCreateModel
 */
export interface LayerFabricAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminCreateModel
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminCreateModel
     */
    'fabricId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminCreateModel
     */
    'colourGroupIds': Array<string>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof LayerFabricAdminCreateModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminCreateModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminCreateModel
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminCreateModel
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminListViewModel
 */
export interface LayerFabricAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminListViewModel
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminListViewModel
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminListViewModel
     */
    'fabricName': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminListViewModel
     */
    'layerName': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminListViewModel
     */
    'layerCode': string;
    /**
     * 
     * @type {Array<ColourGroupAdminListViewModel>}
     * @memberof LayerFabricAdminListViewModel
     */
    'colourGroups': Array<ColourGroupAdminListViewModel>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof LayerFabricAdminListViewModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModel
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminListViewModel
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminListViewModelPaginatedList
 */
export interface LayerFabricAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<LayerFabricAdminListViewModel>}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'items': Array<LayerFabricAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminListViewModelPaginatedListApplicationResult
 */
export interface LayerFabricAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {LayerFabricAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: LayerFabricAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface LayerFabricAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<LayerFabricAdminListViewModel>}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<LayerFabricAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminUpdateModel
 */
export interface LayerFabricAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminUpdateModel
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminUpdateModel
     */
    'fabricId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminUpdateModel
     */
    'colourGroupIds': Array<string>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof LayerFabricAdminUpdateModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminUpdateModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminUpdateModel
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminUpdateModel
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminViewModel
 */
export interface LayerFabricAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModel
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModel
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModel
     */
    'fabricName': string;
    /**
     * 
     * @type {Array<ColourGroupAdminListViewModel>}
     * @memberof LayerFabricAdminViewModel
     */
    'colourGroups': Array<ColourGroupAdminListViewModel>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof LayerFabricAdminViewModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminViewModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminViewModel
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminViewModel
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminViewModelApplicationResult
 */
export interface LayerFabricAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof LayerFabricAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof LayerFabricAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof LayerFabricAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {LayerFabricAdminViewModelApplicationResultResultData}
     * @memberof LayerFabricAdminViewModelApplicationResult
     */
    'resultData'?: LayerFabricAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface LayerFabricAdminViewModelApplicationResultResultData
 */
export interface LayerFabricAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'fabricId': string;
    /**
     * 
     * @type {string}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'fabricName': string;
    /**
     * 
     * @type {Array<ColourGroupAdminListViewModel>}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'colourGroups': Array<ColourGroupAdminListViewModel>;
    /**
     * 
     * @type {ConstructionType}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LayerFabricAdminViewModelApplicationResultResultData
     */
    'productsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface LoginModel
 */
export interface LoginModel {
    /**
     * 
     * @type {string}
     * @memberof LoginModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginModel
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MediaAdminCreateModel
 */
export interface MediaAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof MediaAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminCreateModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaAdminListViewModel
 */
export interface MediaAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof MediaAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminListViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminListViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaAdminListViewModelPaginatedList
 */
export interface MediaAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<MediaAdminListViewModel>}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'items': Array<MediaAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface MediaAdminListViewModelPaginatedListApplicationResult
 */
export interface MediaAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {MediaAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: MediaAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface MediaAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface MediaAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<MediaAdminListViewModel>}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<MediaAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof MediaAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface MediaAdminUpdateModel
 */
export interface MediaAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof MediaAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminUpdateModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaAdminViewModel
 */
export interface MediaAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof MediaAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaAdminViewModelApplicationResult
 */
export interface MediaAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof MediaAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof MediaAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof MediaAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {MediaAdminViewModelApplicationResultResultData}
     * @memberof MediaAdminViewModelApplicationResult
     */
    'resultData'?: MediaAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface MediaAdminViewModelApplicationResultResultData
 */
export interface MediaAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof MediaAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MediaAdminViewModelApplicationResultResultData
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAddressAdminUpdateModel
 */
export interface OrderAddressAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'additionalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressAdminUpdateModel
     */
    'ponumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAddressUpdateModel
 */
export interface OrderAddressUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'additionalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressUpdateModel
     */
    'ponumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAddressViewModel
 */
export interface OrderAddressViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'additionalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressViewModel
     */
    'ponumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAdminListViewModel
 */
export interface OrderAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderAdminListViewModel
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'customerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'deliveryAddressCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModel
     */
    'convertedOrderTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModel
     */
    'convertedRecalculatedOrderTotal'?: number | null;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderAdminListViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModel
     */
    'convertedPaymentOutstanding': number;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'orderManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'salesManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'lastModifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'responseRequestedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'responseReminderSentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'lostReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'amendmentFromCustomer'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderAdminListViewModel
     */
    'factories': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'exWorksApproved'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'groupedUK': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'groupedUSA': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'quoteReminderLastSendDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'isQuote': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'isStoreOrder': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'storeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminListViewModel
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'disableAutoSendFactoryEmails': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderAdminListViewModelPaginatedList
 */
export interface OrderAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderAdminListViewModel>}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'items': Array<OrderAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderAdminListViewModelPaginatedListApplicationResult
 */
export interface OrderAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OrderAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderAdminListViewModel>}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderAdminUpdateModel
 */
export interface OrderAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderAdminUpdateModel
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderAdminUpdateModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'courierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'courierReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {OrderAdminUpdateModelPrimaryBillingAddress}
     * @memberof OrderAdminUpdateModel
     */
    'primaryBillingAddress'?: OrderAdminUpdateModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminUpdateModelPrimaryBillingAddress}
     * @memberof OrderAdminUpdateModel
     */
    'primaryDeliveryAddress'?: OrderAdminUpdateModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {Array<OrderAddressAdminUpdateModel>}
     * @memberof OrderAdminUpdateModel
     */
    'additionalAddresses': Array<OrderAddressAdminUpdateModel>;
    /**
     * 
     * @type {OrderAdminUpdateModelPrimaryCustomerContact}
     * @memberof OrderAdminUpdateModel
     */
    'primaryCustomerContact'?: OrderAdminUpdateModelPrimaryCustomerContact | null;
    /**
     * 
     * @type {Array<OrderCustomerContactAdminUpdateModel>}
     * @memberof OrderAdminUpdateModel
     */
    'additionalCustomerContacts': Array<OrderCustomerContactAdminUpdateModel>;
    /**
     * 
     * @type {Array<OrderItemAdminUpdateModel>}
     * @memberof OrderAdminUpdateModel
     */
    'orderItems': Array<OrderItemAdminUpdateModel>;
    /**
     * 
     * @type {Array<OrderDocumentCreateUpdateModel>}
     * @memberof OrderAdminUpdateModel
     */
    'orderDocuments': Array<OrderDocumentCreateUpdateModel>;
    /**
     * 
     * @type {Array<FactoryAdminProductionNotesModel>}
     * @memberof OrderAdminUpdateModel
     */
    'factories': Array<FactoryAdminProductionNotesModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'exWorksConfirmedByFactoryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'exWorksNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'exWorksApproved'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'groupedUK': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'groupedUSA': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'productionNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'dispatchNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'lostReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'cancellationReason'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'disableQuoteReminder': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'voucherId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'aspNetUserId'?: string | null;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof OrderAdminUpdateModel
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'quoteAlmostExpiredLastSendDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'disableAutoSendFactoryEmails': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModel
     */
    'specialTerms'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminUpdateModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderAdminUpdateModelPrimaryBillingAddress
 */
export interface OrderAdminUpdateModelPrimaryBillingAddress {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'additionalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryBillingAddress
     */
    'ponumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAdminUpdateModelPrimaryCustomerContact
 */
export interface OrderAdminUpdateModelPrimaryCustomerContact {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryCustomerContact
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryCustomerContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryCustomerContact
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminUpdateModelPrimaryCustomerContact
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModel
 */
export interface OrderAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderAdminViewModel
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderAdminViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderAdminViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModel
     */
    'convertedOrderTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModel
     */
    'convertedOrderBalance': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModel
     */
    'totalQuantity': number;
    /**
     * 
     * @type {CourierAdminViewModelApplicationResultResultData}
     * @memberof OrderAdminViewModel
     */
    'courier'?: CourierAdminViewModelApplicationResultResultData | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'courierReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'courierLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'orderManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'salesManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'assetsRedrawRequested'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'orderPackValidAndApproved'?: boolean | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderAdminViewModel
     */
    'primaryBillingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderAdminViewModel
     */
    'primaryDeliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {Array<OrderAddressViewModel>}
     * @memberof OrderAdminViewModel
     */
    'additionalAddresses': Array<OrderAddressViewModel>;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryCustomerContact}
     * @memberof OrderAdminViewModel
     */
    'primaryCustomerContact'?: OrderAdminViewModelPrimaryCustomerContact | null;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderAdminViewModel
     */
    'additionalCustomerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderAdminViewModel
     */
    'customerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {Array<OrderItemAdminViewModel>}
     * @memberof OrderAdminViewModel
     */
    'orderItems': Array<OrderItemAdminViewModel>;
    /**
     * 
     * @type {Array<OrderDocumentViewModel>}
     * @memberof OrderAdminViewModel
     */
    'orderDocuments': Array<OrderDocumentViewModel>;
    /**
     * 
     * @type {Array<FactoryAdminProductionNotesModel>}
     * @memberof OrderAdminViewModel
     */
    'factories': Array<FactoryAdminProductionNotesModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'lastModifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'responseRequestedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'responseReminderSentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'lostReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'amendmentFromCustomer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'exWorksConfirmedByFactoryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'exWorksNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'exWorksApproved'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'groupedUK': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'groupedUSA': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'readyForUploadDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'productionNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'dispatchedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'dispatchNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'orderPackIdAwaitingApproval'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'showOrderPackApprovalLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'showAssetResupplyLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'showOutstandingPaymentLink': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'paymentInternalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'paymentExternalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'cancellationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof OrderAdminViewModel
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'quoteReminderLastSendDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'disableQuoteReminder': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'aspNetUserId'?: string | null;
    /**
     * 
     * @type {OrderAdminViewModelUser}
     * @memberof OrderAdminViewModel
     */
    'user'?: OrderAdminViewModelUser | null;
    /**
     * 
     * @type {OrderAdminViewModelVoucher}
     * @memberof OrderAdminViewModel
     */
    'voucher'?: OrderAdminViewModelVoucher | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'quoteAlmostExpiredLastSendDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'disableAutoSendFactoryEmails': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModel
     */
    'specialTerms'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModelApplicationResult
 */
export interface OrderAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderAdminViewModelApplicationResultResultData}
     * @memberof OrderAdminViewModelApplicationResult
     */
    'resultData'?: OrderAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModelApplicationResultResultData
 */
export interface OrderAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'convertedOrderTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'convertedOrderBalance': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'totalQuantity': number;
    /**
     * 
     * @type {CourierAdminViewModelApplicationResultResultData}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'courier'?: CourierAdminViewModelApplicationResultResultData | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'courierReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'courierLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'assetsRedrawRequested'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderPackValidAndApproved'?: boolean | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'primaryBillingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'primaryDeliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {Array<OrderAddressViewModel>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'additionalAddresses': Array<OrderAddressViewModel>;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryCustomerContact}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'primaryCustomerContact'?: OrderAdminViewModelPrimaryCustomerContact | null;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'additionalCustomerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'customerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {Array<OrderItemAdminViewModel>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderItems': Array<OrderItemAdminViewModel>;
    /**
     * 
     * @type {Array<OrderDocumentViewModel>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderDocuments': Array<OrderDocumentViewModel>;
    /**
     * 
     * @type {Array<FactoryAdminProductionNotesModel>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'factories': Array<FactoryAdminProductionNotesModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'lastModifiedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'responseRequestedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'responseReminderSentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'lostReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'amendmentFromCustomer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'exWorksConfirmedByFactoryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'exWorksNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'exWorksApproved'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'groupedUK': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'groupedUSA': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'readyForUploadDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'productionNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'dispatchedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'dispatchNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'orderPackIdAwaitingApproval'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'showOrderPackApprovalLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'showAssetResupplyLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'showOutstandingPaymentLink': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'paymentInternalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'paymentExternalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'cancellationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'accessKey': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'quoteReminderLastSendDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'disableQuoteReminder': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'aspNetUserId'?: string | null;
    /**
     * 
     * @type {OrderAdminViewModelUser}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'user'?: OrderAdminViewModelUser | null;
    /**
     * 
     * @type {OrderAdminViewModelVoucher}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'voucher'?: OrderAdminViewModelVoucher | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'quoteAlmostExpiredLastSendDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'disableAutoSendFactoryEmails': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'specialTerms'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelApplicationResultResultData
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModelPrimaryBillingAddress
 */
export interface OrderAdminViewModelPrimaryBillingAddress {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'additionalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryBillingAddress
     */
    'ponumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModelPrimaryCustomerContact
 */
export interface OrderAdminViewModelPrimaryCustomerContact {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryCustomerContact
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryCustomerContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryCustomerContact
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryCustomerContact
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryCustomerContact
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelPrimaryCustomerContact
     */
    'paymentReference'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModelUser
 */
export interface OrderAdminViewModelUser {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelUser
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelUser
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAdminViewModelVoucher
 */
export interface OrderAdminViewModelVoucher {
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelVoucher
     */
    'id': string;
    /**
     * 
     * @type {VoucherType}
     * @memberof OrderAdminViewModelVoucher
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof OrderAdminViewModelVoucher
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelVoucher
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelVoucher
     */
    'redemptionCode': string;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelVoucher
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelVoucher
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelVoucher
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAdminViewModelVoucher
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAdminViewModelVoucher
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelVoucher
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderAdminViewModelVoucher
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderAdminViewModelVoucher
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelVoucher
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAdminViewModelVoucher
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrderAuditLogViewModel
 */
export interface OrderAuditLogViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'newValue': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'aspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAuditLogViewModel
     */
    'aspNetUserDisplayName'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderAuditLogViewModelPaginatedList
 */
export interface OrderAuditLogViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderAuditLogViewModel>}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'items': Array<OrderAuditLogViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderAuditLogViewModelPaginatedListApplicationResult
 */
export interface OrderAuditLogViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderAuditLogViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderAuditLogViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderAuditLogViewModelPaginatedListApplicationResultResultData
 */
export interface OrderAuditLogViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderAuditLogViewModel>}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderAuditLogViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderAuditLogViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderCartCreateModel
 */
export interface OrderCartCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartCreateModel
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderCartCreateModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartCreateModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderCartDeliveryOptionViewModel
 */
export interface OrderCartDeliveryOptionViewModel {
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {string}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'deliveryTypeName': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'deliveryDays': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'productionDays': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'deliveryCost': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'deliveryDelayDays': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartDeliveryOptionViewModel
     */
    'productionDelayDays': number;
}
/**
 * 
 * @export
 * @interface OrderCartDeliveryOptionViewModelListApplicationResult
 */
export interface OrderCartDeliveryOptionViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderCartDeliveryOptionViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderCartDeliveryOptionViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartDeliveryOptionViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<OrderCartDeliveryOptionViewModel>}
     * @memberof OrderCartDeliveryOptionViewModelListApplicationResult
     */
    'resultData'?: Array<OrderCartDeliveryOptionViewModel> | null;
}
/**
 * 
 * @export
 * @interface OrderCartItemCreateModel
 */
export interface OrderCartItemCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemCreateModel
     */
    'customizedProductId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemCreateModel
     */
    'orderId': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof OrderCartItemCreateModel
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemCreateModel
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuanityAdjustmentTypeViewModel
 */
export interface OrderCartItemQuanityAdjustmentTypeViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuanityAdjustmentTypeViewModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuanityAdjustmentTypeViewModel
     */
    'adjustmentTypeName': string;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityAdjustmentCreateUpdateModel
 */
export interface OrderCartItemQuantityAdjustmentCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityAdjustmentCreateUpdateModel
     */
    'adjustmentId': string;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityAdjustmentViewModel
 */
export interface OrderCartItemQuantityAdjustmentViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityAdjustmentViewModel
     */
    'adjustmentId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityAdjustmentViewModel
     */
    'adjustmentName': string;
    /**
     * 
     * @type {OrderCartItemQuantityAdjustmentViewModelAdjustmentType}
     * @memberof OrderCartItemQuantityAdjustmentViewModel
     */
    'adjustmentType': OrderCartItemQuantityAdjustmentViewModelAdjustmentType;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityAdjustmentViewModelAdjustmentType
 */
export interface OrderCartItemQuantityAdjustmentViewModelAdjustmentType {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityAdjustmentViewModelAdjustmentType
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityAdjustmentViewModelAdjustmentType
     */
    'adjustmentTypeName': string;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityCreateUpdateModel
 */
export interface OrderCartItemQuantityCreateUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemQuantityCreateUpdateModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityCreateUpdateModel
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityAdjustmentCreateUpdateModel>}
     * @memberof OrderCartItemQuantityCreateUpdateModel
     */
    'adjustments': Array<OrderCartItemQuantityAdjustmentCreateUpdateModel>;
    /**
     * 
     * @type {Array<OrderCartItemQuantityExtraCustomizationCreateUpdateModel>}
     * @memberof OrderCartItemQuantityCreateUpdateModel
     */
    'extraCustomizations': Array<OrderCartItemQuantityExtraCustomizationCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityCreateUpdateModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityCreateUpdateModel
     */
    'orderCustomerContactId'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityExtraCustomizationCreateUpdateModel
 */
export interface OrderCartItemQuantityExtraCustomizationCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityExtraCustomizationCreateUpdateModel
     */
    'extraId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityExtraCustomizationCreateUpdateModel
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemQuantityExtraCustomizationCreateUpdateModel
     */
    'row': number;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityExtraCustomizationViewModel
 */
export interface OrderCartItemQuantityExtraCustomizationViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityExtraCustomizationViewModel
     */
    'extraId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityExtraCustomizationViewModel
     */
    'extraName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityExtraCustomizationViewModel
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemQuantityExtraCustomizationViewModel
     */
    'row': number;
}
/**
 * 
 * @export
 * @interface OrderCartItemQuantityViewModel
 */
export interface OrderCartItemQuantityViewModel {
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemQuantityViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'sizeId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'sizeName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'sizeGroupDisplayName': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityAdjustmentViewModel>}
     * @memberof OrderCartItemQuantityViewModel
     */
    'adjustments': Array<OrderCartItemQuantityAdjustmentViewModel>;
    /**
     * 
     * @type {Array<OrderCartItemQuantityExtraCustomizationViewModel>}
     * @memberof OrderCartItemQuantityViewModel
     */
    'extraCustomizations': Array<OrderCartItemQuantityExtraCustomizationViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'orderCustomerContactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'orderCustomerContactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemQuantityViewModel
     */
    'paymentReference'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCartItemUpdateModel
 */
export interface OrderCartItemUpdateModel {
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof OrderCartItemUpdateModel
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemUpdateModel
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface OrderCartItemViewModel
 */
export interface OrderCartItemViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModel
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModel
     */
    'voucherDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModel
     */
    'discountedTotalPrice': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModel
     */
    'storeExpectedQuantity'?: number | null;
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof OrderCartItemViewModel
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderCartItemQuantityViewModel>}
     * @memberof OrderCartItemViewModel
     */
    'quantities': Array<OrderCartItemQuantityViewModel>;
}
/**
 * 
 * @export
 * @interface OrderCartItemViewModelApplicationResult
 */
export interface OrderCartItemViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderCartItemViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderCartItemViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartItemViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderCartItemViewModelApplicationResultResultData}
     * @memberof OrderCartItemViewModelApplicationResult
     */
    'resultData'?: OrderCartItemViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderCartItemViewModelApplicationResultResultData
 */
export interface OrderCartItemViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'voucherDiscount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'discountedTotalPrice': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'storeExpectedQuantity'?: number | null;
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderCartItemQuantityViewModel>}
     * @memberof OrderCartItemViewModelApplicationResultResultData
     */
    'quantities': Array<OrderCartItemQuantityViewModel>;
}
/**
 * 
 * @export
 * @interface OrderCartUpdateModel
 */
export interface OrderCartUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModel
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartUpdateModel
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModel
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderCartUpdateModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartUpdateModel
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderCartUpdateModelDeliveryAddress}
     * @memberof OrderCartUpdateModel
     */
    'deliveryAddress'?: OrderCartUpdateModelDeliveryAddress | null;
    /**
     * 
     * @type {OrderCartUpdateModelDeliveryAddress}
     * @memberof OrderCartUpdateModel
     */
    'billingAddress'?: OrderCartUpdateModelDeliveryAddress | null;
    /**
     * 
     * @type {OrderCartUpdateModelCustomerContact}
     * @memberof OrderCartUpdateModel
     */
    'customerContact'?: OrderCartUpdateModelCustomerContact | null;
    /**
     * 
     * @type {Array<OrderDocumentCreateUpdateModel>}
     * @memberof OrderCartUpdateModel
     */
    'orderDocuments': Array<OrderDocumentCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModel
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof OrderCartUpdateModel
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartUpdateModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderCartUpdateModelCustomerContact
 */
export interface OrderCartUpdateModelCustomerContact {
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelCustomerContact
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelCustomerContact
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelCustomerContact
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface OrderCartUpdateModelDeliveryAddress
 */
export interface OrderCartUpdateModelDeliveryAddress {
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'additionalNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartUpdateModelDeliveryAddress
     */
    'ponumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCartViewModel
 */
export interface OrderCartViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {Array<OrderCartItemViewModel>}
     * @memberof OrderCartViewModel
     */
    'items': Array<OrderCartItemViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModel
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderCartViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderCartViewModel
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'currencyId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModel
     */
    'currencyPricingMultiplyer': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModel
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderCartViewModel
     */
    'deliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderCartViewModel
     */
    'billingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryCustomerContact}
     * @memberof OrderCartViewModel
     */
    'customerContact'?: OrderAdminViewModelPrimaryCustomerContact | null;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderCartViewModel
     */
    'customerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {Array<OrderDocumentViewModel>}
     * @memberof OrderCartViewModel
     */
    'orderDocuments': Array<OrderDocumentViewModel>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModel
     */
    'isQuote': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModel
     */
    'paymentsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModel
     */
    'paymentsConvertedTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModel
     */
    'additionalCostsConvertedTotal': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'accessKey': string;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof OrderCartViewModel
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {OrderCartViewModelVoucher}
     * @memberof OrderCartViewModel
     */
    'voucher'?: OrderCartViewModelVoucher | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModel
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderCartViewModelApplicationResult
 */
export interface OrderCartViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderCartViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderCartViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderCartViewModelApplicationResultResultData}
     * @memberof OrderCartViewModelApplicationResult
     */
    'resultData'?: OrderCartViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderCartViewModelApplicationResultResultData
 */
export interface OrderCartViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {Array<OrderCartItemViewModel>}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'items': Array<OrderCartItemViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'isGift': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'giftMessage'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'orderStatusId': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'currencyId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'currencyPricingMultiplyer': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'deliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'billingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryCustomerContact}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'customerContact'?: OrderAdminViewModelPrimaryCustomerContact | null;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'customerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {Array<OrderDocumentViewModel>}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'orderDocuments': Array<OrderDocumentViewModel>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'isQuote': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'paymentsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'paymentsConvertedTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'additionalCostsConvertedTotal': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'quoteMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'accessKey': string;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'allowedPaymentProviders': Array<PaymentProvider>;
    /**
     * 
     * @type {OrderCartViewModelVoucher}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'voucher'?: OrderCartViewModelVoucher | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'quoteExpiryDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelApplicationResultResultData
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderCartViewModelVoucher
 */
export interface OrderCartViewModelVoucher {
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelVoucher
     */
    'id': string;
    /**
     * 
     * @type {VoucherType}
     * @memberof OrderCartViewModelVoucher
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof OrderCartViewModelVoucher
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelVoucher
     */
    'redemptionCode': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'productVoucherAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'deliveryVoucherAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'totalVoucherAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelVoucher
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCartViewModelVoucher
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCartViewModelVoucher
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelVoucher
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderCartViewModelVoucher
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderCartViewModelVoucher
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelVoucher
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCartViewModelVoucher
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrderCostAdminCreateModel
 */
export interface OrderCostAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminCreateModel
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminCreateModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminCreateModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminCreateModel
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminCreateModel
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminCreateModel
     */
    'internalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCostAdminListViewModel
 */
export interface OrderCostAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'orderItemName'?: string | null;
    /**
     * 
     * @type {OrderCostType}
     * @memberof OrderCostAdminListViewModel
     */
    'orderCostTypeId': OrderCostType;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminListViewModel
     */
    'lastModifiedDate': string;
}
/**
 * 
 * @export
 * @interface OrderCostAdminListViewModelPaginatedList
 */
export interface OrderCostAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderCostAdminListViewModel>}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'items': Array<OrderCostAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderCostAdminListViewModelPaginatedListApplicationResult
 */
export interface OrderCostAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderCostAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderCostAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderCostAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OrderCostAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderCostAdminListViewModel>}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderCostAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderCostAdminUpdateModel
 */
export interface OrderCostAdminUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminUpdateModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminUpdateModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminUpdateModel
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminUpdateModel
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminUpdateModel
     */
    'internalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCostAdminViewModel
 */
export interface OrderCostAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminViewModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'orderItemName'?: string | null;
    /**
     * 
     * @type {OrderCostType}
     * @memberof OrderCostAdminViewModel
     */
    'orderCostTypeId': OrderCostType;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModel
     */
    'internalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCostAdminViewModelApplicationResult
 */
export interface OrderCostAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderCostAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderCostAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCostAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderCostAdminViewModelApplicationResultResultData}
     * @memberof OrderCostAdminViewModelApplicationResult
     */
    'resultData'?: OrderCostAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderCostAdminViewModelApplicationResultResultData
 */
export interface OrderCostAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'orderId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'orderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'orderItemName'?: string | null;
    /**
     * 
     * @type {OrderCostType}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'orderCostTypeId': OrderCostType;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostAdminViewModelApplicationResultResultData
     */
    'internalReason'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderCostType = {
    Product: 'Product',
    Delivery: 'Delivery',
    Adhoc: 'Adhoc'
} as const;

export type OrderCostType = typeof OrderCostType[keyof typeof OrderCostType];


/**
 * 
 * @export
 * @interface OrderCustomerContactAdminUpdateModel
 */
export interface OrderCustomerContactAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactAdminUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactAdminUpdateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactAdminUpdateModel
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface OrderCustomerContactUpdateModel
 */
export interface OrderCustomerContactUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactUpdateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactUpdateModel
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface OrderCustomerContactViewModel
 */
export interface OrderCustomerContactViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactViewModel
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerContactViewModel
     */
    'paymentReference'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderDocumentCreateUpdateModel
 */
export interface OrderDocumentCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentCreateUpdateModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentCreateUpdateModel
     */
    'documentUrl': string;
}
/**
 * 
 * @export
 * @interface OrderDocumentViewModel
 */
export interface OrderDocumentViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentViewModel
     */
    'documentUrl': string;
}
/**
 * 
 * @export
 * @interface OrderHistoryViewModel
 */
export interface OrderHistoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {OrderPublicStatus}
     * @memberof OrderHistoryViewModel
     */
    'orderPublicStatusId'?: OrderPublicStatus | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderHistoryViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'countryBankTransferDetails'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'paymentsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'paymentsConvertedTotal': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModel
     */
    'assetsRedrawRequested'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'orderTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'orderTotalIncludingVoucher': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'deliveryTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'adhocTotal': number;
    /**
     * 
     * @type {CourierAdminViewModelApplicationResultResultData}
     * @memberof OrderHistoryViewModel
     */
    'courier'?: CourierAdminViewModelApplicationResultResultData | null;
    /**
     * 
     * @type {Array<OrderInvoiceListViewModel>}
     * @memberof OrderHistoryViewModel
     */
    'orderInvoices': Array<OrderInvoiceListViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'courierReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'courierLink'?: string | null;
    /**
     * 
     * @type {Array<OrderItemHistoryViewModel>}
     * @memberof OrderHistoryViewModel
     */
    'orderItems': Array<OrderItemHistoryViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'orderPackIdAwaitingApproval'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModel
     */
    'showOrderPackApprovalLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModel
     */
    'showAssetResupplyLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModel
     */
    'showOutstandingPaymentLink': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'additionalCostsConvertedTotal': number;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderHistoryViewModel
     */
    'primaryBillingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderHistoryViewModel
     */
    'customerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {OrderAdminViewModelVoucher}
     * @memberof OrderHistoryViewModel
     */
    'voucher'?: OrderAdminViewModelVoucher | null;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModel
     */
    'convertedVoucherValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModel
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModel
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderHistoryViewModelApplicationResult
 */
export interface OrderHistoryViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderHistoryViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderHistoryViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderHistoryViewModelApplicationResultResultData}
     * @memberof OrderHistoryViewModelApplicationResult
     */
    'resultData'?: OrderHistoryViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderHistoryViewModelApplicationResultResultData
 */
export interface OrderHistoryViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {OrderPublicStatus}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderPublicStatusId'?: OrderPublicStatus | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'countryBankTransferDetails'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'paymentsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'paymentsConvertedTotal': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'assetsRedrawRequested'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderTotalIncludingVoucher': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'deliveryTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'adhocTotal': number;
    /**
     * 
     * @type {CourierAdminViewModelApplicationResultResultData}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'courier'?: CourierAdminViewModelApplicationResultResultData | null;
    /**
     * 
     * @type {Array<OrderInvoiceListViewModel>}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderInvoices': Array<OrderInvoiceListViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'courierReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'courierLink'?: string | null;
    /**
     * 
     * @type {Array<OrderItemHistoryViewModel>}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderItems': Array<OrderItemHistoryViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'orderPackIdAwaitingApproval'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'showOrderPackApprovalLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'showAssetResupplyLink': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'showOutstandingPaymentLink': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'additionalCostsConvertedTotal': number;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'primaryBillingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {Array<OrderCustomerContactViewModel>}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'customerContacts': Array<OrderCustomerContactViewModel>;
    /**
     * 
     * @type {OrderAdminViewModelVoucher}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'voucher'?: OrderAdminViewModelVoucher | null;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'convertedVoucherValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'storeId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelApplicationResultResultData
     */
    'marketingOptIn': boolean;
}
/**
 * 
 * @export
 * @interface OrderHistoryViewModelPaginatedList
 */
export interface OrderHistoryViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderHistoryViewModel>}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'items': Array<OrderHistoryViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderHistoryViewModelPaginatedListApplicationResult
 */
export interface OrderHistoryViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderHistoryViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderHistoryViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderHistoryViewModelPaginatedListApplicationResultResultData
 */
export interface OrderHistoryViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderHistoryViewModel>}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderHistoryViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderHistoryViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminCreateModel
 */
export interface OrderInvoiceAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminCreateModel
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminCreateModel
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceAdminCreateModel
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminCreateModel
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminListViewModel
 */
export interface OrderInvoiceAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'voidedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModel
     */
    'invoiceNumber': number;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminListViewModelPaginatedList
 */
export interface OrderInvoiceAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderInvoiceAdminListViewModel>}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'items': Array<OrderInvoiceAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminListViewModelPaginatedListApplicationResult
 */
export interface OrderInvoiceAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderInvoiceAdminListViewModel>}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderInvoiceAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminViewModel
 */
export interface OrderInvoiceAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceAdminViewModel
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'voidedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModel
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminViewModel
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {OrderInvoiceAdminViewModelCountry}
     * @memberof OrderInvoiceAdminViewModel
     */
    'country': OrderInvoiceAdminViewModelCountry;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceAdminViewModel
     */
    'deliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceAdminViewModel
     */
    'billingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminViewModelApplicationResult
 */
export interface OrderInvoiceAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderInvoiceAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderInvoiceAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderInvoiceAdminViewModelApplicationResultResultData}
     * @memberof OrderInvoiceAdminViewModelApplicationResult
     */
    'resultData'?: OrderInvoiceAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminViewModelApplicationResultResultData
 */
export interface OrderInvoiceAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'voidedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {OrderInvoiceAdminViewModelCountry}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'country': OrderInvoiceAdminViewModelCountry;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'deliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceAdminViewModelApplicationResultResultData
     */
    'billingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAdminViewModelCountry
 */
export interface OrderInvoiceAdminViewModelCountry {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'code': string;
    /**
     * 
     * @type {Array<CountryDeliveryTypeViewModel>}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'deliveryTypes': Array<CountryDeliveryTypeViewModel>;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'allowBankTransfers': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAdminViewModelCountry
     */
    'bankTransferDetails'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceCostViewModel
 */
export interface OrderInvoiceCostViewModel {
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceCostViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceCostViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceCostViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceCostViewModel
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceCostViewModel
     */
    'dateTimeStamp': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceListViewModel
 */
export interface OrderInvoiceListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceListViewModel
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'voidedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceListViewModel
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceListViewModel
     */
    'invoiceNumber': number;
}
/**
 * 
 * @export
 * @interface OrderInvoicePaymentViewModel
 */
export interface OrderInvoicePaymentViewModel {
    /**
     * 
     * @type {number}
     * @memberof OrderInvoicePaymentViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {PaymentProvider}
     * @memberof OrderInvoicePaymentViewModel
     */
    'paymentProviderId': PaymentProvider;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicePaymentViewModel
     */
    'paymentDate': string;
    /**
     * 
     * @type {OrderAdminViewModelVoucher}
     * @memberof OrderInvoicePaymentViewModel
     */
    'voucher'?: OrderAdminViewModelVoucher | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderInvoiceType = {
    Invoice: 'Invoice',
    Proforma: 'Proforma',
    Quote: 'Quote'
} as const;

export type OrderInvoiceType = typeof OrderInvoiceType[keyof typeof OrderInvoiceType];


/**
 * 
 * @export
 * @interface OrderInvoiceViewModel
 */
export interface OrderInvoiceViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'voidedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceViewModel
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceViewModel
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderInvoiceViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {OrderInvoiceAdminViewModelCountry}
     * @memberof OrderInvoiceViewModel
     */
    'country': OrderInvoiceAdminViewModelCountry;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceViewModel
     */
    'deliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceViewModel
     */
    'billingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceViewModel
     */
    'convertedBalance': number;
    /**
     * 
     * @type {Array<OrderInvoiceCostViewModel>}
     * @memberof OrderInvoiceViewModel
     */
    'costs': Array<OrderInvoiceCostViewModel>;
    /**
     * 
     * @type {Array<OrderInvoicePaymentViewModel>}
     * @memberof OrderInvoiceViewModel
     */
    'payments': Array<OrderInvoicePaymentViewModel>;
}
/**
 * 
 * @export
 * @interface OrderInvoiceViewModelApplicationResult
 */
export interface OrderInvoiceViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderInvoiceViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderInvoiceViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderInvoiceViewModelApplicationResultResultData}
     * @memberof OrderInvoiceViewModelApplicationResult
     */
    'resultData'?: OrderInvoiceViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceViewModelApplicationResultResultData
 */
export interface OrderInvoiceViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'startDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'endDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'voidedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {OrderInvoiceType}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'orderInvoiceTypeId': OrderInvoiceType;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {OrderInvoiceAdminViewModelCountry}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'country': OrderInvoiceAdminViewModelCountry;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'deliveryAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {OrderAdminViewModelPrimaryBillingAddress}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'billingAddress'?: OrderAdminViewModelPrimaryBillingAddress | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'convertedBalance': number;
    /**
     * 
     * @type {Array<OrderInvoiceCostViewModel>}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'costs': Array<OrderInvoiceCostViewModel>;
    /**
     * 
     * @type {Array<OrderInvoicePaymentViewModel>}
     * @memberof OrderInvoiceViewModelApplicationResultResultData
     */
    'payments': Array<OrderInvoicePaymentViewModel>;
}
/**
 * 
 * @export
 * @interface OrderItemAdminUpdateModel
 */
export interface OrderItemAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAdminUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof OrderItemAdminUpdateModel
     */
    'quantities': Array<OrderCartItemQuantityCreateUpdateModel>;
    /**
     * 
     * @type {Array<OrderItemLayerAdminUpdateModel>}
     * @memberof OrderItemAdminUpdateModel
     */
    'layers': Array<OrderItemLayerAdminUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAdminUpdateModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderItemAdminViewModel
 */
export interface OrderItemAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof OrderItemAdminViewModel
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderCartItemQuantityViewModel>}
     * @memberof OrderItemAdminViewModel
     */
    'quantities': Array<OrderCartItemQuantityViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAdminViewModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAdminViewModel
     */
    'factoryId'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdditionaFileCreateUpdateModel
 */
export interface OrderItemAssetAdditionaFileCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdditionaFileCreateUpdateModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdditionaFileCreateUpdateModel
     */
    'fileUrl': string;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdditionalFileViewModel
 */
export interface OrderItemAssetAdditionalFileViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdditionalFileViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdditionalFileViewModel
     */
    'fileUrl': string;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminCreateModel
 */
export interface OrderItemAssetAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminCreateModel
     */
    'orderItemId': string;
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof OrderItemAssetAdminCreateModel
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminCreateModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminCreateModel
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminCreateModel
     */
    'fileUrl': string;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionaFileCreateUpdateModel>}
     * @memberof OrderItemAssetAdminCreateModel
     */
    'additionalFiles': Array<OrderItemAssetAdditionaFileCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminListViewModel
 */
export interface OrderItemAssetAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'orderItemId': string;
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'customizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'fileUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'isFromLibrary': boolean;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionalFileViewModel>}
     * @memberof OrderItemAssetAdminListViewModel
     */
    'additionalFiles': Array<OrderItemAssetAdditionalFileViewModel>;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminListViewModelPaginatedList
 */
export interface OrderItemAssetAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderItemAssetAdminListViewModel>}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'items': Array<OrderItemAssetAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminListViewModelPaginatedListApplicationResult
 */
export interface OrderItemAssetAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderItemAssetAdminListViewModel>}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderItemAssetAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminUpdateModel
 */
export interface OrderItemAssetAdminUpdateModel {
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof OrderItemAssetAdminUpdateModel
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminUpdateModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminUpdateModel
     */
    'fileUrl': string;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionaFileCreateUpdateModel>}
     * @memberof OrderItemAssetAdminUpdateModel
     */
    'additionalFiles': Array<OrderItemAssetAdditionaFileCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminViewModel
 */
export interface OrderItemAssetAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModel
     */
    'orderItemId': string;
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof OrderItemAssetAdminViewModel
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModel
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModel
     */
    'customizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModel
     */
    'fileUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminViewModel
     */
    'isFromLibrary': boolean;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionalFileViewModel>}
     * @memberof OrderItemAssetAdminViewModel
     */
    'additionalFiles': Array<OrderItemAssetAdditionalFileViewModel>;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminViewModelApplicationResult
 */
export interface OrderItemAssetAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderItemAssetAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderItemAssetAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderItemAssetAdminViewModelApplicationResultResultData}
     * @memberof OrderItemAssetAdminViewModelApplicationResult
     */
    'resultData'?: OrderItemAssetAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderItemAssetAdminViewModelApplicationResultResultData
 */
export interface OrderItemAssetAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'orderItemId': string;
    /**
     * 
     * @type {OrderItemAssetStatus}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'orderItemAssetStatusId': OrderItemAssetStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'customizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'fileUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'isFromLibrary': boolean;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionalFileViewModel>}
     * @memberof OrderItemAssetAdminViewModelApplicationResultResultData
     */
    'additionalFiles': Array<OrderItemAssetAdditionalFileViewModel>;
}
/**
 * 
 * @export
 * @interface OrderItemAssetResupplyUpdateModel
 */
export interface OrderItemAssetResupplyUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetResupplyUpdateModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetResupplyUpdateModel
     */
    'fileUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetResupplyUpdateModel
     */
    'requestRedraw': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetResupplyUpdateModel
     */
    'acceptCurrentAsset': boolean;
}
/**
 * 
 * @export
 * @interface OrderItemAssetResupplyViewModel
 */
export interface OrderItemAssetResupplyViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetResupplyViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetResupplyViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemAssetResupplyViewModel
     */
    'fileUrl': string;
}
/**
 * 
 * @export
 * @interface OrderItemAssetResupplyViewModelListApplicationResult
 */
export interface OrderItemAssetResupplyViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderItemAssetResupplyViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderItemAssetResupplyViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemAssetResupplyViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<OrderItemAssetResupplyViewModel>}
     * @memberof OrderItemAssetResupplyViewModelListApplicationResult
     */
    'resultData'?: Array<OrderItemAssetResupplyViewModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderItemAssetStatus = {
    NotChecked: 'NotChecked',
    Required: 'Required',
    Requested: 'Requested',
    Ordered: 'Ordered',
    Ready: 'Ready',
    CustomerApprovedAsIs: 'CustomerApprovedAsIs',
    CustomerRedrawRequested: 'CustomerRedrawRequested',
    CustomerFileProvidedNotChecked: 'CustomerFileProvidedNotChecked',
    CustomerFileProvidedChecked: 'CustomerFileProvidedChecked'
} as const;

export type OrderItemAssetStatus = typeof OrderItemAssetStatus[keyof typeof OrderItemAssetStatus];


/**
 * 
 * @export
 * @interface OrderItemHistoryViewModel
 */
export interface OrderItemHistoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemHistoryViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemHistoryViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemHistoryViewModel
     */
    'price': number;
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof OrderItemHistoryViewModel
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderItemSizeHistoryViewModel>}
     * @memberof OrderItemHistoryViewModel
     */
    'sizes': Array<OrderItemSizeHistoryViewModel>;
    /**
     * 
     * @type {Array<OrderItemSizeTableAdminViewModel>}
     * @memberof OrderItemHistoryViewModel
     */
    'sizeGroups': Array<OrderItemSizeTableAdminViewModel>;
}
/**
 * 
 * @export
 * @interface OrderItemLayerAdminUpdateModel
 */
export interface OrderItemLayerAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemLayerAdminUpdateModel
     */
    'layerId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemLayerAdminUpdateModel
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderItemSizeAdjustmentHistoryViewModel
 */
export interface OrderItemSizeAdjustmentHistoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeAdjustmentHistoryViewModel
     */
    'adjustmentId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeAdjustmentHistoryViewModel
     */
    'adjustmentName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeAdjustmentHistoryViewModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeAdjustmentHistoryViewModel
     */
    'adjustmentTypeName': string;
}
/**
 * 
 * @export
 * @interface OrderItemSizeExtraCustomizationHistoryViewModel
 */
export interface OrderItemSizeExtraCustomizationHistoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeExtraCustomizationHistoryViewModel
     */
    'extraName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeExtraCustomizationHistoryViewModel
     */
    'extraId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeExtraCustomizationHistoryViewModel
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemSizeExtraCustomizationHistoryViewModel
     */
    'row': number;
}
/**
 * 
 * @export
 * @interface OrderItemSizeHistoryViewModel
 */
export interface OrderItemSizeHistoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'sizeId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'sizeName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'sizeGroupDisplayName': string;
    /**
     * 
     * @type {Array<OrderItemSizeAdjustmentHistoryViewModel>}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'adjustments': Array<OrderItemSizeAdjustmentHistoryViewModel>;
    /**
     * 
     * @type {Array<OrderItemSizeExtraCustomizationHistoryViewModel>}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'extraCustomizations': Array<OrderItemSizeExtraCustomizationHistoryViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'orderCustomerContactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'orderCustomerContactName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeHistoryViewModel
     */
    'paymentReference'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderItemSizeTableAdminViewModel
 */
export interface OrderItemSizeTableAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeTableAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeTableAdminViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrderItemSizeTableAdminViewModel
     */
    'columns': { [key: string]: string; };
    /**
     * 
     * @type {Array<OrderItemSizeTableRowAdminViewModel>}
     * @memberof OrderItemSizeTableAdminViewModel
     */
    'rows': Array<OrderItemSizeTableRowAdminViewModel>;
}
/**
 * 
 * @export
 * @interface OrderItemSizeTableRowAdminViewModel
 */
export interface OrderItemSizeTableRowAdminViewModel {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrderItemSizeTableRowAdminViewModel
     */
    'values': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrderItemSizeTableRowAdminViewModel
     */
    'extraColumns': { [key: string]: string; };
    /**
     * 
     * @type {Array<ExtraCustomizationRowAdminViewModel>}
     * @memberof OrderItemSizeTableRowAdminViewModel
     */
    'extraCustomizationRows': Array<ExtraCustomizationRowAdminViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderItemSizeTableRowAdminViewModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemSizeTableRowAdminViewModel
     */
    'hasPaid': boolean;
}
/**
 * 
 * @export
 * @interface OrderOutstandingPaymentViewModel
 */
export interface OrderOutstandingPaymentViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderOutstandingPaymentViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutstandingPaymentViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderOutstandingPaymentViewModel
     */
    'outstandingPaymentValue': number;
    /**
     * 
     * @type {number}
     * @memberof OrderOutstandingPaymentViewModel
     */
    'outstandingPaymentConvertedValue': number;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderOutstandingPaymentViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof OrderOutstandingPaymentViewModel
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderOutstandingPaymentViewModelApplicationResult
 */
export interface OrderOutstandingPaymentViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderOutstandingPaymentViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderOutstandingPaymentViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderOutstandingPaymentViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderOutstandingPaymentViewModelApplicationResultResultData}
     * @memberof OrderOutstandingPaymentViewModelApplicationResult
     */
    'resultData'?: OrderOutstandingPaymentViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderOutstandingPaymentViewModelApplicationResultResultData
 */
export interface OrderOutstandingPaymentViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderOutstandingPaymentViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutstandingPaymentViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderOutstandingPaymentViewModelApplicationResultResultData
     */
    'outstandingPaymentValue': number;
    /**
     * 
     * @type {number}
     * @memberof OrderOutstandingPaymentViewModelApplicationResultResultData
     */
    'outstandingPaymentConvertedValue': number;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderOutstandingPaymentViewModelApplicationResultResultData
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof OrderOutstandingPaymentViewModelApplicationResultResultData
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackAdminListViewModel
 */
export interface OrderPackAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'customerResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'notesToCustomer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {OrderPackStatus}
     * @memberof OrderPackAdminListViewModel
     */
    'orderPackStatusId': OrderPackStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModel
     */
    'versionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'invalidationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'invalidationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'invalidationAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModel
     */
    'invalidationAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModel
     */
    'sizesChanged': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModel
     */
    'filesCount': number;
}
/**
 * 
 * @export
 * @interface OrderPackAdminListViewModelApplicationResult
 */
export interface OrderPackAdminListViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderPackAdminListViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderPackAdminListViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderPackAdminListViewModelApplicationResultResultData}
     * @memberof OrderPackAdminListViewModelApplicationResult
     */
    'resultData'?: OrderPackAdminListViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderPackAdminListViewModelApplicationResultResultData
 */
export interface OrderPackAdminListViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'customerResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'notesToCustomer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {OrderPackStatus}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'orderPackStatusId': OrderPackStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'versionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'invalidationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'invalidationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'invalidationAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'invalidationAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'sizesChanged': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelApplicationResultResultData
     */
    'filesCount': number;
}
/**
 * 
 * @export
 * @interface OrderPackAdminListViewModelPaginatedList
 */
export interface OrderPackAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderPackAdminListViewModel>}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'items': Array<OrderPackAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderPackAdminListViewModelPaginatedListApplicationResult
 */
export interface OrderPackAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderPackAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderPackAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderPackAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OrderPackAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderPackAdminListViewModel>}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderPackAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderPackAdminViewModel
 */
export interface OrderPackAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'customerResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'notesToCustomer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModel
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderPackAdminViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {OrderPackAdminViewModelOrderPack}
     * @memberof OrderPackAdminViewModel
     */
    'orderPack': OrderPackAdminViewModelOrderPack;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModel
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderPackStatus}
     * @memberof OrderPackAdminViewModel
     */
    'orderPackStatusId': OrderPackStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminViewModel
     */
    'versionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'invalidationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'invalidationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'invalidationAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'invalidationAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModel
     */
    'sizesChanged': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPackAdminViewModel
     */
    'files': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModel
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackAdminViewModelApplicationResult
 */
export interface OrderPackAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderPackAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderPackAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderPackAdminViewModelApplicationResultResultData}
     * @memberof OrderPackAdminViewModelApplicationResult
     */
    'resultData'?: OrderPackAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderPackAdminViewModelApplicationResultResultData
 */
export interface OrderPackAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'customerResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'notesToCustomer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {OrderPackAdminViewModelOrderPack}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'orderPack': OrderPackAdminViewModelOrderPack;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderPackStatus}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'orderPackStatusId': OrderPackStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'versionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'invalidationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'invalidationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'invalidationAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'invalidationAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'sizesChanged': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'files': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelApplicationResultResultData
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackAdminViewModelOrderPack
 */
export interface OrderPackAdminViewModelOrderPack {
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelOrderPack
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelOrderPack
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackAdminViewModelOrderPack
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {Array<OrderPackItemViewModel>}
     * @memberof OrderPackAdminViewModelOrderPack
     */
    'orderItems': Array<OrderPackItemViewModel>;
}
/**
 * 
 * @export
 * @interface OrderPackApprovalViewModel
 */
export interface OrderPackApprovalViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'customerResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'notesToCustomer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModel
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderPackApprovalViewModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {OrderPackContentViewModel}
     * @memberof OrderPackApprovalViewModel
     */
    'orderPack': OrderPackContentViewModel;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModel
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderPackStatus}
     * @memberof OrderPackApprovalViewModel
     */
    'orderPackStatusId': OrderPackStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderPackApprovalViewModel
     */
    'versionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'invalidationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'invalidationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'invalidationAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'invalidationAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModel
     */
    'sizesChanged': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'accessKey': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPackApprovalViewModel
     */
    'files': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModel
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackApprovalViewModelApplicationResult
 */
export interface OrderPackApprovalViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderPackApprovalViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderPackApprovalViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderPackApprovalViewModelApplicationResultResultData}
     * @memberof OrderPackApprovalViewModelApplicationResult
     */
    'resultData'?: OrderPackApprovalViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderPackApprovalViewModelApplicationResultResultData
 */
export interface OrderPackApprovalViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'customerResponseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'notesToCustomer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'noFail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'expectedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'updatedDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'exWorksDate'?: string | null;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {OrderPackContentViewModel}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'orderPack': OrderPackContentViewModel;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'requiresCustomerApproval': boolean;
    /**
     * 
     * @type {OrderPackStatus}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'orderPackStatusId': OrderPackStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'versionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'invalidationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'invalidationReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'invalidationAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'invalidationAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'sizesChanged': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'accessKey': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'files': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderPackApprovalViewModelApplicationResultResultData
     */
    'specialTerms'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackContentViewModel
 */
export interface OrderPackContentViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackContentViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackContentViewModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackContentViewModel
     */
    'orderPlacementDate'?: string | null;
    /**
     * 
     * @type {Array<OrderPackItemViewModel>}
     * @memberof OrderPackContentViewModel
     */
    'orderItems': Array<OrderPackItemViewModel>;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductColourViewModel
 */
export interface OrderPackCustomizedProductColourViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'colourId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'hexValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'highQualityPatternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'referenceCode'?: string | null;
    /**
     * 
     * @type {OrderPackCustomizedProductColourViewModelItemAsset}
     * @memberof OrderPackCustomizedProductColourViewModel
     */
    'itemAsset'?: OrderPackCustomizedProductColourViewModelItemAsset | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductColourViewModelItemAsset
 */
export interface OrderPackCustomizedProductColourViewModelItemAsset {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModelItemAsset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModelItemAsset
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductColourViewModelItemAsset
     */
    'fileUrl': string;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelCustomization}
     * @memberof OrderPackCustomizedProductColourViewModelItemAsset
     */
    'customization'?: OrderPackCustomizedProductExtraViewModelCustomization | null;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionalFileViewModel>}
     * @memberof OrderPackCustomizedProductColourViewModelItemAsset
     */
    'additionalFiles': Array<OrderItemAssetAdditionalFileViewModel>;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductCustomizationViewModel
 */
export interface OrderPackCustomizedProductCustomizationViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductCustomizationViewModel
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductCustomizationViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductCustomizationViewModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof OrderPackCustomizedProductCustomizationViewModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductCustomizationViewModel
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductExtraViewModel
 */
export interface OrderPackCustomizedProductExtraViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModel
     */
    'textColourHexValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModel
     */
    'extraId'?: string | null;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelFont}
     * @memberof OrderPackCustomizedProductExtraViewModel
     */
    'font'?: OrderPackCustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelCustomization}
     * @memberof OrderPackCustomizedProductExtraViewModel
     */
    'customization'?: OrderPackCustomizedProductExtraViewModelCustomization | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductExtraViewModelCustomization
 */
export interface OrderPackCustomizedProductExtraViewModelCustomization {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelCustomization
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelCustomization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelCustomization
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof OrderPackCustomizedProductExtraViewModelCustomization
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelCustomization
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductExtraViewModelFont
 */
export interface OrderPackCustomizedProductExtraViewModelFont {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelFont
     */
    'fontId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelFont
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductExtraViewModelFont
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductFabricViewModel
 */
export interface OrderPackCustomizedProductFabricViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'fabricId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'code': string;
    /**
     * 
     * @type {OrderPackCustomizedProductFabricViewModelColour}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'colour': OrderPackCustomizedProductFabricViewModelColour;
    /**
     * 
     * @type {ConstructionType}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductFabricViewModel
     */
    'customPatternPrice': number;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductFabricViewModelColour
 */
export interface OrderPackCustomizedProductFabricViewModelColour {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'colourId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'hexValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'highQualityPatternImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'referenceCode'?: string | null;
    /**
     * 
     * @type {OrderPackCustomizedProductColourViewModelItemAsset}
     * @memberof OrderPackCustomizedProductFabricViewModelColour
     */
    'itemAsset'?: OrderPackCustomizedProductColourViewModelItemAsset | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductFontViewModel
 */
export interface OrderPackCustomizedProductFontViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFontViewModel
     */
    'fontId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFontViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductFontViewModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemAssetViewModel
 */
export interface OrderPackCustomizedProductItemAssetViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemAssetViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemAssetViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemAssetViewModel
     */
    'fileUrl': string;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelCustomization}
     * @memberof OrderPackCustomizedProductItemAssetViewModel
     */
    'customization'?: OrderPackCustomizedProductExtraViewModelCustomization | null;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionalFileViewModel>}
     * @memberof OrderPackCustomizedProductItemAssetViewModel
     */
    'additionalFiles': Array<OrderItemAssetAdditionalFileViewModel>;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemBorderViewModel
 */
export interface OrderPackCustomizedProductItemBorderViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemBorderViewModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemBorderViewModel
     */
    'style'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemBorderViewModel
     */
    'width'?: number | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemViewModel
 */
export interface OrderPackCustomizedProductItemViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'fontSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'view': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'alignment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'fontWeight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'fontStyle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'textSvgUrl'?: string | null;
    /**
     * 
     * @type {OrderPackCustomizedProductItemViewModelExtra}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'extra'?: OrderPackCustomizedProductItemViewModelExtra | null;
    /**
     * 
     * @type {OrderPackCustomizedProductItemViewModelBorder}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'border'?: OrderPackCustomizedProductItemViewModelBorder | null;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelFont}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'font'?: OrderPackCustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {OrderPackCustomizedProductItemViewModelCustomization}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'customization': OrderPackCustomizedProductItemViewModelCustomization;
    /**
     * 
     * @type {OrderPackCustomizedProductItemViewModelItemAsset}
     * @memberof OrderPackCustomizedProductItemViewModel
     */
    'itemAsset': OrderPackCustomizedProductItemViewModelItemAsset;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemViewModelBorder
 */
export interface OrderPackCustomizedProductItemViewModelBorder {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelBorder
     */
    'colour'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModelBorder
     */
    'style'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductItemViewModelBorder
     */
    'width'?: number | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemViewModelCustomization
 */
export interface OrderPackCustomizedProductItemViewModelCustomization {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelCustomization
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelCustomization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelCustomization
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof OrderPackCustomizedProductItemViewModelCustomization
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelCustomization
     */
    'orderPackNotes'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemViewModelExtra
 */
export interface OrderPackCustomizedProductItemViewModelExtra {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelExtra
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelExtra
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelExtra
     */
    'textColourHexValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelExtra
     */
    'extraId'?: string | null;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelFont}
     * @memberof OrderPackCustomizedProductItemViewModelExtra
     */
    'font'?: OrderPackCustomizedProductExtraViewModelFont | null;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelCustomization}
     * @memberof OrderPackCustomizedProductItemViewModelExtra
     */
    'customization'?: OrderPackCustomizedProductExtraViewModelCustomization | null;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductItemViewModelItemAsset
 */
export interface OrderPackCustomizedProductItemViewModelItemAsset {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelItemAsset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelItemAsset
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductItemViewModelItemAsset
     */
    'fileUrl': string;
    /**
     * 
     * @type {OrderPackCustomizedProductExtraViewModelCustomization}
     * @memberof OrderPackCustomizedProductItemViewModelItemAsset
     */
    'customization'?: OrderPackCustomizedProductExtraViewModelCustomization | null;
    /**
     * 
     * @type {Array<OrderItemAssetAdditionalFileViewModel>}
     * @memberof OrderPackCustomizedProductItemViewModelItemAsset
     */
    'additionalFiles': Array<OrderItemAssetAdditionalFileViewModel>;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductLayerViewModel
 */
export interface OrderPackCustomizedProductLayerViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModel
     */
    'layerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModel
     */
    'code': string;
    /**
     * 
     * @type {OrderPackCustomizedProductLayerViewModelFabric}
     * @memberof OrderPackCustomizedProductLayerViewModel
     */
    'fabric': OrderPackCustomizedProductLayerViewModelFabric;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductLayerViewModelFabric
 */
export interface OrderPackCustomizedProductLayerViewModelFabric {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'fabricId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'code': string;
    /**
     * 
     * @type {OrderPackCustomizedProductFabricViewModelColour}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'colour': OrderPackCustomizedProductFabricViewModelColour;
    /**
     * 
     * @type {ConstructionType}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPackCustomizedProductLayerViewModelFabric
     */
    'customPatternPrice': number;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductStyleViewModel
 */
export interface OrderPackCustomizedProductStyleViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'styleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductStyleViewModel
     */
    'factoryId': string;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductViewModel
 */
export interface OrderPackCustomizedProductViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'frontPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {Array<OrderPackCustomizedProductLayerViewModel>}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'layers': Array<OrderPackCustomizedProductLayerViewModel>;
    /**
     * 
     * @type {Array<OrderPackCustomizedProductItemViewModel>}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'items': Array<OrderPackCustomizedProductItemViewModel>;
    /**
     * 
     * @type {Array<OrderPackCustomizedProductExtraViewModel>}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'extras': Array<OrderPackCustomizedProductExtraViewModel>;
    /**
     * 
     * @type {OrderPackCustomizedProductViewModelStyle}
     * @memberof OrderPackCustomizedProductViewModel
     */
    'style': OrderPackCustomizedProductViewModelStyle;
}
/**
 * 
 * @export
 * @interface OrderPackCustomizedProductViewModelStyle
 */
export interface OrderPackCustomizedProductViewModelStyle {
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'styleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackCustomizedProductViewModelStyle
     */
    'factoryId': string;
}
/**
 * 
 * @export
 * @interface OrderPackItemViewModel
 */
export interface OrderPackItemViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModel
     */
    'id': string;
    /**
     * 
     * @type {OrderPackItemViewModelCustomizedProduct}
     * @memberof OrderPackItemViewModel
     */
    'customizedProduct': OrderPackItemViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderItemSizeTableAdminViewModel>}
     * @memberof OrderPackItemViewModel
     */
    'sizeGroups': Array<OrderItemSizeTableAdminViewModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModel
     */
    'orderPackNotes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPackItemViewModel
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface OrderPackItemViewModelCustomizedProduct
 */
export interface OrderPackItemViewModelCustomizedProduct {
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'frontPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'backPreviewImageUrl': string;
    /**
     * 
     * @type {Array<OrderPackCustomizedProductLayerViewModel>}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'layers': Array<OrderPackCustomizedProductLayerViewModel>;
    /**
     * 
     * @type {Array<OrderPackCustomizedProductItemViewModel>}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'items': Array<OrderPackCustomizedProductItemViewModel>;
    /**
     * 
     * @type {Array<OrderPackCustomizedProductExtraViewModel>}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'extras': Array<OrderPackCustomizedProductExtraViewModel>;
    /**
     * 
     * @type {OrderPackCustomizedProductViewModelStyle}
     * @memberof OrderPackItemViewModelCustomizedProduct
     */
    'style': OrderPackCustomizedProductViewModelStyle;
}
/**
 * 
 * @export
 * @interface OrderPackResponseModel
 */
export interface OrderPackResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPackResponseModel
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderPackResponseModel
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPackResponseModel
     */
    'files': Array<string>;
    /**
     * 
     * @type {Array<OrderPackResponseOrderItemModel>}
     * @memberof OrderPackResponseModel
     */
    'orderItems': Array<OrderPackResponseOrderItemModel>;
}
/**
 * 
 * @export
 * @interface OrderPackResponseOrderItemModel
 */
export interface OrderPackResponseOrderItemModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPackResponseOrderItemModel
     */
    'id': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof OrderPackResponseOrderItemModel
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderPackStatus = {
    Generated: 'Generated',
    AwaitingFeedback: 'AwaitingFeedback',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Voided: 'Voided'
} as const;

export type OrderPackStatus = typeof OrderPackStatus[keyof typeof OrderPackStatus];


/**
 * 
 * @export
 * @interface OrderPaymentAdminCreateModel
 */
export interface OrderPaymentAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminCreateModel
     */
    'currencyId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminCreateModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminCreateModel
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminCreateModel
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminCreateModel
     */
    'paymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminCreateModel
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminCreateModel
     */
    'externalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminListViewModel
 */
export interface OrderPaymentAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderPaymentAdminListViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {PaymentProvider}
     * @memberof OrderPaymentAdminListViewModel
     */
    'paymentProviderId': PaymentProvider;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'paymentProviderPaymentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'paymentProviderResponse'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminListViewModel
     */
    'isSuccessful'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'paymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'externalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'orderCustomerContactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminListViewModel
     */
    'orderCustomerContactName'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminListViewModelPaginatedList
 */
export interface OrderPaymentAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderPaymentAdminListViewModel>}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'items': Array<OrderPaymentAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminListViewModelPaginatedListApplicationResult
 */
export interface OrderPaymentAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OrderPaymentAdminListViewModel>}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OrderPaymentAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminUpdateModel
 */
export interface OrderPaymentAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminUpdateModel
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminUpdateModel
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminUpdateModel
     */
    'externalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminViewModel
 */
export interface OrderPaymentAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderPaymentAdminViewModel
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminViewModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {PaymentProvider}
     * @memberof OrderPaymentAdminViewModel
     */
    'paymentProviderId': PaymentProvider;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'paymentProviderPaymentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'paymentProviderResponse'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminViewModel
     */
    'isSuccessful'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'paymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModel
     */
    'externalReason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminViewModelApplicationResult
 */
export interface OrderPaymentAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderPaymentAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderPaymentAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderPaymentAdminViewModelApplicationResultResultData}
     * @memberof OrderPaymentAdminViewModelApplicationResult
     */
    'resultData'?: OrderPaymentAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentAdminViewModelApplicationResultResultData
 */
export interface OrderPaymentAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {CountryViewModelCurrency}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'currency': CountryViewModelCurrency;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {PaymentProvider}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'paymentProviderId': PaymentProvider;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'paymentProviderPaymentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'paymentProviderResponse'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'proofOfPaymentUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'isSuccessful'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'paymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'lastModifiedDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'internalReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentAdminViewModelApplicationResultResultData
     */
    'externalReason'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderPublicStatus = {
    Processing: 'Processing',
    Dispatched: 'Dispatched',
    Cancelled: 'Cancelled',
    Quote: 'Quote',
    InProduction: 'InProduction',
    PreparingToDispatch: 'PreparingToDispatch',
    QuoteExpired: 'QuoteExpired'
} as const;

export type OrderPublicStatus = typeof OrderPublicStatus[keyof typeof OrderPublicStatus];


/**
 * 
 * @export
 * @interface OrderQuoteCreateModel
 */
export interface OrderQuoteCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrderQuoteCreateModel
     */
    'countryId': string;
    /**
     * 
     * @type {DeliveryType}
     * @memberof OrderQuoteCreateModel
     */
    'deliveryTypeId': DeliveryType;
    /**
     * 
     * @type {string}
     * @memberof OrderQuoteCreateModel
     */
    'enquiryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderQuoteCreateModel
     */
    'useEnquiryProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderQuoteCreateModel
     */
    'quoteExpiryDate'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderSortColumn = {
    ExwDate: 'EXWDate',
    LastModifiedDate: 'LastModifiedDate'
} as const;

export type OrderSortColumn = typeof OrderSortColumn[keyof typeof OrderSortColumn];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    Cart: 'Cart',
    NewOrder: 'NewOrder',
    CartValidated: 'CartValidated',
    SignOffCustomerOrderPack: 'SignOffCustomerOrderPack',
    AwaitingAdditionalPayment: 'AwaitingAdditionalPayment',
    ReadyToUpload: 'ReadyToUpload',
    InProduction: 'InProduction',
    PreparingToDispatch: 'PreparingToDispatch',
    Dispatched: 'Dispatched',
    SignOffCustomerFiles: 'SignOffCustomerFiles',
    Cancelled: 'Cancelled',
    BankTransferPending: 'BankTransferPending',
    OnHold: 'OnHold',
    SignOffCliftonOrderPack: 'SignOffCliftonOrderPack',
    AwaitingResponse: 'AwaitingResponse',
    ResponseReceived: 'ResponseReceived',
    PreparingQuote: 'PreparingQuote',
    QuoteProvided: 'QuoteProvided',
    AmendQuote: 'AmendQuote',
    Lost: 'Lost',
    QuoteExpired: 'QuoteExpired'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface OrderStatusListViewModel
 */
export interface OrderStatusListViewModel {
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderStatusListViewModel
     */
    'id': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrderStatusListViewModelListApplicationResult
 */
export interface OrderStatusListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OrderStatusListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OrderStatusListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderStatusListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<OrderStatusListViewModel>}
     * @memberof OrderStatusListViewModelListApplicationResult
     */
    'resultData'?: Array<OrderStatusListViewModel> | null;
}
/**
 * 
 * @export
 * @interface OrdersRespondToOrderPackOrderPackIdPostRequest
 */
export interface OrdersRespondToOrderPackOrderPackIdPostRequest {
    /**
     * 
     * @type {boolean}
     * @memberof OrdersRespondToOrderPackOrderPackIdPostRequest
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrdersRespondToOrderPackOrderPackIdPostRequest
     */
    'notesFromCustomer'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrdersRespondToOrderPackOrderPackIdPostRequest
     */
    'files': Array<string>;
    /**
     * 
     * @type {Array<OrderPackResponseOrderItemModel>}
     * @memberof OrdersRespondToOrderPackOrderPackIdPostRequest
     */
    'orderItems': Array<OrderPackResponseOrderItemModel>;
}
/**
 * 
 * @export
 * @interface OverlayAdminCreateModel
 */
export interface OverlayAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminCreateModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminCreateModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminCreateModel
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface OverlayAdminListViewModel
 */
export interface OverlayAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminListViewModel
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface OverlayAdminListViewModelPaginatedList
 */
export interface OverlayAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OverlayAdminListViewModel>}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'items': Array<OverlayAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OverlayAdminListViewModelPaginatedListApplicationResult
 */
export interface OverlayAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OverlayAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OverlayAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OverlayAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OverlayAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OverlayAdminListViewModel>}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OverlayAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OverlayAdminUpdateModel
 */
export interface OverlayAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminUpdateModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminUpdateModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminUpdateModel
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface OverlayAdminViewModel
 */
export interface OverlayAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminViewModel
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface OverlayAdminViewModelApplicationResult
 */
export interface OverlayAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OverlayAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OverlayAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OverlayAdminViewModelApplicationResultResultData}
     * @memberof OverlayAdminViewModelApplicationResult
     */
    'resultData'?: OverlayAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OverlayAdminViewModelApplicationResultResultData
 */
export interface OverlayAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminCreateModel
 */
export interface OverlayGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverlayGroupAdminCreateModel
     */
    'overlayIds': Array<string>;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminListViewModel
 */
export interface OverlayGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverlayGroupAdminListViewModel
     */
    'overlayIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminListViewModelPaginatedList
 */
export interface OverlayGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OverlayGroupAdminListViewModel>}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'items': Array<OverlayGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface OverlayGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<OverlayGroupAdminListViewModel>}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<OverlayGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof OverlayGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminUpdateModel
 */
export interface OverlayGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverlayGroupAdminUpdateModel
     */
    'overlayIds': Array<string>;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminViewModel
 */
export interface OverlayGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverlayGroupAdminViewModel
     */
    'overlayIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof OverlayGroupAdminViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminViewModelApplicationResult
 */
export interface OverlayGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof OverlayGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof OverlayGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OverlayGroupAdminViewModelApplicationResultResultData}
     * @memberof OverlayGroupAdminViewModelApplicationResult
     */
    'resultData'?: OverlayGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface OverlayGroupAdminViewModelApplicationResultResultData
 */
export interface OverlayGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OverlayGroupAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OverlayGroupAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OverlayGroupAdminViewModelApplicationResultResultData
     */
    'overlayIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof OverlayGroupAdminViewModelApplicationResultResultData
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminCreateModel
 */
export interface PageSeoSettingAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminCreateModel
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminCreateModel
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminCreateModel
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminListViewModel
 */
export interface PageSeoSettingAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminListViewModel
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminListViewModel
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminListViewModel
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminListViewModelPaginatedList
 */
export interface PageSeoSettingAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<PageSeoSettingAdminListViewModel>}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'items': Array<PageSeoSettingAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminListViewModelPaginatedListApplicationResult
 */
export interface PageSeoSettingAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<PageSeoSettingAdminListViewModel>}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<PageSeoSettingAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PageSeoSettingAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminUpdateModel
 */
export interface PageSeoSettingAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminUpdateModel
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminUpdateModel
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminViewModel
 */
export interface PageSeoSettingAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminViewModel
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminViewModel
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminViewModel
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminViewModelApplicationResult
 */
export interface PageSeoSettingAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PageSeoSettingAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PageSeoSettingAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {PageSeoSettingAdminViewModelApplicationResultResultData}
     * @memberof PageSeoSettingAdminViewModelApplicationResult
     */
    'resultData'?: PageSeoSettingAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface PageSeoSettingAdminViewModelApplicationResultResultData
 */
export interface PageSeoSettingAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminViewModelApplicationResultResultData
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingAdminViewModelApplicationResultResultData
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingAdminViewModelApplicationResultResultData
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingViewModel
 */
export interface PageSeoSettingViewModel {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingViewModel
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingViewModel
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface PageSeoSettingViewModelApplicationResult
 */
export interface PageSeoSettingViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PageSeoSettingViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PageSeoSettingViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {PageSeoSettingViewModelApplicationResultResultData}
     * @memberof PageSeoSettingViewModelApplicationResult
     */
    'resultData'?: PageSeoSettingViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface PageSeoSettingViewModelApplicationResultResultData
 */
export interface PageSeoSettingViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof PageSeoSettingViewModelApplicationResultResultData
     */
    'canonicalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeoSettingViewModelApplicationResultResultData
     */
    'noIndex': boolean;
}
/**
 * 
 * @export
 * @interface ParentCategoryViewModel
 */
export interface ParentCategoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof ParentCategoryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParentCategoryViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ParentCategoryViewModel
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof ParentCategoryViewModel
     */
    'parentSlug': string;
    /**
     * 
     * @type {string}
     * @memberof ParentCategoryViewModel
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ParentCategoryViewModel
     */
    'fullSlug': string;
}
/**
 * 
 * @export
 * @interface PasswordUpdateModel
 */
export interface PasswordUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateModel
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateModel
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdateModel
     */
    'newPasswordConfirmation': string;
}
/**
 * 
 * @export
 * @interface PaymentIntentViewModel
 */
export interface PaymentIntentViewModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentViewModel
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @interface PaymentIntentViewModelApplicationResult
 */
export interface PaymentIntentViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PaymentIntentViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PaymentIntentViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentIntentViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {PaymentIntentViewModelApplicationResultResultData}
     * @memberof PaymentIntentViewModelApplicationResult
     */
    'resultData'?: PaymentIntentViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface PaymentIntentViewModelApplicationResultResultData
 */
export interface PaymentIntentViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentViewModelApplicationResultResultData
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentProvider = {
    Eft: 'EFT',
    Stripe: 'Stripe',
    Voucher: 'Voucher'
} as const;

export type PaymentProvider = typeof PaymentProvider[keyof typeof PaymentProvider];


/**
 * 
 * @export
 * @interface PaymentProviderListApplicationResult
 */
export interface PaymentProviderListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PaymentProviderListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PaymentProviderListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentProviderListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<PaymentProvider>}
     * @memberof PaymentProviderListApplicationResult
     */
    'resultData'?: Array<PaymentProvider> | null;
}
/**
 * 
 * @export
 * @interface PriceAdminCreateModel
 */
export interface PriceAdminCreateModel {
    /**
     * 
     * @type {number}
     * @memberof PriceAdminCreateModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminCreateModel
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminCreateModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof PriceAdminCreateModel
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface PriceAdminListViewModel
 */
export interface PriceAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof PriceAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModel
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof PriceAdminListViewModel
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface PriceAdminListViewModelPaginatedList
 */
export interface PriceAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<PriceAdminListViewModel>}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'items': Array<PriceAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PriceAdminListViewModelPaginatedListApplicationResult
 */
export interface PriceAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {PriceAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: PriceAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface PriceAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface PriceAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<PriceAdminListViewModel>}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<PriceAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PriceAdminUpdateModel
 */
export interface PriceAdminUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof PriceAdminUpdateModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminUpdateModel
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminUpdateModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof PriceAdminUpdateModel
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface PriceAdminViewModel
 */
export interface PriceAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof PriceAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminViewModel
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminViewModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof PriceAdminViewModel
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface PriceAdminViewModelApplicationResult
 */
export interface PriceAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof PriceAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof PriceAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {PriceAdminViewModelApplicationResultResultData}
     * @memberof PriceAdminViewModelApplicationResult
     */
    'resultData'?: PriceAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface PriceAdminViewModelApplicationResultResultData
 */
export interface PriceAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof PriceAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminViewModelApplicationResultResultData
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminViewModelApplicationResultResultData
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAdminViewModelApplicationResultResultData
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof PriceAdminViewModelApplicationResultResultData
     */
    'linkedEntityId': string;
}
/**
 * 
 * @export
 * @interface PriceViewModel
 */
export interface PriceViewModel {
    /**
     * 
     * @type {string}
     * @memberof PriceViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PriceViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceViewModel
     */
    'maximumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceViewModel
     */
    'price': number;
}
/**
 * 
 * @export
 * @interface ProductAdjustmentTypeViewModel
 */
export interface ProductAdjustmentTypeViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentTypeViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentTypeViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentTypeViewModel
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ProductAdjustmentViewModel
 */
export interface ProductAdjustmentViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdjustmentViewModel
     */
    'adjustmentTypeId': string;
}
/**
 * 
 * @export
 * @interface ProductAdminCreateModel
 */
export interface ProductAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminCreateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminCreateModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminCreateModel
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminCreateModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminCreateModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {Array<ProductImageAdminCreateModel>}
     * @memberof ProductAdminCreateModel
     */
    'thumbnailImages'?: Array<ProductImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductImageAdminCreateModel>}
     * @memberof ProductAdminCreateModel
     */
    'galleryImages'?: Array<ProductImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductAdminCreateModel>}
     * @memberof ProductAdminCreateModel
     */
    'relatedProducts'?: Array<RelatedProductAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductAdminCreateModel>}
     * @memberof ProductAdminCreateModel
     */
    'productTags'?: Array<ProductTagProductAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductStyleGroupAdminCreateModel>}
     * @memberof ProductAdminCreateModel
     */
    'styleGroups'?: Array<ProductStyleGroupAdminCreateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductCategoryAdminCreateModel>}
     * @memberof ProductAdminCreateModel
     */
    'categories': Array<ProductCategoryAdminCreateModel>;
}
/**
 * 
 * @export
 * @interface ProductAdminListViewModel
 */
export interface ProductAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAdminListViewModel
     */
    'categories': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface ProductAdminListViewModelListApplicationResult
 */
export interface ProductAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<ProductAdminListViewModel>}
     * @memberof ProductAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<ProductAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface ProductAdminListViewModelPaginatedList
 */
export interface ProductAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductAdminListViewModel>}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'items': Array<ProductAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductAdminListViewModelPaginatedListApplicationResult
 */
export interface ProductAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductAdminListViewModel>}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductAdminUpdateModel
 */
export interface ProductAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminUpdateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminUpdateModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminUpdateModel
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminUpdateModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminUpdateModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {Array<ProductImageAdminUpdateModel>}
     * @memberof ProductAdminUpdateModel
     */
    'thumbnailImages'?: Array<ProductImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductImageAdminUpdateModel>}
     * @memberof ProductAdminUpdateModel
     */
    'galleryImages'?: Array<ProductImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductAdminUpdateModel>}
     * @memberof ProductAdminUpdateModel
     */
    'relatedProducts'?: Array<RelatedProductAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductAdminUpdateModel>}
     * @memberof ProductAdminUpdateModel
     */
    'productTags'?: Array<ProductTagProductAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductStyleGroupAdminUpdateModel>}
     * @memberof ProductAdminUpdateModel
     */
    'styleGroups'?: Array<ProductStyleGroupAdminUpdateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductCategoryAdminUpdateModel>}
     * @memberof ProductAdminUpdateModel
     */
    'categories': Array<ProductCategoryAdminUpdateModel>;
}
/**
 * 
 * @export
 * @interface ProductAdminViewModel
 */
export interface ProductAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModel
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminViewModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductAdminViewModel
     */
    'thumbnailImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductAdminViewModel
     */
    'galleryImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<RelatedProductAdminViewModel>}
     * @memberof ProductAdminViewModel
     */
    'relatedProducts': Array<RelatedProductAdminViewModel>;
    /**
     * 
     * @type {Array<ProductTagProductAdminViewModel>}
     * @memberof ProductAdminViewModel
     */
    'productTags': Array<ProductTagProductAdminViewModel>;
    /**
     * 
     * @type {Array<ProductCategoryAdminViewModel>}
     * @memberof ProductAdminViewModel
     */
    'categories': Array<ProductCategoryAdminViewModel>;
    /**
     * 
     * @type {Array<ProductStyleGroupAdminViewModel>}
     * @memberof ProductAdminViewModel
     */
    'styleGroups': Array<ProductStyleGroupAdminViewModel>;
}
/**
 * 
 * @export
 * @interface ProductAdminViewModelApplicationResult
 */
export interface ProductAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductAdminViewModelApplicationResultResultData}
     * @memberof ProductAdminViewModelApplicationResult
     */
    'resultData'?: ProductAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductAdminViewModelApplicationResultResultData
 */
export interface ProductAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'thumbnailImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'galleryImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<RelatedProductAdminViewModel>}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'relatedProducts': Array<RelatedProductAdminViewModel>;
    /**
     * 
     * @type {Array<ProductTagProductAdminViewModel>}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'productTags': Array<ProductTagProductAdminViewModel>;
    /**
     * 
     * @type {Array<ProductCategoryAdminViewModel>}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'categories': Array<ProductCategoryAdminViewModel>;
    /**
     * 
     * @type {Array<ProductStyleGroupAdminViewModel>}
     * @memberof ProductAdminViewModelApplicationResultResultData
     */
    'styleGroups': Array<ProductStyleGroupAdminViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderArtAssetCategoryViewModel
 */
export interface ProductBuilderArtAssetCategoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetCategoryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetCategoryViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetCategoryViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {Array<ProductBuilderArtAssetViewModel>}
     * @memberof ProductBuilderArtAssetCategoryViewModel
     */
    'artAssets': Array<ProductBuilderArtAssetViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderArtAssetViewModel
 */
export interface ProductBuilderArtAssetViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderArtAssetViewModel
     */
    'metaData'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductBuilderColourViewModel
 */
export interface ProductBuilderColourViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderColourViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderColourViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderColourViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderColourViewModel
     */
    'hexValue': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderColourViewModel
     */
    'patternImageUrl'?: string | null;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderColourViewModel
     */
    'prices': Array<PriceViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderCustomizationViewModel
 */
export interface ProductBuilderCustomizationViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderCustomizationViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderCustomizationViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderCustomizationViewModel
     */
    'code': string;
    /**
     * 
     * @type {CustomizationType}
     * @memberof ProductBuilderCustomizationViewModel
     */
    'customizationTypeId': CustomizationType;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderCustomizationViewModel
     */
    'prices': Array<PriceViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderDesignViewModel
 */
export interface ProductBuilderDesignViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderDesignViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderDesignViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderDesignViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderDesignViewModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {Array<ProductBuilderFabricChannelViewModel>}
     * @memberof ProductBuilderDesignViewModel
     */
    'fabricChannels': Array<ProductBuilderFabricChannelViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderLayerViewModel>}
     * @memberof ProductBuilderDesignViewModel
     */
    'layers': Array<ProductBuilderLayerViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderCustomizationViewModel>}
     * @memberof ProductBuilderDesignViewModel
     */
    'customizations': Array<ProductBuilderCustomizationViewModel>;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderDesignViewModel
     */
    'prices': Array<PriceViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderExtraViewModel
 */
export interface ProductBuilderExtraViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderExtraViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderExtraViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderExtraViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderExtraViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderExtraViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderExtraViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderExtraViewModel
     */
    'hasCustomizationText': boolean;
    /**
     * 
     * @type {ExtraAdminViewModelCustomizedProductItemTemplate}
     * @memberof ProductBuilderExtraViewModel
     */
    'customizedProductItemTemplate'?: ExtraAdminViewModelCustomizedProductItemTemplate | null;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderExtraViewModel
     */
    'prices': Array<PriceViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderFabricChannelViewModel
 */
export interface ProductBuilderFabricChannelViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricChannelViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricChannelViewModel
     */
    'externalName': string;
    /**
     * 
     * @type {Array<ProductBuilderFabricListViewModel>}
     * @memberof ProductBuilderFabricChannelViewModel
     */
    'fabrics': Array<ProductBuilderFabricListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderFabricListViewModel
 */
export interface ProductBuilderFabricListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricListViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricListViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderFabricListViewModel
     */
    'price': number;
}
/**
 * 
 * @export
 * @interface ProductBuilderFabricViewModel
 */
export interface ProductBuilderFabricViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFabricViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {ConstructionType}
     * @memberof ProductBuilderFabricViewModel
     */
    'constructionTypeId': ConstructionType;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderFabricViewModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderFabricViewModel
     */
    'customPatternPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderFabricViewModel
     */
    'order': number;
    /**
     * 
     * @type {Array<ProductBuilderColourViewModel>}
     * @memberof ProductBuilderFabricViewModel
     */
    'colours': Array<ProductBuilderColourViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderFontViewModel
 */
export interface ProductBuilderFontViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFontViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFontViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderFontViewModel
     */
    'fontFileUrl': string;
}
/**
 * 
 * @export
 * @interface ProductBuilderLayerViewModel
 */
export interface ProductBuilderLayerViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderLayerViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderLayerViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderLayerViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderLayerViewModel
     */
    'frontMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderLayerViewModel
     */
    'backMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderLayerViewModel
     */
    'fabricChannelId'?: string | null;
    /**
     * 
     * @type {Array<ProductBuilderFabricViewModel>}
     * @memberof ProductBuilderLayerViewModel
     */
    'fabrics': Array<ProductBuilderFabricViewModel>;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderLayerViewModel
     */
    'prices': Array<PriceViewModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductBuilderOptionsGenerationLogType = {
    Warning: 'Warning',
    Error: 'Error'
} as const;

export type ProductBuilderOptionsGenerationLogType = typeof ProductBuilderOptionsGenerationLogType[keyof typeof ProductBuilderOptionsGenerationLogType];


/**
 * 
 * @export
 * @interface ProductBuilderOptionsGenerationLogViewModel
 */
export interface ProductBuilderOptionsGenerationLogViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'id': string;
    /**
     * 
     * @type {ProductBuilderOptionsGenerationLogType}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'productBuilderOptionsGenerationLogTypeId': ProductBuilderOptionsGenerationLogType;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'layerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'fabricChannelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'styleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'designId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'extraId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'colourId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'customizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'designCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'designName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'fabricChannelInternalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'fabricChannelExternalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'layerCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'layerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'productCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'styleCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'styleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'extraCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'extraName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'colourCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'colourName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'customizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsGenerationLogViewModel
     */
    'customizationName'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsGenerationLogViewModelPaginatedList
 */
export interface ProductBuilderOptionsGenerationLogViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductBuilderOptionsGenerationLogViewModel>}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'items': Array<ProductBuilderOptionsGenerationLogViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult
 */
export interface ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
 */
export interface ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductBuilderOptionsGenerationLogViewModel>}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductBuilderOptionsGenerationLogViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsViewModel
 */
export interface ProductBuilderOptionsViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsViewModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModel
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBuilderStyleViewModel>}
     * @memberof ProductBuilderOptionsViewModel
     */
    'styles': Array<ProductBuilderStyleViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderArtAssetCategoryViewModel>}
     * @memberof ProductBuilderOptionsViewModel
     */
    'artAssetCategories': Array<ProductBuilderArtAssetCategoryViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderFontViewModel>}
     * @memberof ProductBuilderOptionsViewModel
     */
    'fonts': Array<ProductBuilderFontViewModel>;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderOptionsViewModel
     */
    'prices': Array<PriceViewModel>;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductBuilderOptionsViewModel
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsViewModelApplicationResult
 */
export interface ProductBuilderOptionsViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductBuilderOptionsViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductBuilderOptionsViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelApplicationResultResultData}
     * @memberof ProductBuilderOptionsViewModelApplicationResult
     */
    'resultData'?: ProductBuilderOptionsViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsViewModelApplicationResultResultData
 */
export interface ProductBuilderOptionsViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBuilderStyleViewModel>}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'styles': Array<ProductBuilderStyleViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderArtAssetCategoryViewModel>}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'artAssetCategories': Array<ProductBuilderArtAssetCategoryViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderFontViewModel>}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'fonts': Array<ProductBuilderFontViewModel>;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'prices': Array<PriceViewModel>;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductBuilderOptionsViewModelApplicationResultResultData
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
}
/**
 * 
 * @export
 * @interface ProductBuilderOptionsViewModelPrimaryCategory
 */
export interface ProductBuilderOptionsViewModelPrimaryCategory {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'parentSlug': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'fullSlug': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'hasProducts': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'blurb': string;
    /**
     * 
     * @type {Array<CategoryImageViewModel>}
     * @memberof ProductBuilderOptionsViewModelPrimaryCategory
     */
    'thumbnailImages': Array<CategoryImageViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBuilderStyleViewModel
 */
export interface ProductBuilderStyleViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderStyleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderStyleViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderStyleViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderStyleViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderStyleViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBuilderStyleViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {Array<ProductBuilderDesignViewModel>}
     * @memberof ProductBuilderStyleViewModel
     */
    'designs': Array<ProductBuilderDesignViewModel>;
    /**
     * 
     * @type {Array<ProductBuilderExtraViewModel>}
     * @memberof ProductBuilderStyleViewModel
     */
    'extras': Array<ProductBuilderExtraViewModel>;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductBuilderStyleViewModel
     */
    'prices': Array<PriceViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminCreateModel
 */
export interface ProductBundleAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminCreateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminCreateModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminCreateModel
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBundleImageAdminCreateModel>}
     * @memberof ProductBundleAdminCreateModel
     */
    'thumbnailImages'?: Array<ProductBundleImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleImageAdminCreateModel>}
     * @memberof ProductBundleAdminCreateModel
     */
    'galleryImages'?: Array<ProductBundleImageAdminCreateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductBundleAdminCreateModel>}
     * @memberof ProductBundleAdminCreateModel
     */
    'relatedProducts'?: Array<RelatedProductBundleAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductBundleAdminCreateModel>}
     * @memberof ProductBundleAdminCreateModel
     */
    'productTags'?: Array<ProductTagProductBundleAdminCreateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleProductAdminCreateModel>}
     * @memberof ProductBundleAdminCreateModel
     */
    'products'?: Array<ProductBundleProductAdminCreateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductBundleCategoryAdminCreateModel>}
     * @memberof ProductBundleAdminCreateModel
     */
    'categories': Array<ProductBundleCategoryAdminCreateModel>;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminListViewModel
 */
export interface ProductBundleAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBundleAdminListViewModel
     */
    'categories': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminListViewModel
     */
    'isDisabled': boolean;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminListViewModelPaginatedList
 */
export interface ProductBundleAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductBundleAdminListViewModel>}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'items': Array<ProductBundleAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminListViewModelPaginatedListApplicationResult
 */
export interface ProductBundleAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductBundleAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductBundleAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductBundleAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductBundleAdminListViewModel>}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductBundleAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminUpdateModel
 */
export interface ProductBundleAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminUpdateModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminUpdateModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminUpdateModel
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBundleImageAdminUpdateModel>}
     * @memberof ProductBundleAdminUpdateModel
     */
    'thumbnailImages'?: Array<ProductBundleImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleImageAdminUpdateModel>}
     * @memberof ProductBundleAdminUpdateModel
     */
    'galleryImages'?: Array<ProductBundleImageAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<RelatedProductBundleAdminUpdateModel>}
     * @memberof ProductBundleAdminUpdateModel
     */
    'relatedProducts'?: Array<RelatedProductBundleAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductBundleProductAdminUpdateModel>}
     * @memberof ProductBundleAdminUpdateModel
     */
    'products'?: Array<ProductBundleProductAdminUpdateModel> | null;
    /**
     * 
     * @type {Array<ProductTagProductBundleAdminUpdateModel>}
     * @memberof ProductBundleAdminUpdateModel
     */
    'productTags'?: Array<ProductTagProductBundleAdminUpdateModel> | null;
    /**
     * One primary category is required
     * @type {Array<ProductBundleCategoryAdminUpdateModel>}
     * @memberof ProductBundleAdminUpdateModel
     */
    'categories': Array<ProductBundleCategoryAdminUpdateModel>;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminViewModel
 */
export interface ProductBundleAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModel
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleAdminViewModel
     */
    'thumbnailImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleAdminViewModel
     */
    'galleryImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<RelatedProductBundleAdminViewModel>}
     * @memberof ProductBundleAdminViewModel
     */
    'relatedProductBundles': Array<RelatedProductBundleAdminViewModel>;
    /**
     * 
     * @type {Array<ProductTagProductBundleAdminViewModel>}
     * @memberof ProductBundleAdminViewModel
     */
    'productTags': Array<ProductTagProductBundleAdminViewModel>;
    /**
     * 
     * @type {Array<ProductBundleCategoryAdminViewModel>}
     * @memberof ProductBundleAdminViewModel
     */
    'categories': Array<ProductBundleCategoryAdminViewModel>;
    /**
     * 
     * @type {Array<ProductBundleProductAdminViewModel>}
     * @memberof ProductBundleAdminViewModel
     */
    'products'?: Array<ProductBundleProductAdminViewModel> | null;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminViewModelApplicationResult
 */
export interface ProductBundleAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductBundleAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductBundleAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductBundleAdminViewModelApplicationResultResultData}
     * @memberof ProductBundleAdminViewModelApplicationResult
     */
    'resultData'?: ProductBundleAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductBundleAdminViewModelApplicationResultResultData
 */
export interface ProductBundleAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'thumbnailImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'galleryImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<RelatedProductBundleAdminViewModel>}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'relatedProductBundles': Array<RelatedProductBundleAdminViewModel>;
    /**
     * 
     * @type {Array<ProductTagProductBundleAdminViewModel>}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'productTags': Array<ProductTagProductBundleAdminViewModel>;
    /**
     * 
     * @type {Array<ProductBundleCategoryAdminViewModel>}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'categories': Array<ProductBundleCategoryAdminViewModel>;
    /**
     * 
     * @type {Array<ProductBundleProductAdminViewModel>}
     * @memberof ProductBundleAdminViewModelApplicationResultResultData
     */
    'products'?: Array<ProductBundleProductAdminViewModel> | null;
}
/**
 * 
 * @export
 * @interface ProductBundleCategoryAdminCreateModel
 */
export interface ProductBundleCategoryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleCategoryAdminCreateModel
     */
    'productCategoryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleCategoryAdminCreateModel
     */
    'isPrimaryCategory': boolean;
}
/**
 * 
 * @export
 * @interface ProductBundleCategoryAdminUpdateModel
 */
export interface ProductBundleCategoryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleCategoryAdminUpdateModel
     */
    'productCategoryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleCategoryAdminUpdateModel
     */
    'isPrimaryCategory': boolean;
}
/**
 * 
 * @export
 * @interface ProductBundleCategoryAdminViewModel
 */
export interface ProductBundleCategoryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleCategoryAdminViewModel
     */
    'productCategoryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleCategoryAdminViewModel
     */
    'isPrimaryCategory': boolean;
}
/**
 * 
 * @export
 * @interface ProductBundleImageAdminCreateModel
 */
export interface ProductBundleImageAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleImageAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleImageAdminCreateModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface ProductBundleImageAdminUpdateModel
 */
export interface ProductBundleImageAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleImageAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleImageAdminUpdateModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleImageAdminUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductBundleImageViewModel
 */
export interface ProductBundleImageViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleImageViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleImageViewModel
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface ProductBundleListViewModel
 */
export interface ProductBundleListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleListViewModel
     */
    'slug': string;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductBundleListViewModel
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleListViewModel
     */
    'thumbnailImages': Array<ProductBundleImageViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBundleListViewModelPaginatedList
 */
export interface ProductBundleListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductBundleListViewModel>}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'items': Array<ProductBundleListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductBundleListViewModelPaginatedListApplicationResult
 */
export interface ProductBundleListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductBundleListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductBundleListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductBundleListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductBundleListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductBundleListViewModel>}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductBundleListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductBundleProductAdminCreateModel
 */
export interface ProductBundleProductAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleProductAdminCreateModel
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductBundleProductAdminUpdateModel
 */
export interface ProductBundleProductAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleProductAdminUpdateModel
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductBundleProductAdminViewModel
 */
export interface ProductBundleProductAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleProductAdminViewModel
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductBundleViewModel
 */
export interface ProductBundleViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModel
     */
    'slug': string;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductBundleViewModel
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleViewModel
     */
    'thumbnailImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleViewModel
     */
    'galleryImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductBundleViewModel
     */
    'relatedProducts': Array<ProductListViewModel>;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductBundleViewModel
     */
    'products': Array<ProductListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductBundleViewModelApplicationResult
 */
export interface ProductBundleViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductBundleViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductBundleViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductBundleViewModelApplicationResultResultData}
     * @memberof ProductBundleViewModelApplicationResult
     */
    'resultData'?: ProductBundleViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductBundleViewModelApplicationResultResultData
 */
export interface ProductBundleViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'isNew': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'thumbnailImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<ProductBundleImageViewModel>}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'galleryImages': Array<ProductBundleImageViewModel>;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'relatedProducts': Array<ProductListViewModel>;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductBundleViewModelApplicationResultResultData
     */
    'products': Array<ProductListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductCategoryAdminCreateModel
 */
export interface ProductCategoryAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryAdminCreateModel
     */
    'productCategoryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryAdminCreateModel
     */
    'isPrimaryCategory': boolean;
}
/**
 * 
 * @export
 * @interface ProductCategoryAdminUpdateModel
 */
export interface ProductCategoryAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryAdminUpdateModel
     */
    'productCategoryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryAdminUpdateModel
     */
    'isPrimaryCategory': boolean;
}
/**
 * 
 * @export
 * @interface ProductCategoryAdminViewModel
 */
export interface ProductCategoryAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryAdminViewModel
     */
    'productCategoryId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryAdminViewModel
     */
    'isPrimaryCategory': boolean;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminCreateModel
 */
export interface ProductCategoryMenuGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminCreateModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminListViewModel
 */
export interface ProductCategoryMenuGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminListViewModelPaginatedList
 */
export interface ProductCategoryMenuGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductCategoryMenuGroupAdminListViewModel>}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'items': Array<ProductCategoryMenuGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductCategoryMenuGroupAdminListViewModel>}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductCategoryMenuGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminUpdateModel
 */
export interface ProductCategoryMenuGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminUpdateModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminViewModel
 */
export interface ProductCategoryMenuGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminViewModelApplicationResult
 */
export interface ProductCategoryMenuGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductCategoryMenuGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductCategoryMenuGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductCategoryMenuGroupAdminViewModelApplicationResultResultData}
     * @memberof ProductCategoryMenuGroupAdminViewModelApplicationResult
     */
    'resultData'?: ProductCategoryMenuGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupAdminViewModelApplicationResultResultData
 */
export interface ProductCategoryMenuGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupViewModel
 */
export interface ProductCategoryMenuGroupViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryMenuGroupViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<CategoryListViewModel>}
     * @memberof ProductCategoryMenuGroupViewModel
     */
    'categories': Array<CategoryListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductCategoryMenuGroupViewModelListApplicationResult
 */
export interface ProductCategoryMenuGroupViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductCategoryMenuGroupViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductCategoryMenuGroupViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryMenuGroupViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<ProductCategoryMenuGroupViewModel>}
     * @memberof ProductCategoryMenuGroupViewModelListApplicationResult
     */
    'resultData'?: Array<ProductCategoryMenuGroupViewModel> | null;
}
/**
 * 
 * @export
 * @interface ProductImageAdminCreateModel
 */
export interface ProductImageAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductImageAdminCreateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ProductImageAdminCreateModel
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface ProductImageAdminUpdateModel
 */
export interface ProductImageAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductImageAdminUpdateModel
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ProductImageAdminUpdateModel
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageAdminUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductImageViewModel
 */
export interface ProductImageViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductImageViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductImageViewModel
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface ProductListViewModel
 */
export interface ProductListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModel
     */
    'defaultQuantityPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductListViewModel
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductListViewModel
     */
    'thumbnailImages': Array<ProductImageViewModel>;
}
/**
 * 
 * @export
 * @interface ProductListViewModelPaginatedList
 */
export interface ProductListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductListViewModelPaginatedList
     */
    'items': Array<ProductListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductListViewModelPaginatedListApplicationResult
 */
export interface ProductListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminCreateModel
 */
export interface ProductProductionSpeedAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminCreateModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminCreateModel
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminListViewModel
 */
export interface ProductProductionSpeedAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminListViewModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminListViewModel
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminListViewModelPaginatedList
 */
export interface ProductProductionSpeedAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductProductionSpeedAdminListViewModel>}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'items': Array<ProductProductionSpeedAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult
 */
export interface ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductProductionSpeedAdminListViewModel>}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductProductionSpeedAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminUpdateModel
 */
export interface ProductProductionSpeedAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminUpdateModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminUpdateModel
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminViewModel
 */
export interface ProductProductionSpeedAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminViewModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminViewModel
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminViewModelApplicationResult
 */
export interface ProductProductionSpeedAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductProductionSpeedAdminViewModelApplicationResultResultData}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResult
     */
    'resultData'?: ProductProductionSpeedAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductProductionSpeedAdminViewModelApplicationResultResultData
 */
export interface ProductProductionSpeedAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResultResultData
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionSpeedAdminViewModelApplicationResultResultData
     */
    'productionSpeedId': string;
}
/**
 * 
 * @export
 * @interface ProductSizeAttributeValueViewModel
 */
export interface ProductSizeAttributeValueViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductSizeAttributeValueViewModel
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeAttributeValueViewModel
     */
    'value': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideListViewModel>}
     * @memberof ProductSizeAttributeValueViewModel
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductSizeGroupAttributeViewModel
 */
export interface ProductSizeGroupAttributeViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductSizeGroupAttributeViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeGroupAttributeViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductSizeGroupViewModel
 */
export interface ProductSizeGroupViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductSizeGroupViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeGroupViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {Array<ProductSizeGroupAttributeViewModel>}
     * @memberof ProductSizeGroupViewModel
     */
    'attributes': Array<ProductSizeGroupAttributeViewModel>;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeGroupViewModel
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductSizeViewModel
 */
export interface ProductSizeViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductSizeViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<ProductSizeAttributeValueViewModel>}
     * @memberof ProductSizeViewModel
     */
    'attributesValues': Array<ProductSizeAttributeValueViewModel>;
}
/**
 * 
 * @export
 * @interface ProductStyleGroupAdminCreateModel
 */
export interface ProductStyleGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductStyleGroupAdminCreateModel
     */
    'productStyleGroupId': string;
}
/**
 * 
 * @export
 * @interface ProductStyleGroupAdminUpdateModel
 */
export interface ProductStyleGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductStyleGroupAdminUpdateModel
     */
    'productStyleGroupId': string;
}
/**
 * 
 * @export
 * @interface ProductStyleGroupAdminViewModel
 */
export interface ProductStyleGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductStyleGroupAdminViewModel
     */
    'productStyleGroupId': string;
}
/**
 * 
 * @export
 * @interface ProductStyleViewModel
 */
export interface ProductStyleViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductStyleViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<ProductSizeGroupViewModel>}
     * @memberof ProductStyleViewModel
     */
    'sizeGroups': Array<ProductSizeGroupViewModel>;
    /**
     * 
     * @type {Array<ProductSizeViewModel>}
     * @memberof ProductStyleViewModel
     */
    'sizes': Array<ProductSizeViewModel>;
    /**
     * 
     * @type {Array<ProductAdjustmentTypeViewModel>}
     * @memberof ProductStyleViewModel
     */
    'adjustmentTypes': Array<ProductAdjustmentTypeViewModel>;
    /**
     * 
     * @type {Array<ProductAdjustmentViewModel>}
     * @memberof ProductStyleViewModel
     */
    'adjustments': Array<ProductAdjustmentViewModel>;
    /**
     * 
     * @type {string}
     * @memberof ProductStyleViewModel
     */
    'sizeGuidelines'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductStyleViewModelApplicationResult
 */
export interface ProductStyleViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductStyleViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductStyleViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductStyleViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductStyleViewModelApplicationResultResultData}
     * @memberof ProductStyleViewModelApplicationResult
     */
    'resultData'?: ProductStyleViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductStyleViewModelApplicationResultResultData
 */
export interface ProductStyleViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductStyleViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {Array<ProductSizeGroupViewModel>}
     * @memberof ProductStyleViewModelApplicationResultResultData
     */
    'sizeGroups': Array<ProductSizeGroupViewModel>;
    /**
     * 
     * @type {Array<ProductSizeViewModel>}
     * @memberof ProductStyleViewModelApplicationResultResultData
     */
    'sizes': Array<ProductSizeViewModel>;
    /**
     * 
     * @type {Array<ProductAdjustmentTypeViewModel>}
     * @memberof ProductStyleViewModelApplicationResultResultData
     */
    'adjustmentTypes': Array<ProductAdjustmentTypeViewModel>;
    /**
     * 
     * @type {Array<ProductAdjustmentViewModel>}
     * @memberof ProductStyleViewModelApplicationResultResultData
     */
    'adjustments': Array<ProductAdjustmentViewModel>;
    /**
     * 
     * @type {string}
     * @memberof ProductStyleViewModelApplicationResultResultData
     */
    'sizeGuidelines'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductTagAdminCreateModel
 */
export interface ProductTagAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTagAdminCreateModel
     */
    'parentProductTagId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductTagAdminUpdateModel
 */
export interface ProductTagAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTagAdminUpdateModel
     */
    'parentProductTagId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductTagProductAdminCreateModel
 */
export interface ProductTagProductAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagProductAdminCreateModel
     */
    'productTagId': string;
}
/**
 * 
 * @export
 * @interface ProductTagProductAdminUpdateModel
 */
export interface ProductTagProductAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagProductAdminUpdateModel
     */
    'productTagId': string;
}
/**
 * 
 * @export
 * @interface ProductTagProductAdminViewModel
 */
export interface ProductTagProductAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagProductAdminViewModel
     */
    'productTagId': string;
}
/**
 * 
 * @export
 * @interface ProductTagProductBundleAdminCreateModel
 */
export interface ProductTagProductBundleAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagProductBundleAdminCreateModel
     */
    'productTagId': string;
}
/**
 * 
 * @export
 * @interface ProductTagProductBundleAdminUpdateModel
 */
export interface ProductTagProductBundleAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagProductBundleAdminUpdateModel
     */
    'productTagId': string;
}
/**
 * 
 * @export
 * @interface ProductTagProductBundleAdminViewModel
 */
export interface ProductTagProductBundleAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagProductBundleAdminViewModel
     */
    'productTagId': string;
}
/**
 * 
 * @export
 * @interface ProductTagViewModel
 */
export interface ProductTagViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTagViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTagViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTagViewModel
     */
    'isChild': boolean;
    /**
     * 
     * @type {Array<ProductTagViewModel>}
     * @memberof ProductTagViewModel
     */
    'childProductTags': Array<ProductTagViewModel>;
}
/**
 * 
 * @export
 * @interface ProductTagViewModelApplicationResult
 */
export interface ProductTagViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductTagViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductTagViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTagViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductTagViewModelApplicationResultResultData}
     * @memberof ProductTagViewModelApplicationResult
     */
    'resultData'?: ProductTagViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductTagViewModelApplicationResultResultData
 */
export interface ProductTagViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductTagViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTagViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTagViewModelApplicationResultResultData
     */
    'isChild': boolean;
    /**
     * 
     * @type {Array<ProductTagViewModel>}
     * @memberof ProductTagViewModelApplicationResultResultData
     */
    'childProductTags': Array<ProductTagViewModel>;
}
/**
 * 
 * @export
 * @interface ProductTagViewModelListApplicationResult
 */
export interface ProductTagViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductTagViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductTagViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTagViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<ProductTagViewModel>}
     * @memberof ProductTagViewModelListApplicationResult
     */
    'resultData'?: Array<ProductTagViewModel> | null;
}
/**
 * 
 * @export
 * @interface ProductViewModel
 */
export interface ProductViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModel
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModel
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductViewModel
     */
    'defaultQuantityPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductViewModel
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductViewModel
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {Array<ProductStyleViewModel>}
     * @memberof ProductViewModel
     */
    'styles': Array<ProductStyleViewModel>;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductViewModel
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductViewModel
     */
    'prices': Array<PriceViewModel>;
    /**
     * 
     * @type {Array<ProductionSpeedViewModel>}
     * @memberof ProductViewModel
     */
    'productionSpeeds': Array<ProductionSpeedViewModel>;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductViewModel
     */
    'thumbnailImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductViewModel
     */
    'galleryImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductViewModel
     */
    'relatedProducts': Array<ProductListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductViewModelApplicationResult
 */
export interface ProductViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductViewModelApplicationResultResultData}
     * @memberof ProductViewModelApplicationResult
     */
    'resultData'?: ProductViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductViewModelApplicationResultResultData
 */
export interface ProductViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'blurb': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'sizeGuidelines'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'seoTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'seoKeywords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'seoDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'isFeatured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'isNew': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'defaultQuantityPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'minimumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'defaultQuantity'?: number | null;
    /**
     * 
     * @type {Array<ProductStyleViewModel>}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'styles': Array<ProductStyleViewModel>;
    /**
     * 
     * @type {ProductBuilderOptionsViewModelPrimaryCategory}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'primaryCategory'?: ProductBuilderOptionsViewModelPrimaryCategory | null;
    /**
     * 
     * @type {Array<PriceViewModel>}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'prices': Array<PriceViewModel>;
    /**
     * 
     * @type {Array<ProductionSpeedViewModel>}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'productionSpeeds': Array<ProductionSpeedViewModel>;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'thumbnailImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<ProductImageViewModel>}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'galleryImages': Array<ProductImageViewModel>;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof ProductViewModelApplicationResultResultData
     */
    'relatedProducts': Array<ProductListViewModel>;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentModifyModel
 */
export interface ProductionSpeedAdjustmentModifyModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentModifyModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentModifyModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentModifyModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentModifyModel
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentModifyModel
     */
    'deliveryDelay': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentModifyModel
     */
    'productionDelay': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentViewModel
 */
export interface ProductionSpeedAdjustmentViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'deliveryDelay': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModel
     */
    'productionDelay': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentViewModelApplicationResult
 */
export interface ProductionSpeedAdjustmentViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductionSpeedAdjustmentViewModelApplicationResultResultData}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResult
     */
    'resultData'?: ProductionSpeedAdjustmentViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentViewModelApplicationResultResultData
 */
export interface ProductionSpeedAdjustmentViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'deliveryDelay': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelApplicationResultResultData
     */
    'productionDelay': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentViewModelPaginatedList
 */
export interface ProductionSpeedAdjustmentViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductionSpeedAdjustmentViewModel>}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'items': Array<ProductionSpeedAdjustmentViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult
 */
export interface ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
 */
export interface ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductionSpeedAdjustmentViewModel>}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductionSpeedAdjustmentViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdjustmentViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminCreateModel
 */
export interface ProductionSpeedAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminCreateModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminCreateModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminCreateModel
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminCreateModel
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ProductionSpeedAdminCreateModel
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminListViewModel
 */
export interface ProductionSpeedAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ProductionSpeedAdminListViewModel
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminListViewModelPaginatedList
 */
export interface ProductionSpeedAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductionSpeedAdminListViewModel>}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'items': Array<ProductionSpeedAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminListViewModelPaginatedListApplicationResult
 */
export interface ProductionSpeedAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<ProductionSpeedAdminListViewModel>}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<ProductionSpeedAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminUpdateModel
 */
export interface ProductionSpeedAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminUpdateModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminUpdateModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminUpdateModel
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminUpdateModel
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ProductionSpeedAdminUpdateModel
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminViewModel
 */
export interface ProductionSpeedAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminViewModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminViewModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminViewModel
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminViewModel
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ProductionSpeedAdminViewModel
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminViewModelApplicationResult
 */
export interface ProductionSpeedAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof ProductionSpeedAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof ProductionSpeedAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {ProductionSpeedAdminViewModelApplicationResultResultData}
     * @memberof ProductionSpeedAdminViewModelApplicationResult
     */
    'resultData'?: ProductionSpeedAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface ProductionSpeedAdminViewModelApplicationResultResultData
 */
export interface ProductionSpeedAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ProductionSpeedAdminViewModelApplicationResultResultData
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface ProductionSpeedViewModel
 */
export interface ProductionSpeedViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionSpeedViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedViewModel
     */
    'pricePerOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedViewModel
     */
    'pricePerItem': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionSpeedViewModel
     */
    'pricePerItemIsPercent': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionSpeedViewModel
     */
    'productionDays': number;
    /**
     * 
     * @type {DeliveryType}
     * @memberof ProductionSpeedViewModel
     */
    'deliveryTypeId': DeliveryType;
}
/**
 * 
 * @export
 * @interface RefreshTokenModel
 */
export interface RefreshTokenModel {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenModel
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RegisterModel
 */
export interface RegisterModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterModel
     */
    'googleRecaptchaResponse': string;
}
/**
 * 
 * @export
 * @interface RelatedProductAdminCreateModel
 */
export interface RelatedProductAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof RelatedProductAdminCreateModel
     */
    'relatedProductId': string;
}
/**
 * 
 * @export
 * @interface RelatedProductAdminUpdateModel
 */
export interface RelatedProductAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof RelatedProductAdminUpdateModel
     */
    'relatedProductId': string;
}
/**
 * 
 * @export
 * @interface RelatedProductAdminViewModel
 */
export interface RelatedProductAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof RelatedProductAdminViewModel
     */
    'relatedProductId': string;
}
/**
 * 
 * @export
 * @interface RelatedProductBundleAdminCreateModel
 */
export interface RelatedProductBundleAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof RelatedProductBundleAdminCreateModel
     */
    'relatedProductId': string;
}
/**
 * 
 * @export
 * @interface RelatedProductBundleAdminUpdateModel
 */
export interface RelatedProductBundleAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof RelatedProductBundleAdminUpdateModel
     */
    'relatedProductId': string;
}
/**
 * 
 * @export
 * @interface RelatedProductBundleAdminViewModel
 */
export interface RelatedProductBundleAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof RelatedProductBundleAdminViewModel
     */
    'relatedProductId': string;
}
/**
 * 
 * @export
 * @interface SizeAdminCreateModel
 */
export interface SizeAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminCreateModel
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface SizeAdminListViewModel
 */
export interface SizeAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminListViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminListViewModel
     */
    'sizeGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminListViewModel
     */
    'sizeGroupDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminListViewModel
     */
    'sizeGroupAttributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeAdminListViewModelListApplicationResult
 */
export interface SizeAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<SizeAdminListViewModel>}
     * @memberof SizeAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<SizeAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface SizeAdminListViewModelPaginatedList
 */
export interface SizeAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeAdminListViewModel>}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'items': Array<SizeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeAdminListViewModelPaginatedListApplicationResult
 */
export interface SizeAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: SizeAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface SizeAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeAdminListViewModel>}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<SizeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeAdminUpdateModel
 */
export interface SizeAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminUpdateModel
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface SizeAdminViewModel
 */
export interface SizeAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModel
     */
    'sizeGroupAttributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeAdminViewModelApplicationResult
 */
export interface SizeAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeAdminViewModelApplicationResultResultData}
     * @memberof SizeAdminViewModelApplicationResult
     */
    'resultData'?: SizeAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeAdminViewModelApplicationResultResultData
 */
export interface SizeAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModelApplicationResultResultData
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeAdminViewModelApplicationResultResultData
     */
    'sizeGroupAttributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminCreateModel
 */
export interface SizeGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminCreateModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminCreateModel
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminListViewModel
 */
export interface SizeGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminListViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminListViewModel
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminListViewModelListApplicationResult
 */
export interface SizeGroupAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<SizeGroupAdminListViewModel>}
     * @memberof SizeGroupAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<SizeGroupAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminListViewModelPaginatedList
 */
export interface SizeGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeGroupAdminListViewModel>}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'items': Array<SizeGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface SizeGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: SizeGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface SizeGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeGroupAdminListViewModel>}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<SizeGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminUpdateModel
 */
export interface SizeGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminUpdateModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminUpdateModel
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminViewModel
 */
export interface SizeGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModel
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminViewModelApplicationResult
 */
export interface SizeGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeGroupAdminViewModelApplicationResultResultData}
     * @memberof SizeGroupAdminViewModelApplicationResult
     */
    'resultData'?: SizeGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAdminViewModelApplicationResultResultData
 */
export interface SizeGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModelApplicationResultResultData
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAdminViewModelApplicationResultResultData
     */
    'attributeValueOverrideAdjustmentTypeId'?: string | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminCreateModel
 */
export interface SizeGroupAttributeAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminCreateModel
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminListViewModel
 */
export interface SizeGroupAttributeAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminListViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminListViewModel
     */
    'sizeGroupName': string;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminListViewModelListApplicationResult
 */
export interface SizeGroupAttributeAdminListViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAttributeAdminListViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAttributeAdminListViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminListViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<SizeGroupAttributeAdminListViewModel>}
     * @memberof SizeGroupAttributeAdminListViewModelListApplicationResult
     */
    'resultData'?: Array<SizeGroupAttributeAdminListViewModel> | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminListViewModelPaginatedList
 */
export interface SizeGroupAttributeAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeGroupAttributeAdminListViewModel>}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'items': Array<SizeGroupAttributeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult
 */
export interface SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeGroupAttributeAdminListViewModel>}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<SizeGroupAttributeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminUpdateModel
 */
export interface SizeGroupAttributeAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminUpdateModel
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminViewModel
 */
export interface SizeGroupAttributeAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminViewModel
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminViewModelApplicationResult
 */
export interface SizeGroupAttributeAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeGroupAttributeAdminViewModelApplicationResultResultData}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResult
     */
    'resultData'?: SizeGroupAttributeAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeAdminViewModelApplicationResultResultData
 */
export interface SizeGroupAttributeAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeAdminViewModelApplicationResultResultData
     */
    'sizeGroupId': string;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminCreateModel
 */
export interface SizeGroupAttributeSizeValueAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminCreateModel
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminCreateModel
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminCreateModel
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>}
     * @memberof SizeGroupAttributeSizeValueAdminCreateModel
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminListViewModel
 */
export interface SizeGroupAttributeSizeValueAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'sizeGroupAttributeName': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'sizeId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'sizeName': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideListViewModel>}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModel
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideListViewModel>;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
 */
export interface SizeGroupAttributeSizeValueAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueAdminListViewModel>}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'items': Array<SizeGroupAttributeSizeValueAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult
 */
export interface SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueAdminListViewModel>}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<SizeGroupAttributeSizeValueAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminUpdateModel
 */
export interface SizeGroupAttributeSizeValueAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminUpdateModel
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminUpdateModel
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminUpdateModel
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>}
     * @memberof SizeGroupAttributeSizeValueAdminUpdateModel
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminViewModel
 */
export interface SizeGroupAttributeSizeValueAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModel
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModel
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModel
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideListViewModel>}
     * @memberof SizeGroupAttributeSizeValueAdminViewModel
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideListViewModel>;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminViewModelApplicationResult
 */
export interface SizeGroupAttributeSizeValueAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResult
     */
    'resultData'?: SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
 */
export interface SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
     */
    'sizeGroupAttributeId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
     */
    'sizeId': string;
    /**
     * 
     * @type {Array<SizeGroupAttributeSizeValueOverrideListViewModel>}
     * @memberof SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
     */
    'overrideValues': Array<SizeGroupAttributeSizeValueOverrideListViewModel>;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel
 */
export interface SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel
     */
    'adjustmentId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueOverrideAdminCreateUpdateModel
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SizeGroupAttributeSizeValueOverrideListViewModel
 */
export interface SizeGroupAttributeSizeValueOverrideListViewModel {
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueOverrideListViewModel
     */
    'adjustmentId': string;
    /**
     * 
     * @type {string}
     * @memberof SizeGroupAttributeSizeValueOverrideListViewModel
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Ascending: 'Ascending',
    Descending: 'Descending'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @interface StoreAdminListViewModel
 */
export interface StoreAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreAdminListViewModel
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'storeOwnerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'storeOwnerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'currencyId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'currencyName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'orderManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminListViewModel
     */
    'salesManagerAspNetUserName'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreAdminListViewModelPaginatedList
 */
export interface StoreAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StoreAdminListViewModel>}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'items': Array<StoreAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StoreAdminListViewModelPaginatedListApplicationResult
 */
export interface StoreAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StoreAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface StoreAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StoreAdminListViewModel>}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StoreAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StoreAdminUpdateModel
 */
export interface StoreAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'ownerAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'orderManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'salesManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminUpdateModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreAdminUpdateModel
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminUpdateModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreAdminViewModel
 */
export interface StoreAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'ownerAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'orderManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'salesManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminViewModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreType}
     * @memberof StoreAdminViewModel
     */
    'storeTypeId': StoreType;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreAdminViewModel
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {StoreAdminViewModelCurrency}
     * @memberof StoreAdminViewModel
     */
    'currency': StoreAdminViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminViewModel
     */
    'canChangeClosedDate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface StoreAdminViewModelApplicationResult
 */
export interface StoreAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreAdminViewModelApplicationResultResultData}
     * @memberof StoreAdminViewModelApplicationResult
     */
    'resultData'?: StoreAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreAdminViewModelApplicationResultResultData
 */
export interface StoreAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'ownerAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'orderManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'salesManagerAspNetUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreType}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'storeTypeId': StoreType;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'countryName': string;
    /**
     * 
     * @type {StoreAdminViewModelCurrency}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'currency': StoreAdminViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoreAdminViewModelApplicationResultResultData
     */
    'canChangeClosedDate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface StoreAdminViewModelCurrency
 */
export interface StoreAdminViewModelCurrency {
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelCurrency
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelCurrency
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelCurrency
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StoreAdminViewModelCurrency
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminViewModelCurrency
     */
    'pricingMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof StoreAdminViewModelCurrency
     */
    'decimalPlaces': number;
}
/**
 * 
 * @export
 * @interface StoreCreateModel
 */
export interface StoreCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreCreateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCreateModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCreateModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductCreateModel
 */
export interface StoreCustomizedProductCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductCreateModel
     */
    'customizedProductId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductCreateModel
     */
    'storeId': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductCreateModel
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductCreateModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductCreateModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductListViewModel
 */
export interface StoreCustomizedProductListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModel
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'customizedProductPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'customizedProductBackPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'customizedProductTitle': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'customizedProductName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModel
     */
    'customizedProductMinimumQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductListViewModel
     */
    'customizedProductStyleId': string;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductListViewModelPaginatedList
 */
export interface StoreCustomizedProductListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StoreCustomizedProductListViewModel>}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'items': Array<StoreCustomizedProductListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductListViewModelPaginatedListApplicationResult
 */
export interface StoreCustomizedProductListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
 */
export interface StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StoreCustomizedProductListViewModel>}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StoreCustomizedProductListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductUpdateModel
 */
export interface StoreCustomizedProductUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductUpdateModel
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductUpdateModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductUpdateModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductViewModel
 */
export interface StoreCustomizedProductViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductViewModel
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductViewModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModel
     */
    'customizedProductPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModel
     */
    'customizedProductBackPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModel
     */
    'customizedProductTitle': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModel
     */
    'customizedProductName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductViewModelApplicationResult
 */
export interface StoreCustomizedProductViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreCustomizedProductViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreCustomizedProductViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCustomizedProductViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreCustomizedProductViewModelApplicationResultResultData}
     * @memberof StoreCustomizedProductViewModelApplicationResult
     */
    'resultData'?: StoreCustomizedProductViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductViewModelApplicationResultResultData
 */
export interface StoreCustomizedProductViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'customizedProductPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'customizedProductBackPreviewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'customizedProductTitle': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'customizedProductName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductViewModelApplicationResultResultData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductsIdDeleteRequest
 */
export interface StoreCustomizedProductsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductsIdDeleteRequest
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductsIdDeleteRequest
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductsIdDeleteRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreCustomizedProductsPostRequest
 */
export interface StoreCustomizedProductsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductsPostRequest
     */
    'customizedProductId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductsPostRequest
     */
    'storeId': string;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductsPostRequest
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StoreCustomizedProductsPostRequest
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof StoreCustomizedProductsPostRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreListViewModel
 */
export interface StoreListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreListViewModel
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'currencyId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'currencyName': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreListViewModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreListViewModelPaginatedList
 */
export interface StoreListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StoreListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StoreListViewModel>}
     * @memberof StoreListViewModelPaginatedList
     */
    'items': Array<StoreListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StoreListViewModelPaginatedListApplicationResult
 */
export interface StoreListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreListViewModelPaginatedListApplicationResultResultData}
     * @memberof StoreListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StoreListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreListViewModelPaginatedListApplicationResultResultData
 */
export interface StoreListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StoreListViewModel>}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StoreListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StoreListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StoreOrderCartCreateModel
 */
export interface StoreOrderCartCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartCreateModel
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartCreateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartCreateModel
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface StoreOrderCartItemCreateModel
 */
export interface StoreOrderCartItemCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartItemCreateModel
     */
    'storeCustomizedProductId': string;
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof StoreOrderCartItemCreateModel
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof StoreOrderCartItemCreateModel
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface StoreOrderCartItemUpdateModel
 */
export interface StoreOrderCartItemUpdateModel {
    /**
     * 
     * @type {Array<OrderCartItemQuantityCreateUpdateModel>}
     * @memberof StoreOrderCartItemUpdateModel
     */
    'quantities'?: Array<OrderCartItemQuantityCreateUpdateModel> | null;
    /**
     * 
     * @type {number}
     * @memberof StoreOrderCartItemUpdateModel
     */
    'totalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface StoreOrderCartItemViewModel
 */
export interface StoreOrderCartItemViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartItemViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof StoreOrderCartItemViewModel
     */
    'price': number;
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof StoreOrderCartItemViewModel
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderCartItemQuantityViewModel>}
     * @memberof StoreOrderCartItemViewModel
     */
    'quantities': Array<OrderCartItemQuantityViewModel>;
}
/**
 * 
 * @export
 * @interface StoreOrderCartItemViewModelApplicationResult
 */
export interface StoreOrderCartItemViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreOrderCartItemViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreOrderCartItemViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreOrderCartItemViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreOrderCartItemViewModelApplicationResultResultData}
     * @memberof StoreOrderCartItemViewModelApplicationResult
     */
    'resultData'?: StoreOrderCartItemViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreOrderCartItemViewModelApplicationResultResultData
 */
export interface StoreOrderCartItemViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartItemViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof StoreOrderCartItemViewModelApplicationResultResultData
     */
    'price': number;
    /**
     * 
     * @type {EnquiryCustomizedProductViewModelCustomizedProduct}
     * @memberof StoreOrderCartItemViewModelApplicationResultResultData
     */
    'customizedProduct': EnquiryCustomizedProductViewModelCustomizedProduct;
    /**
     * 
     * @type {Array<OrderCartItemQuantityViewModel>}
     * @memberof StoreOrderCartItemViewModelApplicationResultResultData
     */
    'quantities': Array<OrderCartItemQuantityViewModel>;
}
/**
 * 
 * @export
 * @interface StoreOrderCartUpdateModel
 */
export interface StoreOrderCartUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartUpdateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartUpdateModel
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface StoreOrderCartViewModel
 */
export interface StoreOrderCartViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModel
     */
    'storeId': string;
    /**
     * 
     * @type {Array<StoreOrderCartItemViewModel>}
     * @memberof StoreOrderCartViewModel
     */
    'items': Array<StoreOrderCartItemViewModel>;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModel
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModel
     */
    'referenceNumber': string;
}
/**
 * 
 * @export
 * @interface StoreOrderCartViewModelApplicationResult
 */
export interface StoreOrderCartViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreOrderCartViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreOrderCartViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreOrderCartViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreOrderCartViewModelApplicationResultResultData}
     * @memberof StoreOrderCartViewModelApplicationResult
     */
    'resultData'?: StoreOrderCartViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreOrderCartViewModelApplicationResultResultData
 */
export interface StoreOrderCartViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'storeId': string;
    /**
     * 
     * @type {Array<StoreOrderCartItemViewModel>}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'items': Array<StoreOrderCartItemViewModel>;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreOrderCartViewModelApplicationResultResultData
     */
    'referenceNumber': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoreStatus = {
    Editing: 'Editing',
    AwaitingApproval: 'AwaitingApproval',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Deactivated: 'Deactivated',
    Closed: 'Closed'
} as const;

export type StoreStatus = typeof StoreStatus[keyof typeof StoreStatus];


/**
 * 
 * @export
 * @interface StoreStatusViewModel
 */
export interface StoreStatusViewModel {
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreStatusViewModel
     */
    'id': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreStatusViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StoreStatusViewModelListApplicationResult
 */
export interface StoreStatusViewModelListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreStatusViewModelListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreStatusViewModelListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreStatusViewModelListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<StoreStatusViewModel>}
     * @memberof StoreStatusViewModelListApplicationResult
     */
    'resultData'?: Array<StoreStatusViewModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoreType = {
    Private: 'Private'
} as const;

export type StoreType = typeof StoreType[keyof typeof StoreType];


/**
 * 
 * @export
 * @interface StoreUpdateModel
 */
export interface StoreUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StoreUpdateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreUpdateModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreUpdateModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoreUpdateModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreUpdateModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreViewModel
 */
export interface StoreViewModel {
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreViewModel
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreViewModel
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'countryName': string;
    /**
     * 
     * @type {StoreAdminViewModelCurrency}
     * @memberof StoreViewModel
     */
    'currency': StoreAdminViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof StoreViewModel
     */
    'orderStatusId'?: OrderStatus | null;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreViewModelApplicationResult
 */
export interface StoreViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StoreViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StoreViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StoreViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StoreViewModelApplicationResultResultData}
     * @memberof StoreViewModelApplicationResult
     */
    'resultData'?: StoreViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StoreViewModelApplicationResultResultData
 */
export interface StoreViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'storeStatusId': StoreStatus;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'countryName': string;
    /**
     * 
     * @type {StoreAdminViewModelCurrency}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'currency': StoreAdminViewModelCurrency;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'orderStatusId'?: OrderStatus | null;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StoreViewModelApplicationResultResultData
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoresGetRequest
 */
export interface StoresGetRequest {
    /**
     * 
     * @type {string}
     * @memberof StoresGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoresGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoresGetRequest
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoresGetRequest
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoresGetRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoresGetRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StoresIdDeleteRequest
 */
export interface StoresIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof StoresIdDeleteRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoresIdDeleteRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoresIdDeleteRequest
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof StoresIdDeleteRequest
     */
    'deliveryFeePerCustomer'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoresIdDeleteRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof StoresIdDeleteRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StringApplicationResult
 */
export interface StringApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StringApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StringApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StringApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {string}
     * @memberof StringApplicationResult
     */
    'resultData'?: string | null;
}
/**
 * 
 * @export
 * @interface StringListApplicationResult
 */
export interface StringListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StringListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StringListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StringListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringListApplicationResult
     */
    'resultData'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface StringStringDictionaryApplicationResult
 */
export interface StringStringDictionaryApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StringStringDictionaryApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StringStringDictionaryApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StringStringDictionaryApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StringStringDictionaryApplicationResult
     */
    'resultData'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminCreateModel
 */
export interface StyleAdjustmentTypeAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminCreateModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminCreateModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdjustmentTypeAdminCreateModel
     */
    'adjustmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminListViewModel
 */
export interface StyleAdjustmentTypeAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminListViewModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminListViewModel
     */
    'adjustmentTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminListViewModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<AdjustmentAdminListViewModel>}
     * @memberof StyleAdjustmentTypeAdminListViewModel
     */
    'adjustments': Array<AdjustmentAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminListViewModelPaginatedList
 */
export interface StyleAdjustmentTypeAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleAdjustmentTypeAdminListViewModel>}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'items': Array<StyleAdjustmentTypeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult
 */
export interface StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleAdjustmentTypeAdminListViewModel>}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StyleAdjustmentTypeAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminUpdateModel
 */
export interface StyleAdjustmentTypeAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminUpdateModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminUpdateModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdjustmentTypeAdminUpdateModel
     */
    'adjustmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminViewModel
 */
export interface StyleAdjustmentTypeAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminViewModel
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminViewModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdjustmentTypeAdminViewModel
     */
    'adjustmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminViewModelApplicationResult
 */
export interface StyleAdjustmentTypeAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleAdjustmentTypeAdminViewModelApplicationResultResultData}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResult
     */
    'resultData'?: StyleAdjustmentTypeAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleAdjustmentTypeAdminViewModelApplicationResultResultData
 */
export interface StyleAdjustmentTypeAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'adjustmentTypeId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdjustmentTypeAdminViewModelApplicationResultResultData
     */
    'adjustmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleAdminCreateModel
 */
export interface StyleAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminCreateModel
     */
    'factoryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminCreateModel
     */
    'designGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminCreateModel
     */
    'extraGroupIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleAdminListViewModel
 */
export interface StyleAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface StyleAdminListViewModelPaginatedList
 */
export interface StyleAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleAdminListViewModel>}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'items': Array<StyleAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleAdminListViewModelPaginatedListApplicationResult
 */
export interface StyleAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StyleAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface StyleAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleAdminListViewModel>}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StyleAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleAdminUpdateModel
 */
export interface StyleAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminUpdateModel
     */
    'factoryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminUpdateModel
     */
    'designGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminUpdateModel
     */
    'extraGroupIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleAdminViewModel
 */
export interface StyleAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModel
     */
    'factoryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminViewModel
     */
    'designGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminViewModel
     */
    'extraGroupIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminViewModel
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface StyleAdminViewModelApplicationResult
 */
export interface StyleAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleAdminViewModelApplicationResultResultData}
     * @memberof StyleAdminViewModelApplicationResult
     */
    'resultData'?: StyleAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleAdminViewModelApplicationResultResultData
 */
export interface StyleAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'previewImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'frontShadowMaskUrl': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'backShadowMaskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'manufacturerReference': string;
    /**
     * 
     * @type {string}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'factoryId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'designGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'extraGroupIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof StyleAdminViewModelApplicationResultResultData
     */
    'hasProductBuilderOptionsGenerationLogs': boolean;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminCreateModel
 */
export interface StyleGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminCreateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminCreateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminCreateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGroupAdminCreateModel
     */
    'styleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminListViewModel
 */
export interface StyleGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminListViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminListViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGroupAdminListViewModel
     */
    'styleIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminListViewModelPaginatedList
 */
export interface StyleGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleGroupAdminListViewModel>}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'items': Array<StyleGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface StyleGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StyleGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface StyleGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleGroupAdminListViewModel>}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StyleGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminUpdateModel
 */
export interface StyleGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminUpdateModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminUpdateModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminUpdateModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGroupAdminUpdateModel
     */
    'styleIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminViewModel
 */
export interface StyleGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminViewModel
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminViewModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGroupAdminViewModel
     */
    'styleIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof StyleGroupAdminViewModel
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminViewModelApplicationResult
 */
export interface StyleGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleGroupAdminViewModelApplicationResultResultData}
     * @memberof StyleGroupAdminViewModelApplicationResult
     */
    'resultData'?: StyleGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleGroupAdminViewModelApplicationResultResultData
 */
export interface StyleGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminViewModelApplicationResultResultData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StyleGroupAdminViewModelApplicationResultResultData
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof StyleGroupAdminViewModelApplicationResultResultData
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleGroupAdminViewModelApplicationResultResultData
     */
    'styleIds': Array<string>;
    /**
     * If true then a user cannot edit the group
     * @type {boolean}
     * @memberof StyleGroupAdminViewModelApplicationResultResultData
     */
    'isSystemManaged': boolean;
}
/**
 * 
 * @export
 * @interface StyleSizeAdminListViewModel
 */
export interface StyleSizeAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeAdminListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface StyleSizeAdminUpdateModel
 */
export interface StyleSizeAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeAdminUpdateModel
     */
    'styleId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeAdminUpdateModel
     */
    'oldSizeId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeAdminUpdateModel
     */
    'newSizeId': string;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminCreateModel
 */
export interface StyleSizeGroupAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminCreateModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminCreateModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleSizeGroupAdminCreateModel
     */
    'sizeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminListViewModel
 */
export interface StyleSizeGroupAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminListViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminListViewModel
     */
    'sizeGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminListViewModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<SizeAdminListViewModel>}
     * @memberof StyleSizeGroupAdminListViewModel
     */
    'sizes': Array<SizeAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminListViewModelPaginatedList
 */
export interface StyleSizeGroupAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleSizeGroupAdminListViewModel>}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'items': Array<StyleSizeGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminListViewModelPaginatedListApplicationResult
 */
export interface StyleSizeGroupAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleSizeGroupAdminListViewModel>}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StyleSizeGroupAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeGroupAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminUpdateModel
 */
export interface StyleSizeGroupAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminUpdateModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminUpdateModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleSizeGroupAdminUpdateModel
     */
    'sizeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminViewModel
 */
export interface StyleSizeGroupAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminViewModel
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminViewModel
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleSizeGroupAdminViewModel
     */
    'sizeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminViewModelApplicationResult
 */
export interface StyleSizeGroupAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleSizeGroupAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleSizeGroupAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeGroupAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleSizeGroupAdminViewModelApplicationResultResultData}
     * @memberof StyleSizeGroupAdminViewModelApplicationResult
     */
    'resultData'?: StyleSizeGroupAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleSizeGroupAdminViewModelApplicationResultResultData
 */
export interface StyleSizeGroupAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminViewModelApplicationResultResultData
     */
    'sizeGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeGroupAdminViewModelApplicationResultResultData
     */
    'styleId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StyleSizeGroupAdminViewModelApplicationResultResultData
     */
    'sizeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface StyleSizeInconsistenciesAdminViewModel
 */
export interface StyleSizeInconsistenciesAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeInconsistenciesAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeInconsistenciesAdminViewModel
     */
    'name': string;
    /**
     * 
     * @type {StyleSizeInconsistenciesAdminViewModelSizeInconsistency}
     * @memberof StyleSizeInconsistenciesAdminViewModel
     */
    'sizeInconsistency': StyleSizeInconsistenciesAdminViewModelSizeInconsistency;
    /**
     * 
     * @type {Array<StyleSizeAdminListViewModel>}
     * @memberof StyleSizeInconsistenciesAdminViewModel
     */
    'availableSizes': Array<StyleSizeAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface StyleSizeInconsistenciesAdminViewModelPaginatedList
 */
export interface StyleSizeInconsistenciesAdminViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleSizeInconsistenciesAdminViewModel>}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'items': Array<StyleSizeInconsistenciesAdminViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult
 */
export interface StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult
     */
    'resultData'?: StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
 */
export interface StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<StyleSizeInconsistenciesAdminViewModel>}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<StyleSizeInconsistenciesAdminViewModel>;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface StyleSizeInconsistenciesAdminViewModelSizeInconsistency
 */
export interface StyleSizeInconsistenciesAdminViewModelSizeInconsistency {
    /**
     * 
     * @type {string}
     * @memberof StyleSizeInconsistenciesAdminViewModelSizeInconsistency
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StyleSizeInconsistenciesAdminViewModelSizeInconsistency
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TaskAdminCreateModel
 */
export interface TaskAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof TaskAdminCreateModel
     */
    'title': string;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof TaskAdminCreateModel
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof TaskAdminCreateModel
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminCreateModel
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminCreateModel
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminCreateModel
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminCreateUpdateModel>}
     * @memberof TaskAdminCreateModel
     */
    'taskRecipients': Array<TaskRecipientAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface TaskAdminListViewModel
 */
export interface TaskAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'fromAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'fromAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof TaskAdminListViewModel
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof TaskAdminListViewModel
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {TaskType}
     * @memberof TaskAdminListViewModel
     */
    'type': TaskType;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminListViewModel
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminListViewModel>}
     * @memberof TaskAdminListViewModel
     */
    'taskRecipients': Array<TaskRecipientAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface TaskAdminListViewModelPaginatedList
 */
export interface TaskAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<TaskAdminListViewModel>}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'items': Array<TaskAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface TaskAdminListViewModelPaginatedListApplicationResult
 */
export interface TaskAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {TaskAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: TaskAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface TaskAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface TaskAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<TaskAdminListViewModel>}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<TaskAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof TaskAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface TaskAdminUpdateModel
 */
export interface TaskAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof TaskAdminUpdateModel
     */
    'title': string;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof TaskAdminUpdateModel
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof TaskAdminUpdateModel
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminUpdateModel
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminCreateUpdateModel>}
     * @memberof TaskAdminUpdateModel
     */
    'taskRecipients': Array<TaskRecipientAdminCreateUpdateModel>;
}
/**
 * 
 * @export
 * @interface TaskAdminViewModel
 */
export interface TaskAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'fromAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'fromAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof TaskAdminViewModel
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof TaskAdminViewModel
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {TaskType}
     * @memberof TaskAdminViewModel
     */
    'type': TaskType;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModel
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminListViewModel>}
     * @memberof TaskAdminViewModel
     */
    'taskRecipients': Array<TaskRecipientAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface TaskAdminViewModelApplicationResult
 */
export interface TaskAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof TaskAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof TaskAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof TaskAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {TaskAdminViewModelApplicationResultResultData}
     * @memberof TaskAdminViewModelApplicationResult
     */
    'resultData'?: TaskAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface TaskAdminViewModelApplicationResultResultData
 */
export interface TaskAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'fromAspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'fromAspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {CliftonTaskPriority}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'taskPriorityId': CliftonTaskPriority;
    /**
     * 
     * @type {CliftonTaskStatus}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'taskStatusId': CliftonTaskStatus;
    /**
     * 
     * @type {TaskType}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'type': TaskType;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'enquiryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'dueDate': string;
    /**
     * 
     * @type {Array<TaskRecipientAdminListViewModel>}
     * @memberof TaskAdminViewModelApplicationResultResultData
     */
    'taskRecipients': Array<TaskRecipientAdminListViewModel>;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminCreateModel
 */
export interface TaskMessageAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminCreateModel
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminCreateModel
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminListViewModel
 */
export interface TaskMessageAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminListViewModel
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminListViewModel
     */
    'aspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminListViewModel
     */
    'aspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminListViewModel
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminListViewModel
     */
    'dateTimeStamp': string;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminListViewModelPaginatedList
 */
export interface TaskMessageAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<TaskMessageAdminListViewModel>}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'items': Array<TaskMessageAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminListViewModelPaginatedListApplicationResult
 */
export interface TaskMessageAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {TaskMessageAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: TaskMessageAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface TaskMessageAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<TaskMessageAdminListViewModel>}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<TaskMessageAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof TaskMessageAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminUpdateModel
 */
export interface TaskMessageAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminUpdateModel
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminViewModel
 */
export interface TaskMessageAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModel
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModel
     */
    'aspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModel
     */
    'aspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModel
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModel
     */
    'dateTimeStamp': string;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminViewModelApplicationResult
 */
export interface TaskMessageAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof TaskMessageAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof TaskMessageAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof TaskMessageAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {TaskMessageAdminViewModelApplicationResultResultData}
     * @memberof TaskMessageAdminViewModelApplicationResult
     */
    'resultData'?: TaskMessageAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface TaskMessageAdminViewModelApplicationResultResultData
 */
export interface TaskMessageAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModelApplicationResultResultData
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModelApplicationResultResultData
     */
    'aspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModelApplicationResultResultData
     */
    'aspNetUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModelApplicationResultResultData
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof TaskMessageAdminViewModelApplicationResultResultData
     */
    'dateTimeStamp': string;
}
/**
 * 
 * @export
 * @interface TaskRecipientAdminCreateUpdateModel
 */
export interface TaskRecipientAdminCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof TaskRecipientAdminCreateUpdateModel
     */
    'aspNetUserId': string;
}
/**
 * 
 * @export
 * @interface TaskRecipientAdminListViewModel
 */
export interface TaskRecipientAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof TaskRecipientAdminListViewModel
     */
    'aspNetUserId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskRecipientAdminListViewModel
     */
    'aspNetUserDisplayName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TaskType = {
    Order: 'Order',
    Enquiry: 'Enquiry'
} as const;

export type TaskType = typeof TaskType[keyof typeof TaskType];


/**
 * 
 * @export
 * @interface UserAddressCreateModel
 */
export interface UserAddressCreateModel {
    /**
     * 
     * @type {string}
     * @memberof UserAddressCreateModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressCreateModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressCreateModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressCreateModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressCreateModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressCreateModel
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressCreateModel
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAddressListViewModel
 */
export interface UserAddressListViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressListViewModel
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressListViewModel
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAddressListViewModelPaginatedList
 */
export interface UserAddressListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<UserAddressListViewModel>}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'items': Array<UserAddressListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface UserAddressListViewModelPaginatedListApplicationResult
 */
export interface UserAddressListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof UserAddressListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof UserAddressListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {UserAddressListViewModelPaginatedListApplicationResultResultData}
     * @memberof UserAddressListViewModelPaginatedListApplicationResult
     */
    'resultData'?: UserAddressListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface UserAddressListViewModelPaginatedListApplicationResultResultData
 */
export interface UserAddressListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<UserAddressListViewModel>}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<UserAddressListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof UserAddressListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface UserAddressUpdateModel
 */
export interface UserAddressUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserAddressUpdateModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressUpdateModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressUpdateModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressUpdateModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressUpdateModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressUpdateModel
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressUpdateModel
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAddressViewModel
 */
export interface UserAddressViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModel
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressViewModel
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAddressViewModelApplicationResult
 */
export interface UserAddressViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof UserAddressViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof UserAddressViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {UserAddressViewModelApplicationResultResultData}
     * @memberof UserAddressViewModelApplicationResult
     */
    'resultData'?: UserAddressViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface UserAddressViewModelApplicationResultResultData
 */
export interface UserAddressViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressViewModelApplicationResultResultData
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAddressesGetRequest
 */
export interface UserAddressesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAddressesGetRequest
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesGetRequest
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesGetRequest
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesGetRequest
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesGetRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesGetRequest
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressesGetRequest
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAddressesIdDeleteRequest
 */
export interface UserAddressesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAddressesIdDeleteRequest
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesIdDeleteRequest
     */
    'addressLine1': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesIdDeleteRequest
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesIdDeleteRequest
     */
    'area': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesIdDeleteRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UserAddressesIdDeleteRequest
     */
    'postalCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAddressesIdDeleteRequest
     */
    'isPrimary': boolean;
}
/**
 * 
 * @export
 * @interface UserAdminCreateModel
 */
export interface UserAdminCreateModel {
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminCreateModel
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminCreateModel
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminCreateModel
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface UserAdminListViewModel
 */
export interface UserAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminListViewModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminListViewModel
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminListViewModel
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModel
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface UserAdminListViewModelPaginatedList
 */
export interface UserAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<UserAdminListViewModel>}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'items': Array<UserAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface UserAdminListViewModelPaginatedListApplicationResult
 */
export interface UserAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof UserAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof UserAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {UserAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof UserAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: UserAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface UserAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface UserAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<UserAdminListViewModel>}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<UserAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface UserAdminUpdateModel
 */
export interface UserAdminUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminUpdateModel
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminUpdateModel
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminUpdateModel
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface UserAdminViewModel
 */
export interface UserAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModel
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminViewModel
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModel
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface UserAdminViewModelApplicationResult
 */
export interface UserAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof UserAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof UserAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {UserAdminViewModelApplicationResultResultData}
     * @memberof UserAdminViewModelApplicationResult
     */
    'resultData'?: UserAdminViewModelApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface UserAdminViewModelApplicationResultResultData
 */
export interface UserAdminViewModelApplicationResultResultData {
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultBankTransferOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultBankTransferSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultStripeOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultStripeSalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultEnquiryOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultEnquirySalesManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultStoreOrderManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminViewModelApplicationResultResultData
     */
    'isDefaultStoreSalesManager': boolean;
}
/**
 * 
 * @export
 * @interface UserSelectModel
 */
export interface UserSelectModel {
    /**
     * 
     * @type {string}
     * @memberof UserSelectModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserSelectModel
     */
    'displayName'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSelectModelPaginatedList
 */
export interface UserSelectModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof UserSelectModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSelectModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<UserSelectModel>}
     * @memberof UserSelectModelPaginatedList
     */
    'items': Array<UserSelectModel>;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface UserSelectModelPaginatedListApplicationResult
 */
export interface UserSelectModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof UserSelectModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof UserSelectModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof UserSelectModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {UserSelectModelPaginatedListApplicationResultResultData}
     * @memberof UserSelectModelPaginatedListApplicationResult
     */
    'resultData'?: UserSelectModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface UserSelectModelPaginatedListApplicationResultResultData
 */
export interface UserSelectModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<UserSelectModel>}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'items': Array<UserSelectModel>;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof UserSelectModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface UserUpdateModel
 */
export interface UserUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UserViewModel
 */
export interface UserViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UserViewModelApplicationResult
 */
export interface UserViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof UserViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof UserViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof UserViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderAdminViewModelUser}
     * @memberof UserViewModelApplicationResult
     */
    'resultData'?: OrderAdminViewModelUser | null;
}
/**
 * 
 * @export
 * @interface UsersUpdateUserPasswordPutRequest
 */
export interface UsersUpdateUserPasswordPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserPasswordPutRequest
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserPasswordPutRequest
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserPasswordPutRequest
     */
    'newPasswordConfirmation': string;
}
/**
 * 
 * @export
 * @interface UsersUpdateUserPutRequest
 */
export interface UsersUpdateUserPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserPutRequest
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserPutRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserPutRequest
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface VoucherAdminCreateModel
 */
export interface VoucherAdminCreateModel {
    /**
     * 
     * @type {VoucherType}
     * @memberof VoucherAdminCreateModel
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof VoucherAdminCreateModel
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminCreateModel
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminCreateModel
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminCreateModel
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminCreateModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminCreateModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminCreateModel
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminCreateModel
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherAdminCreateModel
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherAdminCreateModel
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminCreateModel
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminCreateModel
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VoucherAdminListViewModel
 */
export interface VoucherAdminListViewModel {
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminListViewModel
     */
    'id': string;
    /**
     * 
     * @type {VoucherType}
     * @memberof VoucherAdminListViewModel
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof VoucherAdminListViewModel
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminListViewModel
     */
    'redemptionCode': string;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModel
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModel
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminListViewModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminListViewModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModel
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModel
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModel
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VoucherAdminListViewModelPaginatedList
 */
export interface VoucherAdminListViewModelPaginatedList {
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<VoucherAdminListViewModel>}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'items': Array<VoucherAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedList
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface VoucherAdminListViewModelPaginatedListApplicationResult
 */
export interface VoucherAdminListViewModelPaginatedListApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {VoucherAdminListViewModelPaginatedListApplicationResultResultData}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResult
     */
    'resultData'?: VoucherAdminListViewModelPaginatedListApplicationResultResultData | null;
}
/**
 * 
 * @export
 * @interface VoucherAdminListViewModelPaginatedListApplicationResultResultData
 */
export interface VoucherAdminListViewModelPaginatedListApplicationResultResultData {
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasNextPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {Array<VoucherAdminListViewModel>}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'items': Array<VoucherAdminListViewModel>;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageIndex': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminListViewModelPaginatedListApplicationResultResultData
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface VoucherAdminUpdateModel
 */
export interface VoucherAdminUpdateModel {
    /**
     * 
     * @type {VoucherType}
     * @memberof VoucherAdminUpdateModel
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof VoucherAdminUpdateModel
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminUpdateModel
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminUpdateModel
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminUpdateModel
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminUpdateModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminUpdateModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminUpdateModel
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminUpdateModel
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherAdminUpdateModel
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherAdminUpdateModel
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminUpdateModel
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminUpdateModel
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VoucherAdminViewModel
 */
export interface VoucherAdminViewModel {
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminViewModel
     */
    'id': string;
    /**
     * 
     * @type {VoucherType}
     * @memberof VoucherAdminViewModel
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof VoucherAdminViewModel
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminViewModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminViewModel
     */
    'redemptionCode': string;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminViewModel
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminViewModel
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminViewModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherAdminViewModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherAdminViewModel
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminViewModel
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherAdminViewModel
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherAdminViewModel
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminViewModel
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminViewModel
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VoucherAdminViewModelApplicationResult
 */
export interface VoucherAdminViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof VoucherAdminViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof VoucherAdminViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherAdminViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderAdminViewModelVoucher}
     * @memberof VoucherAdminViewModelApplicationResult
     */
    'resultData'?: OrderAdminViewModelVoucher | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VoucherApplyToType = {
    TotalCost: 'TotalCost',
    ProductCost: 'ProductCost',
    DeliveryCost: 'DeliveryCost'
} as const;

export type VoucherApplyToType = typeof VoucherApplyToType[keyof typeof VoucherApplyToType];


/**
 * 
 * @export
 * @interface VoucherCartViewModel
 */
export interface VoucherCartViewModel {
    /**
     * 
     * @type {string}
     * @memberof VoucherCartViewModel
     */
    'id': string;
    /**
     * 
     * @type {VoucherType}
     * @memberof VoucherCartViewModel
     */
    'voucherTypeId': VoucherType;
    /**
     * 
     * @type {VoucherApplyToType}
     * @memberof VoucherCartViewModel
     */
    'voucherApplyToTypeId': VoucherApplyToType;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof VoucherCartViewModel
     */
    'redemptionCode': string;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'productVoucherAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'deliveryVoucherAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'totalVoucherAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'convertedTotalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'minimumCartValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'maximumRedemptions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherCartViewModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoucherCartViewModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoucherCartViewModel
     */
    'maximumValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherCartViewModel
     */
    'isProductListInclusion'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherCartViewModel
     */
    'productIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherCartViewModel
     */
    'countryIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherCartViewModel
     */
    'applyToStandardDelivery'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherCartViewModel
     */
    'applyToExpressDelivery'?: boolean | null;
}
/**
 * 
 * @export
 * @interface VoucherCartViewModelApplicationResult
 */
export interface VoucherCartViewModelApplicationResult {
    /**
     * 
     * @type {Array<ApplicationError>}
     * @memberof VoucherCartViewModelApplicationResult
     */
    'errors': Array<ApplicationError>;
    /**
     * 
     * @type {ApplicationResultFailureType}
     * @memberof VoucherCartViewModelApplicationResult
     */
    'resultFailureType': ApplicationResultFailureType;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherCartViewModelApplicationResult
     */
    'succeeded': boolean;
    /**
     * 
     * @type {OrderCartViewModelVoucher}
     * @memberof VoucherCartViewModelApplicationResult
     */
    'resultData'?: OrderCartViewModelVoucher | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VoucherType = {
    Absolute: 'Absolute',
    Percentage: 'Percentage'
} as const;

export type VoucherType = typeof VoucherType[keyof typeof VoucherType];



/**
 * AdminAdjustmentTypesApi - axios parameter creator
 * @export
 */
export const AdminAdjustmentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminAdjustmentTypesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminAdjustmentTypesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/AdjustmentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentTypesIdDelete', 'id', id)
            const localVarPath = `/Admin/AdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentTypesIdGet', 'id', id)
            const localVarPath = `/Admin/AdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousAdjustmentTypeId] 
         * @param {string} [nextAdjustmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdPatch: async (id: string, previousAdjustmentTypeId?: string, nextAdjustmentTypeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentTypesIdPatch', 'id', id)
            const localVarPath = `/Admin/AdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousAdjustmentTypeId !== undefined) {
                localVarQueryParameter['previousAdjustmentTypeId'] = previousAdjustmentTypeId;
            }

            if (nextAdjustmentTypeId !== undefined) {
                localVarQueryParameter['nextAdjustmentTypeId'] = nextAdjustmentTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminAdjustmentTypesIdDeleteRequest} [adminAdjustmentTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdPut: async (id: string, adminAdjustmentTypesIdDeleteRequest?: AdminAdjustmentTypesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentTypesIdPut', 'id', id)
            const localVarPath = `/Admin/AdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdjustmentTypesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminAdjustmentTypesGetRequest} [adminAdjustmentTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesPost: async (adminAdjustmentTypesGetRequest?: AdminAdjustmentTypesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/AdjustmentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdjustmentTypesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAdjustmentTypesApi - functional programming interface
 * @export
 */
export const AdminAdjustmentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAdjustmentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentTypesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentTypeAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentTypesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentTypesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentTypesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentTypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentTypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousAdjustmentTypeId] 
         * @param {string} [nextAdjustmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentTypesIdPatch(id: string, previousAdjustmentTypeId?: string, nextAdjustmentTypeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentTypesIdPatch(id, previousAdjustmentTypeId, nextAdjustmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminAdjustmentTypesIdDeleteRequest} [adminAdjustmentTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentTypesIdPut(id: string, adminAdjustmentTypesIdDeleteRequest?: AdminAdjustmentTypesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentTypesIdPut(id, adminAdjustmentTypesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminAdjustmentTypesGetRequest} [adminAdjustmentTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentTypesPost(adminAdjustmentTypesGetRequest?: AdminAdjustmentTypesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentTypesPost(adminAdjustmentTypesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAdjustmentTypesApi - factory interface
 * @export
 */
export const AdminAdjustmentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAdjustmentTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<AdjustmentTypeAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminAdjustmentTypesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminAdjustmentTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdGet(id: string, options?: any): AxiosPromise<AdjustmentTypeAdminViewModelApplicationResult> {
            return localVarFp.adminAdjustmentTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousAdjustmentTypeId] 
         * @param {string} [nextAdjustmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdPatch(id: string, previousAdjustmentTypeId?: string, nextAdjustmentTypeId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminAdjustmentTypesIdPatch(id, previousAdjustmentTypeId, nextAdjustmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminAdjustmentTypesIdDeleteRequest} [adminAdjustmentTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesIdPut(id: string, adminAdjustmentTypesIdDeleteRequest?: AdminAdjustmentTypesIdDeleteRequest, options?: any): AxiosPromise<AdjustmentTypeAdminViewModelApplicationResult> {
            return localVarFp.adminAdjustmentTypesIdPut(id, adminAdjustmentTypesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminAdjustmentTypesGetRequest} [adminAdjustmentTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentTypesPost(adminAdjustmentTypesGetRequest?: AdminAdjustmentTypesGetRequest, options?: any): AxiosPromise<AdjustmentTypeAdminViewModelApplicationResult> {
            return localVarFp.adminAdjustmentTypesPost(adminAdjustmentTypesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAdjustmentTypesApi - object-oriented interface
 * @export
 * @class AdminAdjustmentTypesApi
 * @extends {BaseAPI}
 */
export class AdminAdjustmentTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentTypesApi
     */
    public adminAdjustmentTypesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentTypesApiFp(this.configuration).adminAdjustmentTypesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentTypesApi
     */
    public adminAdjustmentTypesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentTypesApiFp(this.configuration).adminAdjustmentTypesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentTypesApi
     */
    public adminAdjustmentTypesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentTypesApiFp(this.configuration).adminAdjustmentTypesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousAdjustmentTypeId] 
     * @param {string} [nextAdjustmentTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentTypesApi
     */
    public adminAdjustmentTypesIdPatch(id: string, previousAdjustmentTypeId?: string, nextAdjustmentTypeId?: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentTypesApiFp(this.configuration).adminAdjustmentTypesIdPatch(id, previousAdjustmentTypeId, nextAdjustmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminAdjustmentTypesIdDeleteRequest} [adminAdjustmentTypesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentTypesApi
     */
    public adminAdjustmentTypesIdPut(id: string, adminAdjustmentTypesIdDeleteRequest?: AdminAdjustmentTypesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminAdjustmentTypesApiFp(this.configuration).adminAdjustmentTypesIdPut(id, adminAdjustmentTypesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminAdjustmentTypesGetRequest} [adminAdjustmentTypesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentTypesApi
     */
    public adminAdjustmentTypesPost(adminAdjustmentTypesGetRequest?: AdminAdjustmentTypesGetRequest, options?: AxiosRequestConfig) {
        return AdminAdjustmentTypesApiFp(this.configuration).adminAdjustmentTypesPost(adminAdjustmentTypesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminAdjustmentsApi - axios parameter creator
 * @export
 */
export const AdminAdjustmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} adjustmentTypeId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsGet: async (pageIndex: number, pageSize: number, adjustmentTypeId: string, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminAdjustmentsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminAdjustmentsGet', 'pageSize', pageSize)
            // verify required parameter 'adjustmentTypeId' is not null or undefined
            assertParamExists('adminAdjustmentsGet', 'adjustmentTypeId', adjustmentTypeId)
            const localVarPath = `/Admin/Adjustments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (adjustmentTypeId !== undefined) {
                localVarQueryParameter['adjustmentTypeId'] = adjustmentTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentsIdDelete', 'id', id)
            const localVarPath = `/Admin/Adjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentsIdGet', 'id', id)
            const localVarPath = `/Admin/Adjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousAdjustmentId] 
         * @param {string} [nextAdjustmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdPatch: async (id: string, previousAdjustmentId?: string, nextAdjustmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentsIdPatch', 'id', id)
            const localVarPath = `/Admin/Adjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousAdjustmentId !== undefined) {
                localVarQueryParameter['previousAdjustmentId'] = previousAdjustmentId;
            }

            if (nextAdjustmentId !== undefined) {
                localVarQueryParameter['nextAdjustmentId'] = nextAdjustmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminAdjustmentsIdDeleteRequest} [adminAdjustmentsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdPut: async (id: string, adminAdjustmentsIdDeleteRequest?: AdminAdjustmentsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminAdjustmentsIdPut', 'id', id)
            const localVarPath = `/Admin/Adjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdjustmentsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminAdjustmentsGetRequest} [adminAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsPost: async (adminAdjustmentsGetRequest?: AdminAdjustmentsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Adjustments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdjustmentsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAdjustmentsApi - functional programming interface
 * @export
 */
export const AdminAdjustmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAdjustmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} adjustmentTypeId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentsGet(pageIndex: number, pageSize: number, adjustmentTypeId: string, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentsGet(pageIndex, pageSize, adjustmentTypeId, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousAdjustmentId] 
         * @param {string} [nextAdjustmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentsIdPatch(id: string, previousAdjustmentId?: string, nextAdjustmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentsIdPatch(id, previousAdjustmentId, nextAdjustmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminAdjustmentsIdDeleteRequest} [adminAdjustmentsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentsIdPut(id: string, adminAdjustmentsIdDeleteRequest?: AdminAdjustmentsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentsIdPut(id, adminAdjustmentsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminAdjustmentsGetRequest} [adminAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdjustmentsPost(adminAdjustmentsGetRequest?: AdminAdjustmentsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdjustmentsPost(adminAdjustmentsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAdjustmentsApi - factory interface
 * @export
 */
export const AdminAdjustmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAdjustmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} adjustmentTypeId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsGet(pageIndex: number, pageSize: number, adjustmentTypeId: string, searchTerm?: string, options?: any): AxiosPromise<AdjustmentAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminAdjustmentsGet(pageIndex, pageSize, adjustmentTypeId, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminAdjustmentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdGet(id: string, options?: any): AxiosPromise<AdjustmentAdminViewModelApplicationResult> {
            return localVarFp.adminAdjustmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousAdjustmentId] 
         * @param {string} [nextAdjustmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdPatch(id: string, previousAdjustmentId?: string, nextAdjustmentId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminAdjustmentsIdPatch(id, previousAdjustmentId, nextAdjustmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminAdjustmentsIdDeleteRequest} [adminAdjustmentsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsIdPut(id: string, adminAdjustmentsIdDeleteRequest?: AdminAdjustmentsIdDeleteRequest, options?: any): AxiosPromise<AdjustmentAdminViewModelApplicationResult> {
            return localVarFp.adminAdjustmentsIdPut(id, adminAdjustmentsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminAdjustmentsGetRequest} [adminAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdjustmentsPost(adminAdjustmentsGetRequest?: AdminAdjustmentsGetRequest, options?: any): AxiosPromise<AdjustmentAdminViewModelApplicationResult> {
            return localVarFp.adminAdjustmentsPost(adminAdjustmentsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAdjustmentsApi - object-oriented interface
 * @export
 * @class AdminAdjustmentsApi
 * @extends {BaseAPI}
 */
export class AdminAdjustmentsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} adjustmentTypeId 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentsApi
     */
    public adminAdjustmentsGet(pageIndex: number, pageSize: number, adjustmentTypeId: string, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentsApiFp(this.configuration).adminAdjustmentsGet(pageIndex, pageSize, adjustmentTypeId, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentsApi
     */
    public adminAdjustmentsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentsApiFp(this.configuration).adminAdjustmentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentsApi
     */
    public adminAdjustmentsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentsApiFp(this.configuration).adminAdjustmentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousAdjustmentId] 
     * @param {string} [nextAdjustmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentsApi
     */
    public adminAdjustmentsIdPatch(id: string, previousAdjustmentId?: string, nextAdjustmentId?: string, options?: AxiosRequestConfig) {
        return AdminAdjustmentsApiFp(this.configuration).adminAdjustmentsIdPatch(id, previousAdjustmentId, nextAdjustmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminAdjustmentsIdDeleteRequest} [adminAdjustmentsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentsApi
     */
    public adminAdjustmentsIdPut(id: string, adminAdjustmentsIdDeleteRequest?: AdminAdjustmentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminAdjustmentsApiFp(this.configuration).adminAdjustmentsIdPut(id, adminAdjustmentsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminAdjustmentsGetRequest} [adminAdjustmentsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAdjustmentsApi
     */
    public adminAdjustmentsPost(adminAdjustmentsGetRequest?: AdminAdjustmentsGetRequest, options?: AxiosRequestConfig) {
        return AdminAdjustmentsApiFp(this.configuration).adminAdjustmentsPost(adminAdjustmentsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminArtAssetCategoriesApi - axios parameter creator
 * @export
 */
export const AdminArtAssetCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminArtAssetCategoriesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminArtAssetCategoriesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ArtAssetCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetCategoriesIdDelete', 'id', id)
            const localVarPath = `/Admin/ArtAssetCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetCategoriesIdGet', 'id', id)
            const localVarPath = `/Admin/ArtAssetCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousArtAssetCategoryId] 
         * @param {string} [nextArtAssetCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdPatch: async (id: string, previousArtAssetCategoryId?: string, nextArtAssetCategoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetCategoriesIdPatch', 'id', id)
            const localVarPath = `/Admin/ArtAssetCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousArtAssetCategoryId !== undefined) {
                localVarQueryParameter['previousArtAssetCategoryId'] = previousArtAssetCategoryId;
            }

            if (nextArtAssetCategoryId !== undefined) {
                localVarQueryParameter['nextArtAssetCategoryId'] = nextArtAssetCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminArtAssetCategoriesIdDeleteRequest} [adminArtAssetCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdPut: async (id: string, adminArtAssetCategoriesIdDeleteRequest?: AdminArtAssetCategoriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetCategoriesIdPut', 'id', id)
            const localVarPath = `/Admin/ArtAssetCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArtAssetCategoriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminArtAssetCategoriesGetRequest} [adminArtAssetCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesPost: async (adminArtAssetCategoriesGetRequest?: AdminArtAssetCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ArtAssetCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArtAssetCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminArtAssetCategoriesApi - functional programming interface
 * @export
 */
export const AdminArtAssetCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminArtAssetCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetCategoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetCategoriesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetCategoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetCategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousArtAssetCategoryId] 
         * @param {string} [nextArtAssetCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetCategoriesIdPatch(id: string, previousArtAssetCategoryId?: string, nextArtAssetCategoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetCategoriesIdPatch(id, previousArtAssetCategoryId, nextArtAssetCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminArtAssetCategoriesIdDeleteRequest} [adminArtAssetCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetCategoriesIdPut(id: string, adminArtAssetCategoriesIdDeleteRequest?: AdminArtAssetCategoriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetCategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetCategoriesIdPut(id, adminArtAssetCategoriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminArtAssetCategoriesGetRequest} [adminArtAssetCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetCategoriesPost(adminArtAssetCategoriesGetRequest?: AdminArtAssetCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetCategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetCategoriesPost(adminArtAssetCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminArtAssetCategoriesApi - factory interface
 * @export
 */
export const AdminArtAssetCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminArtAssetCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<ArtAssetCategoryAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminArtAssetCategoriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminArtAssetCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdGet(id: string, options?: any): AxiosPromise<ArtAssetCategoryAdminViewModelApplicationResult> {
            return localVarFp.adminArtAssetCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousArtAssetCategoryId] 
         * @param {string} [nextArtAssetCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdPatch(id: string, previousArtAssetCategoryId?: string, nextArtAssetCategoryId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminArtAssetCategoriesIdPatch(id, previousArtAssetCategoryId, nextArtAssetCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminArtAssetCategoriesIdDeleteRequest} [adminArtAssetCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesIdPut(id: string, adminArtAssetCategoriesIdDeleteRequest?: AdminArtAssetCategoriesIdDeleteRequest, options?: any): AxiosPromise<ArtAssetCategoryAdminViewModelApplicationResult> {
            return localVarFp.adminArtAssetCategoriesIdPut(id, adminArtAssetCategoriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminArtAssetCategoriesGetRequest} [adminArtAssetCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetCategoriesPost(adminArtAssetCategoriesGetRequest?: AdminArtAssetCategoriesGetRequest, options?: any): AxiosPromise<ArtAssetCategoryAdminViewModelApplicationResult> {
            return localVarFp.adminArtAssetCategoriesPost(adminArtAssetCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminArtAssetCategoriesApi - object-oriented interface
 * @export
 * @class AdminArtAssetCategoriesApi
 * @extends {BaseAPI}
 */
export class AdminArtAssetCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetCategoriesApi
     */
    public adminArtAssetCategoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminArtAssetCategoriesApiFp(this.configuration).adminArtAssetCategoriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetCategoriesApi
     */
    public adminArtAssetCategoriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminArtAssetCategoriesApiFp(this.configuration).adminArtAssetCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetCategoriesApi
     */
    public adminArtAssetCategoriesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminArtAssetCategoriesApiFp(this.configuration).adminArtAssetCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousArtAssetCategoryId] 
     * @param {string} [nextArtAssetCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetCategoriesApi
     */
    public adminArtAssetCategoriesIdPatch(id: string, previousArtAssetCategoryId?: string, nextArtAssetCategoryId?: string, options?: AxiosRequestConfig) {
        return AdminArtAssetCategoriesApiFp(this.configuration).adminArtAssetCategoriesIdPatch(id, previousArtAssetCategoryId, nextArtAssetCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminArtAssetCategoriesIdDeleteRequest} [adminArtAssetCategoriesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetCategoriesApi
     */
    public adminArtAssetCategoriesIdPut(id: string, adminArtAssetCategoriesIdDeleteRequest?: AdminArtAssetCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminArtAssetCategoriesApiFp(this.configuration).adminArtAssetCategoriesIdPut(id, adminArtAssetCategoriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminArtAssetCategoriesGetRequest} [adminArtAssetCategoriesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetCategoriesApi
     */
    public adminArtAssetCategoriesPost(adminArtAssetCategoriesGetRequest?: AdminArtAssetCategoriesGetRequest, options?: AxiosRequestConfig) {
        return AdminArtAssetCategoriesApiFp(this.configuration).adminArtAssetCategoriesPost(adminArtAssetCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminArtAssetsApi - axios parameter creator
 * @export
 */
export const AdminArtAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [artAssetName] 
         * @param {Array<string>} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsGet: async (pageIndex: number, pageSize: number, artAssetName?: string, categories?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminArtAssetsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminArtAssetsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ArtAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (artAssetName !== undefined) {
                localVarQueryParameter['artAssetName'] = artAssetName;
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetsIdDelete', 'id', id)
            const localVarPath = `/Admin/ArtAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetsIdGet', 'id', id)
            const localVarPath = `/Admin/ArtAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousArtAssetId] 
         * @param {string} [nextArtAssetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdPatch: async (id: string, previousArtAssetId?: string, nextArtAssetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetsIdPatch', 'id', id)
            const localVarPath = `/Admin/ArtAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousArtAssetId !== undefined) {
                localVarQueryParameter['previousArtAssetId'] = previousArtAssetId;
            }

            if (nextArtAssetId !== undefined) {
                localVarQueryParameter['nextArtAssetId'] = nextArtAssetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminArtAssetsIdDeleteRequest} [adminArtAssetsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdPut: async (id: string, adminArtAssetsIdDeleteRequest?: AdminArtAssetsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminArtAssetsIdPut', 'id', id)
            const localVarPath = `/Admin/ArtAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArtAssetsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminArtAssetsGetRequest} [adminArtAssetsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsPost: async (adminArtAssetsGetRequest?: AdminArtAssetsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ArtAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArtAssetsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminArtAssetsApi - functional programming interface
 * @export
 */
export const AdminArtAssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminArtAssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [artAssetName] 
         * @param {Array<string>} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetsGet(pageIndex: number, pageSize: number, artAssetName?: string, categories?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetsAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetsGet(pageIndex, pageSize, artAssetName, categories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetsAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousArtAssetId] 
         * @param {string} [nextArtAssetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetsIdPatch(id: string, previousArtAssetId?: string, nextArtAssetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetsIdPatch(id, previousArtAssetId, nextArtAssetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminArtAssetsIdDeleteRequest} [adminArtAssetsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetsIdPut(id: string, adminArtAssetsIdDeleteRequest?: AdminArtAssetsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetsAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetsIdPut(id, adminArtAssetsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminArtAssetsGetRequest} [adminArtAssetsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminArtAssetsPost(adminArtAssetsGetRequest?: AdminArtAssetsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtAssetsAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminArtAssetsPost(adminArtAssetsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminArtAssetsApi - factory interface
 * @export
 */
export const AdminArtAssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminArtAssetsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [artAssetName] 
         * @param {Array<string>} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsGet(pageIndex: number, pageSize: number, artAssetName?: string, categories?: Array<string>, options?: any): AxiosPromise<ArtAssetsAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminArtAssetsGet(pageIndex, pageSize, artAssetName, categories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminArtAssetsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdGet(id: string, options?: any): AxiosPromise<ArtAssetsAdminViewModelApplicationResult> {
            return localVarFp.adminArtAssetsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousArtAssetId] 
         * @param {string} [nextArtAssetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdPatch(id: string, previousArtAssetId?: string, nextArtAssetId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminArtAssetsIdPatch(id, previousArtAssetId, nextArtAssetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminArtAssetsIdDeleteRequest} [adminArtAssetsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsIdPut(id: string, adminArtAssetsIdDeleteRequest?: AdminArtAssetsIdDeleteRequest, options?: any): AxiosPromise<ArtAssetsAdminViewModelApplicationResult> {
            return localVarFp.adminArtAssetsIdPut(id, adminArtAssetsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminArtAssetsGetRequest} [adminArtAssetsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminArtAssetsPost(adminArtAssetsGetRequest?: AdminArtAssetsGetRequest, options?: any): AxiosPromise<ArtAssetsAdminViewModelApplicationResult> {
            return localVarFp.adminArtAssetsPost(adminArtAssetsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminArtAssetsApi - object-oriented interface
 * @export
 * @class AdminArtAssetsApi
 * @extends {BaseAPI}
 */
export class AdminArtAssetsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [artAssetName] 
     * @param {Array<string>} [categories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetsApi
     */
    public adminArtAssetsGet(pageIndex: number, pageSize: number, artAssetName?: string, categories?: Array<string>, options?: AxiosRequestConfig) {
        return AdminArtAssetsApiFp(this.configuration).adminArtAssetsGet(pageIndex, pageSize, artAssetName, categories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetsApi
     */
    public adminArtAssetsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminArtAssetsApiFp(this.configuration).adminArtAssetsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetsApi
     */
    public adminArtAssetsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminArtAssetsApiFp(this.configuration).adminArtAssetsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousArtAssetId] 
     * @param {string} [nextArtAssetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetsApi
     */
    public adminArtAssetsIdPatch(id: string, previousArtAssetId?: string, nextArtAssetId?: string, options?: AxiosRequestConfig) {
        return AdminArtAssetsApiFp(this.configuration).adminArtAssetsIdPatch(id, previousArtAssetId, nextArtAssetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminArtAssetsIdDeleteRequest} [adminArtAssetsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetsApi
     */
    public adminArtAssetsIdPut(id: string, adminArtAssetsIdDeleteRequest?: AdminArtAssetsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminArtAssetsApiFp(this.configuration).adminArtAssetsIdPut(id, adminArtAssetsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminArtAssetsGetRequest} [adminArtAssetsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArtAssetsApi
     */
    public adminArtAssetsPost(adminArtAssetsGetRequest?: AdminArtAssetsGetRequest, options?: AxiosRequestConfig) {
        return AdminArtAssetsApiFp(this.configuration).adminArtAssetsPost(adminArtAssetsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBannerItemsApi - axios parameter creator
 * @export
 */
export const AdminBannerItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminBannerItemsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminBannerItemsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/BannerItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerItemsIdDelete', 'id', id)
            const localVarPath = `/Admin/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerItemsIdGet', 'id', id)
            const localVarPath = `/Admin/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBannerItemId] 
         * @param {string} [nextBannerItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdPatch: async (id: string, previousBannerItemId?: string, nextBannerItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerItemsIdPatch', 'id', id)
            const localVarPath = `/Admin/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousBannerItemId !== undefined) {
                localVarQueryParameter['previousBannerItemId'] = previousBannerItemId;
            }

            if (nextBannerItemId !== undefined) {
                localVarQueryParameter['nextBannerItemId'] = nextBannerItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBannerItemsIdDeleteRequest} [adminBannerItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdPut: async (id: string, adminBannerItemsIdDeleteRequest?: AdminBannerItemsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerItemsIdPut', 'id', id)
            const localVarPath = `/Admin/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBannerItemsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBannerItemsGetRequest} [adminBannerItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsPost: async (adminBannerItemsGetRequest?: AdminBannerItemsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/BannerItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBannerItemsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBannerItemsApi - functional programming interface
 * @export
 */
export const AdminBannerItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBannerItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerItemsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerItemAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerItemsGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerItemsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerItemsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerItemsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerItemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBannerItemId] 
         * @param {string} [nextBannerItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerItemsIdPatch(id: string, previousBannerItemId?: string, nextBannerItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerItemsIdPatch(id, previousBannerItemId, nextBannerItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBannerItemsIdDeleteRequest} [adminBannerItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerItemsIdPut(id: string, adminBannerItemsIdDeleteRequest?: AdminBannerItemsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerItemsIdPut(id, adminBannerItemsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBannerItemsGetRequest} [adminBannerItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerItemsPost(adminBannerItemsGetRequest?: AdminBannerItemsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerItemsPost(adminBannerItemsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBannerItemsApi - factory interface
 * @export
 */
export const AdminBannerItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBannerItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<BannerItemAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminBannerItemsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBannerItemsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdGet(id: string, options?: any): AxiosPromise<BannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminBannerItemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBannerItemId] 
         * @param {string} [nextBannerItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdPatch(id: string, previousBannerItemId?: string, nextBannerItemId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBannerItemsIdPatch(id, previousBannerItemId, nextBannerItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBannerItemsIdDeleteRequest} [adminBannerItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsIdPut(id: string, adminBannerItemsIdDeleteRequest?: AdminBannerItemsIdDeleteRequest, options?: any): AxiosPromise<BannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminBannerItemsIdPut(id, adminBannerItemsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBannerItemsGetRequest} [adminBannerItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerItemsPost(adminBannerItemsGetRequest?: AdminBannerItemsGetRequest, options?: any): AxiosPromise<BannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminBannerItemsPost(adminBannerItemsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBannerItemsApi - object-oriented interface
 * @export
 * @class AdminBannerItemsApi
 * @extends {BaseAPI}
 */
export class AdminBannerItemsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerItemsApi
     */
    public adminBannerItemsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminBannerItemsApiFp(this.configuration).adminBannerItemsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerItemsApi
     */
    public adminBannerItemsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminBannerItemsApiFp(this.configuration).adminBannerItemsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerItemsApi
     */
    public adminBannerItemsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminBannerItemsApiFp(this.configuration).adminBannerItemsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousBannerItemId] 
     * @param {string} [nextBannerItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerItemsApi
     */
    public adminBannerItemsIdPatch(id: string, previousBannerItemId?: string, nextBannerItemId?: string, options?: AxiosRequestConfig) {
        return AdminBannerItemsApiFp(this.configuration).adminBannerItemsIdPatch(id, previousBannerItemId, nextBannerItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminBannerItemsIdDeleteRequest} [adminBannerItemsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerItemsApi
     */
    public adminBannerItemsIdPut(id: string, adminBannerItemsIdDeleteRequest?: AdminBannerItemsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminBannerItemsApiFp(this.configuration).adminBannerItemsIdPut(id, adminBannerItemsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminBannerItemsGetRequest} [adminBannerItemsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerItemsApi
     */
    public adminBannerItemsPost(adminBannerItemsGetRequest?: AdminBannerItemsGetRequest, options?: AxiosRequestConfig) {
        return AdminBannerItemsApiFp(this.configuration).adminBannerItemsPost(adminBannerItemsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBannerMessagesApi - axios parameter creator
 * @export
 */
export const AdminBannerMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [countryId] 
         * @param {boolean} [isActive] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesGet: async (pageIndex: number, pageSize: number, countryId?: string, isActive?: boolean, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminBannerMessagesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminBannerMessagesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/BannerMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerMessagesIdDelete', 'id', id)
            const localVarPath = `/Admin/BannerMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerMessagesIdGet', 'id', id)
            const localVarPath = `/Admin/BannerMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBannerMessageId] 
         * @param {string} [nextBannerMessageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdPatch: async (id: string, previousBannerMessageId?: string, nextBannerMessageId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerMessagesIdPatch', 'id', id)
            const localVarPath = `/Admin/BannerMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousBannerMessageId !== undefined) {
                localVarQueryParameter['previousBannerMessageId'] = previousBannerMessageId;
            }

            if (nextBannerMessageId !== undefined) {
                localVarQueryParameter['nextBannerMessageId'] = nextBannerMessageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBannerMessagesIdDeleteRequest} [adminBannerMessagesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdPut: async (id: string, adminBannerMessagesIdDeleteRequest?: AdminBannerMessagesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBannerMessagesIdPut', 'id', id)
            const localVarPath = `/Admin/BannerMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBannerMessagesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBannerMessagesGetRequest} [adminBannerMessagesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesPost: async (adminBannerMessagesGetRequest?: AdminBannerMessagesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/BannerMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBannerMessagesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBannerMessagesApi - functional programming interface
 * @export
 */
export const AdminBannerMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBannerMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [countryId] 
         * @param {boolean} [isActive] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerMessagesGet(pageIndex: number, pageSize: number, countryId?: string, isActive?: boolean, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerMessageAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerMessagesGet(pageIndex, pageSize, countryId, isActive, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerMessagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerMessagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerMessagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerMessageAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerMessagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBannerMessageId] 
         * @param {string} [nextBannerMessageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerMessagesIdPatch(id: string, previousBannerMessageId?: string, nextBannerMessageId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerMessagesIdPatch(id, previousBannerMessageId, nextBannerMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBannerMessagesIdDeleteRequest} [adminBannerMessagesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerMessagesIdPut(id: string, adminBannerMessagesIdDeleteRequest?: AdminBannerMessagesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerMessageAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerMessagesIdPut(id, adminBannerMessagesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBannerMessagesGetRequest} [adminBannerMessagesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerMessagesPost(adminBannerMessagesGetRequest?: AdminBannerMessagesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerMessageAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerMessagesPost(adminBannerMessagesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBannerMessagesApi - factory interface
 * @export
 */
export const AdminBannerMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBannerMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [countryId] 
         * @param {boolean} [isActive] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesGet(pageIndex: number, pageSize: number, countryId?: string, isActive?: boolean, searchTerm?: string, options?: any): AxiosPromise<BannerMessageAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminBannerMessagesGet(pageIndex, pageSize, countryId, isActive, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBannerMessagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdGet(id: string, options?: any): AxiosPromise<BannerMessageAdminViewModelApplicationResult> {
            return localVarFp.adminBannerMessagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBannerMessageId] 
         * @param {string} [nextBannerMessageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdPatch(id: string, previousBannerMessageId?: string, nextBannerMessageId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBannerMessagesIdPatch(id, previousBannerMessageId, nextBannerMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBannerMessagesIdDeleteRequest} [adminBannerMessagesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesIdPut(id: string, adminBannerMessagesIdDeleteRequest?: AdminBannerMessagesIdDeleteRequest, options?: any): AxiosPromise<BannerMessageAdminViewModelApplicationResult> {
            return localVarFp.adminBannerMessagesIdPut(id, adminBannerMessagesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBannerMessagesGetRequest} [adminBannerMessagesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerMessagesPost(adminBannerMessagesGetRequest?: AdminBannerMessagesGetRequest, options?: any): AxiosPromise<BannerMessageAdminViewModelApplicationResult> {
            return localVarFp.adminBannerMessagesPost(adminBannerMessagesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBannerMessagesApi - object-oriented interface
 * @export
 * @class AdminBannerMessagesApi
 * @extends {BaseAPI}
 */
export class AdminBannerMessagesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [countryId] 
     * @param {boolean} [isActive] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerMessagesApi
     */
    public adminBannerMessagesGet(pageIndex: number, pageSize: number, countryId?: string, isActive?: boolean, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminBannerMessagesApiFp(this.configuration).adminBannerMessagesGet(pageIndex, pageSize, countryId, isActive, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerMessagesApi
     */
    public adminBannerMessagesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminBannerMessagesApiFp(this.configuration).adminBannerMessagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerMessagesApi
     */
    public adminBannerMessagesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminBannerMessagesApiFp(this.configuration).adminBannerMessagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousBannerMessageId] 
     * @param {string} [nextBannerMessageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerMessagesApi
     */
    public adminBannerMessagesIdPatch(id: string, previousBannerMessageId?: string, nextBannerMessageId?: string, options?: AxiosRequestConfig) {
        return AdminBannerMessagesApiFp(this.configuration).adminBannerMessagesIdPatch(id, previousBannerMessageId, nextBannerMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminBannerMessagesIdDeleteRequest} [adminBannerMessagesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerMessagesApi
     */
    public adminBannerMessagesIdPut(id: string, adminBannerMessagesIdDeleteRequest?: AdminBannerMessagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminBannerMessagesApiFp(this.configuration).adminBannerMessagesIdPut(id, adminBannerMessagesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminBannerMessagesGetRequest} [adminBannerMessagesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerMessagesApi
     */
    public adminBannerMessagesPost(adminBannerMessagesGetRequest?: AdminBannerMessagesGetRequest, options?: AxiosRequestConfig) {
        return AdminBannerMessagesApiFp(this.configuration).adminBannerMessagesPost(adminBannerMessagesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBlogCategoriesApi - axios parameter creator
 * @export
 */
export const AdminBlogCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminBlogCategoriesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminBlogCategoriesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/BlogCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogCategoriesIdDelete', 'id', id)
            const localVarPath = `/Admin/BlogCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogCategoriesIdGet', 'id', id)
            const localVarPath = `/Admin/BlogCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBlogCategoryId] 
         * @param {string} [nextBlogCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdPatch: async (id: string, previousBlogCategoryId?: string, nextBlogCategoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogCategoriesIdPatch', 'id', id)
            const localVarPath = `/Admin/BlogCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousBlogCategoryId !== undefined) {
                localVarQueryParameter['previousBlogCategoryId'] = previousBlogCategoryId;
            }

            if (nextBlogCategoryId !== undefined) {
                localVarQueryParameter['nextBlogCategoryId'] = nextBlogCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBlogCategoriesIdDeleteRequest} [adminBlogCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdPut: async (id: string, adminBlogCategoriesIdDeleteRequest?: AdminBlogCategoriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogCategoriesIdPut', 'id', id)
            const localVarPath = `/Admin/BlogCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBlogCategoriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBlogCategoriesGetRequest} [adminBlogCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesPost: async (adminBlogCategoriesGetRequest?: AdminBlogCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/BlogCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBlogCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBlogCategoriesApi - functional programming interface
 * @export
 */
export const AdminBlogCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBlogCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogCategoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogCategoryAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogCategoriesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogCategoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogCategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBlogCategoryId] 
         * @param {string} [nextBlogCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogCategoriesIdPatch(id: string, previousBlogCategoryId?: string, nextBlogCategoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogCategoriesIdPatch(id, previousBlogCategoryId, nextBlogCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBlogCategoriesIdDeleteRequest} [adminBlogCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogCategoriesIdPut(id: string, adminBlogCategoriesIdDeleteRequest?: AdminBlogCategoriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogCategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogCategoriesIdPut(id, adminBlogCategoriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBlogCategoriesGetRequest} [adminBlogCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogCategoriesPost(adminBlogCategoriesGetRequest?: AdminBlogCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogCategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogCategoriesPost(adminBlogCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBlogCategoriesApi - factory interface
 * @export
 */
export const AdminBlogCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBlogCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<BlogCategoryAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminBlogCategoriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBlogCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdGet(id: string, options?: any): AxiosPromise<BlogCategoryAdminViewModelApplicationResult> {
            return localVarFp.adminBlogCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousBlogCategoryId] 
         * @param {string} [nextBlogCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdPatch(id: string, previousBlogCategoryId?: string, nextBlogCategoryId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBlogCategoriesIdPatch(id, previousBlogCategoryId, nextBlogCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBlogCategoriesIdDeleteRequest} [adminBlogCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesIdPut(id: string, adminBlogCategoriesIdDeleteRequest?: AdminBlogCategoriesIdDeleteRequest, options?: any): AxiosPromise<BlogCategoryAdminViewModelApplicationResult> {
            return localVarFp.adminBlogCategoriesIdPut(id, adminBlogCategoriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBlogCategoriesGetRequest} [adminBlogCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogCategoriesPost(adminBlogCategoriesGetRequest?: AdminBlogCategoriesGetRequest, options?: any): AxiosPromise<BlogCategoryAdminViewModelApplicationResult> {
            return localVarFp.adminBlogCategoriesPost(adminBlogCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBlogCategoriesApi - object-oriented interface
 * @export
 * @class AdminBlogCategoriesApi
 * @extends {BaseAPI}
 */
export class AdminBlogCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogCategoriesApi
     */
    public adminBlogCategoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminBlogCategoriesApiFp(this.configuration).adminBlogCategoriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogCategoriesApi
     */
    public adminBlogCategoriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminBlogCategoriesApiFp(this.configuration).adminBlogCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogCategoriesApi
     */
    public adminBlogCategoriesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminBlogCategoriesApiFp(this.configuration).adminBlogCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousBlogCategoryId] 
     * @param {string} [nextBlogCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogCategoriesApi
     */
    public adminBlogCategoriesIdPatch(id: string, previousBlogCategoryId?: string, nextBlogCategoryId?: string, options?: AxiosRequestConfig) {
        return AdminBlogCategoriesApiFp(this.configuration).adminBlogCategoriesIdPatch(id, previousBlogCategoryId, nextBlogCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminBlogCategoriesIdDeleteRequest} [adminBlogCategoriesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogCategoriesApi
     */
    public adminBlogCategoriesIdPut(id: string, adminBlogCategoriesIdDeleteRequest?: AdminBlogCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminBlogCategoriesApiFp(this.configuration).adminBlogCategoriesIdPut(id, adminBlogCategoriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminBlogCategoriesGetRequest} [adminBlogCategoriesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogCategoriesApi
     */
    public adminBlogCategoriesPost(adminBlogCategoriesGetRequest?: AdminBlogCategoriesGetRequest, options?: AxiosRequestConfig) {
        return AdminBlogCategoriesApiFp(this.configuration).adminBlogCategoriesPost(adminBlogCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBlogsApi - axios parameter creator
 * @export
 */
export const AdminBlogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [slug] 
         * @param {string} [name] 
         * @param {boolean} [isPublic] 
         * @param {boolean} [isFeatured] 
         * @param {Array<string>} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsGet: async (pageIndex: number, pageSize: number, slug?: string, name?: string, isPublic?: boolean, isFeatured?: boolean, categories?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminBlogsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminBlogsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Blogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (isFeatured !== undefined) {
                localVarQueryParameter['isFeatured'] = isFeatured;
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogsIdDelete', 'id', id)
            const localVarPath = `/Admin/Blogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogsIdGet', 'id', id)
            const localVarPath = `/Admin/Blogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBlogsIdDeleteRequest} [adminBlogsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsIdPut: async (id: string, adminBlogsIdDeleteRequest?: AdminBlogsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminBlogsIdPut', 'id', id)
            const localVarPath = `/Admin/Blogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBlogsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBlogsGetRequest} [adminBlogsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsPost: async (adminBlogsGetRequest?: AdminBlogsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Blogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminBlogsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBlogsApi - functional programming interface
 * @export
 */
export const AdminBlogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBlogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [slug] 
         * @param {string} [name] 
         * @param {boolean} [isPublic] 
         * @param {boolean} [isFeatured] 
         * @param {Array<string>} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogsGet(pageIndex: number, pageSize: number, slug?: string, name?: string, isPublic?: boolean, isFeatured?: boolean, categories?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogsGet(pageIndex, pageSize, slug, name, isPublic, isFeatured, categories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBlogsIdDeleteRequest} [adminBlogsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogsIdPut(id: string, adminBlogsIdDeleteRequest?: AdminBlogsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogsIdPut(id, adminBlogsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBlogsGetRequest} [adminBlogsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBlogsPost(adminBlogsGetRequest?: AdminBlogsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBlogsPost(adminBlogsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBlogsApi - factory interface
 * @export
 */
export const AdminBlogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBlogsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [slug] 
         * @param {string} [name] 
         * @param {boolean} [isPublic] 
         * @param {boolean} [isFeatured] 
         * @param {Array<string>} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsGet(pageIndex: number, pageSize: number, slug?: string, name?: string, isPublic?: boolean, isFeatured?: boolean, categories?: Array<string>, options?: any): AxiosPromise<BlogAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminBlogsGet(pageIndex, pageSize, slug, name, isPublic, isFeatured, categories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminBlogsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsIdGet(id: string, options?: any): AxiosPromise<BlogAdminViewModelApplicationResult> {
            return localVarFp.adminBlogsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminBlogsIdDeleteRequest} [adminBlogsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsIdPut(id: string, adminBlogsIdDeleteRequest?: AdminBlogsIdDeleteRequest, options?: any): AxiosPromise<BlogAdminViewModelApplicationResult> {
            return localVarFp.adminBlogsIdPut(id, adminBlogsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminBlogsGetRequest} [adminBlogsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBlogsPost(adminBlogsGetRequest?: AdminBlogsGetRequest, options?: any): AxiosPromise<BlogAdminViewModelApplicationResult> {
            return localVarFp.adminBlogsPost(adminBlogsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBlogsApi - object-oriented interface
 * @export
 * @class AdminBlogsApi
 * @extends {BaseAPI}
 */
export class AdminBlogsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [slug] 
     * @param {string} [name] 
     * @param {boolean} [isPublic] 
     * @param {boolean} [isFeatured] 
     * @param {Array<string>} [categories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogsApi
     */
    public adminBlogsGet(pageIndex: number, pageSize: number, slug?: string, name?: string, isPublic?: boolean, isFeatured?: boolean, categories?: Array<string>, options?: AxiosRequestConfig) {
        return AdminBlogsApiFp(this.configuration).adminBlogsGet(pageIndex, pageSize, slug, name, isPublic, isFeatured, categories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogsApi
     */
    public adminBlogsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminBlogsApiFp(this.configuration).adminBlogsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogsApi
     */
    public adminBlogsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminBlogsApiFp(this.configuration).adminBlogsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminBlogsIdDeleteRequest} [adminBlogsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogsApi
     */
    public adminBlogsIdPut(id: string, adminBlogsIdDeleteRequest?: AdminBlogsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminBlogsApiFp(this.configuration).adminBlogsIdPut(id, adminBlogsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminBlogsGetRequest} [adminBlogsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBlogsApi
     */
    public adminBlogsPost(adminBlogsGetRequest?: AdminBlogsGetRequest, options?: AxiosRequestConfig) {
        return AdminBlogsApiFp(this.configuration).adminBlogsPost(adminBlogsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCampaignsApi - axios parameter creator
 * @export
 */
export const AdminCampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} bannerItemId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsCopyPost: async (bannerItemId: string, campaignId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerItemId' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsCopyPost', 'bannerItemId', bannerItemId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsCopyPost', 'campaignId', campaignId)
            const localVarPath = `/Admin/Campaigns/BannerItems/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bannerItemId !== undefined) {
                localVarQueryParameter['bannerItemId'] = bannerItemId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} campaignId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsGet: async (pageIndex: number, pageSize: number, campaignId: string, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsGet', 'pageSize', pageSize)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsGet', 'campaignId', campaignId)
            const localVarPath = `/Admin/Campaigns/BannerItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsIdDelete', 'id', id)
            const localVarPath = `/Admin/Campaigns/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsIdGet', 'id', id)
            const localVarPath = `/Admin/Campaigns/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {string} [previousCampaignBannerItemId] 
         * @param {string} [nextCampaignBannerItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdPatch: async (id: string, previousCampaignBannerItemId?: string, nextCampaignBannerItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsIdPatch', 'id', id)
            const localVarPath = `/Admin/Campaigns/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousCampaignBannerItemId !== undefined) {
                localVarQueryParameter['previousCampaignBannerItemId'] = previousCampaignBannerItemId;
            }

            if (nextCampaignBannerItemId !== undefined) {
                localVarQueryParameter['nextCampaignBannerItemId'] = nextCampaignBannerItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsBannerItemsIdDeleteRequest} [adminCampaignsBannerItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdPut: async (id: string, adminCampaignsBannerItemsIdDeleteRequest?: AdminCampaignsBannerItemsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsBannerItemsIdPut', 'id', id)
            const localVarPath = `/Admin/Campaigns/BannerItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCampaignsBannerItemsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsBannerItemsGetRequest} [adminCampaignsBannerItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsPost: async (adminCampaignsBannerItemsGetRequest?: AdminCampaignsBannerItemsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Campaigns/BannerItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCampaignsBannerItemsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} clientId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsCopyPost: async (clientId: string, campaignId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('adminCampaignsClientsCopyPost', 'clientId', clientId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminCampaignsClientsCopyPost', 'campaignId', campaignId)
            const localVarPath = `/Admin/Campaigns/Clients/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} campaignId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsGet: async (pageIndex: number, pageSize: number, campaignId: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCampaignsClientsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCampaignsClientsGet', 'pageSize', pageSize)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('adminCampaignsClientsGet', 'campaignId', campaignId)
            const localVarPath = `/Admin/Campaigns/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsClientsIdDelete', 'id', id)
            const localVarPath = `/Admin/Campaigns/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsClientsIdGet', 'id', id)
            const localVarPath = `/Admin/Campaigns/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {string} [previousCampaignClientId] 
         * @param {string} [nextCampaignClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdPatch: async (id: string, previousCampaignClientId?: string, nextCampaignClientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsClientsIdPatch', 'id', id)
            const localVarPath = `/Admin/Campaigns/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousCampaignClientId !== undefined) {
                localVarQueryParameter['previousCampaignClientId'] = previousCampaignClientId;
            }

            if (nextCampaignClientId !== undefined) {
                localVarQueryParameter['nextCampaignClientId'] = nextCampaignClientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsClientsIdDeleteRequest} [adminCampaignsClientsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdPut: async (id: string, adminCampaignsClientsIdDeleteRequest?: AdminCampaignsClientsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsClientsIdPut', 'id', id)
            const localVarPath = `/Admin/Campaigns/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCampaignsClientsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsClientsGetRequest} [adminCampaignsClientsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsPost: async (adminCampaignsClientsGetRequest?: AdminCampaignsClientsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Campaigns/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCampaignsClientsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [slug] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsGet: async (pageIndex: number, pageSize: number, slug?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCampaignsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCampaignsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsIdDelete', 'id', id)
            const localVarPath = `/Admin/Campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {boolean} calculateQuoteTotals 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsIdGet: async (id: string, calculateQuoteTotals: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsIdGet', 'id', id)
            // verify required parameter 'calculateQuoteTotals' is not null or undefined
            assertParamExists('adminCampaignsIdGet', 'calculateQuoteTotals', calculateQuoteTotals)
            const localVarPath = `/Admin/Campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (calculateQuoteTotals !== undefined) {
                localVarQueryParameter['calculateQuoteTotals'] = calculateQuoteTotals;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsIdDeleteRequest} [adminCampaignsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsIdPut: async (id: string, adminCampaignsIdDeleteRequest?: AdminCampaignsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCampaignsIdPut', 'id', id)
            const localVarPath = `/Admin/Campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCampaignsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsGetRequest} [adminCampaignsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsPost: async (adminCampaignsGetRequest?: AdminCampaignsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCampaignsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCampaignsApi - functional programming interface
 * @export
 */
export const AdminCampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} bannerItemId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsCopyPost(bannerItemId: string, campaignId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsCopyPost(bannerItemId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} campaignId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsGet(pageIndex: number, pageSize: number, campaignId: string, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignBannerItemAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsGet(pageIndex, pageSize, campaignId, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {string} [previousCampaignBannerItemId] 
         * @param {string} [nextCampaignBannerItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsIdPatch(id: string, previousCampaignBannerItemId?: string, nextCampaignBannerItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsIdPatch(id, previousCampaignBannerItemId, nextCampaignBannerItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsBannerItemsIdDeleteRequest} [adminCampaignsBannerItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsIdPut(id: string, adminCampaignsBannerItemsIdDeleteRequest?: AdminCampaignsBannerItemsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsIdPut(id, adminCampaignsBannerItemsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsBannerItemsGetRequest} [adminCampaignsBannerItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsBannerItemsPost(adminCampaignsBannerItemsGetRequest?: AdminCampaignsBannerItemsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsBannerItemsPost(adminCampaignsBannerItemsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} clientId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsCopyPost(clientId: string, campaignId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsCopyPost(clientId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} campaignId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsGet(pageIndex: number, pageSize: number, campaignId: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignClientAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsGet(pageIndex, pageSize, campaignId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {string} [previousCampaignClientId] 
         * @param {string} [nextCampaignClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsIdPatch(id: string, previousCampaignClientId?: string, nextCampaignClientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsIdPatch(id, previousCampaignClientId, nextCampaignClientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsClientsIdDeleteRequest} [adminCampaignsClientsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsIdPut(id: string, adminCampaignsClientsIdDeleteRequest?: AdminCampaignsClientsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsIdPut(id, adminCampaignsClientsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsClientsGetRequest} [adminCampaignsClientsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsClientsPost(adminCampaignsClientsGetRequest?: AdminCampaignsClientsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsClientsPost(adminCampaignsClientsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [slug] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsGet(pageIndex: number, pageSize: number, slug?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsGet(pageIndex, pageSize, slug, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {boolean} calculateQuoteTotals 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsIdGet(id: string, calculateQuoteTotals: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsIdGet(id, calculateQuoteTotals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsIdDeleteRequest} [adminCampaignsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsIdPut(id: string, adminCampaignsIdDeleteRequest?: AdminCampaignsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsIdPut(id, adminCampaignsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsGetRequest} [adminCampaignsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCampaignsPost(adminCampaignsGetRequest?: AdminCampaignsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsPost(adminCampaignsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCampaignsApi - factory interface
 * @export
 */
export const AdminCampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCampaignsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} bannerItemId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsCopyPost(bannerItemId: string, campaignId: string, options?: any): AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsCopyPost(bannerItemId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} campaignId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsGet(pageIndex: number, pageSize: number, campaignId: string, searchTerm?: string, options?: any): AxiosPromise<CampaignBannerItemAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsGet(pageIndex, pageSize, campaignId, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdGet(id: string, options?: any): AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {string} [previousCampaignBannerItemId] 
         * @param {string} [nextCampaignBannerItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdPatch(id: string, previousCampaignBannerItemId?: string, nextCampaignBannerItemId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsIdPatch(id, previousCampaignBannerItemId, nextCampaignBannerItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsBannerItemsIdDeleteRequest} [adminCampaignsBannerItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsIdPut(id: string, adminCampaignsBannerItemsIdDeleteRequest?: AdminCampaignsBannerItemsIdDeleteRequest, options?: any): AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsIdPut(id, adminCampaignsBannerItemsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsBannerItemsGetRequest} [adminCampaignsBannerItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsBannerItemsPost(adminCampaignsBannerItemsGetRequest?: AdminCampaignsBannerItemsGetRequest, options?: any): AxiosPromise<CampaignBannerItemAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsBannerItemsPost(adminCampaignsBannerItemsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} clientId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsCopyPost(clientId: string, campaignId: string, options?: any): AxiosPromise<CampaignClientAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsClientsCopyPost(clientId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} campaignId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsGet(pageIndex: number, pageSize: number, campaignId: string, name?: string, options?: any): AxiosPromise<CampaignClientAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCampaignsClientsGet(pageIndex, pageSize, campaignId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCampaignsClientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdGet(id: string, options?: any): AxiosPromise<CampaignClientAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsClientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {string} [previousCampaignClientId] 
         * @param {string} [nextCampaignClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdPatch(id: string, previousCampaignClientId?: string, nextCampaignClientId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCampaignsClientsIdPatch(id, previousCampaignClientId, nextCampaignClientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsClientsIdDeleteRequest} [adminCampaignsClientsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsIdPut(id: string, adminCampaignsClientsIdDeleteRequest?: AdminCampaignsClientsIdDeleteRequest, options?: any): AxiosPromise<CampaignClientAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsClientsIdPut(id, adminCampaignsClientsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsClientsGetRequest} [adminCampaignsClientsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsClientsPost(adminCampaignsClientsGetRequest?: AdminCampaignsClientsGetRequest, options?: any): AxiosPromise<CampaignClientAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsClientsPost(adminCampaignsClientsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [slug] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsGet(pageIndex: number, pageSize: number, slug?: string, name?: string, options?: any): AxiosPromise<CampaignAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCampaignsGet(pageIndex, pageSize, slug, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCampaignsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {boolean} calculateQuoteTotals 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsIdGet(id: string, calculateQuoteTotals: boolean, options?: any): AxiosPromise<CampaignAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsIdGet(id, calculateQuoteTotals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {string} id 
         * @param {AdminCampaignsIdDeleteRequest} [adminCampaignsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsIdPut(id: string, adminCampaignsIdDeleteRequest?: AdminCampaignsIdDeleteRequest, options?: any): AxiosPromise<CampaignAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsIdPut(id, adminCampaignsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageCampaigns)
         * @param {AdminCampaignsGetRequest} [adminCampaignsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCampaignsPost(adminCampaignsGetRequest?: AdminCampaignsGetRequest, options?: any): AxiosPromise<CampaignAdminViewModelApplicationResult> {
            return localVarFp.adminCampaignsPost(adminCampaignsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCampaignsApi - object-oriented interface
 * @export
 * @class AdminCampaignsApi
 * @extends {BaseAPI}
 */
export class AdminCampaignsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} bannerItemId 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsCopyPost(bannerItemId: string, campaignId: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsCopyPost(bannerItemId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} campaignId 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsGet(pageIndex: number, pageSize: number, campaignId: string, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsGet(pageIndex, pageSize, campaignId, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {string} [previousCampaignBannerItemId] 
     * @param {string} [nextCampaignBannerItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsIdPatch(id: string, previousCampaignBannerItemId?: string, nextCampaignBannerItemId?: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsIdPatch(id, previousCampaignBannerItemId, nextCampaignBannerItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {AdminCampaignsBannerItemsIdDeleteRequest} [adminCampaignsBannerItemsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsIdPut(id: string, adminCampaignsBannerItemsIdDeleteRequest?: AdminCampaignsBannerItemsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsIdPut(id, adminCampaignsBannerItemsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {AdminCampaignsBannerItemsGetRequest} [adminCampaignsBannerItemsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsBannerItemsPost(adminCampaignsBannerItemsGetRequest?: AdminCampaignsBannerItemsGetRequest, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsBannerItemsPost(adminCampaignsBannerItemsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} clientId 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsCopyPost(clientId: string, campaignId: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsCopyPost(clientId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} campaignId 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsGet(pageIndex: number, pageSize: number, campaignId: string, name?: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsGet(pageIndex, pageSize, campaignId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {string} [previousCampaignClientId] 
     * @param {string} [nextCampaignClientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsIdPatch(id: string, previousCampaignClientId?: string, nextCampaignClientId?: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsIdPatch(id, previousCampaignClientId, nextCampaignClientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {AdminCampaignsClientsIdDeleteRequest} [adminCampaignsClientsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsIdPut(id: string, adminCampaignsClientsIdDeleteRequest?: AdminCampaignsClientsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsIdPut(id, adminCampaignsClientsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {AdminCampaignsClientsGetRequest} [adminCampaignsClientsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsClientsPost(adminCampaignsClientsGetRequest?: AdminCampaignsClientsGetRequest, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsClientsPost(adminCampaignsClientsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [slug] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsGet(pageIndex: number, pageSize: number, slug?: string, name?: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsGet(pageIndex, pageSize, slug, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {boolean} calculateQuoteTotals 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsIdGet(id: string, calculateQuoteTotals: boolean, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsIdGet(id, calculateQuoteTotals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {string} id 
     * @param {AdminCampaignsIdDeleteRequest} [adminCampaignsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsIdPut(id: string, adminCampaignsIdDeleteRequest?: AdminCampaignsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsIdPut(id, adminCampaignsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageCampaigns)
     * @param {AdminCampaignsGetRequest} [adminCampaignsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCampaignsApi
     */
    public adminCampaignsPost(adminCampaignsGetRequest?: AdminCampaignsGetRequest, options?: AxiosRequestConfig) {
        return AdminCampaignsApiFp(this.configuration).adminCampaignsPost(adminCampaignsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCarouselItemsApi - axios parameter creator
 * @export
 */
export const AdminCarouselItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, section?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCarouselItemsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCarouselItemsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/CarouselItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCarouselItemsIdDelete', 'id', id)
            const localVarPath = `/Admin/CarouselItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCarouselItemsIdGet', 'id', id)
            const localVarPath = `/Admin/CarouselItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCarouselItemId] 
         * @param {string} [nextCarouselItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdPatch: async (id: string, previousCarouselItemId?: string, nextCarouselItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCarouselItemsIdPatch', 'id', id)
            const localVarPath = `/Admin/CarouselItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousCarouselItemId !== undefined) {
                localVarQueryParameter['previousCarouselItemId'] = previousCarouselItemId;
            }

            if (nextCarouselItemId !== undefined) {
                localVarQueryParameter['nextCarouselItemId'] = nextCarouselItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCarouselItemsIdDeleteRequest} [adminCarouselItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdPut: async (id: string, adminCarouselItemsIdDeleteRequest?: AdminCarouselItemsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCarouselItemsIdPut', 'id', id)
            const localVarPath = `/Admin/CarouselItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCarouselItemsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCarouselItemsGetRequest} [adminCarouselItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsPost: async (adminCarouselItemsGetRequest?: AdminCarouselItemsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/CarouselItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCarouselItemsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCarouselItemsApi - functional programming interface
 * @export
 */
export const AdminCarouselItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCarouselItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCarouselItemsGet(pageIndex: number, pageSize: number, searchTerm?: string, section?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarouselItemAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCarouselItemsGet(pageIndex, pageSize, searchTerm, section, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCarouselItemsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCarouselItemsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCarouselItemsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarouselItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCarouselItemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCarouselItemId] 
         * @param {string} [nextCarouselItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCarouselItemsIdPatch(id: string, previousCarouselItemId?: string, nextCarouselItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCarouselItemsIdPatch(id, previousCarouselItemId, nextCarouselItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCarouselItemsIdDeleteRequest} [adminCarouselItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCarouselItemsIdPut(id: string, adminCarouselItemsIdDeleteRequest?: AdminCarouselItemsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarouselItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCarouselItemsIdPut(id, adminCarouselItemsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCarouselItemsGetRequest} [adminCarouselItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCarouselItemsPost(adminCarouselItemsGetRequest?: AdminCarouselItemsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarouselItemAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCarouselItemsPost(adminCarouselItemsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCarouselItemsApi - factory interface
 * @export
 */
export const AdminCarouselItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCarouselItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsGet(pageIndex: number, pageSize: number, searchTerm?: string, section?: string, options?: any): AxiosPromise<CarouselItemAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCarouselItemsGet(pageIndex, pageSize, searchTerm, section, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCarouselItemsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdGet(id: string, options?: any): AxiosPromise<CarouselItemAdminViewModelApplicationResult> {
            return localVarFp.adminCarouselItemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCarouselItemId] 
         * @param {string} [nextCarouselItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdPatch(id: string, previousCarouselItemId?: string, nextCarouselItemId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCarouselItemsIdPatch(id, previousCarouselItemId, nextCarouselItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCarouselItemsIdDeleteRequest} [adminCarouselItemsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsIdPut(id: string, adminCarouselItemsIdDeleteRequest?: AdminCarouselItemsIdDeleteRequest, options?: any): AxiosPromise<CarouselItemAdminViewModelApplicationResult> {
            return localVarFp.adminCarouselItemsIdPut(id, adminCarouselItemsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCarouselItemsGetRequest} [adminCarouselItemsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCarouselItemsPost(adminCarouselItemsGetRequest?: AdminCarouselItemsGetRequest, options?: any): AxiosPromise<CarouselItemAdminViewModelApplicationResult> {
            return localVarFp.adminCarouselItemsPost(adminCarouselItemsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCarouselItemsApi - object-oriented interface
 * @export
 * @class AdminCarouselItemsApi
 * @extends {BaseAPI}
 */
export class AdminCarouselItemsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {string} [section] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCarouselItemsApi
     */
    public adminCarouselItemsGet(pageIndex: number, pageSize: number, searchTerm?: string, section?: string, options?: AxiosRequestConfig) {
        return AdminCarouselItemsApiFp(this.configuration).adminCarouselItemsGet(pageIndex, pageSize, searchTerm, section, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCarouselItemsApi
     */
    public adminCarouselItemsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCarouselItemsApiFp(this.configuration).adminCarouselItemsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCarouselItemsApi
     */
    public adminCarouselItemsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCarouselItemsApiFp(this.configuration).adminCarouselItemsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousCarouselItemId] 
     * @param {string} [nextCarouselItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCarouselItemsApi
     */
    public adminCarouselItemsIdPatch(id: string, previousCarouselItemId?: string, nextCarouselItemId?: string, options?: AxiosRequestConfig) {
        return AdminCarouselItemsApiFp(this.configuration).adminCarouselItemsIdPatch(id, previousCarouselItemId, nextCarouselItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCarouselItemsIdDeleteRequest} [adminCarouselItemsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCarouselItemsApi
     */
    public adminCarouselItemsIdPut(id: string, adminCarouselItemsIdDeleteRequest?: AdminCarouselItemsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCarouselItemsApiFp(this.configuration).adminCarouselItemsIdPut(id, adminCarouselItemsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCarouselItemsGetRequest} [adminCarouselItemsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCarouselItemsApi
     */
    public adminCarouselItemsPost(adminCarouselItemsGetRequest?: AdminCarouselItemsGetRequest, options?: AxiosRequestConfig) {
        return AdminCarouselItemsApiFp(this.configuration).adminCarouselItemsPost(adminCarouselItemsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCategoriesApi - axios parameter creator
 * @export
 */
export const AdminCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoriesIdDelete', 'id', id)
            const localVarPath = `/Admin/Categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoriesIdGet', 'id', id)
            const localVarPath = `/Admin/Categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSiblingCategoryId] 
         * @param {string} [nextSiblingCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdPatch: async (id: string, previousSiblingCategoryId?: string, nextSiblingCategoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoriesIdPatch', 'id', id)
            const localVarPath = `/Admin/Categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousSiblingCategoryId !== undefined) {
                localVarQueryParameter['previousSiblingCategoryId'] = previousSiblingCategoryId;
            }

            if (nextSiblingCategoryId !== undefined) {
                localVarQueryParameter['nextSiblingCategoryId'] = nextSiblingCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCategoriesIdDeleteRequest} [adminCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdPut: async (id: string, adminCategoriesIdDeleteRequest?: AdminCategoriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCategoriesIdPut', 'id', id)
            const localVarPath = `/Admin/Categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCategoriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCategoriesGetRequest} [adminCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesPost: async (adminCategoriesGetRequest?: AdminCategoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCategoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCategoriesApi - functional programming interface
 * @export
 */
export const AdminCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSiblingCategoryId] 
         * @param {string} [nextSiblingCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesIdPatch(id: string, previousSiblingCategoryId?: string, nextSiblingCategoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesIdPatch(id, previousSiblingCategoryId, nextSiblingCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCategoriesIdDeleteRequest} [adminCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesIdPut(id: string, adminCategoriesIdDeleteRequest?: AdminCategoriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesIdPut(id, adminCategoriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCategoriesGetRequest} [adminCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesPost(adminCategoriesGetRequest?: AdminCategoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesPost(adminCategoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCategoriesApi - factory interface
 * @export
 */
export const AdminCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesGet(options?: any): AxiosPromise<CategoryAdminListViewModelListApplicationResult> {
            return localVarFp.adminCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdGet(id: string, options?: any): AxiosPromise<CategoryAdminViewModelApplicationResult> {
            return localVarFp.adminCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSiblingCategoryId] 
         * @param {string} [nextSiblingCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdPatch(id: string, previousSiblingCategoryId?: string, nextSiblingCategoryId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCategoriesIdPatch(id, previousSiblingCategoryId, nextSiblingCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCategoriesIdDeleteRequest} [adminCategoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesIdPut(id: string, adminCategoriesIdDeleteRequest?: AdminCategoriesIdDeleteRequest, options?: any): AxiosPromise<CategoryAdminViewModelApplicationResult> {
            return localVarFp.adminCategoriesIdPut(id, adminCategoriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCategoriesGetRequest} [adminCategoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesPost(adminCategoriesGetRequest?: AdminCategoriesGetRequest, options?: any): AxiosPromise<CategoryAdminViewModelApplicationResult> {
            return localVarFp.adminCategoriesPost(adminCategoriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCategoriesApi - object-oriented interface
 * @export
 * @class AdminCategoriesApi
 * @extends {BaseAPI}
 */
export class AdminCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCategoriesApi
     */
    public adminCategoriesGet(options?: AxiosRequestConfig) {
        return AdminCategoriesApiFp(this.configuration).adminCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCategoriesApi
     */
    public adminCategoriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCategoriesApiFp(this.configuration).adminCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCategoriesApi
     */
    public adminCategoriesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCategoriesApiFp(this.configuration).adminCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousSiblingCategoryId] 
     * @param {string} [nextSiblingCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCategoriesApi
     */
    public adminCategoriesIdPatch(id: string, previousSiblingCategoryId?: string, nextSiblingCategoryId?: string, options?: AxiosRequestConfig) {
        return AdminCategoriesApiFp(this.configuration).adminCategoriesIdPatch(id, previousSiblingCategoryId, nextSiblingCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCategoriesIdDeleteRequest} [adminCategoriesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCategoriesApi
     */
    public adminCategoriesIdPut(id: string, adminCategoriesIdDeleteRequest?: AdminCategoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCategoriesApiFp(this.configuration).adminCategoriesIdPut(id, adminCategoriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCategoriesGetRequest} [adminCategoriesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCategoriesApi
     */
    public adminCategoriesPost(adminCategoriesGetRequest?: AdminCategoriesGetRequest, options?: AxiosRequestConfig) {
        return AdminCategoriesApiFp(this.configuration).adminCategoriesPost(adminCategoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminClientsApi - axios parameter creator
 * @export
 */
export const AdminClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsGet: async (pageIndex: number, pageSize: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminClientsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminClientsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminClientsIdDelete', 'id', id)
            const localVarPath = `/Admin/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminClientsIdGet', 'id', id)
            const localVarPath = `/Admin/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousClientId] 
         * @param {string} [nextClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdPatch: async (id: string, previousClientId?: string, nextClientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminClientsIdPatch', 'id', id)
            const localVarPath = `/Admin/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousClientId !== undefined) {
                localVarQueryParameter['previousClientId'] = previousClientId;
            }

            if (nextClientId !== undefined) {
                localVarQueryParameter['nextClientId'] = nextClientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminClientsIdDeleteRequest} [adminClientsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdPut: async (id: string, adminClientsIdDeleteRequest?: AdminClientsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminClientsIdPut', 'id', id)
            const localVarPath = `/Admin/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminClientsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminClientsGetRequest} [adminClientsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsPost: async (adminClientsGetRequest?: AdminClientsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminClientsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminClientsApi - functional programming interface
 * @export
 */
export const AdminClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientsGet(pageIndex: number, pageSize: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientsGet(pageIndex, pageSize, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousClientId] 
         * @param {string} [nextClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientsIdPatch(id: string, previousClientId?: string, nextClientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientsIdPatch(id, previousClientId, nextClientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminClientsIdDeleteRequest} [adminClientsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientsIdPut(id: string, adminClientsIdDeleteRequest?: AdminClientsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientsIdPut(id, adminClientsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminClientsGetRequest} [adminClientsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientsPost(adminClientsGetRequest?: AdminClientsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientsPost(adminClientsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminClientsApi - factory interface
 * @export
 */
export const AdminClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsGet(pageIndex: number, pageSize: number, name?: string, options?: any): AxiosPromise<ClientAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminClientsGet(pageIndex, pageSize, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminClientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdGet(id: string, options?: any): AxiosPromise<ClientAdminViewModelApplicationResult> {
            return localVarFp.adminClientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousClientId] 
         * @param {string} [nextClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdPatch(id: string, previousClientId?: string, nextClientId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminClientsIdPatch(id, previousClientId, nextClientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminClientsIdDeleteRequest} [adminClientsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsIdPut(id: string, adminClientsIdDeleteRequest?: AdminClientsIdDeleteRequest, options?: any): AxiosPromise<ClientAdminViewModelApplicationResult> {
            return localVarFp.adminClientsIdPut(id, adminClientsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminClientsGetRequest} [adminClientsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientsPost(adminClientsGetRequest?: AdminClientsGetRequest, options?: any): AxiosPromise<ClientAdminViewModelApplicationResult> {
            return localVarFp.adminClientsPost(adminClientsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminClientsApi - object-oriented interface
 * @export
 * @class AdminClientsApi
 * @extends {BaseAPI}
 */
export class AdminClientsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminClientsApi
     */
    public adminClientsGet(pageIndex: number, pageSize: number, name?: string, options?: AxiosRequestConfig) {
        return AdminClientsApiFp(this.configuration).adminClientsGet(pageIndex, pageSize, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminClientsApi
     */
    public adminClientsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminClientsApiFp(this.configuration).adminClientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminClientsApi
     */
    public adminClientsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminClientsApiFp(this.configuration).adminClientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousClientId] 
     * @param {string} [nextClientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminClientsApi
     */
    public adminClientsIdPatch(id: string, previousClientId?: string, nextClientId?: string, options?: AxiosRequestConfig) {
        return AdminClientsApiFp(this.configuration).adminClientsIdPatch(id, previousClientId, nextClientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminClientsIdDeleteRequest} [adminClientsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminClientsApi
     */
    public adminClientsIdPut(id: string, adminClientsIdDeleteRequest?: AdminClientsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminClientsApiFp(this.configuration).adminClientsIdPut(id, adminClientsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminClientsGetRequest} [adminClientsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminClientsApi
     */
    public adminClientsPost(adminClientsGetRequest?: AdminClientsGetRequest, options?: AxiosRequestConfig) {
        return AdminClientsApiFp(this.configuration).adminClientsPost(adminClientsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminColourGroupsApi - axios parameter creator
 * @export
 */
export const AdminColourGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminColourGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminColourGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ColourGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (isSystemManaged !== undefined) {
                localVarQueryParameter['isSystemManaged'] = isSystemManaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/ColourGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/ColourGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousColourGroupId] 
         * @param {string} [nextColourGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdPatch: async (id: string, previousColourGroupId?: string, nextColourGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/ColourGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousColourGroupId !== undefined) {
                localVarQueryParameter['previousColourGroupId'] = previousColourGroupId;
            }

            if (nextColourGroupId !== undefined) {
                localVarQueryParameter['nextColourGroupId'] = nextColourGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourGroupsIdDeleteRequest} [adminColourGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdPut: async (id: string, adminColourGroupsIdDeleteRequest?: AdminColourGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/ColourGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourGroupsGetRequest} [adminColourGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsPost: async (adminColourGroupsGetRequest?: AdminColourGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ColourGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminColourGroupsApi - functional programming interface
 * @export
 */
export const AdminColourGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminColourGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousColourGroupId] 
         * @param {string} [nextColourGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourGroupsIdPatch(id: string, previousColourGroupId?: string, nextColourGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourGroupsIdPatch(id, previousColourGroupId, nextColourGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourGroupsIdDeleteRequest} [adminColourGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourGroupsIdPut(id: string, adminColourGroupsIdDeleteRequest?: AdminColourGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourGroupsIdPut(id, adminColourGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourGroupsGetRequest} [adminColourGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourGroupsPost(adminColourGroupsGetRequest?: AdminColourGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourGroupsPost(adminColourGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminColourGroupsApi - factory interface
 * @export
 */
export const AdminColourGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminColourGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: any): AxiosPromise<ColourGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminColourGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminColourGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdGet(id: string, options?: any): AxiosPromise<ColourGroupAdminViewModelApplicationResult> {
            return localVarFp.adminColourGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousColourGroupId] 
         * @param {string} [nextColourGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdPatch(id: string, previousColourGroupId?: string, nextColourGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminColourGroupsIdPatch(id, previousColourGroupId, nextColourGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourGroupsIdDeleteRequest} [adminColourGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsIdPut(id: string, adminColourGroupsIdDeleteRequest?: AdminColourGroupsIdDeleteRequest, options?: any): AxiosPromise<ColourGroupAdminViewModelApplicationResult> {
            return localVarFp.adminColourGroupsIdPut(id, adminColourGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourGroupsGetRequest} [adminColourGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourGroupsPost(adminColourGroupsGetRequest?: AdminColourGroupsGetRequest, options?: any): AxiosPromise<ColourGroupAdminViewModelApplicationResult> {
            return localVarFp.adminColourGroupsPost(adminColourGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminColourGroupsApi - object-oriented interface
 * @export
 * @class AdminColourGroupsApi
 * @extends {BaseAPI}
 */
export class AdminColourGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [isSystemManaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourGroupsApi
     */
    public adminColourGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig) {
        return AdminColourGroupsApiFp(this.configuration).adminColourGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourGroupsApi
     */
    public adminColourGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminColourGroupsApiFp(this.configuration).adminColourGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourGroupsApi
     */
    public adminColourGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminColourGroupsApiFp(this.configuration).adminColourGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousColourGroupId] 
     * @param {string} [nextColourGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourGroupsApi
     */
    public adminColourGroupsIdPatch(id: string, previousColourGroupId?: string, nextColourGroupId?: string, options?: AxiosRequestConfig) {
        return AdminColourGroupsApiFp(this.configuration).adminColourGroupsIdPatch(id, previousColourGroupId, nextColourGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourGroupsIdDeleteRequest} [adminColourGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourGroupsApi
     */
    public adminColourGroupsIdPut(id: string, adminColourGroupsIdDeleteRequest?: AdminColourGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminColourGroupsApiFp(this.configuration).adminColourGroupsIdPut(id, adminColourGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourGroupsGetRequest} [adminColourGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourGroupsApi
     */
    public adminColourGroupsPost(adminColourGroupsGetRequest?: AdminColourGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminColourGroupsApiFp(this.configuration).adminColourGroupsPost(adminColourGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminColourPricesApi - axios parameter creator
 * @export
 */
export const AdminColourPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminColourPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminColourPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminColourPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/ColourPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/ColourPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourPricesIdGet', 'id', id)
            const localVarPath = `/Admin/ColourPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColourPricesIdPut', 'id', id)
            const localVarPath = `/Admin/ColourPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ColourPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminColourPricesApi - functional programming interface
 * @export
 */
export const AdminColourPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminColourPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColourPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColourPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminColourPricesApi - factory interface
 * @export
 */
export const AdminColourPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminColourPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminColourPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminColourPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminColourPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminColourPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColourPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminColourPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminColourPricesApi - object-oriented interface
 * @export
 * @class AdminColourPricesApi
 * @extends {BaseAPI}
 */
export class AdminColourPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourPricesApi
     */
    public adminColourPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminColourPricesApiFp(this.configuration).adminColourPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourPricesApi
     */
    public adminColourPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminColourPricesApiFp(this.configuration).adminColourPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourPricesApi
     */
    public adminColourPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminColourPricesApiFp(this.configuration).adminColourPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourPricesApi
     */
    public adminColourPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminColourPricesApiFp(this.configuration).adminColourPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColourPricesApi
     */
    public adminColourPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminColourPricesApiFp(this.configuration).adminColourPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminColoursApi - axios parameter creator
 * @export
 */
export const AdminColoursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminColoursGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminColoursGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Colours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColoursIdDelete', 'id', id)
            const localVarPath = `/Admin/Colours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColoursIdGet', 'id', id)
            const localVarPath = `/Admin/Colours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousColourId] 
         * @param {string} [nextColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdPatch: async (id: string, previousColourId?: string, nextColourId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColoursIdPatch', 'id', id)
            const localVarPath = `/Admin/Colours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousColourId !== undefined) {
                localVarQueryParameter['previousColourId'] = previousColourId;
            }

            if (nextColourId !== undefined) {
                localVarQueryParameter['nextColourId'] = nextColourId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColoursIdDeleteRequest} [adminColoursIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdPut: async (id: string, adminColoursIdDeleteRequest?: AdminColoursIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminColoursIdPut', 'id', id)
            const localVarPath = `/Admin/Colours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColoursIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColoursGetRequest} [adminColoursGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursPost: async (adminColoursGetRequest?: AdminColoursGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Colours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColoursGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminColoursApi - functional programming interface
 * @export
 */
export const AdminColoursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminColoursApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColoursGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColoursGet(pageIndex, pageSize, searchTerm, isDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColoursIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColoursIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColoursIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColoursIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousColourId] 
         * @param {string} [nextColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColoursIdPatch(id: string, previousColourId?: string, nextColourId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColoursIdPatch(id, previousColourId, nextColourId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColoursIdDeleteRequest} [adminColoursIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColoursIdPut(id: string, adminColoursIdDeleteRequest?: AdminColoursIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColoursIdPut(id, adminColoursIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColoursGetRequest} [adminColoursGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminColoursPost(adminColoursGetRequest?: AdminColoursGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminColoursPost(adminColoursGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminColoursApi - factory interface
 * @export
 */
export const AdminColoursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminColoursApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: any): AxiosPromise<ColourAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminColoursGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminColoursIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdGet(id: string, options?: any): AxiosPromise<ColourAdminViewModelApplicationResult> {
            return localVarFp.adminColoursIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousColourId] 
         * @param {string} [nextColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdPatch(id: string, previousColourId?: string, nextColourId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminColoursIdPatch(id, previousColourId, nextColourId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColoursIdDeleteRequest} [adminColoursIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursIdPut(id: string, adminColoursIdDeleteRequest?: AdminColoursIdDeleteRequest, options?: any): AxiosPromise<ColourAdminViewModelApplicationResult> {
            return localVarFp.adminColoursIdPut(id, adminColoursIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColoursGetRequest} [adminColoursGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminColoursPost(adminColoursGetRequest?: AdminColoursGetRequest, options?: any): AxiosPromise<ColourAdminViewModelApplicationResult> {
            return localVarFp.adminColoursPost(adminColoursGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminColoursApi - object-oriented interface
 * @export
 * @class AdminColoursApi
 * @extends {BaseAPI}
 */
export class AdminColoursApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColoursApi
     */
    public adminColoursGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig) {
        return AdminColoursApiFp(this.configuration).adminColoursGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColoursApi
     */
    public adminColoursIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminColoursApiFp(this.configuration).adminColoursIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColoursApi
     */
    public adminColoursIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminColoursApiFp(this.configuration).adminColoursIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousColourId] 
     * @param {string} [nextColourId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColoursApi
     */
    public adminColoursIdPatch(id: string, previousColourId?: string, nextColourId?: string, options?: AxiosRequestConfig) {
        return AdminColoursApiFp(this.configuration).adminColoursIdPatch(id, previousColourId, nextColourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColoursIdDeleteRequest} [adminColoursIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColoursApi
     */
    public adminColoursIdPut(id: string, adminColoursIdDeleteRequest?: AdminColoursIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminColoursApiFp(this.configuration).adminColoursIdPut(id, adminColoursIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColoursGetRequest} [adminColoursGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminColoursApi
     */
    public adminColoursPost(adminColoursGetRequest?: AdminColoursGetRequest, options?: AxiosRequestConfig) {
        return AdminColoursApiFp(this.configuration).adminColoursPost(adminColoursGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCountriesApi - axios parameter creator
 * @export
 */
export const AdminCountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCountriesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCountriesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountriesIdDelete', 'id', id)
            const localVarPath = `/Admin/Countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountriesIdGet', 'id', id)
            const localVarPath = `/Admin/Countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCountryId] 
         * @param {string} [nextCountryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdPatch: async (id: string, previousCountryId?: string, nextCountryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountriesIdPatch', 'id', id)
            const localVarPath = `/Admin/Countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousCountryId !== undefined) {
                localVarQueryParameter['previousCountryId'] = previousCountryId;
            }

            if (nextCountryId !== undefined) {
                localVarQueryParameter['nextCountryId'] = nextCountryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCountriesIdDeleteRequest} [adminCountriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdPut: async (id: string, adminCountriesIdDeleteRequest?: AdminCountriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountriesIdPut', 'id', id)
            const localVarPath = `/Admin/Countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCountriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCountriesGetRequest} [adminCountriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesPost: async (adminCountriesGetRequest?: AdminCountriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCountriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCountriesApi - functional programming interface
 * @export
 */
export const AdminCountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCountryId] 
         * @param {string} [nextCountryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesIdPatch(id: string, previousCountryId?: string, nextCountryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesIdPatch(id, previousCountryId, nextCountryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCountriesIdDeleteRequest} [adminCountriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesIdPut(id: string, adminCountriesIdDeleteRequest?: AdminCountriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesIdPut(id, adminCountriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCountriesGetRequest} [adminCountriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountriesPost(adminCountriesGetRequest?: AdminCountriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountriesPost(adminCountriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCountriesApi - factory interface
 * @export
 */
export const AdminCountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCountriesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<CountryAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCountriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCountriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdGet(id: string, options?: any): AxiosPromise<CountryAdminViewModelApplicationResult> {
            return localVarFp.adminCountriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCountryId] 
         * @param {string} [nextCountryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdPatch(id: string, previousCountryId?: string, nextCountryId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCountriesIdPatch(id, previousCountryId, nextCountryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCountriesIdDeleteRequest} [adminCountriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesIdPut(id: string, adminCountriesIdDeleteRequest?: AdminCountriesIdDeleteRequest, options?: any): AxiosPromise<CountryAdminViewModelApplicationResult> {
            return localVarFp.adminCountriesIdPut(id, adminCountriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCountriesGetRequest} [adminCountriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountriesPost(adminCountriesGetRequest?: AdminCountriesGetRequest, options?: any): AxiosPromise<CountryAdminViewModelApplicationResult> {
            return localVarFp.adminCountriesPost(adminCountriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCountriesApi - object-oriented interface
 * @export
 * @class AdminCountriesApi
 * @extends {BaseAPI}
 */
export class AdminCountriesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountriesApi
     */
    public adminCountriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminCountriesApiFp(this.configuration).adminCountriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountriesApi
     */
    public adminCountriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCountriesApiFp(this.configuration).adminCountriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountriesApi
     */
    public adminCountriesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCountriesApiFp(this.configuration).adminCountriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousCountryId] 
     * @param {string} [nextCountryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountriesApi
     */
    public adminCountriesIdPatch(id: string, previousCountryId?: string, nextCountryId?: string, options?: AxiosRequestConfig) {
        return AdminCountriesApiFp(this.configuration).adminCountriesIdPatch(id, previousCountryId, nextCountryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCountriesIdDeleteRequest} [adminCountriesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountriesApi
     */
    public adminCountriesIdPut(id: string, adminCountriesIdDeleteRequest?: AdminCountriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCountriesApiFp(this.configuration).adminCountriesIdPut(id, adminCountriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCountriesGetRequest} [adminCountriesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountriesApi
     */
    public adminCountriesPost(adminCountriesGetRequest?: AdminCountriesGetRequest, options?: AxiosRequestConfig) {
        return AdminCountriesApiFp(this.configuration).adminCountriesPost(adminCountriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCountryDeliveryTypesApi - axios parameter creator
 * @export
 */
export const AdminCountryDeliveryTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesGet: async (pageIndex: number, pageSize: number, countryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCountryDeliveryTypesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCountryDeliveryTypesGet', 'pageSize', pageSize)
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('adminCountryDeliveryTypesGet', 'countryId', countryId)
            const localVarPath = `/Admin/CountryDeliveryTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountryDeliveryTypesIdDelete', 'id', id)
            const localVarPath = `/Admin/CountryDeliveryTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountryDeliveryTypesIdGet', 'id', id)
            const localVarPath = `/Admin/CountryDeliveryTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCountryDeliveryTypesIdDeleteRequest} [adminCountryDeliveryTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesIdPut: async (id: string, adminCountryDeliveryTypesIdDeleteRequest?: AdminCountryDeliveryTypesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCountryDeliveryTypesIdPut', 'id', id)
            const localVarPath = `/Admin/CountryDeliveryTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCountryDeliveryTypesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCountryDeliveryTypesGetRequest} [adminCountryDeliveryTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesPost: async (adminCountryDeliveryTypesGetRequest?: AdminCountryDeliveryTypesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/CountryDeliveryTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCountryDeliveryTypesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCountryDeliveryTypesApi - functional programming interface
 * @export
 */
export const AdminCountryDeliveryTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCountryDeliveryTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryDeliveryTypesGet(pageIndex: number, pageSize: number, countryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryDeliveryTypesGet(pageIndex, pageSize, countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryDeliveryTypesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryDeliveryTypesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryDeliveryTypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDeliveryTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryDeliveryTypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCountryDeliveryTypesIdDeleteRequest} [adminCountryDeliveryTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryDeliveryTypesIdPut(id: string, adminCountryDeliveryTypesIdDeleteRequest?: AdminCountryDeliveryTypesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDeliveryTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryDeliveryTypesIdPut(id, adminCountryDeliveryTypesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCountryDeliveryTypesGetRequest} [adminCountryDeliveryTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryDeliveryTypesPost(adminCountryDeliveryTypesGetRequest?: AdminCountryDeliveryTypesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDeliveryTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryDeliveryTypesPost(adminCountryDeliveryTypesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCountryDeliveryTypesApi - factory interface
 * @export
 */
export const AdminCountryDeliveryTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCountryDeliveryTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesGet(pageIndex: number, pageSize: number, countryId: string, options?: any): AxiosPromise<CountryDeliveryTypeAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCountryDeliveryTypesGet(pageIndex, pageSize, countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCountryDeliveryTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesIdGet(id: string, options?: any): AxiosPromise<CountryDeliveryTypeAdminViewModelApplicationResult> {
            return localVarFp.adminCountryDeliveryTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCountryDeliveryTypesIdDeleteRequest} [adminCountryDeliveryTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesIdPut(id: string, adminCountryDeliveryTypesIdDeleteRequest?: AdminCountryDeliveryTypesIdDeleteRequest, options?: any): AxiosPromise<CountryDeliveryTypeAdminViewModelApplicationResult> {
            return localVarFp.adminCountryDeliveryTypesIdPut(id, adminCountryDeliveryTypesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCountryDeliveryTypesGetRequest} [adminCountryDeliveryTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryDeliveryTypesPost(adminCountryDeliveryTypesGetRequest?: AdminCountryDeliveryTypesGetRequest, options?: any): AxiosPromise<CountryDeliveryTypeAdminViewModelApplicationResult> {
            return localVarFp.adminCountryDeliveryTypesPost(adminCountryDeliveryTypesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCountryDeliveryTypesApi - object-oriented interface
 * @export
 * @class AdminCountryDeliveryTypesApi
 * @extends {BaseAPI}
 */
export class AdminCountryDeliveryTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryDeliveryTypesApi
     */
    public adminCountryDeliveryTypesGet(pageIndex: number, pageSize: number, countryId: string, options?: AxiosRequestConfig) {
        return AdminCountryDeliveryTypesApiFp(this.configuration).adminCountryDeliveryTypesGet(pageIndex, pageSize, countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryDeliveryTypesApi
     */
    public adminCountryDeliveryTypesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCountryDeliveryTypesApiFp(this.configuration).adminCountryDeliveryTypesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryDeliveryTypesApi
     */
    public adminCountryDeliveryTypesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCountryDeliveryTypesApiFp(this.configuration).adminCountryDeliveryTypesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCountryDeliveryTypesIdDeleteRequest} [adminCountryDeliveryTypesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryDeliveryTypesApi
     */
    public adminCountryDeliveryTypesIdPut(id: string, adminCountryDeliveryTypesIdDeleteRequest?: AdminCountryDeliveryTypesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCountryDeliveryTypesApiFp(this.configuration).adminCountryDeliveryTypesIdPut(id, adminCountryDeliveryTypesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCountryDeliveryTypesGetRequest} [adminCountryDeliveryTypesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryDeliveryTypesApi
     */
    public adminCountryDeliveryTypesPost(adminCountryDeliveryTypesGetRequest?: AdminCountryDeliveryTypesGetRequest, options?: AxiosRequestConfig) {
        return AdminCountryDeliveryTypesApiFp(this.configuration).adminCountryDeliveryTypesPost(adminCountryDeliveryTypesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCountryGroupsApi - axios parameter creator
 * @export
 */
export const AdminCountryGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryGroupsGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCountryGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCountryGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/CountryGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCountryGroupsApi - functional programming interface
 * @export
 */
export const AdminCountryGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCountryGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryGroupsGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryGroupListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryGroupsGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCountryGroupsApi - factory interface
 * @export
 */
export const AdminCountryGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCountryGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryGroupsGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<CountryGroupListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCountryGroupsGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCountryGroupsApi - object-oriented interface
 * @export
 * @class AdminCountryGroupsApi
 * @extends {BaseAPI}
 */
export class AdminCountryGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryGroupsApi
     */
    public adminCountryGroupsGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return AdminCountryGroupsApiFp(this.configuration).adminCountryGroupsGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCountryImportDutyTypesApi - axios parameter creator
 * @export
 */
export const AdminCountryImportDutyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryImportDutyTypesGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCountryImportDutyTypesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCountryImportDutyTypesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/CountryImportDutyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCountryImportDutyTypesApi - functional programming interface
 * @export
 */
export const AdminCountryImportDutyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCountryImportDutyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCountryImportDutyTypesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDutyTypeListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCountryImportDutyTypesGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCountryImportDutyTypesApi - factory interface
 * @export
 */
export const AdminCountryImportDutyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCountryImportDutyTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCountryImportDutyTypesGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<ImportDutyTypeListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCountryImportDutyTypesGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCountryImportDutyTypesApi - object-oriented interface
 * @export
 * @class AdminCountryImportDutyTypesApi
 * @extends {BaseAPI}
 */
export class AdminCountryImportDutyTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCountryImportDutyTypesApi
     */
    public adminCountryImportDutyTypesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return AdminCountryImportDutyTypesApiFp(this.configuration).adminCountryImportDutyTypesGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCouriersApi - axios parameter creator
 * @export
 */
export const AdminCouriersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ViewCouriers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCouriersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCouriersGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Couriers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCouriersIdDelete', 'id', id)
            const localVarPath = `/Admin/Couriers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCouriersIdGet', 'id', id)
            const localVarPath = `/Admin/Couriers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCouriersIdDeleteRequest} [adminCouriersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersIdPut: async (id: string, adminCouriersIdDeleteRequest?: AdminCouriersIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCouriersIdPut', 'id', id)
            const localVarPath = `/Admin/Couriers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCouriersIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCouriersGetRequest} [adminCouriersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersPost: async (adminCouriersGetRequest?: AdminCouriersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Couriers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCouriersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCouriersApi - functional programming interface
 * @export
 */
export const AdminCouriersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCouriersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ViewCouriers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCouriersGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourierAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCouriersGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCouriersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCouriersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCouriersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourierAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCouriersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCouriersIdDeleteRequest} [adminCouriersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCouriersIdPut(id: string, adminCouriersIdDeleteRequest?: AdminCouriersIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourierAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCouriersIdPut(id, adminCouriersIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCouriersGetRequest} [adminCouriersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCouriersPost(adminCouriersGetRequest?: AdminCouriersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourierAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCouriersPost(adminCouriersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCouriersApi - factory interface
 * @export
 */
export const AdminCouriersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCouriersApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ViewCouriers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<CourierAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCouriersGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCouriersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersIdGet(id: string, options?: any): AxiosPromise<CourierAdminViewModelApplicationResult> {
            return localVarFp.adminCouriersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCouriersIdDeleteRequest} [adminCouriersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersIdPut(id: string, adminCouriersIdDeleteRequest?: AdminCouriersIdDeleteRequest, options?: any): AxiosPromise<CourierAdminViewModelApplicationResult> {
            return localVarFp.adminCouriersIdPut(id, adminCouriersIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCouriersGetRequest} [adminCouriersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCouriersPost(adminCouriersGetRequest?: AdminCouriersGetRequest, options?: any): AxiosPromise<CourierAdminViewModelApplicationResult> {
            return localVarFp.adminCouriersPost(adminCouriersGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCouriersApi - object-oriented interface
 * @export
 * @class AdminCouriersApi
 * @extends {BaseAPI}
 */
export class AdminCouriersApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ViewCouriers)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCouriersApi
     */
    public adminCouriersGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminCouriersApiFp(this.configuration).adminCouriersGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCouriersApi
     */
    public adminCouriersIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCouriersApiFp(this.configuration).adminCouriersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCouriersApi
     */
    public adminCouriersIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCouriersApiFp(this.configuration).adminCouriersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCouriersIdDeleteRequest} [adminCouriersIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCouriersApi
     */
    public adminCouriersIdPut(id: string, adminCouriersIdDeleteRequest?: AdminCouriersIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCouriersApiFp(this.configuration).adminCouriersIdPut(id, adminCouriersIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCouriersGetRequest} [adminCouriersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCouriersApi
     */
    public adminCouriersPost(adminCouriersGetRequest?: AdminCouriersGetRequest, options?: AxiosRequestConfig) {
        return AdminCouriersApiFp(this.configuration).adminCouriersPost(adminCouriersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCurrenciesApi - axios parameter creator
 * @export
 */
export const AdminCurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCurrenciesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCurrenciesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCurrenciesIdDelete', 'id', id)
            const localVarPath = `/Admin/Currencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCurrenciesIdGet', 'id', id)
            const localVarPath = `/Admin/Currencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCurrenciesIdDeleteRequest} [adminCurrenciesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesIdPut: async (id: string, adminCurrenciesIdDeleteRequest?: AdminCurrenciesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCurrenciesIdPut', 'id', id)
            const localVarPath = `/Admin/Currencies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCurrenciesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCurrenciesGetRequest} [adminCurrenciesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesPost: async (adminCurrenciesGetRequest?: AdminCurrenciesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCurrenciesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCurrenciesApi - functional programming interface
 * @export
 */
export const AdminCurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCurrenciesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCurrenciesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCurrenciesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCurrenciesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCurrenciesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCurrenciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCurrenciesIdDeleteRequest} [adminCurrenciesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCurrenciesIdPut(id: string, adminCurrenciesIdDeleteRequest?: AdminCurrenciesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCurrenciesIdPut(id, adminCurrenciesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCurrenciesGetRequest} [adminCurrenciesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCurrenciesPost(adminCurrenciesGetRequest?: AdminCurrenciesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCurrenciesPost(adminCurrenciesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCurrenciesApi - factory interface
 * @export
 */
export const AdminCurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<CurrencyAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCurrenciesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCurrenciesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesIdGet(id: string, options?: any): AxiosPromise<CurrencyAdminViewModelApplicationResult> {
            return localVarFp.adminCurrenciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCurrenciesIdDeleteRequest} [adminCurrenciesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesIdPut(id: string, adminCurrenciesIdDeleteRequest?: AdminCurrenciesIdDeleteRequest, options?: any): AxiosPromise<CurrencyAdminViewModelApplicationResult> {
            return localVarFp.adminCurrenciesIdPut(id, adminCurrenciesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCurrenciesGetRequest} [adminCurrenciesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCurrenciesPost(adminCurrenciesGetRequest?: AdminCurrenciesGetRequest, options?: any): AxiosPromise<CurrencyAdminViewModelApplicationResult> {
            return localVarFp.adminCurrenciesPost(adminCurrenciesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCurrenciesApi - object-oriented interface
 * @export
 * @class AdminCurrenciesApi
 * @extends {BaseAPI}
 */
export class AdminCurrenciesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCurrenciesApi
     */
    public adminCurrenciesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminCurrenciesApiFp(this.configuration).adminCurrenciesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCurrenciesApi
     */
    public adminCurrenciesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCurrenciesApiFp(this.configuration).adminCurrenciesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCurrenciesApi
     */
    public adminCurrenciesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCurrenciesApiFp(this.configuration).adminCurrenciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCurrenciesIdDeleteRequest} [adminCurrenciesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCurrenciesApi
     */
    public adminCurrenciesIdPut(id: string, adminCurrenciesIdDeleteRequest?: AdminCurrenciesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCurrenciesApiFp(this.configuration).adminCurrenciesIdPut(id, adminCurrenciesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCurrenciesGetRequest} [adminCurrenciesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCurrenciesApi
     */
    public adminCurrenciesPost(adminCurrenciesGetRequest?: AdminCurrenciesGetRequest, options?: AxiosRequestConfig) {
        return AdminCurrenciesApiFp(this.configuration).adminCurrenciesPost(adminCurrenciesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCustomizationGroupsApi - axios parameter creator
 * @export
 */
export const AdminCustomizationGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCustomizationGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCustomizationGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/CustomizationGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (isSystemManaged !== undefined) {
                localVarQueryParameter['isSystemManaged'] = isSystemManaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/CustomizationGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/CustomizationGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCustomizationGroupId] 
         * @param {string} [nextCustomizationGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdPatch: async (id: string, previousCustomizationGroupId?: string, nextCustomizationGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/CustomizationGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousCustomizationGroupId !== undefined) {
                localVarQueryParameter['previousCustomizationGroupId'] = previousCustomizationGroupId;
            }

            if (nextCustomizationGroupId !== undefined) {
                localVarQueryParameter['nextCustomizationGroupId'] = nextCustomizationGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCustomizationGroupsIdDeleteRequest} [adminCustomizationGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdPut: async (id: string, adminCustomizationGroupsIdDeleteRequest?: AdminCustomizationGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/CustomizationGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCustomizationGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCustomizationGroupsGetRequest} [adminCustomizationGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsPost: async (adminCustomizationGroupsGetRequest?: AdminCustomizationGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/CustomizationGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCustomizationGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCustomizationGroupsApi - functional programming interface
 * @export
 */
export const AdminCustomizationGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCustomizationGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCustomizationGroupId] 
         * @param {string} [nextCustomizationGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationGroupsIdPatch(id: string, previousCustomizationGroupId?: string, nextCustomizationGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationGroupsIdPatch(id, previousCustomizationGroupId, nextCustomizationGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCustomizationGroupsIdDeleteRequest} [adminCustomizationGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationGroupsIdPut(id: string, adminCustomizationGroupsIdDeleteRequest?: AdminCustomizationGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationGroupsIdPut(id, adminCustomizationGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCustomizationGroupsGetRequest} [adminCustomizationGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationGroupsPost(adminCustomizationGroupsGetRequest?: AdminCustomizationGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationGroupsPost(adminCustomizationGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCustomizationGroupsApi - factory interface
 * @export
 */
export const AdminCustomizationGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCustomizationGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: any): AxiosPromise<CustomizationGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCustomizationGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCustomizationGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdGet(id: string, options?: any): AxiosPromise<CustomizationGroupAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCustomizationGroupId] 
         * @param {string} [nextCustomizationGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdPatch(id: string, previousCustomizationGroupId?: string, nextCustomizationGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCustomizationGroupsIdPatch(id, previousCustomizationGroupId, nextCustomizationGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCustomizationGroupsIdDeleteRequest} [adminCustomizationGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsIdPut(id: string, adminCustomizationGroupsIdDeleteRequest?: AdminCustomizationGroupsIdDeleteRequest, options?: any): AxiosPromise<CustomizationGroupAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationGroupsIdPut(id, adminCustomizationGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCustomizationGroupsGetRequest} [adminCustomizationGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationGroupsPost(adminCustomizationGroupsGetRequest?: AdminCustomizationGroupsGetRequest, options?: any): AxiosPromise<CustomizationGroupAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationGroupsPost(adminCustomizationGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCustomizationGroupsApi - object-oriented interface
 * @export
 * @class AdminCustomizationGroupsApi
 * @extends {BaseAPI}
 */
export class AdminCustomizationGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [isSystemManaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationGroupsApi
     */
    public adminCustomizationGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig) {
        return AdminCustomizationGroupsApiFp(this.configuration).adminCustomizationGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationGroupsApi
     */
    public adminCustomizationGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCustomizationGroupsApiFp(this.configuration).adminCustomizationGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationGroupsApi
     */
    public adminCustomizationGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCustomizationGroupsApiFp(this.configuration).adminCustomizationGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousCustomizationGroupId] 
     * @param {string} [nextCustomizationGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationGroupsApi
     */
    public adminCustomizationGroupsIdPatch(id: string, previousCustomizationGroupId?: string, nextCustomizationGroupId?: string, options?: AxiosRequestConfig) {
        return AdminCustomizationGroupsApiFp(this.configuration).adminCustomizationGroupsIdPatch(id, previousCustomizationGroupId, nextCustomizationGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCustomizationGroupsIdDeleteRequest} [adminCustomizationGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationGroupsApi
     */
    public adminCustomizationGroupsIdPut(id: string, adminCustomizationGroupsIdDeleteRequest?: AdminCustomizationGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCustomizationGroupsApiFp(this.configuration).adminCustomizationGroupsIdPut(id, adminCustomizationGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCustomizationGroupsGetRequest} [adminCustomizationGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationGroupsApi
     */
    public adminCustomizationGroupsPost(adminCustomizationGroupsGetRequest?: AdminCustomizationGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminCustomizationGroupsApiFp(this.configuration).adminCustomizationGroupsPost(adminCustomizationGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCustomizationPricesApi - axios parameter creator
 * @export
 */
export const AdminCustomizationPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCustomizationPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCustomizationPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminCustomizationPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/CustomizationPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/CustomizationPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationPricesIdGet', 'id', id)
            const localVarPath = `/Admin/CustomizationPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationPricesIdPut', 'id', id)
            const localVarPath = `/Admin/CustomizationPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/CustomizationPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCustomizationPricesApi - functional programming interface
 * @export
 */
export const AdminCustomizationPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCustomizationPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCustomizationPricesApi - factory interface
 * @export
 */
export const AdminCustomizationPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCustomizationPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCustomizationPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCustomizationPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCustomizationPricesApi - object-oriented interface
 * @export
 * @class AdminCustomizationPricesApi
 * @extends {BaseAPI}
 */
export class AdminCustomizationPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationPricesApi
     */
    public adminCustomizationPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminCustomizationPricesApiFp(this.configuration).adminCustomizationPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationPricesApi
     */
    public adminCustomizationPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCustomizationPricesApiFp(this.configuration).adminCustomizationPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationPricesApi
     */
    public adminCustomizationPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCustomizationPricesApiFp(this.configuration).adminCustomizationPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationPricesApi
     */
    public adminCustomizationPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCustomizationPricesApiFp(this.configuration).adminCustomizationPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationPricesApi
     */
    public adminCustomizationPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminCustomizationPricesApiFp(this.configuration).adminCustomizationPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCustomizationTypesApi - axios parameter creator
 * @export
 */
export const AdminCustomizationTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/CustomizationTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCustomizationTypesApi - functional programming interface
 * @export
 */
export const AdminCustomizationTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCustomizationTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationTypeListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCustomizationTypesApi - factory interface
 * @export
 */
export const AdminCustomizationTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCustomizationTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationTypesGet(options?: any): AxiosPromise<CustomizationTypeListViewModelListApplicationResult> {
            return localVarFp.adminCustomizationTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCustomizationTypesApi - object-oriented interface
 * @export
 * @class AdminCustomizationTypesApi
 * @extends {BaseAPI}
 */
export class AdminCustomizationTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationTypesApi
     */
    public adminCustomizationTypesGet(options?: AxiosRequestConfig) {
        return AdminCustomizationTypesApiFp(this.configuration).adminCustomizationTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCustomizationsApi - axios parameter creator
 * @export
 */
export const AdminCustomizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminCustomizationsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminCustomizationsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Customizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationsIdDelete', 'id', id)
            const localVarPath = `/Admin/Customizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationsIdGet', 'id', id)
            const localVarPath = `/Admin/Customizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCustomizationId] 
         * @param {string} [nextCustomizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdPatch: async (id: string, previousCustomizationId?: string, nextCustomizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationsIdPatch', 'id', id)
            const localVarPath = `/Admin/Customizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousCustomizationId !== undefined) {
                localVarQueryParameter['previousCustomizationId'] = previousCustomizationId;
            }

            if (nextCustomizationId !== undefined) {
                localVarQueryParameter['nextCustomizationId'] = nextCustomizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCustomizationsIdDeleteRequest} [adminCustomizationsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdPut: async (id: string, adminCustomizationsIdDeleteRequest?: AdminCustomizationsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomizationsIdPut', 'id', id)
            const localVarPath = `/Admin/Customizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCustomizationsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCustomizationsGetRequest} [adminCustomizationsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsPost: async (adminCustomizationsGetRequest?: AdminCustomizationsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Customizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCustomizationsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCustomizationsApi - functional programming interface
 * @export
 */
export const AdminCustomizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCustomizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationsGet(pageIndex, pageSize, searchTerm, isDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCustomizationId] 
         * @param {string} [nextCustomizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationsIdPatch(id: string, previousCustomizationId?: string, nextCustomizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationsIdPatch(id, previousCustomizationId, nextCustomizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCustomizationsIdDeleteRequest} [adminCustomizationsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationsIdPut(id: string, adminCustomizationsIdDeleteRequest?: AdminCustomizationsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationsIdPut(id, adminCustomizationsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCustomizationsGetRequest} [adminCustomizationsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomizationsPost(adminCustomizationsGetRequest?: AdminCustomizationsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizationAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomizationsPost(adminCustomizationsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCustomizationsApi - factory interface
 * @export
 */
export const AdminCustomizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCustomizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: any): AxiosPromise<CustomizationAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminCustomizationsGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCustomizationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdGet(id: string, options?: any): AxiosPromise<CustomizationAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousCustomizationId] 
         * @param {string} [nextCustomizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdPatch(id: string, previousCustomizationId?: string, nextCustomizationId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminCustomizationsIdPatch(id, previousCustomizationId, nextCustomizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminCustomizationsIdDeleteRequest} [adminCustomizationsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsIdPut(id: string, adminCustomizationsIdDeleteRequest?: AdminCustomizationsIdDeleteRequest, options?: any): AxiosPromise<CustomizationAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationsIdPut(id, adminCustomizationsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminCustomizationsGetRequest} [adminCustomizationsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomizationsPost(adminCustomizationsGetRequest?: AdminCustomizationsGetRequest, options?: any): AxiosPromise<CustomizationAdminViewModelApplicationResult> {
            return localVarFp.adminCustomizationsPost(adminCustomizationsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCustomizationsApi - object-oriented interface
 * @export
 * @class AdminCustomizationsApi
 * @extends {BaseAPI}
 */
export class AdminCustomizationsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationsApi
     */
    public adminCustomizationsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig) {
        return AdminCustomizationsApiFp(this.configuration).adminCustomizationsGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationsApi
     */
    public adminCustomizationsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminCustomizationsApiFp(this.configuration).adminCustomizationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationsApi
     */
    public adminCustomizationsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminCustomizationsApiFp(this.configuration).adminCustomizationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousCustomizationId] 
     * @param {string} [nextCustomizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationsApi
     */
    public adminCustomizationsIdPatch(id: string, previousCustomizationId?: string, nextCustomizationId?: string, options?: AxiosRequestConfig) {
        return AdminCustomizationsApiFp(this.configuration).adminCustomizationsIdPatch(id, previousCustomizationId, nextCustomizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminCustomizationsIdDeleteRequest} [adminCustomizationsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationsApi
     */
    public adminCustomizationsIdPut(id: string, adminCustomizationsIdDeleteRequest?: AdminCustomizationsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminCustomizationsApiFp(this.configuration).adminCustomizationsIdPut(id, adminCustomizationsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminCustomizationsGetRequest} [adminCustomizationsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCustomizationsApi
     */
    public adminCustomizationsPost(adminCustomizationsGetRequest?: AdminCustomizationsGetRequest, options?: AxiosRequestConfig) {
        return AdminCustomizationsApiFp(this.configuration).adminCustomizationsPost(adminCustomizationsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDashboardApi - axios parameter creator
 * @export
 */
export const AdminDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ViewSupplierDashboards)
         * @param {boolean} isAssignedToMe 
         * @param {string} [aspNetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDashboardGet: async (isAssignedToMe: boolean, aspNetUserId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isAssignedToMe' is not null or undefined
            assertParamExists('adminDashboardGet', 'isAssignedToMe', isAssignedToMe)
            const localVarPath = `/Admin/Dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aspNetUserId !== undefined) {
                localVarQueryParameter['aspNetUserId'] = aspNetUserId;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['isAssignedToMe'] = isAssignedToMe;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDashboardApi - functional programming interface
 * @export
 */
export const AdminDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ViewSupplierDashboards)
         * @param {boolean} isAssignedToMe 
         * @param {string} [aspNetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDashboardGet(isAssignedToMe: boolean, aspNetUserId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardItemViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDashboardGet(isAssignedToMe, aspNetUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDashboardApi - factory interface
 * @export
 */
export const AdminDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ViewSupplierDashboards)
         * @param {boolean} isAssignedToMe 
         * @param {string} [aspNetUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDashboardGet(isAssignedToMe: boolean, aspNetUserId?: string, options?: any): AxiosPromise<DashboardItemViewModelListApplicationResult> {
            return localVarFp.adminDashboardGet(isAssignedToMe, aspNetUserId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDashboardApi - object-oriented interface
 * @export
 * @class AdminDashboardApi
 * @extends {BaseAPI}
 */
export class AdminDashboardApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ViewSupplierDashboards)
     * @param {boolean} isAssignedToMe 
     * @param {string} [aspNetUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDashboardApi
     */
    public adminDashboardGet(isAssignedToMe: boolean, aspNetUserId?: string, options?: AxiosRequestConfig) {
        return AdminDashboardApiFp(this.configuration).adminDashboardGet(isAssignedToMe, aspNetUserId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDeliveryTypesApi - axios parameter creator
 * @export
 */
export const AdminDeliveryTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeliveryTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/DeliveryTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDeliveryTypesApi - functional programming interface
 * @export
 */
export const AdminDeliveryTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDeliveryTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeliveryTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryTypeListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeliveryTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDeliveryTypesApi - factory interface
 * @export
 */
export const AdminDeliveryTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDeliveryTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeliveryTypesGet(options?: any): AxiosPromise<DeliveryTypeListViewModelListApplicationResult> {
            return localVarFp.adminDeliveryTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDeliveryTypesApi - object-oriented interface
 * @export
 * @class AdminDeliveryTypesApi
 * @extends {BaseAPI}
 */
export class AdminDeliveryTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDeliveryTypesApi
     */
    public adminDeliveryTypesGet(options?: AxiosRequestConfig) {
        return AdminDeliveryTypesApiFp(this.configuration).adminDeliveryTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDesignGroupsApi - axios parameter creator
 * @export
 */
export const AdminDesignGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminDesignGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminDesignGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/DesignGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (isSystemManaged !== undefined) {
                localVarQueryParameter['isSystemManaged'] = isSystemManaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/DesignGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/DesignGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousDesignGroupId] 
         * @param {string} [nextDesignGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdPatch: async (id: string, previousDesignGroupId?: string, nextDesignGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/DesignGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousDesignGroupId !== undefined) {
                localVarQueryParameter['previousDesignGroupId'] = previousDesignGroupId;
            }

            if (nextDesignGroupId !== undefined) {
                localVarQueryParameter['nextDesignGroupId'] = nextDesignGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminDesignGroupsIdDeleteRequest} [adminDesignGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdPut: async (id: string, adminDesignGroupsIdDeleteRequest?: AdminDesignGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/DesignGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDesignGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminDesignGroupsGetRequest} [adminDesignGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsPost: async (adminDesignGroupsGetRequest?: AdminDesignGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/DesignGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDesignGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDesignGroupsApi - functional programming interface
 * @export
 */
export const AdminDesignGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDesignGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousDesignGroupId] 
         * @param {string} [nextDesignGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignGroupsIdPatch(id: string, previousDesignGroupId?: string, nextDesignGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignGroupsIdPatch(id, previousDesignGroupId, nextDesignGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminDesignGroupsIdDeleteRequest} [adminDesignGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignGroupsIdPut(id: string, adminDesignGroupsIdDeleteRequest?: AdminDesignGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignGroupsIdPut(id, adminDesignGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminDesignGroupsGetRequest} [adminDesignGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignGroupsPost(adminDesignGroupsGetRequest?: AdminDesignGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignGroupsPost(adminDesignGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDesignGroupsApi - factory interface
 * @export
 */
export const AdminDesignGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDesignGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: any): AxiosPromise<DesignGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminDesignGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminDesignGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdGet(id: string, options?: any): AxiosPromise<DesignGroupAdminViewModelApplicationResult> {
            return localVarFp.adminDesignGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousDesignGroupId] 
         * @param {string} [nextDesignGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdPatch(id: string, previousDesignGroupId?: string, nextDesignGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminDesignGroupsIdPatch(id, previousDesignGroupId, nextDesignGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminDesignGroupsIdDeleteRequest} [adminDesignGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsIdPut(id: string, adminDesignGroupsIdDeleteRequest?: AdminDesignGroupsIdDeleteRequest, options?: any): AxiosPromise<DesignGroupAdminViewModelApplicationResult> {
            return localVarFp.adminDesignGroupsIdPut(id, adminDesignGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminDesignGroupsGetRequest} [adminDesignGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignGroupsPost(adminDesignGroupsGetRequest?: AdminDesignGroupsGetRequest, options?: any): AxiosPromise<DesignGroupAdminViewModelApplicationResult> {
            return localVarFp.adminDesignGroupsPost(adminDesignGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDesignGroupsApi - object-oriented interface
 * @export
 * @class AdminDesignGroupsApi
 * @extends {BaseAPI}
 */
export class AdminDesignGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [isSystemManaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignGroupsApi
     */
    public adminDesignGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig) {
        return AdminDesignGroupsApiFp(this.configuration).adminDesignGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignGroupsApi
     */
    public adminDesignGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminDesignGroupsApiFp(this.configuration).adminDesignGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignGroupsApi
     */
    public adminDesignGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminDesignGroupsApiFp(this.configuration).adminDesignGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousDesignGroupId] 
     * @param {string} [nextDesignGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignGroupsApi
     */
    public adminDesignGroupsIdPatch(id: string, previousDesignGroupId?: string, nextDesignGroupId?: string, options?: AxiosRequestConfig) {
        return AdminDesignGroupsApiFp(this.configuration).adminDesignGroupsIdPatch(id, previousDesignGroupId, nextDesignGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminDesignGroupsIdDeleteRequest} [adminDesignGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignGroupsApi
     */
    public adminDesignGroupsIdPut(id: string, adminDesignGroupsIdDeleteRequest?: AdminDesignGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminDesignGroupsApiFp(this.configuration).adminDesignGroupsIdPut(id, adminDesignGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminDesignGroupsGetRequest} [adminDesignGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignGroupsApi
     */
    public adminDesignGroupsPost(adminDesignGroupsGetRequest?: AdminDesignGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminDesignGroupsApiFp(this.configuration).adminDesignGroupsPost(adminDesignGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDesignPricesApi - axios parameter creator
 * @export
 */
export const AdminDesignPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminDesignPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminDesignPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminDesignPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/DesignPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/DesignPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignPricesIdGet', 'id', id)
            const localVarPath = `/Admin/DesignPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignPricesIdPut', 'id', id)
            const localVarPath = `/Admin/DesignPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/DesignPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDesignPricesApi - functional programming interface
 * @export
 */
export const AdminDesignPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDesignPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDesignPricesApi - factory interface
 * @export
 */
export const AdminDesignPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDesignPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminDesignPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminDesignPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminDesignPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminDesignPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminDesignPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDesignPricesApi - object-oriented interface
 * @export
 * @class AdminDesignPricesApi
 * @extends {BaseAPI}
 */
export class AdminDesignPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignPricesApi
     */
    public adminDesignPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminDesignPricesApiFp(this.configuration).adminDesignPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignPricesApi
     */
    public adminDesignPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminDesignPricesApiFp(this.configuration).adminDesignPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignPricesApi
     */
    public adminDesignPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminDesignPricesApiFp(this.configuration).adminDesignPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignPricesApi
     */
    public adminDesignPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminDesignPricesApiFp(this.configuration).adminDesignPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignPricesApi
     */
    public adminDesignPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminDesignPricesApiFp(this.configuration).adminDesignPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDesignsApi - axios parameter creator
 * @export
 */
export const AdminDesignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminDesignsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminDesignsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Designs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (hasProductBuilderOptionsGenerationLogs !== undefined) {
                localVarQueryParameter['hasProductBuilderOptionsGenerationLogs'] = hasProductBuilderOptionsGenerationLogs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignsIdDelete', 'id', id)
            const localVarPath = `/Admin/Designs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignsIdGet', 'id', id)
            const localVarPath = `/Admin/Designs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousDesignId] 
         * @param {string} [nextDesignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdPatch: async (id: string, previousDesignId?: string, nextDesignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignsIdPatch', 'id', id)
            const localVarPath = `/Admin/Designs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousDesignId !== undefined) {
                localVarQueryParameter['previousDesignId'] = previousDesignId;
            }

            if (nextDesignId !== undefined) {
                localVarQueryParameter['nextDesignId'] = nextDesignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminDesignsIdDeleteRequest} [adminDesignsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdPut: async (id: string, adminDesignsIdDeleteRequest?: AdminDesignsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminDesignsIdPut', 'id', id)
            const localVarPath = `/Admin/Designs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDesignsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminDesignsGetRequest} [adminDesignsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsPost: async (adminDesignsGetRequest?: AdminDesignsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Designs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDesignsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDesignsApi - functional programming interface
 * @export
 */
export const AdminDesignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDesignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignsGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousDesignId] 
         * @param {string} [nextDesignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignsIdPatch(id: string, previousDesignId?: string, nextDesignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignsIdPatch(id, previousDesignId, nextDesignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminDesignsIdDeleteRequest} [adminDesignsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignsIdPut(id: string, adminDesignsIdDeleteRequest?: AdminDesignsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignsIdPut(id, adminDesignsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminDesignsGetRequest} [adminDesignsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDesignsPost(adminDesignsGetRequest?: AdminDesignsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDesignsPost(adminDesignsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDesignsApi - factory interface
 * @export
 */
export const AdminDesignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDesignsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: any): AxiosPromise<DesignAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminDesignsGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminDesignsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdGet(id: string, options?: any): AxiosPromise<DesignAdminViewModelApplicationResult> {
            return localVarFp.adminDesignsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousDesignId] 
         * @param {string} [nextDesignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdPatch(id: string, previousDesignId?: string, nextDesignId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminDesignsIdPatch(id, previousDesignId, nextDesignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminDesignsIdDeleteRequest} [adminDesignsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsIdPut(id: string, adminDesignsIdDeleteRequest?: AdminDesignsIdDeleteRequest, options?: any): AxiosPromise<DesignAdminViewModelApplicationResult> {
            return localVarFp.adminDesignsIdPut(id, adminDesignsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminDesignsGetRequest} [adminDesignsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDesignsPost(adminDesignsGetRequest?: AdminDesignsGetRequest, options?: any): AxiosPromise<DesignAdminViewModelApplicationResult> {
            return localVarFp.adminDesignsPost(adminDesignsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDesignsApi - object-oriented interface
 * @export
 * @class AdminDesignsApi
 * @extends {BaseAPI}
 */
export class AdminDesignsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignsApi
     */
    public adminDesignsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig) {
        return AdminDesignsApiFp(this.configuration).adminDesignsGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignsApi
     */
    public adminDesignsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminDesignsApiFp(this.configuration).adminDesignsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignsApi
     */
    public adminDesignsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminDesignsApiFp(this.configuration).adminDesignsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousDesignId] 
     * @param {string} [nextDesignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignsApi
     */
    public adminDesignsIdPatch(id: string, previousDesignId?: string, nextDesignId?: string, options?: AxiosRequestConfig) {
        return AdminDesignsApiFp(this.configuration).adminDesignsIdPatch(id, previousDesignId, nextDesignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminDesignsIdDeleteRequest} [adminDesignsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignsApi
     */
    public adminDesignsIdPut(id: string, adminDesignsIdDeleteRequest?: AdminDesignsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminDesignsApiFp(this.configuration).adminDesignsIdPut(id, adminDesignsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminDesignsGetRequest} [adminDesignsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDesignsApi
     */
    public adminDesignsPost(adminDesignsGetRequest?: AdminDesignsGetRequest, options?: AxiosRequestConfig) {
        return AdminDesignsApiFp(this.configuration).adminDesignsPost(adminDesignsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminEnquiriesApi - axios parameter creator
 * @export
 */
export const AdminEnquiriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesCreateEnquiryAdminPost: async (enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Enquiries/CreateEnquiryAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enquiriesCreateEnquiryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} calculateQuoteTotals 
         * @param {Array<EnquiryStatus>} [enquiryStatusIds] 
         * @param {Array<EnquiryType>} [enquiryTypeIds] 
         * @param {string} [searchTerm] 
         * @param {string} [lastModifiedStartDate] 
         * @param {string} [lastModifiedEndDate] 
         * @param {string} [storeId] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {string} [startDateFilter] 
         * @param {string} [endDateFilter] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesGet: async (pageIndex: number, pageSize: number, calculateQuoteTotals: boolean, enquiryStatusIds?: Array<EnquiryStatus>, enquiryTypeIds?: Array<EnquiryType>, searchTerm?: string, lastModifiedStartDate?: string, lastModifiedEndDate?: string, storeId?: string, orderManagerId?: string, salesManagerId?: string, startDateFilter?: string, endDateFilter?: string, campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminEnquiriesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminEnquiriesGet', 'pageSize', pageSize)
            // verify required parameter 'calculateQuoteTotals' is not null or undefined
            assertParamExists('adminEnquiriesGet', 'calculateQuoteTotals', calculateQuoteTotals)
            const localVarPath = `/Admin/Enquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (enquiryStatusIds) {
                localVarQueryParameter['enquiryStatusIds'] = enquiryStatusIds;
            }

            if (enquiryTypeIds) {
                localVarQueryParameter['enquiryTypeIds'] = enquiryTypeIds;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (lastModifiedStartDate !== undefined) {
                localVarQueryParameter['lastModifiedStartDate'] = (lastModifiedStartDate as any instanceof Date) ?
                    (lastModifiedStartDate as any).toISOString() :
                    lastModifiedStartDate;
            }

            if (lastModifiedEndDate !== undefined) {
                localVarQueryParameter['lastModifiedEndDate'] = (lastModifiedEndDate as any instanceof Date) ?
                    (lastModifiedEndDate as any).toISOString() :
                    lastModifiedEndDate;
            }

            if (calculateQuoteTotals !== undefined) {
                localVarQueryParameter['calculateQuoteTotals'] = calculateQuoteTotals;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (orderManagerId !== undefined) {
                localVarQueryParameter['orderManagerId'] = orderManagerId;
            }

            if (salesManagerId !== undefined) {
                localVarQueryParameter['salesManagerId'] = salesManagerId;
            }

            if (startDateFilter !== undefined) {
                localVarQueryParameter['startDateFilter'] = (startDateFilter as any instanceof Date) ?
                    (startDateFilter as any).toISOString() :
                    startDateFilter;
            }

            if (endDateFilter !== undefined) {
                localVarQueryParameter['endDateFilter'] = (endDateFilter as any instanceof Date) ?
                    (endDateFilter as any).toISOString() :
                    endDateFilter;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesGetEnquiryCustomerTypesGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminEnquiriesGetEnquiryCustomerTypesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminEnquiriesGetEnquiryCustomerTypesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Enquiries/GetEnquiryCustomerTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {EnquiryType} enquiryTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesGetEnquiryStatusForTypeGet: async (pageIndex: number, pageSize: number, enquiryTypeId: EnquiryType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminEnquiriesGetEnquiryStatusForTypeGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminEnquiriesGetEnquiryStatusForTypeGet', 'pageSize', pageSize)
            // verify required parameter 'enquiryTypeId' is not null or undefined
            assertParamExists('adminEnquiriesGetEnquiryStatusForTypeGet', 'enquiryTypeId', enquiryTypeId)
            const localVarPath = `/Admin/Enquiries/GetEnquiryStatusForType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (enquiryTypeId !== undefined) {
                localVarQueryParameter['enquiryTypeId'] = enquiryTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminEnquiriesIdDelete', 'id', id)
            const localVarPath = `/Admin/Enquiries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminEnquiriesIdGet', 'id', id)
            const localVarPath = `/Admin/Enquiries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {AdminEnquiriesIdDeleteRequest} [adminEnquiriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesIdPut: async (id: string, adminEnquiriesIdDeleteRequest?: AdminEnquiriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminEnquiriesIdPut', 'id', id)
            const localVarPath = `/Admin/Enquiries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEnquiriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminEnquiriesApi - functional programming interface
 * @export
 */
export const AdminEnquiriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminEnquiriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesCreateEnquiryAdminPost(enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesCreateEnquiryAdminPost(enquiriesCreateEnquiryPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} calculateQuoteTotals 
         * @param {Array<EnquiryStatus>} [enquiryStatusIds] 
         * @param {Array<EnquiryType>} [enquiryTypeIds] 
         * @param {string} [searchTerm] 
         * @param {string} [lastModifiedStartDate] 
         * @param {string} [lastModifiedEndDate] 
         * @param {string} [storeId] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {string} [startDateFilter] 
         * @param {string} [endDateFilter] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesGet(pageIndex: number, pageSize: number, calculateQuoteTotals: boolean, enquiryStatusIds?: Array<EnquiryStatus>, enquiryTypeIds?: Array<EnquiryType>, searchTerm?: string, lastModifiedStartDate?: string, lastModifiedEndDate?: string, storeId?: string, orderManagerId?: string, salesManagerId?: string, startDateFilter?: string, endDateFilter?: string, campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnquiryAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesGet(pageIndex, pageSize, calculateQuoteTotals, enquiryStatusIds, enquiryTypeIds, searchTerm, lastModifiedStartDate, lastModifiedEndDate, storeId, orderManagerId, salesManagerId, startDateFilter, endDateFilter, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesGetEnquiryCustomerTypesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnquiryCustomerTypeListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesGetEnquiryCustomerTypesGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {EnquiryType} enquiryTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesGetEnquiryStatusForTypeGet(pageIndex: number, pageSize: number, enquiryTypeId: EnquiryType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnquiryStatusListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesGetEnquiryStatusForTypeGet(pageIndex, pageSize, enquiryTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnquiryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {AdminEnquiriesIdDeleteRequest} [adminEnquiriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEnquiriesIdPut(id: string, adminEnquiriesIdDeleteRequest?: AdminEnquiriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnquiryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEnquiriesIdPut(id, adminEnquiriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminEnquiriesApi - factory interface
 * @export
 */
export const AdminEnquiriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminEnquiriesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesCreateEnquiryAdminPost(enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options?: any): AxiosPromise<GuidApplicationResult> {
            return localVarFp.adminEnquiriesCreateEnquiryAdminPost(enquiriesCreateEnquiryPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} calculateQuoteTotals 
         * @param {Array<EnquiryStatus>} [enquiryStatusIds] 
         * @param {Array<EnquiryType>} [enquiryTypeIds] 
         * @param {string} [searchTerm] 
         * @param {string} [lastModifiedStartDate] 
         * @param {string} [lastModifiedEndDate] 
         * @param {string} [storeId] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {string} [startDateFilter] 
         * @param {string} [endDateFilter] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesGet(pageIndex: number, pageSize: number, calculateQuoteTotals: boolean, enquiryStatusIds?: Array<EnquiryStatus>, enquiryTypeIds?: Array<EnquiryType>, searchTerm?: string, lastModifiedStartDate?: string, lastModifiedEndDate?: string, storeId?: string, orderManagerId?: string, salesManagerId?: string, startDateFilter?: string, endDateFilter?: string, campaignId?: string, options?: any): AxiosPromise<EnquiryAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminEnquiriesGet(pageIndex, pageSize, calculateQuoteTotals, enquiryStatusIds, enquiryTypeIds, searchTerm, lastModifiedStartDate, lastModifiedEndDate, storeId, orderManagerId, salesManagerId, startDateFilter, endDateFilter, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesGetEnquiryCustomerTypesGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<EnquiryCustomerTypeListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminEnquiriesGetEnquiryCustomerTypesGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {EnquiryType} enquiryTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesGetEnquiryStatusForTypeGet(pageIndex: number, pageSize: number, enquiryTypeId: EnquiryType, options?: any): AxiosPromise<EnquiryStatusListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminEnquiriesGetEnquiryStatusForTypeGet(pageIndex, pageSize, enquiryTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminEnquiriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesIdGet(id: string, options?: any): AxiosPromise<EnquiryAdminViewModelApplicationResult> {
            return localVarFp.adminEnquiriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageEnquiries)
         * @param {string} id 
         * @param {AdminEnquiriesIdDeleteRequest} [adminEnquiriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEnquiriesIdPut(id: string, adminEnquiriesIdDeleteRequest?: AdminEnquiriesIdDeleteRequest, options?: any): AxiosPromise<EnquiryAdminViewModelApplicationResult> {
            return localVarFp.adminEnquiriesIdPut(id, adminEnquiriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminEnquiriesApi - object-oriented interface
 * @export
 * @class AdminEnquiriesApi
 * @extends {BaseAPI}
 */
export class AdminEnquiriesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesCreateEnquiryAdminPost(enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesCreateEnquiryAdminPost(enquiriesCreateEnquiryPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {boolean} calculateQuoteTotals 
     * @param {Array<EnquiryStatus>} [enquiryStatusIds] 
     * @param {Array<EnquiryType>} [enquiryTypeIds] 
     * @param {string} [searchTerm] 
     * @param {string} [lastModifiedStartDate] 
     * @param {string} [lastModifiedEndDate] 
     * @param {string} [storeId] 
     * @param {string} [orderManagerId] 
     * @param {string} [salesManagerId] 
     * @param {string} [startDateFilter] 
     * @param {string} [endDateFilter] 
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesGet(pageIndex: number, pageSize: number, calculateQuoteTotals: boolean, enquiryStatusIds?: Array<EnquiryStatus>, enquiryTypeIds?: Array<EnquiryType>, searchTerm?: string, lastModifiedStartDate?: string, lastModifiedEndDate?: string, storeId?: string, orderManagerId?: string, salesManagerId?: string, startDateFilter?: string, endDateFilter?: string, campaignId?: string, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesGet(pageIndex, pageSize, calculateQuoteTotals, enquiryStatusIds, enquiryTypeIds, searchTerm, lastModifiedStartDate, lastModifiedEndDate, storeId, orderManagerId, salesManagerId, startDateFilter, endDateFilter, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesGetEnquiryCustomerTypesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesGetEnquiryCustomerTypesGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {EnquiryType} enquiryTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesGetEnquiryStatusForTypeGet(pageIndex: number, pageSize: number, enquiryTypeId: EnquiryType, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesGetEnquiryStatusForTypeGet(pageIndex, pageSize, enquiryTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageEnquiries)
     * @param {string} id 
     * @param {AdminEnquiriesIdDeleteRequest} [adminEnquiriesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnquiriesApi
     */
    public adminEnquiriesIdPut(id: string, adminEnquiriesIdDeleteRequest?: AdminEnquiriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminEnquiriesApiFp(this.configuration).adminEnquiriesIdPut(id, adminEnquiriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminExtraCustomizedProductItemTemplateApi - axios parameter creator
 * @export
 */
export const AdminExtraCustomizedProductItemTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} extraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraCustomizedProductItemTemplateExtraIdDelete: async (extraId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extraId' is not null or undefined
            assertParamExists('adminExtraCustomizedProductItemTemplateExtraIdDelete', 'extraId', extraId)
            const localVarPath = `/Admin/ExtraCustomizedProductItemTemplate/{extraId}`
                .replace(`{${"extraId"}}`, encodeURIComponent(String(extraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} extraId 
         * @param {AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest} [adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraCustomizedProductItemTemplateExtraIdPut: async (extraId: string, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest?: AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extraId' is not null or undefined
            assertParamExists('adminExtraCustomizedProductItemTemplateExtraIdPut', 'extraId', extraId)
            const localVarPath = `/Admin/ExtraCustomizedProductItemTemplate/{extraId}`
                .replace(`{${"extraId"}}`, encodeURIComponent(String(extraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminExtraCustomizedProductItemTemplateApi - functional programming interface
 * @export
 */
export const AdminExtraCustomizedProductItemTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminExtraCustomizedProductItemTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} extraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraCustomizedProductItemTemplateExtraIdDelete(extraId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraCustomizedProductItemTemplateExtraIdDelete(extraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} extraId 
         * @param {AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest} [adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraCustomizedProductItemTemplateExtraIdPut(extraId: string, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest?: AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraCustomizedProductItemTemplateExtraIdPut(extraId, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminExtraCustomizedProductItemTemplateApi - factory interface
 * @export
 */
export const AdminExtraCustomizedProductItemTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminExtraCustomizedProductItemTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} extraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraCustomizedProductItemTemplateExtraIdDelete(extraId: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtraCustomizedProductItemTemplateExtraIdDelete(extraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} extraId 
         * @param {AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest} [adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraCustomizedProductItemTemplateExtraIdPut(extraId: string, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest?: AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtraCustomizedProductItemTemplateExtraIdPut(extraId, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminExtraCustomizedProductItemTemplateApi - object-oriented interface
 * @export
 * @class AdminExtraCustomizedProductItemTemplateApi
 * @extends {BaseAPI}
 */
export class AdminExtraCustomizedProductItemTemplateApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} extraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraCustomizedProductItemTemplateApi
     */
    public adminExtraCustomizedProductItemTemplateExtraIdDelete(extraId: string, options?: AxiosRequestConfig) {
        return AdminExtraCustomizedProductItemTemplateApiFp(this.configuration).adminExtraCustomizedProductItemTemplateExtraIdDelete(extraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} extraId 
     * @param {AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest} [adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraCustomizedProductItemTemplateApi
     */
    public adminExtraCustomizedProductItemTemplateExtraIdPut(extraId: string, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest?: AdminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminExtraCustomizedProductItemTemplateApiFp(this.configuration).adminExtraCustomizedProductItemTemplateExtraIdPut(extraId, adminExtraCustomizedProductItemTemplateExtraIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminExtraGroupsApi - axios parameter creator
 * @export
 */
export const AdminExtraGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminExtraGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminExtraGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ExtraGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (isSystemManaged !== undefined) {
                localVarQueryParameter['isSystemManaged'] = isSystemManaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/ExtraGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/ExtraGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousExtraGroupId] 
         * @param {string} [nextExtraGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdPatch: async (id: string, previousExtraGroupId?: string, nextExtraGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/ExtraGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousExtraGroupId !== undefined) {
                localVarQueryParameter['previousExtraGroupId'] = previousExtraGroupId;
            }

            if (nextExtraGroupId !== undefined) {
                localVarQueryParameter['nextExtraGroupId'] = nextExtraGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminExtraGroupsIdDeleteRequest} [adminExtraGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdPut: async (id: string, adminExtraGroupsIdDeleteRequest?: AdminExtraGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/ExtraGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExtraGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminExtraGroupsGetRequest} [adminExtraGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsPost: async (adminExtraGroupsGetRequest?: AdminExtraGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ExtraGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExtraGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminExtraGroupsApi - functional programming interface
 * @export
 */
export const AdminExtraGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminExtraGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousExtraGroupId] 
         * @param {string} [nextExtraGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraGroupsIdPatch(id: string, previousExtraGroupId?: string, nextExtraGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraGroupsIdPatch(id, previousExtraGroupId, nextExtraGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminExtraGroupsIdDeleteRequest} [adminExtraGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraGroupsIdPut(id: string, adminExtraGroupsIdDeleteRequest?: AdminExtraGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraGroupsIdPut(id, adminExtraGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminExtraGroupsGetRequest} [adminExtraGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraGroupsPost(adminExtraGroupsGetRequest?: AdminExtraGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraGroupsPost(adminExtraGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminExtraGroupsApi - factory interface
 * @export
 */
export const AdminExtraGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminExtraGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: any): AxiosPromise<ExtraGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminExtraGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtraGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdGet(id: string, options?: any): AxiosPromise<ExtraGroupAdminViewModelApplicationResult> {
            return localVarFp.adminExtraGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousExtraGroupId] 
         * @param {string} [nextExtraGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdPatch(id: string, previousExtraGroupId?: string, nextExtraGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtraGroupsIdPatch(id, previousExtraGroupId, nextExtraGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminExtraGroupsIdDeleteRequest} [adminExtraGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsIdPut(id: string, adminExtraGroupsIdDeleteRequest?: AdminExtraGroupsIdDeleteRequest, options?: any): AxiosPromise<ExtraGroupAdminViewModelApplicationResult> {
            return localVarFp.adminExtraGroupsIdPut(id, adminExtraGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminExtraGroupsGetRequest} [adminExtraGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraGroupsPost(adminExtraGroupsGetRequest?: AdminExtraGroupsGetRequest, options?: any): AxiosPromise<ExtraGroupAdminViewModelApplicationResult> {
            return localVarFp.adminExtraGroupsPost(adminExtraGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminExtraGroupsApi - object-oriented interface
 * @export
 * @class AdminExtraGroupsApi
 * @extends {BaseAPI}
 */
export class AdminExtraGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [isSystemManaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraGroupsApi
     */
    public adminExtraGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig) {
        return AdminExtraGroupsApiFp(this.configuration).adminExtraGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraGroupsApi
     */
    public adminExtraGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminExtraGroupsApiFp(this.configuration).adminExtraGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraGroupsApi
     */
    public adminExtraGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminExtraGroupsApiFp(this.configuration).adminExtraGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousExtraGroupId] 
     * @param {string} [nextExtraGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraGroupsApi
     */
    public adminExtraGroupsIdPatch(id: string, previousExtraGroupId?: string, nextExtraGroupId?: string, options?: AxiosRequestConfig) {
        return AdminExtraGroupsApiFp(this.configuration).adminExtraGroupsIdPatch(id, previousExtraGroupId, nextExtraGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminExtraGroupsIdDeleteRequest} [adminExtraGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraGroupsApi
     */
    public adminExtraGroupsIdPut(id: string, adminExtraGroupsIdDeleteRequest?: AdminExtraGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminExtraGroupsApiFp(this.configuration).adminExtraGroupsIdPut(id, adminExtraGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminExtraGroupsGetRequest} [adminExtraGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraGroupsApi
     */
    public adminExtraGroupsPost(adminExtraGroupsGetRequest?: AdminExtraGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminExtraGroupsApiFp(this.configuration).adminExtraGroupsPost(adminExtraGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminExtraPricesApi - axios parameter creator
 * @export
 */
export const AdminExtraPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminExtraPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminExtraPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminExtraPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/ExtraPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/ExtraPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraPricesIdGet', 'id', id)
            const localVarPath = `/Admin/ExtraPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtraPricesIdPut', 'id', id)
            const localVarPath = `/Admin/ExtraPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ExtraPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminExtraPricesApi - functional programming interface
 * @export
 */
export const AdminExtraPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminExtraPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtraPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtraPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminExtraPricesApi - factory interface
 * @export
 */
export const AdminExtraPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminExtraPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminExtraPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtraPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminExtraPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminExtraPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtraPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminExtraPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminExtraPricesApi - object-oriented interface
 * @export
 * @class AdminExtraPricesApi
 * @extends {BaseAPI}
 */
export class AdminExtraPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraPricesApi
     */
    public adminExtraPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminExtraPricesApiFp(this.configuration).adminExtraPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraPricesApi
     */
    public adminExtraPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminExtraPricesApiFp(this.configuration).adminExtraPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraPricesApi
     */
    public adminExtraPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminExtraPricesApiFp(this.configuration).adminExtraPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraPricesApi
     */
    public adminExtraPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminExtraPricesApiFp(this.configuration).adminExtraPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtraPricesApi
     */
    public adminExtraPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminExtraPricesApiFp(this.configuration).adminExtraPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminExtrasApi - axios parameter creator
 * @export
 */
export const AdminExtrasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminExtrasGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminExtrasGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Extras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtrasIdDelete', 'id', id)
            const localVarPath = `/Admin/Extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtrasIdGet', 'id', id)
            const localVarPath = `/Admin/Extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousExtraId] 
         * @param {string} [nextExtraId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdPatch: async (id: string, previousExtraId?: string, nextExtraId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtrasIdPatch', 'id', id)
            const localVarPath = `/Admin/Extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousExtraId !== undefined) {
                localVarQueryParameter['previousExtraId'] = previousExtraId;
            }

            if (nextExtraId !== undefined) {
                localVarQueryParameter['nextExtraId'] = nextExtraId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminExtrasIdDeleteRequest} [adminExtrasIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdPut: async (id: string, adminExtrasIdDeleteRequest?: AdminExtrasIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminExtrasIdPut', 'id', id)
            const localVarPath = `/Admin/Extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExtrasIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminExtrasGetRequest} [adminExtrasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasPost: async (adminExtrasGetRequest?: AdminExtrasGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Extras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminExtrasGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminExtrasApi - functional programming interface
 * @export
 */
export const AdminExtrasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminExtrasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtrasGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtrasGet(pageIndex, pageSize, searchTerm, isDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtrasIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtrasIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtrasIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtrasIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousExtraId] 
         * @param {string} [nextExtraId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtrasIdPatch(id: string, previousExtraId?: string, nextExtraId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtrasIdPatch(id, previousExtraId, nextExtraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminExtrasIdDeleteRequest} [adminExtrasIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtrasIdPut(id: string, adminExtrasIdDeleteRequest?: AdminExtrasIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtrasIdPut(id, adminExtrasIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminExtrasGetRequest} [adminExtrasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExtrasPost(adminExtrasGetRequest?: AdminExtrasGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExtrasPost(adminExtrasGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminExtrasApi - factory interface
 * @export
 */
export const AdminExtrasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminExtrasApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: any): AxiosPromise<ExtraAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminExtrasGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtrasIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdGet(id: string, options?: any): AxiosPromise<ExtraAdminViewModelApplicationResult> {
            return localVarFp.adminExtrasIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousExtraId] 
         * @param {string} [nextExtraId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdPatch(id: string, previousExtraId?: string, nextExtraId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminExtrasIdPatch(id, previousExtraId, nextExtraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminExtrasIdDeleteRequest} [adminExtrasIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasIdPut(id: string, adminExtrasIdDeleteRequest?: AdminExtrasIdDeleteRequest, options?: any): AxiosPromise<ExtraAdminViewModelApplicationResult> {
            return localVarFp.adminExtrasIdPut(id, adminExtrasIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminExtrasGetRequest} [adminExtrasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExtrasPost(adminExtrasGetRequest?: AdminExtrasGetRequest, options?: any): AxiosPromise<ExtraAdminViewModelApplicationResult> {
            return localVarFp.adminExtrasPost(adminExtrasGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminExtrasApi - object-oriented interface
 * @export
 * @class AdminExtrasApi
 * @extends {BaseAPI}
 */
export class AdminExtrasApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtrasApi
     */
    public adminExtrasGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig) {
        return AdminExtrasApiFp(this.configuration).adminExtrasGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtrasApi
     */
    public adminExtrasIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminExtrasApiFp(this.configuration).adminExtrasIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtrasApi
     */
    public adminExtrasIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminExtrasApiFp(this.configuration).adminExtrasIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousExtraId] 
     * @param {string} [nextExtraId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtrasApi
     */
    public adminExtrasIdPatch(id: string, previousExtraId?: string, nextExtraId?: string, options?: AxiosRequestConfig) {
        return AdminExtrasApiFp(this.configuration).adminExtrasIdPatch(id, previousExtraId, nextExtraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminExtrasIdDeleteRequest} [adminExtrasIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtrasApi
     */
    public adminExtrasIdPut(id: string, adminExtrasIdDeleteRequest?: AdminExtrasIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminExtrasApiFp(this.configuration).adminExtrasIdPut(id, adminExtrasIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminExtrasGetRequest} [adminExtrasGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminExtrasApi
     */
    public adminExtrasPost(adminExtrasGetRequest?: AdminExtrasGetRequest, options?: AxiosRequestConfig) {
        return AdminExtrasApiFp(this.configuration).adminExtrasPost(adminExtrasGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFabricChannelsApi - axios parameter creator
 * @export
 */
export const AdminFabricChannelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, hasProductBuilderOptionsGenerationLogs?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFabricChannelsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFabricChannelsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/FabricChannels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (hasProductBuilderOptionsGenerationLogs !== undefined) {
                localVarQueryParameter['hasProductBuilderOptionsGenerationLogs'] = hasProductBuilderOptionsGenerationLogs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricChannelsIdDelete', 'id', id)
            const localVarPath = `/Admin/FabricChannels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricChannelsIdGet', 'id', id)
            const localVarPath = `/Admin/FabricChannels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFabricChannelId] 
         * @param {string} [nextFabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdPatch: async (id: string, previousFabricChannelId?: string, nextFabricChannelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricChannelsIdPatch', 'id', id)
            const localVarPath = `/Admin/FabricChannels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousFabricChannelId !== undefined) {
                localVarQueryParameter['previousFabricChannelId'] = previousFabricChannelId;
            }

            if (nextFabricChannelId !== undefined) {
                localVarQueryParameter['nextFabricChannelId'] = nextFabricChannelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricChannelsIdDeleteRequest} [adminFabricChannelsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdPut: async (id: string, adminFabricChannelsIdDeleteRequest?: AdminFabricChannelsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricChannelsIdPut', 'id', id)
            const localVarPath = `/Admin/FabricChannels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFabricChannelsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricChannelsGetRequest} [adminFabricChannelsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsPost: async (adminFabricChannelsGetRequest?: AdminFabricChannelsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/FabricChannels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFabricChannelsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFabricChannelsApi - functional programming interface
 * @export
 */
export const AdminFabricChannelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFabricChannelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricChannelsGet(pageIndex: number, pageSize: number, searchTerm?: string, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricChannelAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricChannelsGet(pageIndex, pageSize, searchTerm, hasProductBuilderOptionsGenerationLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricChannelsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricChannelsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricChannelsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricChannelAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricChannelsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFabricChannelId] 
         * @param {string} [nextFabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricChannelsIdPatch(id: string, previousFabricChannelId?: string, nextFabricChannelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricChannelsIdPatch(id, previousFabricChannelId, nextFabricChannelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricChannelsIdDeleteRequest} [adminFabricChannelsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricChannelsIdPut(id: string, adminFabricChannelsIdDeleteRequest?: AdminFabricChannelsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricChannelAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricChannelsIdPut(id, adminFabricChannelsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricChannelsGetRequest} [adminFabricChannelsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricChannelsPost(adminFabricChannelsGetRequest?: AdminFabricChannelsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricChannelAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricChannelsPost(adminFabricChannelsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFabricChannelsApi - factory interface
 * @export
 */
export const AdminFabricChannelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFabricChannelsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsGet(pageIndex: number, pageSize: number, searchTerm?: string, hasProductBuilderOptionsGenerationLogs?: boolean, options?: any): AxiosPromise<FabricChannelAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminFabricChannelsGet(pageIndex, pageSize, searchTerm, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFabricChannelsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdGet(id: string, options?: any): AxiosPromise<FabricChannelAdminViewModelApplicationResult> {
            return localVarFp.adminFabricChannelsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFabricChannelId] 
         * @param {string} [nextFabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdPatch(id: string, previousFabricChannelId?: string, nextFabricChannelId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFabricChannelsIdPatch(id, previousFabricChannelId, nextFabricChannelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricChannelsIdDeleteRequest} [adminFabricChannelsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsIdPut(id: string, adminFabricChannelsIdDeleteRequest?: AdminFabricChannelsIdDeleteRequest, options?: any): AxiosPromise<FabricChannelAdminViewModelApplicationResult> {
            return localVarFp.adminFabricChannelsIdPut(id, adminFabricChannelsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricChannelsGetRequest} [adminFabricChannelsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricChannelsPost(adminFabricChannelsGetRequest?: AdminFabricChannelsGetRequest, options?: any): AxiosPromise<FabricChannelAdminViewModelApplicationResult> {
            return localVarFp.adminFabricChannelsPost(adminFabricChannelsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFabricChannelsApi - object-oriented interface
 * @export
 * @class AdminFabricChannelsApi
 * @extends {BaseAPI}
 */
export class AdminFabricChannelsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricChannelsApi
     */
    public adminFabricChannelsGet(pageIndex: number, pageSize: number, searchTerm?: string, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig) {
        return AdminFabricChannelsApiFp(this.configuration).adminFabricChannelsGet(pageIndex, pageSize, searchTerm, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricChannelsApi
     */
    public adminFabricChannelsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminFabricChannelsApiFp(this.configuration).adminFabricChannelsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricChannelsApi
     */
    public adminFabricChannelsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminFabricChannelsApiFp(this.configuration).adminFabricChannelsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousFabricChannelId] 
     * @param {string} [nextFabricChannelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricChannelsApi
     */
    public adminFabricChannelsIdPatch(id: string, previousFabricChannelId?: string, nextFabricChannelId?: string, options?: AxiosRequestConfig) {
        return AdminFabricChannelsApiFp(this.configuration).adminFabricChannelsIdPatch(id, previousFabricChannelId, nextFabricChannelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminFabricChannelsIdDeleteRequest} [adminFabricChannelsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricChannelsApi
     */
    public adminFabricChannelsIdPut(id: string, adminFabricChannelsIdDeleteRequest?: AdminFabricChannelsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminFabricChannelsApiFp(this.configuration).adminFabricChannelsIdPut(id, adminFabricChannelsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminFabricChannelsGetRequest} [adminFabricChannelsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricChannelsApi
     */
    public adminFabricChannelsPost(adminFabricChannelsGetRequest?: AdminFabricChannelsGetRequest, options?: AxiosRequestConfig) {
        return AdminFabricChannelsApiFp(this.configuration).adminFabricChannelsPost(adminFabricChannelsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFabricColoursApi - axios parameter creator
 * @export
 */
export const AdminFabricColoursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [fabricId] 
         * @param {string} [colourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursGet: async (pageIndex: number, pageSize: number, fabricId?: string, colourId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFabricColoursGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFabricColoursGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/FabricColours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fabricId !== undefined) {
                localVarQueryParameter['fabricId'] = fabricId;
            }

            if (colourId !== undefined) {
                localVarQueryParameter['colourId'] = colourId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {string} [fabricColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursGetColoursForFabricGet: async (fabricId: string, fabricColourId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fabricId' is not null or undefined
            assertParamExists('adminFabricColoursGetColoursForFabricGet', 'fabricId', fabricId)
            const localVarPath = `/Admin/FabricColours/GetColoursForFabric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fabricId !== undefined) {
                localVarQueryParameter['fabricId'] = fabricId;
            }

            if (fabricColourId !== undefined) {
                localVarQueryParameter['fabricColourId'] = fabricColourId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} colourId 
         * @param {string} [fabricColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursGetFabricsForColourGet: async (colourId: string, fabricColourId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'colourId' is not null or undefined
            assertParamExists('adminFabricColoursGetFabricsForColourGet', 'colourId', colourId)
            const localVarPath = `/Admin/FabricColours/GetFabricsForColour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (colourId !== undefined) {
                localVarQueryParameter['colourId'] = colourId;
            }

            if (fabricColourId !== undefined) {
                localVarQueryParameter['fabricColourId'] = fabricColourId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricColoursIdDelete', 'id', id)
            const localVarPath = `/Admin/FabricColours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricColoursIdGet', 'id', id)
            const localVarPath = `/Admin/FabricColours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricColoursIdDeleteRequest} [adminFabricColoursIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursIdPut: async (id: string, adminFabricColoursIdDeleteRequest?: AdminFabricColoursIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricColoursIdPut', 'id', id)
            const localVarPath = `/Admin/FabricColours/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFabricColoursIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricColoursGetRequest} [adminFabricColoursGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursPost: async (adminFabricColoursGetRequest?: AdminFabricColoursGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/FabricColours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFabricColoursGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFabricColoursApi - functional programming interface
 * @export
 */
export const AdminFabricColoursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFabricColoursApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [fabricId] 
         * @param {string} [colourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursGet(pageIndex: number, pageSize: number, fabricId?: string, colourId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricColourAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursGet(pageIndex, pageSize, fabricId, colourId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {string} [fabricColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursGetColoursForFabricGet(fabricId: string, fabricColourId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursGetColoursForFabricGet(fabricId, fabricColourId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} colourId 
         * @param {string} [fabricColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursGetFabricsForColourGet(colourId: string, fabricColourId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursGetFabricsForColourGet(colourId, fabricColourId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricColourAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricColoursIdDeleteRequest} [adminFabricColoursIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursIdPut(id: string, adminFabricColoursIdDeleteRequest?: AdminFabricColoursIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricColourAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursIdPut(id, adminFabricColoursIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricColoursGetRequest} [adminFabricColoursGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricColoursPost(adminFabricColoursGetRequest?: AdminFabricColoursGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricColourAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricColoursPost(adminFabricColoursGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFabricColoursApi - factory interface
 * @export
 */
export const AdminFabricColoursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFabricColoursApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [fabricId] 
         * @param {string} [colourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursGet(pageIndex: number, pageSize: number, fabricId?: string, colourId?: string, options?: any): AxiosPromise<FabricColourAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminFabricColoursGet(pageIndex, pageSize, fabricId, colourId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {string} [fabricColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursGetColoursForFabricGet(fabricId: string, fabricColourId?: string, options?: any): AxiosPromise<ColourAdminListViewModelListApplicationResult> {
            return localVarFp.adminFabricColoursGetColoursForFabricGet(fabricId, fabricColourId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} colourId 
         * @param {string} [fabricColourId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursGetFabricsForColourGet(colourId: string, fabricColourId?: string, options?: any): AxiosPromise<FabricAdminListViewModelListApplicationResult> {
            return localVarFp.adminFabricColoursGetFabricsForColourGet(colourId, fabricColourId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFabricColoursIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursIdGet(id: string, options?: any): AxiosPromise<FabricColourAdminViewModelApplicationResult> {
            return localVarFp.adminFabricColoursIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricColoursIdDeleteRequest} [adminFabricColoursIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursIdPut(id: string, adminFabricColoursIdDeleteRequest?: AdminFabricColoursIdDeleteRequest, options?: any): AxiosPromise<FabricColourAdminViewModelApplicationResult> {
            return localVarFp.adminFabricColoursIdPut(id, adminFabricColoursIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricColoursGetRequest} [adminFabricColoursGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricColoursPost(adminFabricColoursGetRequest?: AdminFabricColoursGetRequest, options?: any): AxiosPromise<FabricColourAdminViewModelApplicationResult> {
            return localVarFp.adminFabricColoursPost(adminFabricColoursGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFabricColoursApi - object-oriented interface
 * @export
 * @class AdminFabricColoursApi
 * @extends {BaseAPI}
 */
export class AdminFabricColoursApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [fabricId] 
     * @param {string} [colourId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursGet(pageIndex: number, pageSize: number, fabricId?: string, colourId?: string, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursGet(pageIndex, pageSize, fabricId, colourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} fabricId 
     * @param {string} [fabricColourId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursGetColoursForFabricGet(fabricId: string, fabricColourId?: string, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursGetColoursForFabricGet(fabricId, fabricColourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} colourId 
     * @param {string} [fabricColourId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursGetFabricsForColourGet(colourId: string, fabricColourId?: string, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursGetFabricsForColourGet(colourId, fabricColourId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminFabricColoursIdDeleteRequest} [adminFabricColoursIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursIdPut(id: string, adminFabricColoursIdDeleteRequest?: AdminFabricColoursIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursIdPut(id, adminFabricColoursIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminFabricColoursGetRequest} [adminFabricColoursGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricColoursApi
     */
    public adminFabricColoursPost(adminFabricColoursGetRequest?: AdminFabricColoursGetRequest, options?: AxiosRequestConfig) {
        return AdminFabricColoursApiFp(this.configuration).adminFabricColoursPost(adminFabricColoursGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFabricsApi - axios parameter creator
 * @export
 */
export const AdminFabricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFabricsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFabricsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Fabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricsIdDelete', 'id', id)
            const localVarPath = `/Admin/Fabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricsIdGet', 'id', id)
            const localVarPath = `/Admin/Fabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFabricId] 
         * @param {string} [nextFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdPatch: async (id: string, previousFabricId?: string, nextFabricId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricsIdPatch', 'id', id)
            const localVarPath = `/Admin/Fabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousFabricId !== undefined) {
                localVarQueryParameter['previousFabricId'] = previousFabricId;
            }

            if (nextFabricId !== undefined) {
                localVarQueryParameter['nextFabricId'] = nextFabricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricsIdDeleteRequest} [adminFabricsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdPut: async (id: string, adminFabricsIdDeleteRequest?: AdminFabricsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFabricsIdPut', 'id', id)
            const localVarPath = `/Admin/Fabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFabricsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricsGetRequest} [adminFabricsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsPost: async (adminFabricsGetRequest?: AdminFabricsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Fabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFabricsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFabricsApi - functional programming interface
 * @export
 */
export const AdminFabricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFabricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricsGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFabricId] 
         * @param {string} [nextFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricsIdPatch(id: string, previousFabricId?: string, nextFabricId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricsIdPatch(id, previousFabricId, nextFabricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricsIdDeleteRequest} [adminFabricsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricsIdPut(id: string, adminFabricsIdDeleteRequest?: AdminFabricsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricsIdPut(id, adminFabricsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricsGetRequest} [adminFabricsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFabricsPost(adminFabricsGetRequest?: AdminFabricsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFabricsPost(adminFabricsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFabricsApi - factory interface
 * @export
 */
export const AdminFabricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFabricsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<FabricAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminFabricsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFabricsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdGet(id: string, options?: any): AxiosPromise<FabricAdminViewModelApplicationResult> {
            return localVarFp.adminFabricsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFabricId] 
         * @param {string} [nextFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdPatch(id: string, previousFabricId?: string, nextFabricId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFabricsIdPatch(id, previousFabricId, nextFabricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFabricsIdDeleteRequest} [adminFabricsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsIdPut(id: string, adminFabricsIdDeleteRequest?: AdminFabricsIdDeleteRequest, options?: any): AxiosPromise<FabricAdminViewModelApplicationResult> {
            return localVarFp.adminFabricsIdPut(id, adminFabricsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFabricsGetRequest} [adminFabricsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFabricsPost(adminFabricsGetRequest?: AdminFabricsGetRequest, options?: any): AxiosPromise<FabricAdminViewModelApplicationResult> {
            return localVarFp.adminFabricsPost(adminFabricsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFabricsApi - object-oriented interface
 * @export
 * @class AdminFabricsApi
 * @extends {BaseAPI}
 */
export class AdminFabricsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricsApi
     */
    public adminFabricsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminFabricsApiFp(this.configuration).adminFabricsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricsApi
     */
    public adminFabricsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminFabricsApiFp(this.configuration).adminFabricsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricsApi
     */
    public adminFabricsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminFabricsApiFp(this.configuration).adminFabricsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousFabricId] 
     * @param {string} [nextFabricId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricsApi
     */
    public adminFabricsIdPatch(id: string, previousFabricId?: string, nextFabricId?: string, options?: AxiosRequestConfig) {
        return AdminFabricsApiFp(this.configuration).adminFabricsIdPatch(id, previousFabricId, nextFabricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminFabricsIdDeleteRequest} [adminFabricsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricsApi
     */
    public adminFabricsIdPut(id: string, adminFabricsIdDeleteRequest?: AdminFabricsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminFabricsApiFp(this.configuration).adminFabricsIdPut(id, adminFabricsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminFabricsGetRequest} [adminFabricsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFabricsApi
     */
    public adminFabricsPost(adminFabricsGetRequest?: AdminFabricsGetRequest, options?: AxiosRequestConfig) {
        return AdminFabricsApiFp(this.configuration).adminFabricsPost(adminFabricsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFactoriesApi - axios parameter creator
 * @export
 */
export const AdminFactoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFactoriesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFactoriesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Factories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ViewOrderPackFactoryNames)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGetFactoriesForOrderPackGet: async (pageIndex: number, pageSize: number, orderPackId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFactoriesGetFactoriesForOrderPackGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFactoriesGetFactoriesForOrderPackGet', 'pageSize', pageSize)
            // verify required parameter 'orderPackId' is not null or undefined
            assertParamExists('adminFactoriesGetFactoriesForOrderPackGet', 'orderPackId', orderPackId)
            const localVarPath = `/Admin/Factories/GetFactoriesForOrderPack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderPackId !== undefined) {
                localVarQueryParameter['orderPackId'] = orderPackId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ViewAllFactoryNames)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGetFactoryNamesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFactoriesGetFactoryNamesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFactoriesGetFactoryNamesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Factories/GetFactoryNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {Array<string>} [factoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGetFactoryOrdersReportGet: async (factoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Factories/GetFactoryOrdersReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (factoryIds) {
                localVarQueryParameter['factoryIds'] = factoryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFactoriesIdDelete', 'id', id)
            const localVarPath = `/Admin/Factories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFactoriesIdGet', 'id', id)
            const localVarPath = `/Admin/Factories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {AdminFactoriesIdDeleteRequest} [adminFactoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesIdPut: async (id: string, adminFactoriesIdDeleteRequest?: AdminFactoriesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFactoriesIdPut', 'id', id)
            const localVarPath = `/Admin/Factories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFactoriesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {AdminFactoriesGetRequest} [adminFactoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesPost: async (adminFactoriesGetRequest?: AdminFactoriesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Factories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFactoriesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFactoriesApi - functional programming interface
 * @export
 */
export const AdminFactoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFactoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoryAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ViewOrderPackFactoryNames)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesGetFactoriesForOrderPackGet(pageIndex: number, pageSize: number, orderPackId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactorySelectModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesGetFactoriesForOrderPackGet(pageIndex, pageSize, orderPackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ViewAllFactoryNames)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesGetFactoryNamesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactorySelectModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesGetFactoryNamesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {Array<string>} [factoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesGetFactoryOrdersReportGet(factoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesGetFactoryOrdersReportGet(factoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {AdminFactoriesIdDeleteRequest} [adminFactoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesIdPut(id: string, adminFactoriesIdDeleteRequest?: AdminFactoriesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesIdPut(id, adminFactoriesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {AdminFactoriesGetRequest} [adminFactoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFactoriesPost(adminFactoriesGetRequest?: AdminFactoriesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoryAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFactoriesPost(adminFactoriesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFactoriesApi - factory interface
 * @export
 */
export const AdminFactoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFactoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<FactoryAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminFactoriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ViewOrderPackFactoryNames)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGetFactoriesForOrderPackGet(pageIndex: number, pageSize: number, orderPackId: string, options?: any): AxiosPromise<FactorySelectModelPaginatedListApplicationResult> {
            return localVarFp.adminFactoriesGetFactoriesForOrderPackGet(pageIndex, pageSize, orderPackId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ViewAllFactoryNames)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGetFactoryNamesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<FactorySelectModelPaginatedListApplicationResult> {
            return localVarFp.adminFactoriesGetFactoryNamesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {Array<string>} [factoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesGetFactoryOrdersReportGet(factoryIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.adminFactoriesGetFactoryOrdersReportGet(factoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFactoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesIdGet(id: string, options?: any): AxiosPromise<FactoryAdminViewModelApplicationResult> {
            return localVarFp.adminFactoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {string} id 
         * @param {AdminFactoriesIdDeleteRequest} [adminFactoriesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesIdPut(id: string, adminFactoriesIdDeleteRequest?: AdminFactoriesIdDeleteRequest, options?: any): AxiosPromise<FactoryAdminViewModelApplicationResult> {
            return localVarFp.adminFactoriesIdPut(id, adminFactoriesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageFactories)
         * @param {AdminFactoriesGetRequest} [adminFactoriesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFactoriesPost(adminFactoriesGetRequest?: AdminFactoriesGetRequest, options?: any): AxiosPromise<FactoryAdminViewModelApplicationResult> {
            return localVarFp.adminFactoriesPost(adminFactoriesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFactoriesApi - object-oriented interface
 * @export
 * @class AdminFactoriesApi
 * @extends {BaseAPI}
 */
export class AdminFactoriesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageFactories)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ViewOrderPackFactoryNames)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderPackId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesGetFactoriesForOrderPackGet(pageIndex: number, pageSize: number, orderPackId: string, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesGetFactoriesForOrderPackGet(pageIndex, pageSize, orderPackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ViewAllFactoryNames)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesGetFactoryNamesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesGetFactoryNamesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageFactories)
     * @param {Array<string>} [factoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesGetFactoryOrdersReportGet(factoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesGetFactoryOrdersReportGet(factoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageFactories)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageFactories)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageFactories)
     * @param {string} id 
     * @param {AdminFactoriesIdDeleteRequest} [adminFactoriesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesIdPut(id: string, adminFactoriesIdDeleteRequest?: AdminFactoriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesIdPut(id, adminFactoriesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageFactories)
     * @param {AdminFactoriesGetRequest} [adminFactoriesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFactoriesApi
     */
    public adminFactoriesPost(adminFactoriesGetRequest?: AdminFactoriesGetRequest, options?: AxiosRequestConfig) {
        return AdminFactoriesApiFp(this.configuration).adminFactoriesPost(adminFactoriesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFontsApi - axios parameter creator
 * @export
 */
export const AdminFontsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [fontName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsGet: async (pageIndex: number, pageSize: number, fontName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminFontsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminFontsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Fonts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fontName !== undefined) {
                localVarQueryParameter['fontName'] = fontName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFontsIdDelete', 'id', id)
            const localVarPath = `/Admin/Fonts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFontsIdGet', 'id', id)
            const localVarPath = `/Admin/Fonts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFontId] 
         * @param {string} [nextFontId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdPatch: async (id: string, previousFontId?: string, nextFontId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFontsIdPatch', 'id', id)
            const localVarPath = `/Admin/Fonts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousFontId !== undefined) {
                localVarQueryParameter['previousFontId'] = previousFontId;
            }

            if (nextFontId !== undefined) {
                localVarQueryParameter['nextFontId'] = nextFontId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFontsIdDeleteRequest} [adminFontsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdPut: async (id: string, adminFontsIdDeleteRequest?: AdminFontsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminFontsIdPut', 'id', id)
            const localVarPath = `/Admin/Fonts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFontsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFontsGetRequest} [adminFontsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsPost: async (adminFontsGetRequest?: AdminFontsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Fonts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminFontsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFontsApi - functional programming interface
 * @export
 */
export const AdminFontsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFontsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [fontName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFontsGet(pageIndex: number, pageSize: number, fontName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFontsGet(pageIndex, pageSize, fontName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFontsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFontsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFontsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFontsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFontId] 
         * @param {string} [nextFontId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFontsIdPatch(id: string, previousFontId?: string, nextFontId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFontsIdPatch(id, previousFontId, nextFontId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFontsIdDeleteRequest} [adminFontsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFontsIdPut(id: string, adminFontsIdDeleteRequest?: AdminFontsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFontsIdPut(id, adminFontsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFontsGetRequest} [adminFontsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFontsPost(adminFontsGetRequest?: AdminFontsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFontsPost(adminFontsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFontsApi - factory interface
 * @export
 */
export const AdminFontsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFontsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [fontName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsGet(pageIndex: number, pageSize: number, fontName?: string, options?: any): AxiosPromise<FontAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminFontsGet(pageIndex, pageSize, fontName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFontsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdGet(id: string, options?: any): AxiosPromise<FontAdminViewModelApplicationResult> {
            return localVarFp.adminFontsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousFontId] 
         * @param {string} [nextFontId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdPatch(id: string, previousFontId?: string, nextFontId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminFontsIdPatch(id, previousFontId, nextFontId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminFontsIdDeleteRequest} [adminFontsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsIdPut(id: string, adminFontsIdDeleteRequest?: AdminFontsIdDeleteRequest, options?: any): AxiosPromise<FontAdminViewModelApplicationResult> {
            return localVarFp.adminFontsIdPut(id, adminFontsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminFontsGetRequest} [adminFontsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFontsPost(adminFontsGetRequest?: AdminFontsGetRequest, options?: any): AxiosPromise<FontAdminViewModelApplicationResult> {
            return localVarFp.adminFontsPost(adminFontsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFontsApi - object-oriented interface
 * @export
 * @class AdminFontsApi
 * @extends {BaseAPI}
 */
export class AdminFontsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [fontName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFontsApi
     */
    public adminFontsGet(pageIndex: number, pageSize: number, fontName?: string, options?: AxiosRequestConfig) {
        return AdminFontsApiFp(this.configuration).adminFontsGet(pageIndex, pageSize, fontName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFontsApi
     */
    public adminFontsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminFontsApiFp(this.configuration).adminFontsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFontsApi
     */
    public adminFontsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminFontsApiFp(this.configuration).adminFontsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousFontId] 
     * @param {string} [nextFontId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFontsApi
     */
    public adminFontsIdPatch(id: string, previousFontId?: string, nextFontId?: string, options?: AxiosRequestConfig) {
        return AdminFontsApiFp(this.configuration).adminFontsIdPatch(id, previousFontId, nextFontId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminFontsIdDeleteRequest} [adminFontsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFontsApi
     */
    public adminFontsIdPut(id: string, adminFontsIdDeleteRequest?: AdminFontsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminFontsApiFp(this.configuration).adminFontsIdPut(id, adminFontsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminFontsGetRequest} [adminFontsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFontsApi
     */
    public adminFontsPost(adminFontsGetRequest?: AdminFontsGetRequest, options?: AxiosRequestConfig) {
        return AdminFontsApiFp(this.configuration).adminFontsPost(adminFontsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminGeneralSettingsApi - axios parameter creator
 * @export
 */
export const AdminGeneralSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsGetGeneralSettingsGet: async (keys: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('adminGeneralSettingsGetGeneralSettingsGet', 'keys', keys)
            const localVarPath = `/Admin/GeneralSettings/GetGeneralSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsKeyDelete: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adminGeneralSettingsKeyDelete', 'key', key)
            const localVarPath = `/Admin/GeneralSettings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adminGeneralSettingsKeyGet', 'key', key)
            const localVarPath = `/Admin/GeneralSettings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsKeyPut: async (key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adminGeneralSettingsKeyPut', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('adminGeneralSettingsKeyPut', 'value', value)
            const localVarPath = `/Admin/GeneralSettings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsPost: async (key: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adminGeneralSettingsPost', 'key', key)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('adminGeneralSettingsPost', 'value', value)
            const localVarPath = `/Admin/GeneralSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsSetGeneralSettingsPost: async (requestBody?: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/GeneralSettings/SetGeneralSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminGeneralSettingsApi - functional programming interface
 * @export
 */
export const AdminGeneralSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminGeneralSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGeneralSettingsGetGeneralSettingsGet(keys: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringStringDictionaryApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGeneralSettingsGetGeneralSettingsGet(keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGeneralSettingsKeyDelete(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGeneralSettingsKeyDelete(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGeneralSettingsKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGeneralSettingsKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGeneralSettingsKeyPut(key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGeneralSettingsKeyPut(key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGeneralSettingsPost(key: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGeneralSettingsPost(key, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGeneralSettingsSetGeneralSettingsPost(requestBody?: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringStringDictionaryApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGeneralSettingsSetGeneralSettingsPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminGeneralSettingsApi - factory interface
 * @export
 */
export const AdminGeneralSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminGeneralSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsGetGeneralSettingsGet(keys: Array<string>, options?: any): AxiosPromise<StringStringDictionaryApplicationResult> {
            return localVarFp.adminGeneralSettingsGetGeneralSettingsGet(keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsKeyDelete(key: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminGeneralSettingsKeyDelete(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsKeyGet(key: string, options?: any): AxiosPromise<StringApplicationResult> {
            return localVarFp.adminGeneralSettingsKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsKeyPut(key: string, value: string, options?: any): AxiosPromise<StringApplicationResult> {
            return localVarFp.adminGeneralSettingsKeyPut(key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} key 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsPost(key: string, value: string, options?: any): AxiosPromise<StringApplicationResult> {
            return localVarFp.adminGeneralSettingsPost(key, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGeneralSettingsSetGeneralSettingsPost(requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<StringStringDictionaryApplicationResult> {
            return localVarFp.adminGeneralSettingsSetGeneralSettingsPost(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminGeneralSettingsApi - object-oriented interface
 * @export
 * @class AdminGeneralSettingsApi
 * @extends {BaseAPI}
 */
export class AdminGeneralSettingsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {Array<string>} keys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralSettingsApi
     */
    public adminGeneralSettingsGetGeneralSettingsGet(keys: Array<string>, options?: AxiosRequestConfig) {
        return AdminGeneralSettingsApiFp(this.configuration).adminGeneralSettingsGetGeneralSettingsGet(keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralSettingsApi
     */
    public adminGeneralSettingsKeyDelete(key: string, options?: AxiosRequestConfig) {
        return AdminGeneralSettingsApiFp(this.configuration).adminGeneralSettingsKeyDelete(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralSettingsApi
     */
    public adminGeneralSettingsKeyGet(key: string, options?: AxiosRequestConfig) {
        return AdminGeneralSettingsApiFp(this.configuration).adminGeneralSettingsKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralSettingsApi
     */
    public adminGeneralSettingsKeyPut(key: string, value: string, options?: AxiosRequestConfig) {
        return AdminGeneralSettingsApiFp(this.configuration).adminGeneralSettingsKeyPut(key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} key 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralSettingsApi
     */
    public adminGeneralSettingsPost(key: string, value: string, options?: AxiosRequestConfig) {
        return AdminGeneralSettingsApiFp(this.configuration).adminGeneralSettingsPost(key, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGeneralSettingsApi
     */
    public adminGeneralSettingsSetGeneralSettingsPost(requestBody?: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return AdminGeneralSettingsApiFp(this.configuration).adminGeneralSettingsSetGeneralSettingsPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminLayerFabricsApi - axios parameter creator
 * @export
 */
export const AdminLayerFabricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<string>} idsToDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsDeleteLayerFabricsDelete: async (idsToDelete: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idsToDelete' is not null or undefined
            assertParamExists('adminLayerFabricsDeleteLayerFabricsDelete', 'idsToDelete', idsToDelete)
            const localVarPath = `/Admin/LayerFabrics/DeleteLayerFabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idsToDelete) {
                localVarQueryParameter['idsToDelete'] = idsToDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} layerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGet: async (pageIndex: number, pageSize: number, layerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminLayerFabricsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminLayerFabricsGet', 'pageSize', pageSize)
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('adminLayerFabricsGet', 'layerId', layerId)
            const localVarPath = `/Admin/LayerFabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (layerId !== undefined) {
                localVarQueryParameter['layerId'] = layerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetColourGroupsForFabricGet: async (fabricId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fabricId' is not null or undefined
            assertParamExists('adminLayerFabricsGetColourGroupsForFabricGet', 'fabricId', fabricId)
            const localVarPath = `/Admin/LayerFabrics/GetColourGroupsForFabric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fabricId !== undefined) {
                localVarQueryParameter['fabricId'] = fabricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} layerId 
         * @param {string} [layerFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetFabricsForLayerGet: async (layerId: string, layerFabricId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('adminLayerFabricsGetFabricsForLayerGet', 'layerId', layerId)
            const localVarPath = `/Admin/LayerFabrics/GetFabricsForLayer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (layerId !== undefined) {
                localVarQueryParameter['layerId'] = layerId;
            }

            if (layerFabricId !== undefined) {
                localVarQueryParameter['layerFabricId'] = layerFabricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} fabricsId 
         * @param {string} [productId] 
         * @param {string} [styleId] 
         * @param {string} [designId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetFabricsLayerByFabricIdAdminGet: async (pageIndex: number, pageSize: number, fabricsId: string, productId?: string, styleId?: string, designId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminLayerFabricsGetFabricsLayerByFabricIdAdminGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminLayerFabricsGetFabricsLayerByFabricIdAdminGet', 'pageSize', pageSize)
            // verify required parameter 'fabricsId' is not null or undefined
            assertParamExists('adminLayerFabricsGetFabricsLayerByFabricIdAdminGet', 'fabricsId', fabricsId)
            const localVarPath = `/Admin/LayerFabrics/GetFabricsLayerByFabricIdAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fabricsId !== undefined) {
                localVarQueryParameter['fabricsId'] = fabricsId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }

            if (designId !== undefined) {
                localVarQueryParameter['designId'] = designId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {Array<string>} productIds 
         * @param {string} [layerFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetLayersForFabricGet: async (fabricId: string, productIds: Array<string>, layerFabricId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fabricId' is not null or undefined
            assertParamExists('adminLayerFabricsGetLayersForFabricGet', 'fabricId', fabricId)
            // verify required parameter 'productIds' is not null or undefined
            assertParamExists('adminLayerFabricsGetLayersForFabricGet', 'productIds', productIds)
            const localVarPath = `/Admin/LayerFabrics/GetLayersForFabric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fabricId !== undefined) {
                localVarQueryParameter['fabricId'] = fabricId;
            }

            if (layerFabricId !== undefined) {
                localVarQueryParameter['layerFabricId'] = layerFabricId;
            }

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} layerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetProductsForLayerGet: async (layerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layerId' is not null or undefined
            assertParamExists('adminLayerFabricsGetProductsForLayerGet', 'layerId', layerId)
            const localVarPath = `/Admin/LayerFabrics/GetProductsForLayer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (layerId !== undefined) {
                localVarQueryParameter['layerId'] = layerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayerFabricsIdDelete', 'id', id)
            const localVarPath = `/Admin/LayerFabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayerFabricsIdGet', 'id', id)
            const localVarPath = `/Admin/LayerFabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminLayerFabricsIdDeleteRequest} [adminLayerFabricsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsIdPut: async (id: string, adminLayerFabricsIdDeleteRequest?: AdminLayerFabricsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayerFabricsIdPut', 'id', id)
            const localVarPath = `/Admin/LayerFabrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLayerFabricsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminLayerFabricsGetRequest} [adminLayerFabricsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsPost: async (adminLayerFabricsGetRequest?: AdminLayerFabricsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/LayerFabrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLayerFabricsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLayerFabricsApi - functional programming interface
 * @export
 */
export const AdminLayerFabricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLayerFabricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<string>} idsToDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsDeleteLayerFabricsDelete(idsToDelete: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsDeleteLayerFabricsDelete(idsToDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} layerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsGet(pageIndex: number, pageSize: number, layerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerFabricAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsGet(pageIndex, pageSize, layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsGetColourGroupsForFabricGet(fabricId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColourGroupAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsGetColourGroupsForFabricGet(fabricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} layerId 
         * @param {string} [layerFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsGetFabricsForLayerGet(layerId: string, layerFabricId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FabricAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsGetFabricsForLayerGet(layerId, layerFabricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} fabricsId 
         * @param {string} [productId] 
         * @param {string} [styleId] 
         * @param {string} [designId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(pageIndex: number, pageSize: number, fabricsId: string, productId?: string, styleId?: string, designId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerFabricAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(pageIndex, pageSize, fabricsId, productId, styleId, designId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {Array<string>} productIds 
         * @param {string} [layerFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsGetLayersForFabricGet(fabricId: string, productIds: Array<string>, layerFabricId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsGetLayersForFabricGet(fabricId, productIds, layerFabricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} layerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsGetProductsForLayerGet(layerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsGetProductsForLayerGet(layerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerFabricAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminLayerFabricsIdDeleteRequest} [adminLayerFabricsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsIdPut(id: string, adminLayerFabricsIdDeleteRequest?: AdminLayerFabricsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerFabricAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsIdPut(id, adminLayerFabricsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminLayerFabricsGetRequest} [adminLayerFabricsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerFabricsPost(adminLayerFabricsGetRequest?: AdminLayerFabricsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerFabricAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerFabricsPost(adminLayerFabricsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLayerFabricsApi - factory interface
 * @export
 */
export const AdminLayerFabricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLayerFabricsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<string>} idsToDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsDeleteLayerFabricsDelete(idsToDelete: Array<string>, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminLayerFabricsDeleteLayerFabricsDelete(idsToDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} layerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGet(pageIndex: number, pageSize: number, layerId: string, options?: any): AxiosPromise<LayerFabricAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminLayerFabricsGet(pageIndex, pageSize, layerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetColourGroupsForFabricGet(fabricId: string, options?: any): AxiosPromise<ColourGroupAdminListViewModelListApplicationResult> {
            return localVarFp.adminLayerFabricsGetColourGroupsForFabricGet(fabricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} layerId 
         * @param {string} [layerFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetFabricsForLayerGet(layerId: string, layerFabricId?: string, options?: any): AxiosPromise<FabricAdminListViewModelListApplicationResult> {
            return localVarFp.adminLayerFabricsGetFabricsForLayerGet(layerId, layerFabricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} fabricsId 
         * @param {string} [productId] 
         * @param {string} [styleId] 
         * @param {string} [designId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(pageIndex: number, pageSize: number, fabricsId: string, productId?: string, styleId?: string, designId?: string, options?: any): AxiosPromise<LayerFabricAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(pageIndex, pageSize, fabricsId, productId, styleId, designId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} fabricId 
         * @param {Array<string>} productIds 
         * @param {string} [layerFabricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetLayersForFabricGet(fabricId: string, productIds: Array<string>, layerFabricId?: string, options?: any): AxiosPromise<LayerAdminListViewModelListApplicationResult> {
            return localVarFp.adminLayerFabricsGetLayersForFabricGet(fabricId, productIds, layerFabricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} layerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsGetProductsForLayerGet(layerId: string, options?: any): AxiosPromise<ProductAdminListViewModelListApplicationResult> {
            return localVarFp.adminLayerFabricsGetProductsForLayerGet(layerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminLayerFabricsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsIdGet(id: string, options?: any): AxiosPromise<LayerFabricAdminViewModelApplicationResult> {
            return localVarFp.adminLayerFabricsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminLayerFabricsIdDeleteRequest} [adminLayerFabricsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsIdPut(id: string, adminLayerFabricsIdDeleteRequest?: AdminLayerFabricsIdDeleteRequest, options?: any): AxiosPromise<LayerFabricAdminViewModelApplicationResult> {
            return localVarFp.adminLayerFabricsIdPut(id, adminLayerFabricsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminLayerFabricsGetRequest} [adminLayerFabricsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerFabricsPost(adminLayerFabricsGetRequest?: AdminLayerFabricsGetRequest, options?: any): AxiosPromise<LayerFabricAdminViewModelApplicationResult> {
            return localVarFp.adminLayerFabricsPost(adminLayerFabricsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminLayerFabricsApi - object-oriented interface
 * @export
 * @class AdminLayerFabricsApi
 * @extends {BaseAPI}
 */
export class AdminLayerFabricsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {Array<string>} idsToDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsDeleteLayerFabricsDelete(idsToDelete: Array<string>, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsDeleteLayerFabricsDelete(idsToDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} layerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsGet(pageIndex: number, pageSize: number, layerId: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsGet(pageIndex, pageSize, layerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} fabricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsGetColourGroupsForFabricGet(fabricId: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsGetColourGroupsForFabricGet(fabricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} layerId 
     * @param {string} [layerFabricId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsGetFabricsForLayerGet(layerId: string, layerFabricId?: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsGetFabricsForLayerGet(layerId, layerFabricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} fabricsId 
     * @param {string} [productId] 
     * @param {string} [styleId] 
     * @param {string} [designId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(pageIndex: number, pageSize: number, fabricsId: string, productId?: string, styleId?: string, designId?: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(pageIndex, pageSize, fabricsId, productId, styleId, designId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} fabricId 
     * @param {Array<string>} productIds 
     * @param {string} [layerFabricId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsGetLayersForFabricGet(fabricId: string, productIds: Array<string>, layerFabricId?: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsGetLayersForFabricGet(fabricId, productIds, layerFabricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} layerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsGetProductsForLayerGet(layerId: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsGetProductsForLayerGet(layerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminLayerFabricsIdDeleteRequest} [adminLayerFabricsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsIdPut(id: string, adminLayerFabricsIdDeleteRequest?: AdminLayerFabricsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsIdPut(id, adminLayerFabricsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminLayerFabricsGetRequest} [adminLayerFabricsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerFabricsApi
     */
    public adminLayerFabricsPost(adminLayerFabricsGetRequest?: AdminLayerFabricsGetRequest, options?: AxiosRequestConfig) {
        return AdminLayerFabricsApiFp(this.configuration).adminLayerFabricsPost(adminLayerFabricsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminLayerPricesApi - axios parameter creator
 * @export
 */
export const AdminLayerPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminLayerPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminLayerPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminLayerPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/LayerPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayerPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/LayerPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayerPricesIdGet', 'id', id)
            const localVarPath = `/Admin/LayerPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayerPricesIdPut', 'id', id)
            const localVarPath = `/Admin/LayerPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/LayerPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLayerPricesApi - functional programming interface
 * @export
 */
export const AdminLayerPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLayerPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayerPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayerPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLayerPricesApi - factory interface
 * @export
 */
export const AdminLayerPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLayerPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminLayerPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminLayerPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminLayerPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminLayerPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayerPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminLayerPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminLayerPricesApi - object-oriented interface
 * @export
 * @class AdminLayerPricesApi
 * @extends {BaseAPI}
 */
export class AdminLayerPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerPricesApi
     */
    public adminLayerPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminLayerPricesApiFp(this.configuration).adminLayerPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerPricesApi
     */
    public adminLayerPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminLayerPricesApiFp(this.configuration).adminLayerPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerPricesApi
     */
    public adminLayerPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminLayerPricesApiFp(this.configuration).adminLayerPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerPricesApi
     */
    public adminLayerPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminLayerPricesApiFp(this.configuration).adminLayerPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayerPricesApi
     */
    public adminLayerPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminLayerPricesApiFp(this.configuration).adminLayerPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminLayersApi - axios parameter creator
 * @export
 */
export const AdminLayersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {string} [fabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, fabricChannelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminLayersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminLayersGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Layers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (hasProductBuilderOptionsGenerationLogs !== undefined) {
                localVarQueryParameter['hasProductBuilderOptionsGenerationLogs'] = hasProductBuilderOptionsGenerationLogs;
            }

            if (fabricChannelId !== undefined) {
                localVarQueryParameter['fabricChannelId'] = fabricChannelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayersIdDelete', 'id', id)
            const localVarPath = `/Admin/Layers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayersIdGet', 'id', id)
            const localVarPath = `/Admin/Layers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousLayerId] 
         * @param {string} [nextLayerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdPatch: async (id: string, previousLayerId?: string, nextLayerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayersIdPatch', 'id', id)
            const localVarPath = `/Admin/Layers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousLayerId !== undefined) {
                localVarQueryParameter['previousLayerId'] = previousLayerId;
            }

            if (nextLayerId !== undefined) {
                localVarQueryParameter['nextLayerId'] = nextLayerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminLayersIdDeleteRequest} [adminLayersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdPut: async (id: string, adminLayersIdDeleteRequest?: AdminLayersIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminLayersIdPut', 'id', id)
            const localVarPath = `/Admin/Layers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLayersIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminLayersGetRequest} [adminLayersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersPost: async (adminLayersGetRequest?: AdminLayersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Layers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLayersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLayersApi - functional programming interface
 * @export
 */
export const AdminLayersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLayersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {string} [fabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayersGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, fabricChannelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayersGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, fabricChannelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousLayerId] 
         * @param {string} [nextLayerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayersIdPatch(id: string, previousLayerId?: string, nextLayerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayersIdPatch(id, previousLayerId, nextLayerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminLayersIdDeleteRequest} [adminLayersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayersIdPut(id: string, adminLayersIdDeleteRequest?: AdminLayersIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayersIdPut(id, adminLayersIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminLayersGetRequest} [adminLayersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLayersPost(adminLayersGetRequest?: AdminLayersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayerAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLayersPost(adminLayersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLayersApi - factory interface
 * @export
 */
export const AdminLayersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLayersApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {string} [fabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, fabricChannelId?: string, options?: any): AxiosPromise<LayerAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminLayersGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, fabricChannelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminLayersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdGet(id: string, options?: any): AxiosPromise<LayerAdminViewModelApplicationResult> {
            return localVarFp.adminLayersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousLayerId] 
         * @param {string} [nextLayerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdPatch(id: string, previousLayerId?: string, nextLayerId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminLayersIdPatch(id, previousLayerId, nextLayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminLayersIdDeleteRequest} [adminLayersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersIdPut(id: string, adminLayersIdDeleteRequest?: AdminLayersIdDeleteRequest, options?: any): AxiosPromise<LayerAdminViewModelApplicationResult> {
            return localVarFp.adminLayersIdPut(id, adminLayersIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminLayersGetRequest} [adminLayersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLayersPost(adminLayersGetRequest?: AdminLayersGetRequest, options?: any): AxiosPromise<LayerAdminViewModelApplicationResult> {
            return localVarFp.adminLayersPost(adminLayersGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminLayersApi - object-oriented interface
 * @export
 * @class AdminLayersApi
 * @extends {BaseAPI}
 */
export class AdminLayersApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
     * @param {string} [fabricChannelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayersApi
     */
    public adminLayersGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, fabricChannelId?: string, options?: AxiosRequestConfig) {
        return AdminLayersApiFp(this.configuration).adminLayersGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, fabricChannelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayersApi
     */
    public adminLayersIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminLayersApiFp(this.configuration).adminLayersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayersApi
     */
    public adminLayersIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminLayersApiFp(this.configuration).adminLayersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousLayerId] 
     * @param {string} [nextLayerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayersApi
     */
    public adminLayersIdPatch(id: string, previousLayerId?: string, nextLayerId?: string, options?: AxiosRequestConfig) {
        return AdminLayersApiFp(this.configuration).adminLayersIdPatch(id, previousLayerId, nextLayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminLayersIdDeleteRequest} [adminLayersIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayersApi
     */
    public adminLayersIdPut(id: string, adminLayersIdDeleteRequest?: AdminLayersIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminLayersApiFp(this.configuration).adminLayersIdPut(id, adminLayersIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminLayersGetRequest} [adminLayersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLayersApi
     */
    public adminLayersPost(adminLayersGetRequest?: AdminLayersGetRequest, options?: AxiosRequestConfig) {
        return AdminLayersApiFp(this.configuration).adminLayersPost(adminLayersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminMediasApi - axios parameter creator
 * @export
 */
export const AdminMediasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminMediasGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminMediasGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Medias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMediasIdDelete', 'id', id)
            const localVarPath = `/Admin/Medias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMediasIdGet', 'id', id)
            const localVarPath = `/Admin/Medias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminMediasIdDeleteRequest} [adminMediasIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasIdPut: async (id: string, adminMediasIdDeleteRequest?: AdminMediasIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminMediasIdPut', 'id', id)
            const localVarPath = `/Admin/Medias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMediasIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminMediasGetRequest} [adminMediasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasPost: async (adminMediasGetRequest?: AdminMediasGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Medias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMediasGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMediasApi - functional programming interface
 * @export
 */
export const AdminMediasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMediasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediasGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediasGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediasIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediasIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediasIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediasIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminMediasIdDeleteRequest} [adminMediasIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediasIdPut(id: string, adminMediasIdDeleteRequest?: AdminMediasIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediasIdPut(id, adminMediasIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminMediasGetRequest} [adminMediasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMediasPost(adminMediasGetRequest?: AdminMediasGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMediasPost(adminMediasGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMediasApi - factory interface
 * @export
 */
export const AdminMediasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMediasApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<MediaAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminMediasGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminMediasIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasIdGet(id: string, options?: any): AxiosPromise<MediaAdminViewModelApplicationResult> {
            return localVarFp.adminMediasIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminMediasIdDeleteRequest} [adminMediasIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasIdPut(id: string, adminMediasIdDeleteRequest?: AdminMediasIdDeleteRequest, options?: any): AxiosPromise<MediaAdminViewModelApplicationResult> {
            return localVarFp.adminMediasIdPut(id, adminMediasIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminMediasGetRequest} [adminMediasGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMediasPost(adminMediasGetRequest?: AdminMediasGetRequest, options?: any): AxiosPromise<MediaAdminViewModelApplicationResult> {
            return localVarFp.adminMediasPost(adminMediasGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminMediasApi - object-oriented interface
 * @export
 * @class AdminMediasApi
 * @extends {BaseAPI}
 */
export class AdminMediasApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediasApi
     */
    public adminMediasGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminMediasApiFp(this.configuration).adminMediasGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediasApi
     */
    public adminMediasIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminMediasApiFp(this.configuration).adminMediasIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediasApi
     */
    public adminMediasIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminMediasApiFp(this.configuration).adminMediasIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminMediasIdDeleteRequest} [adminMediasIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediasApi
     */
    public adminMediasIdPut(id: string, adminMediasIdDeleteRequest?: AdminMediasIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminMediasApiFp(this.configuration).adminMediasIdPut(id, adminMediasIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminMediasGetRequest} [adminMediasGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMediasApi
     */
    public adminMediasPost(adminMediasGetRequest?: AdminMediasGetRequest, options?: AxiosRequestConfig) {
        return AdminMediasApiFp(this.configuration).adminMediasPost(adminMediasGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderAuditLogsApi - axios parameter creator
 * @export
 */
export const AdminOrderAuditLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderAuditLogsGetAuditLogFieldsOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderAuditLogsGetAuditLogFieldsOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Admin/OrderAuditLogs/GetAuditLogFields/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {Array<string>} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderAuditLogsGetAuditLogsOrderIdGet: async (pageIndex: number, pageSize: number, orderId: string, fields?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrderAuditLogsGetAuditLogsOrderIdGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderAuditLogsGetAuditLogsOrderIdGet', 'pageSize', pageSize)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderAuditLogsGetAuditLogsOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Admin/OrderAuditLogs/GetAuditLogs/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderAuditLogsApi - functional programming interface
 * @export
 */
export const AdminOrderAuditLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderAuditLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {Array<string>} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderAuditLogsGetAuditLogsOrderIdGet(pageIndex: number, pageSize: number, orderId: string, fields?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAuditLogViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderAuditLogsGetAuditLogsOrderIdGet(pageIndex, pageSize, orderId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderAuditLogsApi - factory interface
 * @export
 */
export const AdminOrderAuditLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderAuditLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(orderId: string, options?: any): AxiosPromise<StringListApplicationResult> {
            return localVarFp.adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {Array<string>} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderAuditLogsGetAuditLogsOrderIdGet(pageIndex: number, pageSize: number, orderId: string, fields?: Array<string>, options?: any): AxiosPromise<OrderAuditLogViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrderAuditLogsGetAuditLogsOrderIdGet(pageIndex, pageSize, orderId, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderAuditLogsApi - object-oriented interface
 * @export
 * @class AdminOrderAuditLogsApi
 * @extends {BaseAPI}
 */
export class AdminOrderAuditLogsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderAuditLogsApi
     */
    public adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return AdminOrderAuditLogsApiFp(this.configuration).adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderId 
     * @param {Array<string>} [fields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderAuditLogsApi
     */
    public adminOrderAuditLogsGetAuditLogsOrderIdGet(pageIndex: number, pageSize: number, orderId: string, fields?: Array<string>, options?: AxiosRequestConfig) {
        return AdminOrderAuditLogsApiFp(this.configuration).adminOrderAuditLogsGetAuditLogsOrderIdGet(pageIndex, pageSize, orderId, fields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderCostsApi - axios parameter creator
 * @export
 */
export const AdminOrderCostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {OrderCostType} [orderCostTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsGet: async (pageIndex: number, pageSize: number, orderId: string, orderCostTypeId?: OrderCostType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrderCostsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderCostsGet', 'pageSize', pageSize)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderCostsGet', 'orderId', orderId)
            const localVarPath = `/Admin/OrderCosts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (orderCostTypeId !== undefined) {
                localVarQueryParameter['orderCostTypeId'] = orderCostTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderCostsIdDelete', 'id', id)
            const localVarPath = `/Admin/OrderCosts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderCostsIdGet', 'id', id)
            const localVarPath = `/Admin/OrderCosts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrderCostsIdDeleteRequest} [adminOrderCostsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsIdPut: async (id: string, adminOrderCostsIdDeleteRequest?: AdminOrderCostsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderCostsIdPut', 'id', id)
            const localVarPath = `/Admin/OrderCosts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderCostsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderCostsGetRequest} [adminOrderCostsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsPost: async (adminOrderCostsGetRequest?: AdminOrderCostsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderCosts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderCostsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderCostsApi - functional programming interface
 * @export
 */
export const AdminOrderCostsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderCostsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {OrderCostType} [orderCostTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderCostsGet(pageIndex: number, pageSize: number, orderId: string, orderCostTypeId?: OrderCostType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCostAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderCostsGet(pageIndex, pageSize, orderId, orderCostTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderCostsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderCostsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderCostsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCostAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderCostsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrderCostsIdDeleteRequest} [adminOrderCostsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderCostsIdPut(id: string, adminOrderCostsIdDeleteRequest?: AdminOrderCostsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCostAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderCostsIdPut(id, adminOrderCostsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderCostsGetRequest} [adminOrderCostsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderCostsPost(adminOrderCostsGetRequest?: AdminOrderCostsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCostAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderCostsPost(adminOrderCostsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderCostsApi - factory interface
 * @export
 */
export const AdminOrderCostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderCostsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {OrderCostType} [orderCostTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsGet(pageIndex: number, pageSize: number, orderId: string, orderCostTypeId?: OrderCostType, options?: any): AxiosPromise<OrderCostAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrderCostsGet(pageIndex, pageSize, orderId, orderCostTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderCostsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsIdGet(id: string, options?: any): AxiosPromise<OrderCostAdminViewModelApplicationResult> {
            return localVarFp.adminOrderCostsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrderCostsIdDeleteRequest} [adminOrderCostsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsIdPut(id: string, adminOrderCostsIdDeleteRequest?: AdminOrderCostsIdDeleteRequest, options?: any): AxiosPromise<OrderCostAdminViewModelApplicationResult> {
            return localVarFp.adminOrderCostsIdPut(id, adminOrderCostsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderCostsGetRequest} [adminOrderCostsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderCostsPost(adminOrderCostsGetRequest?: AdminOrderCostsGetRequest, options?: any): AxiosPromise<OrderCostAdminViewModelApplicationResult> {
            return localVarFp.adminOrderCostsPost(adminOrderCostsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderCostsApi - object-oriented interface
 * @export
 * @class AdminOrderCostsApi
 * @extends {BaseAPI}
 */
export class AdminOrderCostsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderId 
     * @param {OrderCostType} [orderCostTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderCostsApi
     */
    public adminOrderCostsGet(pageIndex: number, pageSize: number, orderId: string, orderCostTypeId?: OrderCostType, options?: AxiosRequestConfig) {
        return AdminOrderCostsApiFp(this.configuration).adminOrderCostsGet(pageIndex, pageSize, orderId, orderCostTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderCostsApi
     */
    public adminOrderCostsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOrderCostsApiFp(this.configuration).adminOrderCostsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderCostsApi
     */
    public adminOrderCostsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrderCostsApiFp(this.configuration).adminOrderCostsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {AdminOrderCostsIdDeleteRequest} [adminOrderCostsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderCostsApi
     */
    public adminOrderCostsIdPut(id: string, adminOrderCostsIdDeleteRequest?: AdminOrderCostsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminOrderCostsApiFp(this.configuration).adminOrderCostsIdPut(id, adminOrderCostsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {AdminOrderCostsGetRequest} [adminOrderCostsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderCostsApi
     */
    public adminOrderCostsPost(adminOrderCostsGetRequest?: AdminOrderCostsGetRequest, options?: AxiosRequestConfig) {
        return AdminOrderCostsApiFp(this.configuration).adminOrderCostsPost(adminOrderCostsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderInvoicesApi - axios parameter creator
 * @export
 */
export const AdminOrderInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {OrderInvoiceType} [orderInvoiceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesGet: async (pageIndex: number, pageSize: number, orderId: string, orderInvoiceType?: OrderInvoiceType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrderInvoicesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderInvoicesGet', 'pageSize', pageSize)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderInvoicesGet', 'orderId', orderId)
            const localVarPath = `/Admin/OrderInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderInvoiceType !== undefined) {
                localVarQueryParameter['orderInvoiceType'] = orderInvoiceType;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: DeleteOrderInvoice, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderInvoicesIdDelete', 'id', id)
            const localVarPath = `/Admin/OrderInvoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderInvoicesIdGet', 'id', id)
            const localVarPath = `/Admin/OrderInvoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderInvoicesGetRequest} [adminOrderInvoicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesPost: async (adminOrderInvoicesGetRequest?: AdminOrderInvoicesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderInvoicesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesVoidOrderInvoiceIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderInvoicesVoidOrderInvoiceIdPut', 'id', id)
            const localVarPath = `/Admin/OrderInvoices/VoidOrderInvoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderInvoicesApi - functional programming interface
 * @export
 */
export const AdminOrderInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {OrderInvoiceType} [orderInvoiceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderInvoicesGet(pageIndex: number, pageSize: number, orderId: string, orderInvoiceType?: OrderInvoiceType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderInvoicesGet(pageIndex, pageSize, orderId, orderInvoiceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: DeleteOrderInvoice, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderInvoicesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderInvoicesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderInvoicesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderInvoicesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderInvoicesGetRequest} [adminOrderInvoicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderInvoicesPost(adminOrderInvoicesGetRequest?: AdminOrderInvoicesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderInvoicesPost(adminOrderInvoicesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderInvoicesVoidOrderInvoiceIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderInvoicesVoidOrderInvoiceIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderInvoicesApi - factory interface
 * @export
 */
export const AdminOrderInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {OrderInvoiceType} [orderInvoiceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesGet(pageIndex: number, pageSize: number, orderId: string, orderInvoiceType?: OrderInvoiceType, options?: any): AxiosPromise<OrderInvoiceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrderInvoicesGet(pageIndex, pageSize, orderId, orderInvoiceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: DeleteOrderInvoice, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderInvoicesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesIdGet(id: string, options?: any): AxiosPromise<OrderInvoiceAdminViewModelApplicationResult> {
            return localVarFp.adminOrderInvoicesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderInvoicesGetRequest} [adminOrderInvoicesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesPost(adminOrderInvoicesGetRequest?: AdminOrderInvoicesGetRequest, options?: any): AxiosPromise<OrderInvoiceAdminViewModelApplicationResult> {
            return localVarFp.adminOrderInvoicesPost(adminOrderInvoicesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderInvoicesVoidOrderInvoiceIdPut(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderInvoicesVoidOrderInvoiceIdPut(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderInvoicesApi - object-oriented interface
 * @export
 * @class AdminOrderInvoicesApi
 * @extends {BaseAPI}
 */
export class AdminOrderInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderId 
     * @param {OrderInvoiceType} [orderInvoiceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderInvoicesApi
     */
    public adminOrderInvoicesGet(pageIndex: number, pageSize: number, orderId: string, orderInvoiceType?: OrderInvoiceType, options?: AxiosRequestConfig) {
        return AdminOrderInvoicesApiFp(this.configuration).adminOrderInvoicesGet(pageIndex, pageSize, orderId, orderInvoiceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: DeleteOrderInvoice, ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderInvoicesApi
     */
    public adminOrderInvoicesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOrderInvoicesApiFp(this.configuration).adminOrderInvoicesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderInvoicesApi
     */
    public adminOrderInvoicesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrderInvoicesApiFp(this.configuration).adminOrderInvoicesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {AdminOrderInvoicesGetRequest} [adminOrderInvoicesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderInvoicesApi
     */
    public adminOrderInvoicesPost(adminOrderInvoicesGetRequest?: AdminOrderInvoicesGetRequest, options?: AxiosRequestConfig) {
        return AdminOrderInvoicesApiFp(this.configuration).adminOrderInvoicesPost(adminOrderInvoicesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderInvoicesApi
     */
    public adminOrderInvoicesVoidOrderInvoiceIdPut(id: string, options?: AxiosRequestConfig) {
        return AdminOrderInvoicesApiFp(this.configuration).adminOrderInvoicesVoidOrderInvoiceIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderItemAssetsApi - axios parameter creator
 * @export
 */
export const AdminOrderItemAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsGet: async (pageIndex: number, pageSize: number, orderItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrderItemAssetsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderItemAssetsGet', 'pageSize', pageSize)
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('adminOrderItemAssetsGet', 'orderItemId', orderItemId)
            const localVarPath = `/Admin/OrderItemAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderItemId !== undefined) {
                localVarQueryParameter['orderItemId'] = orderItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderItemAssetsIdDelete', 'id', id)
            const localVarPath = `/Admin/OrderItemAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderItemAssetsIdGet', 'id', id)
            const localVarPath = `/Admin/OrderItemAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrderItemAssetsIdDeleteRequest} [adminOrderItemAssetsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsIdPut: async (id: string, adminOrderItemAssetsIdDeleteRequest?: AdminOrderItemAssetsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderItemAssetsIdPut', 'id', id)
            const localVarPath = `/Admin/OrderItemAssets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderItemAssetsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderItemAssetsGetRequest} [adminOrderItemAssetsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsPost: async (adminOrderItemAssetsGetRequest?: AdminOrderItemAssetsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderItemAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderItemAssetsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost', 'id', id)
            const localVarPath = `/Admin/OrderItemAssets/SendOrderItemAssetResupplyEmail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderItemAssetsApi - functional programming interface
 * @export
 */
export const AdminOrderItemAssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderItemAssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderItemAssetsGet(pageIndex: number, pageSize: number, orderItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemAssetAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderItemAssetsGet(pageIndex, pageSize, orderItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderItemAssetsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderItemAssetsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderItemAssetsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemAssetAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderItemAssetsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrderItemAssetsIdDeleteRequest} [adminOrderItemAssetsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderItemAssetsIdPut(id: string, adminOrderItemAssetsIdDeleteRequest?: AdminOrderItemAssetsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemAssetAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderItemAssetsIdPut(id, adminOrderItemAssetsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderItemAssetsGetRequest} [adminOrderItemAssetsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderItemAssetsPost(adminOrderItemAssetsGetRequest?: AdminOrderItemAssetsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemAssetAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderItemAssetsPost(adminOrderItemAssetsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderItemAssetsApi - factory interface
 * @export
 */
export const AdminOrderItemAssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderItemAssetsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsGet(pageIndex: number, pageSize: number, orderItemId: string, options?: any): AxiosPromise<OrderItemAssetAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrderItemAssetsGet(pageIndex, pageSize, orderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderItemAssetsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsIdGet(id: string, options?: any): AxiosPromise<OrderItemAssetAdminViewModelApplicationResult> {
            return localVarFp.adminOrderItemAssetsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrderItemAssetsIdDeleteRequest} [adminOrderItemAssetsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsIdPut(id: string, adminOrderItemAssetsIdDeleteRequest?: AdminOrderItemAssetsIdDeleteRequest, options?: any): AxiosPromise<OrderItemAssetAdminViewModelApplicationResult> {
            return localVarFp.adminOrderItemAssetsIdPut(id, adminOrderItemAssetsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrderItemAssetsGetRequest} [adminOrderItemAssetsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsPost(adminOrderItemAssetsGetRequest?: AdminOrderItemAssetsGetRequest, options?: any): AxiosPromise<OrderItemAssetAdminViewModelApplicationResult> {
            return localVarFp.adminOrderItemAssetsPost(adminOrderItemAssetsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderItemAssetsApi - object-oriented interface
 * @export
 * @class AdminOrderItemAssetsApi
 * @extends {BaseAPI}
 */
export class AdminOrderItemAssetsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderItemAssetsApi
     */
    public adminOrderItemAssetsGet(pageIndex: number, pageSize: number, orderItemId: string, options?: AxiosRequestConfig) {
        return AdminOrderItemAssetsApiFp(this.configuration).adminOrderItemAssetsGet(pageIndex, pageSize, orderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderItemAssetsApi
     */
    public adminOrderItemAssetsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOrderItemAssetsApiFp(this.configuration).adminOrderItemAssetsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderItemAssetsApi
     */
    public adminOrderItemAssetsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrderItemAssetsApiFp(this.configuration).adminOrderItemAssetsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {AdminOrderItemAssetsIdDeleteRequest} [adminOrderItemAssetsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderItemAssetsApi
     */
    public adminOrderItemAssetsIdPut(id: string, adminOrderItemAssetsIdDeleteRequest?: AdminOrderItemAssetsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminOrderItemAssetsApiFp(this.configuration).adminOrderItemAssetsIdPut(id, adminOrderItemAssetsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {AdminOrderItemAssetsGetRequest} [adminOrderItemAssetsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderItemAssetsApi
     */
    public adminOrderItemAssetsPost(adminOrderItemAssetsGetRequest?: AdminOrderItemAssetsGetRequest, options?: AxiosRequestConfig) {
        return AdminOrderItemAssetsApiFp(this.configuration).adminOrderItemAssetsPost(adminOrderItemAssetsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderItemAssetsApi
     */
    public adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(id: string, options?: AxiosRequestConfig) {
        return AdminOrderItemAssetsApiFp(this.configuration).adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderPacksApi - axios parameter creator
 * @export
 */
export const AdminOrderPacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksGet: async (pageIndex: number, pageSize: number, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrderPacksGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderPacksGet', 'pageSize', pageSize)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderPacksGet', 'orderId', orderId)
            const localVarPath = `/Admin/OrderPacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} [factoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksGetAssetArchiveForOrderPackIdGet: async (id: string, factoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPacksGetAssetArchiveForOrderPackIdGet', 'id', id)
            const localVarPath = `/Admin/OrderPacks/GetAssetArchiveForOrderPack/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (factoryId !== undefined) {
                localVarQueryParameter['factoryId'] = factoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet', 'id', id)
            const localVarPath = `/Admin/OrderPacks/GetOrderPackUploadedFilesArchive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPacksIdDelete', 'id', id)
            const localVarPath = `/Admin/OrderPacks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} [factoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksIdGet: async (id: string, factoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPacksIdGet', 'id', id)
            const localVarPath = `/Admin/OrderPacks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (factoryId !== undefined) {
                localVarQueryParameter['factoryId'] = factoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} orderId 
         * @param {string} [notesToCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksIdPut: async (id: string, orderId: string, notesToCustomer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPacksIdPut', 'id', id)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderPacksIdPut', 'orderId', orderId)
            const localVarPath = `/Admin/OrderPacks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (notesToCustomer !== undefined) {
                localVarQueryParameter['NotesToCustomer'] = notesToCustomer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {string} [notesToCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksPost: async (orderId: string, notesToCustomer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderPacksPost', 'orderId', orderId)
            const localVarPath = `/Admin/OrderPacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (notesToCustomer !== undefined) {
                localVarQueryParameter['NotesToCustomer'] = notesToCustomer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksSendOrderPackToCustomerOrderPackIdPost: async (orderPackId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPackId' is not null or undefined
            assertParamExists('adminOrderPacksSendOrderPackToCustomerOrderPackIdPost', 'orderPackId', orderPackId)
            const localVarPath = `/Admin/OrderPacks/SendOrderPackToCustomer/{orderPackId}`
                .replace(`{${"orderPackId"}}`, encodeURIComponent(String(orderPackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderPacksApi - functional programming interface
 * @export
 */
export const AdminOrderPacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderPacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksGet(pageIndex: number, pageSize: number, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPackAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksGet(pageIndex, pageSize, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} [factoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksGetAssetArchiveForOrderPackIdGet(id: string, factoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksGetAssetArchiveForOrderPackIdGet(id, factoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} [factoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksIdGet(id: string, factoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPackAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksIdGet(id, factoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} orderId 
         * @param {string} [notesToCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksIdPut(id: string, orderId: string, notesToCustomer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPackAdminListViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksIdPut(id, orderId, notesToCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {string} [notesToCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksPost(orderId: string, notesToCustomer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPackAdminListViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksPost(orderId, notesToCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(orderPackId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(orderPackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderPacksApi - factory interface
 * @export
 */
export const AdminOrderPacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderPacksApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksGet(pageIndex: number, pageSize: number, orderId: string, options?: any): AxiosPromise<OrderPackAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrderPacksGet(pageIndex, pageSize, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} [factoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksGetAssetArchiveForOrderPackIdGet(id: string, factoryId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminOrderPacksGetAssetArchiveForOrderPackIdGet(id, factoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderPacksIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} [factoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksIdGet(id: string, factoryId?: string, options?: any): AxiosPromise<OrderPackAdminViewModelApplicationResult> {
            return localVarFp.adminOrderPacksIdGet(id, factoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {string} orderId 
         * @param {string} [notesToCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksIdPut(id: string, orderId: string, notesToCustomer?: string, options?: any): AxiosPromise<OrderPackAdminListViewModelApplicationResult> {
            return localVarFp.adminOrderPacksIdPut(id, orderId, notesToCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {string} [notesToCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksPost(orderId: string, notesToCustomer?: string, options?: any): AxiosPromise<OrderPackAdminListViewModelApplicationResult> {
            return localVarFp.adminOrderPacksPost(orderId, notesToCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(orderPackId: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(orderPackId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderPacksApi - object-oriented interface
 * @export
 * @class AdminOrderPacksApi
 * @extends {BaseAPI}
 */
export class AdminOrderPacksApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksGet(pageIndex: number, pageSize: number, orderId: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksGet(pageIndex, pageSize, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {string} [factoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksGetAssetArchiveForOrderPackIdGet(id: string, factoryId?: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksGetAssetArchiveForOrderPackIdGet(id, factoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {string} [factoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksIdGet(id: string, factoryId?: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksIdGet(id, factoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {string} orderId 
     * @param {string} [notesToCustomer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksIdPut(id: string, orderId: string, notesToCustomer?: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksIdPut(id, orderId, notesToCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderId 
     * @param {string} [notesToCustomer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksPost(orderId: string, notesToCustomer?: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksPost(orderId, notesToCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderPackId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPacksApi
     */
    public adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(orderPackId: string, options?: AxiosRequestConfig) {
        return AdminOrderPacksApiFp(this.configuration).adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(orderPackId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderPaymentsApi - axios parameter creator
 * @export
 */
export const AdminOrderPaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {PaymentProvider} [paymentProviderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsGet: async (pageIndex: number, pageSize: number, orderId: string, paymentProviderId?: PaymentProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrderPaymentsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderPaymentsGet', 'pageSize', pageSize)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrderPaymentsGet', 'orderId', orderId)
            const localVarPath = `/Admin/OrderPayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (paymentProviderId !== undefined) {
                localVarQueryParameter['paymentProviderId'] = paymentProviderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPaymentsIdDelete', 'id', id)
            const localVarPath = `/Admin/OrderPayments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPaymentsIdGet', 'id', id)
            const localVarPath = `/Admin/OrderPayments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {string} id 
         * @param {AdminOrderPaymentsIdDeleteRequest} [adminOrderPaymentsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsIdPut: async (id: string, adminOrderPaymentsIdDeleteRequest?: AdminOrderPaymentsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrderPaymentsIdPut', 'id', id)
            const localVarPath = `/Admin/OrderPayments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderPaymentsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {AdminOrderPaymentsGetRequest} [adminOrderPaymentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsPost: async (adminOrderPaymentsGetRequest?: AdminOrderPaymentsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OrderPayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrderPaymentsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderPaymentsApi - functional programming interface
 * @export
 */
export const AdminOrderPaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderPaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {PaymentProvider} [paymentProviderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPaymentsGet(pageIndex: number, pageSize: number, orderId: string, paymentProviderId?: PaymentProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPaymentsGet(pageIndex, pageSize, orderId, paymentProviderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPaymentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPaymentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPaymentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPaymentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {string} id 
         * @param {AdminOrderPaymentsIdDeleteRequest} [adminOrderPaymentsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPaymentsIdPut(id: string, adminOrderPaymentsIdDeleteRequest?: AdminOrderPaymentsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPaymentsIdPut(id, adminOrderPaymentsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {AdminOrderPaymentsGetRequest} [adminOrderPaymentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderPaymentsPost(adminOrderPaymentsGetRequest?: AdminOrderPaymentsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderPaymentsPost(adminOrderPaymentsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderPaymentsApi - factory interface
 * @export
 */
export const AdminOrderPaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderPaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} orderId 
         * @param {PaymentProvider} [paymentProviderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsGet(pageIndex: number, pageSize: number, orderId: string, paymentProviderId?: PaymentProvider, options?: any): AxiosPromise<OrderPaymentAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrderPaymentsGet(pageIndex, pageSize, orderId, paymentProviderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrderPaymentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsIdGet(id: string, options?: any): AxiosPromise<OrderPaymentAdminViewModelApplicationResult> {
            return localVarFp.adminOrderPaymentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {string} id 
         * @param {AdminOrderPaymentsIdDeleteRequest} [adminOrderPaymentsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsIdPut(id: string, adminOrderPaymentsIdDeleteRequest?: AdminOrderPaymentsIdDeleteRequest, options?: any): AxiosPromise<OrderPaymentAdminViewModelApplicationResult> {
            return localVarFp.adminOrderPaymentsIdPut(id, adminOrderPaymentsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
         * @param {AdminOrderPaymentsGetRequest} [adminOrderPaymentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderPaymentsPost(adminOrderPaymentsGetRequest?: AdminOrderPaymentsGetRequest, options?: any): AxiosPromise<OrderPaymentAdminViewModelApplicationResult> {
            return localVarFp.adminOrderPaymentsPost(adminOrderPaymentsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderPaymentsApi - object-oriented interface
 * @export
 * @class AdminOrderPaymentsApi
 * @extends {BaseAPI}
 */
export class AdminOrderPaymentsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} orderId 
     * @param {PaymentProvider} [paymentProviderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPaymentsApi
     */
    public adminOrderPaymentsGet(pageIndex: number, pageSize: number, orderId: string, paymentProviderId?: PaymentProvider, options?: AxiosRequestConfig) {
        return AdminOrderPaymentsApiFp(this.configuration).adminOrderPaymentsGet(pageIndex, pageSize, orderId, paymentProviderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPaymentsApi
     */
    public adminOrderPaymentsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOrderPaymentsApiFp(this.configuration).adminOrderPaymentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPaymentsApi
     */
    public adminOrderPaymentsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrderPaymentsApiFp(this.configuration).adminOrderPaymentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
     * @param {string} id 
     * @param {AdminOrderPaymentsIdDeleteRequest} [adminOrderPaymentsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPaymentsApi
     */
    public adminOrderPaymentsIdPut(id: string, adminOrderPaymentsIdDeleteRequest?: AdminOrderPaymentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminOrderPaymentsApiFp(this.configuration).adminOrderPaymentsIdPut(id, adminOrderPaymentsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageBankTransfers, ManageOrders)
     * @param {AdminOrderPaymentsGetRequest} [adminOrderPaymentsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderPaymentsApi
     */
    public adminOrderPaymentsPost(adminOrderPaymentsGetRequest?: AdminOrderPaymentsGetRequest, options?: AxiosRequestConfig) {
        return AdminOrderPaymentsApiFp(this.configuration).adminOrderPaymentsPost(adminOrderPaymentsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrdersApi - axios parameter creator
 * @export
 */
export const AdminOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersAssetArchiveDownloadTokenGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminOrdersAssetArchiveDownloadTokenGet', 'token', token)
            const localVarPath = `/Admin/Orders/AssetArchiveDownload/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrdersCreateOrderQuotePostRequest} [adminOrdersCreateOrderQuotePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersCreateOrderQuotePost: async (adminOrdersCreateOrderQuotePostRequest?: AdminOrdersCreateOrderQuotePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Orders/CreateOrderQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrdersCreateOrderQuotePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} recalculateOrderTotal 
         * @param {boolean} includePaymentId 
         * @param {Array<OrderStatus>} [orderStatusIds] 
         * @param {string} [searchTerm] 
         * @param {string} [orderPlacementStartDate] 
         * @param {string} [orderPlacementEndDate] 
         * @param {string} [expectedDeliveryStartDate] 
         * @param {string} [expectedDeliveryEndDate] 
         * @param {string} [enquiryId] 
         * @param {string} [factoryId] 
         * @param {boolean} [exWorksApproved] 
         * @param {OrderSortColumn} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {boolean} [isQuote] 
         * @param {boolean} [isStoreOrder] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGet: async (pageIndex: number, pageSize: number, recalculateOrderTotal: boolean, includePaymentId: boolean, orderStatusIds?: Array<OrderStatus>, searchTerm?: string, orderPlacementStartDate?: string, orderPlacementEndDate?: string, expectedDeliveryStartDate?: string, expectedDeliveryEndDate?: string, enquiryId?: string, factoryId?: string, exWorksApproved?: boolean, sortColumn?: OrderSortColumn, sortDirection?: SortDirection, isQuote?: boolean, isStoreOrder?: boolean, orderManagerId?: string, salesManagerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOrdersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrdersGet', 'pageSize', pageSize)
            // verify required parameter 'recalculateOrderTotal' is not null or undefined
            assertParamExists('adminOrdersGet', 'recalculateOrderTotal', recalculateOrderTotal)
            // verify required parameter 'includePaymentId' is not null or undefined
            assertParamExists('adminOrdersGet', 'includePaymentId', includePaymentId)
            const localVarPath = `/Admin/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderStatusIds) {
                localVarQueryParameter['orderStatusIds'] = orderStatusIds;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (orderPlacementStartDate !== undefined) {
                localVarQueryParameter['orderPlacementStartDate'] = (orderPlacementStartDate as any instanceof Date) ?
                    (orderPlacementStartDate as any).toISOString() :
                    orderPlacementStartDate;
            }

            if (orderPlacementEndDate !== undefined) {
                localVarQueryParameter['orderPlacementEndDate'] = (orderPlacementEndDate as any instanceof Date) ?
                    (orderPlacementEndDate as any).toISOString() :
                    orderPlacementEndDate;
            }

            if (expectedDeliveryStartDate !== undefined) {
                localVarQueryParameter['expectedDeliveryStartDate'] = (expectedDeliveryStartDate as any instanceof Date) ?
                    (expectedDeliveryStartDate as any).toISOString() :
                    expectedDeliveryStartDate;
            }

            if (expectedDeliveryEndDate !== undefined) {
                localVarQueryParameter['expectedDeliveryEndDate'] = (expectedDeliveryEndDate as any instanceof Date) ?
                    (expectedDeliveryEndDate as any).toISOString() :
                    expectedDeliveryEndDate;
            }

            if (enquiryId !== undefined) {
                localVarQueryParameter['enquiryId'] = enquiryId;
            }

            if (factoryId !== undefined) {
                localVarQueryParameter['factoryId'] = factoryId;
            }

            if (exWorksApproved !== undefined) {
                localVarQueryParameter['exWorksApproved'] = exWorksApproved;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (isQuote !== undefined) {
                localVarQueryParameter['isQuote'] = isQuote;
            }

            if (recalculateOrderTotal !== undefined) {
                localVarQueryParameter['recalculateOrderTotal'] = recalculateOrderTotal;
            }

            if (includePaymentId !== undefined) {
                localVarQueryParameter['includePaymentId'] = includePaymentId;
            }

            if (isStoreOrder !== undefined) {
                localVarQueryParameter['isStoreOrder'] = isStoreOrder;
            }

            if (orderManagerId !== undefined) {
                localVarQueryParameter['orderManagerId'] = orderManagerId;
            }

            if (salesManagerId !== undefined) {
                localVarQueryParameter['salesManagerId'] = salesManagerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetAvailableOrderStatusesForOrderIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersGetAvailableOrderStatusesForOrderIdGet', 'id', id)
            const localVarPath = `/Admin/Orders/GetAvailableOrderStatusesForOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetConvertedOrderBalanceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersGetConvertedOrderBalanceIdGet', 'id', id)
            const localVarPath = `/Admin/Orders/GetConvertedOrderBalance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetOrderStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Orders/GetOrderStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetPaymentProvidersForOrderOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrdersGetPaymentProvidersForOrderOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Admin/Orders/GetPaymentProvidersForOrder/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetStatusGraphIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersGetStatusGraphIdGet', 'id', id)
            const localVarPath = `/Admin/Orders/GetStatusGraph/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersIdGet', 'id', id)
            const localVarPath = `/Admin/Orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrdersIdGetRequest} [adminOrdersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersIdPut: async (id: string, adminOrdersIdGetRequest?: AdminOrdersIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersIdPut', 'id', id)
            const localVarPath = `/Admin/Orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOrdersIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersOrderPackFactoryTokenGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('adminOrdersOrderPackFactoryTokenGet', 'token', token)
            const localVarPath = `/Admin/Orders/OrderPackFactory/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderQuoteId 
         * @param {string} customerEmail 
         * @param {string} customerName 
         * @param {string} customerPhoneNumber 
         * @param {Array<PaymentProvider>} allowedPaymentProviders 
         * @param {string} [quoteMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendOrderQuoteOrderQuoteIdPost: async (orderQuoteId: string, customerEmail: string, customerName: string, customerPhoneNumber: string, allowedPaymentProviders: Array<PaymentProvider>, quoteMessage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderQuoteId' is not null or undefined
            assertParamExists('adminOrdersSendOrderQuoteOrderQuoteIdPost', 'orderQuoteId', orderQuoteId)
            // verify required parameter 'customerEmail' is not null or undefined
            assertParamExists('adminOrdersSendOrderQuoteOrderQuoteIdPost', 'customerEmail', customerEmail)
            // verify required parameter 'customerName' is not null or undefined
            assertParamExists('adminOrdersSendOrderQuoteOrderQuoteIdPost', 'customerName', customerName)
            // verify required parameter 'customerPhoneNumber' is not null or undefined
            assertParamExists('adminOrdersSendOrderQuoteOrderQuoteIdPost', 'customerPhoneNumber', customerPhoneNumber)
            // verify required parameter 'allowedPaymentProviders' is not null or undefined
            assertParamExists('adminOrdersSendOrderQuoteOrderQuoteIdPost', 'allowedPaymentProviders', allowedPaymentProviders)
            const localVarPath = `/Admin/Orders/SendOrderQuote/{orderQuoteId}`
                .replace(`{${"orderQuoteId"}}`, encodeURIComponent(String(orderQuoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerEmail !== undefined) {
                localVarQueryParameter['customerEmail'] = customerEmail;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['customerName'] = customerName;
            }

            if (customerPhoneNumber !== undefined) {
                localVarQueryParameter['customerPhoneNumber'] = customerPhoneNumber;
            }

            if (quoteMessage !== undefined) {
                localVarQueryParameter['quoteMessage'] = quoteMessage;
            }

            if (allowedPaymentProviders) {
                localVarQueryParameter['allowedPaymentProviders'] = allowedPaymentProviders;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {string} internalReason 
         * @param {string} externalReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendOutstandingPaymentEmailOrderIdPost: async (orderId: string, internalReason: string, externalReason: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrdersSendOutstandingPaymentEmailOrderIdPost', 'orderId', orderId)
            // verify required parameter 'internalReason' is not null or undefined
            assertParamExists('adminOrdersSendOutstandingPaymentEmailOrderIdPost', 'internalReason', internalReason)
            // verify required parameter 'externalReason' is not null or undefined
            assertParamExists('adminOrdersSendOutstandingPaymentEmailOrderIdPost', 'externalReason', externalReason)
            const localVarPath = `/Admin/Orders/SendOutstandingPaymentEmail/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (internalReason !== undefined) {
                localVarQueryParameter['internalReason'] = internalReason;
            }

            if (externalReason !== undefined) {
                localVarQueryParameter['externalReason'] = externalReason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendQuoteReminderOrderIdPost: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminOrdersSendQuoteReminderOrderIdPost', 'orderId', orderId)
            const localVarPath = `/Admin/Orders/SendQuoteReminder/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendResponeReminderIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOrdersSendResponeReminderIdPost', 'id', id)
            const localVarPath = `/Admin/Orders/SendResponeReminder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrdersApi - functional programming interface
 * @export
 */
export const AdminOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersAssetArchiveDownloadTokenGet(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersAssetArchiveDownloadTokenGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrdersCreateOrderQuotePostRequest} [adminOrdersCreateOrderQuotePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersCreateOrderQuotePost(adminOrdersCreateOrderQuotePostRequest?: AdminOrdersCreateOrderQuotePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersCreateOrderQuotePost(adminOrdersCreateOrderQuotePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} recalculateOrderTotal 
         * @param {boolean} includePaymentId 
         * @param {Array<OrderStatus>} [orderStatusIds] 
         * @param {string} [searchTerm] 
         * @param {string} [orderPlacementStartDate] 
         * @param {string} [orderPlacementEndDate] 
         * @param {string} [expectedDeliveryStartDate] 
         * @param {string} [expectedDeliveryEndDate] 
         * @param {string} [enquiryId] 
         * @param {string} [factoryId] 
         * @param {boolean} [exWorksApproved] 
         * @param {OrderSortColumn} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {boolean} [isQuote] 
         * @param {boolean} [isStoreOrder] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersGet(pageIndex: number, pageSize: number, recalculateOrderTotal: boolean, includePaymentId: boolean, orderStatusIds?: Array<OrderStatus>, searchTerm?: string, orderPlacementStartDate?: string, orderPlacementEndDate?: string, expectedDeliveryStartDate?: string, expectedDeliveryEndDate?: string, enquiryId?: string, factoryId?: string, exWorksApproved?: boolean, sortColumn?: OrderSortColumn, sortDirection?: SortDirection, isQuote?: boolean, isStoreOrder?: boolean, orderManagerId?: string, salesManagerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersGet(pageIndex, pageSize, recalculateOrderTotal, includePaymentId, orderStatusIds, searchTerm, orderPlacementStartDate, orderPlacementEndDate, expectedDeliveryStartDate, expectedDeliveryEndDate, enquiryId, factoryId, exWorksApproved, sortColumn, sortDirection, isQuote, isStoreOrder, orderManagerId, salesManagerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersGetAvailableOrderStatusesForOrderIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableOrderStatusViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersGetAvailableOrderStatusesForOrderIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersGetConvertedOrderBalanceIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DecimalApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersGetConvertedOrderBalanceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersGetOrderStatusesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatusListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersGetOrderStatusesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersGetPaymentProvidersForOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProviderListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersGetPaymentProvidersForOrderOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersGetStatusGraphIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersGetStatusGraphIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrdersIdGetRequest} [adminOrdersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersIdPut(id: string, adminOrdersIdGetRequest?: AdminOrdersIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersIdPut(id, adminOrdersIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersOrderPackFactoryTokenGet(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPackAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersOrderPackFactoryTokenGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderQuoteId 
         * @param {string} customerEmail 
         * @param {string} customerName 
         * @param {string} customerPhoneNumber 
         * @param {Array<PaymentProvider>} allowedPaymentProviders 
         * @param {string} [quoteMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersSendOrderQuoteOrderQuoteIdPost(orderQuoteId: string, customerEmail: string, customerName: string, customerPhoneNumber: string, allowedPaymentProviders: Array<PaymentProvider>, quoteMessage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersSendOrderQuoteOrderQuoteIdPost(orderQuoteId, customerEmail, customerName, customerPhoneNumber, allowedPaymentProviders, quoteMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {string} internalReason 
         * @param {string} externalReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersSendOutstandingPaymentEmailOrderIdPost(orderId: string, internalReason: string, externalReason: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersSendOutstandingPaymentEmailOrderIdPost(orderId, internalReason, externalReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersSendQuoteReminderOrderIdPost(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersSendQuoteReminderOrderIdPost(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrdersSendResponeReminderIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrdersSendResponeReminderIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrdersApi - factory interface
 * @export
 */
export const AdminOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersAssetArchiveDownloadTokenGet(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminOrdersAssetArchiveDownloadTokenGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminOrdersCreateOrderQuotePostRequest} [adminOrdersCreateOrderQuotePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersCreateOrderQuotePost(adminOrdersCreateOrderQuotePostRequest?: AdminOrdersCreateOrderQuotePostRequest, options?: any): AxiosPromise<OrderCartViewModelApplicationResult> {
            return localVarFp.adminOrdersCreateOrderQuotePost(adminOrdersCreateOrderQuotePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} recalculateOrderTotal 
         * @param {boolean} includePaymentId 
         * @param {Array<OrderStatus>} [orderStatusIds] 
         * @param {string} [searchTerm] 
         * @param {string} [orderPlacementStartDate] 
         * @param {string} [orderPlacementEndDate] 
         * @param {string} [expectedDeliveryStartDate] 
         * @param {string} [expectedDeliveryEndDate] 
         * @param {string} [enquiryId] 
         * @param {string} [factoryId] 
         * @param {boolean} [exWorksApproved] 
         * @param {OrderSortColumn} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {boolean} [isQuote] 
         * @param {boolean} [isStoreOrder] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGet(pageIndex: number, pageSize: number, recalculateOrderTotal: boolean, includePaymentId: boolean, orderStatusIds?: Array<OrderStatus>, searchTerm?: string, orderPlacementStartDate?: string, orderPlacementEndDate?: string, expectedDeliveryStartDate?: string, expectedDeliveryEndDate?: string, enquiryId?: string, factoryId?: string, exWorksApproved?: boolean, sortColumn?: OrderSortColumn, sortDirection?: SortDirection, isQuote?: boolean, isStoreOrder?: boolean, orderManagerId?: string, salesManagerId?: string, options?: any): AxiosPromise<OrderAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOrdersGet(pageIndex, pageSize, recalculateOrderTotal, includePaymentId, orderStatusIds, searchTerm, orderPlacementStartDate, orderPlacementEndDate, expectedDeliveryStartDate, expectedDeliveryEndDate, enquiryId, factoryId, exWorksApproved, sortColumn, sortDirection, isQuote, isStoreOrder, orderManagerId, salesManagerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetAvailableOrderStatusesForOrderIdGet(id: string, options?: any): AxiosPromise<AvailableOrderStatusViewModelListApplicationResult> {
            return localVarFp.adminOrdersGetAvailableOrderStatusesForOrderIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetConvertedOrderBalanceIdGet(id: string, options?: any): AxiosPromise<DecimalApplicationResult> {
            return localVarFp.adminOrdersGetConvertedOrderBalanceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetOrderStatusesGet(options?: any): AxiosPromise<OrderStatusListViewModelListApplicationResult> {
            return localVarFp.adminOrdersGetOrderStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetPaymentProvidersForOrderOrderIdGet(orderId: string, options?: any): AxiosPromise<PaymentProviderListApplicationResult> {
            return localVarFp.adminOrdersGetPaymentProvidersForOrderOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersGetStatusGraphIdGet(id: string, options?: any): AxiosPromise<StringApplicationResult> {
            return localVarFp.adminOrdersGetStatusGraphIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersIdGet(id: string, options?: any): AxiosPromise<OrderAdminViewModelApplicationResult> {
            return localVarFp.adminOrdersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminOrdersIdGetRequest} [adminOrdersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersIdPut(id: string, adminOrdersIdGetRequest?: AdminOrdersIdGetRequest, options?: any): AxiosPromise<OrderAdminViewModelApplicationResult> {
            return localVarFp.adminOrdersIdPut(id, adminOrdersIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersOrderPackFactoryTokenGet(token: string, options?: any): AxiosPromise<OrderPackAdminViewModelApplicationResult> {
            return localVarFp.adminOrdersOrderPackFactoryTokenGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderQuoteId 
         * @param {string} customerEmail 
         * @param {string} customerName 
         * @param {string} customerPhoneNumber 
         * @param {Array<PaymentProvider>} allowedPaymentProviders 
         * @param {string} [quoteMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendOrderQuoteOrderQuoteIdPost(orderQuoteId: string, customerEmail: string, customerName: string, customerPhoneNumber: string, allowedPaymentProviders: Array<PaymentProvider>, quoteMessage?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrdersSendOrderQuoteOrderQuoteIdPost(orderQuoteId, customerEmail, customerName, customerPhoneNumber, allowedPaymentProviders, quoteMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {string} internalReason 
         * @param {string} externalReason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendOutstandingPaymentEmailOrderIdPost(orderId: string, internalReason: string, externalReason: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrdersSendOutstandingPaymentEmailOrderIdPost(orderId, internalReason, externalReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendQuoteReminderOrderIdPost(orderId: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrdersSendQuoteReminderOrderIdPost(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrdersSendResponeReminderIdPost(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOrdersSendResponeReminderIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrdersApi - object-oriented interface
 * @export
 * @class AdminOrdersApi
 * @extends {BaseAPI}
 */
export class AdminOrdersApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersAssetArchiveDownloadTokenGet(token: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersAssetArchiveDownloadTokenGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {AdminOrdersCreateOrderQuotePostRequest} [adminOrdersCreateOrderQuotePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersCreateOrderQuotePost(adminOrdersCreateOrderQuotePostRequest?: AdminOrdersCreateOrderQuotePostRequest, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersCreateOrderQuotePost(adminOrdersCreateOrderQuotePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {boolean} recalculateOrderTotal 
     * @param {boolean} includePaymentId 
     * @param {Array<OrderStatus>} [orderStatusIds] 
     * @param {string} [searchTerm] 
     * @param {string} [orderPlacementStartDate] 
     * @param {string} [orderPlacementEndDate] 
     * @param {string} [expectedDeliveryStartDate] 
     * @param {string} [expectedDeliveryEndDate] 
     * @param {string} [enquiryId] 
     * @param {string} [factoryId] 
     * @param {boolean} [exWorksApproved] 
     * @param {OrderSortColumn} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {boolean} [isQuote] 
     * @param {boolean} [isStoreOrder] 
     * @param {string} [orderManagerId] 
     * @param {string} [salesManagerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersGet(pageIndex: number, pageSize: number, recalculateOrderTotal: boolean, includePaymentId: boolean, orderStatusIds?: Array<OrderStatus>, searchTerm?: string, orderPlacementStartDate?: string, orderPlacementEndDate?: string, expectedDeliveryStartDate?: string, expectedDeliveryEndDate?: string, enquiryId?: string, factoryId?: string, exWorksApproved?: boolean, sortColumn?: OrderSortColumn, sortDirection?: SortDirection, isQuote?: boolean, isStoreOrder?: boolean, orderManagerId?: string, salesManagerId?: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersGet(pageIndex, pageSize, recalculateOrderTotal, includePaymentId, orderStatusIds, searchTerm, orderPlacementStartDate, orderPlacementEndDate, expectedDeliveryStartDate, expectedDeliveryEndDate, enquiryId, factoryId, exWorksApproved, sortColumn, sortDirection, isQuote, isStoreOrder, orderManagerId, salesManagerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersGetAvailableOrderStatusesForOrderIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersGetAvailableOrderStatusesForOrderIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersGetConvertedOrderBalanceIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersGetConvertedOrderBalanceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersGetOrderStatusesGet(options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersGetOrderStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersGetPaymentProvidersForOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersGetPaymentProvidersForOrderOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersGetStatusGraphIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersGetStatusGraphIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {AdminOrdersIdGetRequest} [adminOrdersIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersIdPut(id: string, adminOrdersIdGetRequest?: AdminOrdersIdGetRequest, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersIdPut(id, adminOrdersIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersOrderPackFactoryTokenGet(token: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersOrderPackFactoryTokenGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderQuoteId 
     * @param {string} customerEmail 
     * @param {string} customerName 
     * @param {string} customerPhoneNumber 
     * @param {Array<PaymentProvider>} allowedPaymentProviders 
     * @param {string} [quoteMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersSendOrderQuoteOrderQuoteIdPost(orderQuoteId: string, customerEmail: string, customerName: string, customerPhoneNumber: string, allowedPaymentProviders: Array<PaymentProvider>, quoteMessage?: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersSendOrderQuoteOrderQuoteIdPost(orderQuoteId, customerEmail, customerName, customerPhoneNumber, allowedPaymentProviders, quoteMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderId 
     * @param {string} internalReason 
     * @param {string} externalReason 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersSendOutstandingPaymentEmailOrderIdPost(orderId: string, internalReason: string, externalReason: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersSendOutstandingPaymentEmailOrderIdPost(orderId, internalReason, externalReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersSendQuoteReminderOrderIdPost(orderId: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersSendQuoteReminderOrderIdPost(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public adminOrdersSendResponeReminderIdPost(id: string, options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).adminOrdersSendResponeReminderIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOverlayGroupsApi - axios parameter creator
 * @export
 */
export const AdminOverlayGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOverlayGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOverlayGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/OverlayGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (isSystemManaged !== undefined) {
                localVarQueryParameter['isSystemManaged'] = isSystemManaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/OverlayGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/OverlayGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousOverlayGroupId] 
         * @param {string} [nextOverlayGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdPatch: async (id: string, previousOverlayGroupId?: string, nextOverlayGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/OverlayGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousOverlayGroupId !== undefined) {
                localVarQueryParameter['previousOverlayGroupId'] = previousOverlayGroupId;
            }

            if (nextOverlayGroupId !== undefined) {
                localVarQueryParameter['nextOverlayGroupId'] = nextOverlayGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminOverlayGroupsIdDeleteRequest} [adminOverlayGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdPut: async (id: string, adminOverlayGroupsIdDeleteRequest?: AdminOverlayGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/OverlayGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOverlayGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminOverlayGroupsGetRequest} [adminOverlayGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsPost: async (adminOverlayGroupsGetRequest?: AdminOverlayGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OverlayGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOverlayGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOverlayGroupsApi - functional programming interface
 * @export
 */
export const AdminOverlayGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOverlayGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousOverlayGroupId] 
         * @param {string} [nextOverlayGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayGroupsIdPatch(id: string, previousOverlayGroupId?: string, nextOverlayGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayGroupsIdPatch(id, previousOverlayGroupId, nextOverlayGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminOverlayGroupsIdDeleteRequest} [adminOverlayGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayGroupsIdPut(id: string, adminOverlayGroupsIdDeleteRequest?: AdminOverlayGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayGroupsIdPut(id, adminOverlayGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminOverlayGroupsGetRequest} [adminOverlayGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayGroupsPost(adminOverlayGroupsGetRequest?: AdminOverlayGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayGroupsPost(adminOverlayGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOverlayGroupsApi - factory interface
 * @export
 */
export const AdminOverlayGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOverlayGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: any): AxiosPromise<OverlayGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOverlayGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOverlayGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdGet(id: string, options?: any): AxiosPromise<OverlayGroupAdminViewModelApplicationResult> {
            return localVarFp.adminOverlayGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousOverlayGroupId] 
         * @param {string} [nextOverlayGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdPatch(id: string, previousOverlayGroupId?: string, nextOverlayGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOverlayGroupsIdPatch(id, previousOverlayGroupId, nextOverlayGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminOverlayGroupsIdDeleteRequest} [adminOverlayGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsIdPut(id: string, adminOverlayGroupsIdDeleteRequest?: AdminOverlayGroupsIdDeleteRequest, options?: any): AxiosPromise<OverlayGroupAdminViewModelApplicationResult> {
            return localVarFp.adminOverlayGroupsIdPut(id, adminOverlayGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminOverlayGroupsGetRequest} [adminOverlayGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayGroupsPost(adminOverlayGroupsGetRequest?: AdminOverlayGroupsGetRequest, options?: any): AxiosPromise<OverlayGroupAdminViewModelApplicationResult> {
            return localVarFp.adminOverlayGroupsPost(adminOverlayGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOverlayGroupsApi - object-oriented interface
 * @export
 * @class AdminOverlayGroupsApi
 * @extends {BaseAPI}
 */
export class AdminOverlayGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [isSystemManaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayGroupsApi
     */
    public adminOverlayGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig) {
        return AdminOverlayGroupsApiFp(this.configuration).adminOverlayGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayGroupsApi
     */
    public adminOverlayGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOverlayGroupsApiFp(this.configuration).adminOverlayGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayGroupsApi
     */
    public adminOverlayGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOverlayGroupsApiFp(this.configuration).adminOverlayGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousOverlayGroupId] 
     * @param {string} [nextOverlayGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayGroupsApi
     */
    public adminOverlayGroupsIdPatch(id: string, previousOverlayGroupId?: string, nextOverlayGroupId?: string, options?: AxiosRequestConfig) {
        return AdminOverlayGroupsApiFp(this.configuration).adminOverlayGroupsIdPatch(id, previousOverlayGroupId, nextOverlayGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminOverlayGroupsIdDeleteRequest} [adminOverlayGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayGroupsApi
     */
    public adminOverlayGroupsIdPut(id: string, adminOverlayGroupsIdDeleteRequest?: AdminOverlayGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminOverlayGroupsApiFp(this.configuration).adminOverlayGroupsIdPut(id, adminOverlayGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminOverlayGroupsGetRequest} [adminOverlayGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayGroupsApi
     */
    public adminOverlayGroupsPost(adminOverlayGroupsGetRequest?: AdminOverlayGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminOverlayGroupsApiFp(this.configuration).adminOverlayGroupsPost(adminOverlayGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOverlayPricesApi - axios parameter creator
 * @export
 */
export const AdminOverlayPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOverlayPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOverlayPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminOverlayPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/OverlayPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/OverlayPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayPricesIdGet', 'id', id)
            const localVarPath = `/Admin/OverlayPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlayPricesIdPut', 'id', id)
            const localVarPath = `/Admin/OverlayPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/OverlayPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOverlayPricesApi - functional programming interface
 * @export
 */
export const AdminOverlayPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOverlayPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlayPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlayPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOverlayPricesApi - factory interface
 * @export
 */
export const AdminOverlayPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOverlayPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOverlayPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOverlayPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminOverlayPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminOverlayPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlayPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminOverlayPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOverlayPricesApi - object-oriented interface
 * @export
 * @class AdminOverlayPricesApi
 * @extends {BaseAPI}
 */
export class AdminOverlayPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayPricesApi
     */
    public adminOverlayPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminOverlayPricesApiFp(this.configuration).adminOverlayPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayPricesApi
     */
    public adminOverlayPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOverlayPricesApiFp(this.configuration).adminOverlayPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayPricesApi
     */
    public adminOverlayPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOverlayPricesApiFp(this.configuration).adminOverlayPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayPricesApi
     */
    public adminOverlayPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminOverlayPricesApiFp(this.configuration).adminOverlayPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlayPricesApi
     */
    public adminOverlayPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminOverlayPricesApiFp(this.configuration).adminOverlayPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOverlaysApi - axios parameter creator
 * @export
 */
export const AdminOverlaysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminOverlaysGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOverlaysGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Overlays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlaysIdDelete', 'id', id)
            const localVarPath = `/Admin/Overlays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlaysIdGet', 'id', id)
            const localVarPath = `/Admin/Overlays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousOverlayId] 
         * @param {string} [nextOverlayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdPatch: async (id: string, previousOverlayId?: string, nextOverlayId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlaysIdPatch', 'id', id)
            const localVarPath = `/Admin/Overlays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousOverlayId !== undefined) {
                localVarQueryParameter['previousOverlayId'] = previousOverlayId;
            }

            if (nextOverlayId !== undefined) {
                localVarQueryParameter['nextOverlayId'] = nextOverlayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminOverlaysIdDeleteRequest} [adminOverlaysIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdPut: async (id: string, adminOverlaysIdDeleteRequest?: AdminOverlaysIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminOverlaysIdPut', 'id', id)
            const localVarPath = `/Admin/Overlays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOverlaysIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminOverlaysGetRequest} [adminOverlaysGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysPost: async (adminOverlaysGetRequest?: AdminOverlaysGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Overlays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOverlaysGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOverlaysApi - functional programming interface
 * @export
 */
export const AdminOverlaysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOverlaysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlaysGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlaysGet(pageIndex, pageSize, searchTerm, isDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlaysIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlaysIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlaysIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlaysIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousOverlayId] 
         * @param {string} [nextOverlayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlaysIdPatch(id: string, previousOverlayId?: string, nextOverlayId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlaysIdPatch(id, previousOverlayId, nextOverlayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminOverlaysIdDeleteRequest} [adminOverlaysIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlaysIdPut(id: string, adminOverlaysIdDeleteRequest?: AdminOverlaysIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlaysIdPut(id, adminOverlaysIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminOverlaysGetRequest} [adminOverlaysGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOverlaysPost(adminOverlaysGetRequest?: AdminOverlaysGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverlayAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOverlaysPost(adminOverlaysGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOverlaysApi - factory interface
 * @export
 */
export const AdminOverlaysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOverlaysApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: any): AxiosPromise<OverlayAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminOverlaysGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOverlaysIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdGet(id: string, options?: any): AxiosPromise<OverlayAdminViewModelApplicationResult> {
            return localVarFp.adminOverlaysIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousOverlayId] 
         * @param {string} [nextOverlayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdPatch(id: string, previousOverlayId?: string, nextOverlayId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminOverlaysIdPatch(id, previousOverlayId, nextOverlayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminOverlaysIdDeleteRequest} [adminOverlaysIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysIdPut(id: string, adminOverlaysIdDeleteRequest?: AdminOverlaysIdDeleteRequest, options?: any): AxiosPromise<OverlayAdminViewModelApplicationResult> {
            return localVarFp.adminOverlaysIdPut(id, adminOverlaysIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminOverlaysGetRequest} [adminOverlaysGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOverlaysPost(adminOverlaysGetRequest?: AdminOverlaysGetRequest, options?: any): AxiosPromise<OverlayAdminViewModelApplicationResult> {
            return localVarFp.adminOverlaysPost(adminOverlaysGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOverlaysApi - object-oriented interface
 * @export
 * @class AdminOverlaysApi
 * @extends {BaseAPI}
 */
export class AdminOverlaysApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlaysApi
     */
    public adminOverlaysGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, options?: AxiosRequestConfig) {
        return AdminOverlaysApiFp(this.configuration).adminOverlaysGet(pageIndex, pageSize, searchTerm, isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlaysApi
     */
    public adminOverlaysIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminOverlaysApiFp(this.configuration).adminOverlaysIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlaysApi
     */
    public adminOverlaysIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminOverlaysApiFp(this.configuration).adminOverlaysIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousOverlayId] 
     * @param {string} [nextOverlayId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlaysApi
     */
    public adminOverlaysIdPatch(id: string, previousOverlayId?: string, nextOverlayId?: string, options?: AxiosRequestConfig) {
        return AdminOverlaysApiFp(this.configuration).adminOverlaysIdPatch(id, previousOverlayId, nextOverlayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminOverlaysIdDeleteRequest} [adminOverlaysIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlaysApi
     */
    public adminOverlaysIdPut(id: string, adminOverlaysIdDeleteRequest?: AdminOverlaysIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminOverlaysApiFp(this.configuration).adminOverlaysIdPut(id, adminOverlaysIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminOverlaysGetRequest} [adminOverlaysGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOverlaysApi
     */
    public adminOverlaysPost(adminOverlaysGetRequest?: AdminOverlaysGetRequest, options?: AxiosRequestConfig) {
        return AdminOverlaysApiFp(this.configuration).adminOverlaysPost(adminOverlaysGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPageSeoSettingsApi - axios parameter creator
 * @export
 */
export const AdminPageSeoSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsDelete: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('adminPageSeoSettingsDelete', 'url', url)
            const localVarPath = `/Admin/PageSeoSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminPageSeoSettingsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminPageSeoSettingsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/PageSeoSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminPageSeoSettingsDeleteRequest1} [adminPageSeoSettingsDeleteRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsPost: async (adminPageSeoSettingsDeleteRequest1?: AdminPageSeoSettingsDeleteRequest1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/PageSeoSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPageSeoSettingsDeleteRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {AdminPageSeoSettingsDeleteRequest} [adminPageSeoSettingsDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsPut: async (url: string, adminPageSeoSettingsDeleteRequest?: AdminPageSeoSettingsDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('adminPageSeoSettingsPut', 'url', url)
            const localVarPath = `/Admin/PageSeoSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPageSeoSettingsDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsSingleGet: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('adminPageSeoSettingsSingleGet', 'url', url)
            const localVarPath = `/Admin/PageSeoSettings/single`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPageSeoSettingsApi - functional programming interface
 * @export
 */
export const AdminPageSeoSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPageSeoSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPageSeoSettingsDelete(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPageSeoSettingsDelete(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPageSeoSettingsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeoSettingAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPageSeoSettingsGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminPageSeoSettingsDeleteRequest1} [adminPageSeoSettingsDeleteRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPageSeoSettingsPost(adminPageSeoSettingsDeleteRequest1?: AdminPageSeoSettingsDeleteRequest1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeoSettingAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPageSeoSettingsPost(adminPageSeoSettingsDeleteRequest1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {AdminPageSeoSettingsDeleteRequest} [adminPageSeoSettingsDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPageSeoSettingsPut(url: string, adminPageSeoSettingsDeleteRequest?: AdminPageSeoSettingsDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeoSettingAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPageSeoSettingsPut(url, adminPageSeoSettingsDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPageSeoSettingsSingleGet(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeoSettingAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPageSeoSettingsSingleGet(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPageSeoSettingsApi - factory interface
 * @export
 */
export const AdminPageSeoSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPageSeoSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsDelete(url: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminPageSeoSettingsDelete(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<PageSeoSettingAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminPageSeoSettingsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminPageSeoSettingsDeleteRequest1} [adminPageSeoSettingsDeleteRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsPost(adminPageSeoSettingsDeleteRequest1?: AdminPageSeoSettingsDeleteRequest1, options?: any): AxiosPromise<PageSeoSettingAdminViewModelApplicationResult> {
            return localVarFp.adminPageSeoSettingsPost(adminPageSeoSettingsDeleteRequest1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {AdminPageSeoSettingsDeleteRequest} [adminPageSeoSettingsDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsPut(url: string, adminPageSeoSettingsDeleteRequest?: AdminPageSeoSettingsDeleteRequest, options?: any): AxiosPromise<PageSeoSettingAdminViewModelApplicationResult> {
            return localVarFp.adminPageSeoSettingsPut(url, adminPageSeoSettingsDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPageSeoSettingsSingleGet(url: string, options?: any): AxiosPromise<PageSeoSettingAdminViewModelApplicationResult> {
            return localVarFp.adminPageSeoSettingsSingleGet(url, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPageSeoSettingsApi - object-oriented interface
 * @export
 * @class AdminPageSeoSettingsApi
 * @extends {BaseAPI}
 */
export class AdminPageSeoSettingsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPageSeoSettingsApi
     */
    public adminPageSeoSettingsDelete(url: string, options?: AxiosRequestConfig) {
        return AdminPageSeoSettingsApiFp(this.configuration).adminPageSeoSettingsDelete(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPageSeoSettingsApi
     */
    public adminPageSeoSettingsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminPageSeoSettingsApiFp(this.configuration).adminPageSeoSettingsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminPageSeoSettingsDeleteRequest1} [adminPageSeoSettingsDeleteRequest1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPageSeoSettingsApi
     */
    public adminPageSeoSettingsPost(adminPageSeoSettingsDeleteRequest1?: AdminPageSeoSettingsDeleteRequest1, options?: AxiosRequestConfig) {
        return AdminPageSeoSettingsApiFp(this.configuration).adminPageSeoSettingsPost(adminPageSeoSettingsDeleteRequest1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} url 
     * @param {AdminPageSeoSettingsDeleteRequest} [adminPageSeoSettingsDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPageSeoSettingsApi
     */
    public adminPageSeoSettingsPut(url: string, adminPageSeoSettingsDeleteRequest?: AdminPageSeoSettingsDeleteRequest, options?: AxiosRequestConfig) {
        return AdminPageSeoSettingsApiFp(this.configuration).adminPageSeoSettingsPut(url, adminPageSeoSettingsDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPageSeoSettingsApi
     */
    public adminPageSeoSettingsSingleGet(url: string, options?: AxiosRequestConfig) {
        return AdminPageSeoSettingsApiFp(this.configuration).adminPageSeoSettingsSingleGet(url, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductBuilderApi - axios parameter creator
 * @export
 */
export const AdminProductBuilderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productId] 
         * @param {string} [styleId] 
         * @param {string} [designId] 
         * @param {string} [layerId] 
         * @param {string} [fabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBuilderGetProductBuilderOptionsGenerationLogsGet: async (pageIndex: number, pageSize: number, productId?: string, styleId?: string, designId?: string, layerId?: string, fabricChannelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductBuilderGetProductBuilderOptionsGenerationLogsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductBuilderGetProductBuilderOptionsGenerationLogsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ProductBuilder/GetProductBuilderOptionsGenerationLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }

            if (designId !== undefined) {
                localVarQueryParameter['designId'] = designId;
            }

            if (layerId !== undefined) {
                localVarQueryParameter['layerId'] = layerId;
            }

            if (fabricChannelId !== undefined) {
                localVarQueryParameter['fabricChannelId'] = fabricChannelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductBuilderApi - functional programming interface
 * @export
 */
export const AdminProductBuilderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductBuilderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productId] 
         * @param {string} [styleId] 
         * @param {string} [designId] 
         * @param {string} [layerId] 
         * @param {string} [fabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(pageIndex: number, pageSize: number, productId?: string, styleId?: string, designId?: string, layerId?: string, fabricChannelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(pageIndex, pageSize, productId, styleId, designId, layerId, fabricChannelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductBuilderApi - factory interface
 * @export
 */
export const AdminProductBuilderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductBuilderApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productId] 
         * @param {string} [styleId] 
         * @param {string} [designId] 
         * @param {string} [layerId] 
         * @param {string} [fabricChannelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(pageIndex: number, pageSize: number, productId?: string, styleId?: string, designId?: string, layerId?: string, fabricChannelId?: string, options?: any): AxiosPromise<ProductBuilderOptionsGenerationLogViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(pageIndex, pageSize, productId, styleId, designId, layerId, fabricChannelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductBuilderApi - object-oriented interface
 * @export
 * @class AdminProductBuilderApi
 * @extends {BaseAPI}
 */
export class AdminProductBuilderApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [productId] 
     * @param {string} [styleId] 
     * @param {string} [designId] 
     * @param {string} [layerId] 
     * @param {string} [fabricChannelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBuilderApi
     */
    public adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(pageIndex: number, pageSize: number, productId?: string, styleId?: string, designId?: string, layerId?: string, fabricChannelId?: string, options?: AxiosRequestConfig) {
        return AdminProductBuilderApiFp(this.configuration).adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(pageIndex, pageSize, productId, styleId, designId, layerId, fabricChannelId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductBundlePricesApi - axios parameter creator
 * @export
 */
export const AdminProductBundlePricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductBundlePricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductBundlePricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminProductBundlePricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/ProductBundlePrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlePricesIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductBundlePrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlePricesIdGet', 'id', id)
            const localVarPath = `/Admin/ProductBundlePrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlePricesIdPut', 'id', id)
            const localVarPath = `/Admin/ProductBundlePrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductBundlePrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductBundlePricesApi - functional programming interface
 * @export
 */
export const AdminProductBundlePricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductBundlePricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlePricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlePricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlePricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlePricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlePricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlePricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlePricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlePricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlePricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlePricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductBundlePricesApi - factory interface
 * @export
 */
export const AdminProductBundlePricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductBundlePricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductBundlePricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductBundlePricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminProductBundlePricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminProductBundlePricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlePricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminProductBundlePricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductBundlePricesApi - object-oriented interface
 * @export
 * @class AdminProductBundlePricesApi
 * @extends {BaseAPI}
 */
export class AdminProductBundlePricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlePricesApi
     */
    public adminProductBundlePricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminProductBundlePricesApiFp(this.configuration).adminProductBundlePricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlePricesApi
     */
    public adminProductBundlePricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductBundlePricesApiFp(this.configuration).adminProductBundlePricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlePricesApi
     */
    public adminProductBundlePricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductBundlePricesApiFp(this.configuration).adminProductBundlePricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlePricesApi
     */
    public adminProductBundlePricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductBundlePricesApiFp(this.configuration).adminProductBundlePricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlePricesApi
     */
    public adminProductBundlePricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminProductBundlePricesApiFp(this.configuration).adminProductBundlePricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductBundlesApi - axios parameter creator
 * @export
 */
export const AdminProductBundlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productBundleName] 
         * @param {Array<string>} [productBundleCategoryIds] 
         * @param {boolean} [isDisabled] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesGet: async (pageIndex: number, pageSize: number, productBundleName?: string, productBundleCategoryIds?: Array<string>, isDisabled?: boolean, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductBundlesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductBundlesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ProductBundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productBundleName !== undefined) {
                localVarQueryParameter['productBundleName'] = productBundleName;
            }

            if (productBundleCategoryIds) {
                localVarQueryParameter['productBundleCategoryIds'] = productBundleCategoryIds;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlesIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductBundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlesIdGet', 'id', id)
            const localVarPath = `/Admin/ProductBundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder a productBundle in reference to other productBundles (Auth policies: ManageContent)
         * @param {string} id The id of the productBundle being reordered
         * @param {string} [previousProductBundleId] The id of the productBundle that should appear before the target productBundle
         * @param {string} [nextProductBundleId] The id of the productBundle that should appear after the target productBundle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdPatch: async (id: string, previousProductBundleId?: string, nextProductBundleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlesIdPatch', 'id', id)
            const localVarPath = `/Admin/ProductBundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousProductBundleId !== undefined) {
                localVarQueryParameter['previousProductBundleId'] = previousProductBundleId;
            }

            if (nextProductBundleId !== undefined) {
                localVarQueryParameter['nextProductBundleId'] = nextProductBundleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductBundlesIdDeleteRequest} [adminProductBundlesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdPut: async (id: string, adminProductBundlesIdDeleteRequest?: AdminProductBundlesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductBundlesIdPut', 'id', id)
            const localVarPath = `/Admin/ProductBundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductBundlesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductBundlesGetRequest} [adminProductBundlesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesPost: async (adminProductBundlesGetRequest?: AdminProductBundlesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductBundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductBundlesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductBundlesApi - functional programming interface
 * @export
 */
export const AdminProductBundlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductBundlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productBundleName] 
         * @param {Array<string>} [productBundleCategoryIds] 
         * @param {boolean} [isDisabled] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlesGet(pageIndex: number, pageSize: number, productBundleName?: string, productBundleCategoryIds?: Array<string>, isDisabled?: boolean, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlesGet(pageIndex, pageSize, productBundleName, productBundleCategoryIds, isDisabled, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder a productBundle in reference to other productBundles (Auth policies: ManageContent)
         * @param {string} id The id of the productBundle being reordered
         * @param {string} [previousProductBundleId] The id of the productBundle that should appear before the target productBundle
         * @param {string} [nextProductBundleId] The id of the productBundle that should appear after the target productBundle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlesIdPatch(id: string, previousProductBundleId?: string, nextProductBundleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlesIdPatch(id, previousProductBundleId, nextProductBundleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductBundlesIdDeleteRequest} [adminProductBundlesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlesIdPut(id: string, adminProductBundlesIdDeleteRequest?: AdminProductBundlesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlesIdPut(id, adminProductBundlesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductBundlesGetRequest} [adminProductBundlesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductBundlesPost(adminProductBundlesGetRequest?: AdminProductBundlesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductBundlesPost(adminProductBundlesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductBundlesApi - factory interface
 * @export
 */
export const AdminProductBundlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductBundlesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productBundleName] 
         * @param {Array<string>} [productBundleCategoryIds] 
         * @param {boolean} [isDisabled] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesGet(pageIndex: number, pageSize: number, productBundleName?: string, productBundleCategoryIds?: Array<string>, isDisabled?: boolean, code?: string, options?: any): AxiosPromise<ProductBundleAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductBundlesGet(pageIndex, pageSize, productBundleName, productBundleCategoryIds, isDisabled, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductBundlesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdGet(id: string, options?: any): AxiosPromise<ProductBundleAdminViewModelApplicationResult> {
            return localVarFp.adminProductBundlesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder a productBundle in reference to other productBundles (Auth policies: ManageContent)
         * @param {string} id The id of the productBundle being reordered
         * @param {string} [previousProductBundleId] The id of the productBundle that should appear before the target productBundle
         * @param {string} [nextProductBundleId] The id of the productBundle that should appear after the target productBundle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdPatch(id: string, previousProductBundleId?: string, nextProductBundleId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductBundlesIdPatch(id, previousProductBundleId, nextProductBundleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductBundlesIdDeleteRequest} [adminProductBundlesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesIdPut(id: string, adminProductBundlesIdDeleteRequest?: AdminProductBundlesIdDeleteRequest, options?: any): AxiosPromise<ProductBundleAdminViewModelApplicationResult> {
            return localVarFp.adminProductBundlesIdPut(id, adminProductBundlesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductBundlesGetRequest} [adminProductBundlesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductBundlesPost(adminProductBundlesGetRequest?: AdminProductBundlesGetRequest, options?: any): AxiosPromise<ProductBundleAdminViewModelApplicationResult> {
            return localVarFp.adminProductBundlesPost(adminProductBundlesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductBundlesApi - object-oriented interface
 * @export
 * @class AdminProductBundlesApi
 * @extends {BaseAPI}
 */
export class AdminProductBundlesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [productBundleName] 
     * @param {Array<string>} [productBundleCategoryIds] 
     * @param {boolean} [isDisabled] 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlesApi
     */
    public adminProductBundlesGet(pageIndex: number, pageSize: number, productBundleName?: string, productBundleCategoryIds?: Array<string>, isDisabled?: boolean, code?: string, options?: AxiosRequestConfig) {
        return AdminProductBundlesApiFp(this.configuration).adminProductBundlesGet(pageIndex, pageSize, productBundleName, productBundleCategoryIds, isDisabled, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlesApi
     */
    public adminProductBundlesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductBundlesApiFp(this.configuration).adminProductBundlesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlesApi
     */
    public adminProductBundlesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductBundlesApiFp(this.configuration).adminProductBundlesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder a productBundle in reference to other productBundles (Auth policies: ManageContent)
     * @param {string} id The id of the productBundle being reordered
     * @param {string} [previousProductBundleId] The id of the productBundle that should appear before the target productBundle
     * @param {string} [nextProductBundleId] The id of the productBundle that should appear after the target productBundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlesApi
     */
    public adminProductBundlesIdPatch(id: string, previousProductBundleId?: string, nextProductBundleId?: string, options?: AxiosRequestConfig) {
        return AdminProductBundlesApiFp(this.configuration).adminProductBundlesIdPatch(id, previousProductBundleId, nextProductBundleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductBundlesIdDeleteRequest} [adminProductBundlesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlesApi
     */
    public adminProductBundlesIdPut(id: string, adminProductBundlesIdDeleteRequest?: AdminProductBundlesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductBundlesApiFp(this.configuration).adminProductBundlesIdPut(id, adminProductBundlesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductBundlesGetRequest} [adminProductBundlesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductBundlesApi
     */
    public adminProductBundlesPost(adminProductBundlesGetRequest?: AdminProductBundlesGetRequest, options?: AxiosRequestConfig) {
        return AdminProductBundlesApiFp(this.configuration).adminProductBundlesPost(adminProductBundlesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductCategoryMenuGroupsApi - axios parameter creator
 * @export
 */
export const AdminProductCategoryMenuGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductCategoryMenuGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductCategoryMenuGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ProductCategoryMenuGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryMenuGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductCategoryMenuGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryMenuGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/ProductCategoryMenuGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousProductCategoryMenuGroupId] 
         * @param {string} [nextProductCategoryMenuGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdPatch: async (id: string, previousProductCategoryMenuGroupId?: string, nextProductCategoryMenuGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryMenuGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/ProductCategoryMenuGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousProductCategoryMenuGroupId !== undefined) {
                localVarQueryParameter['previousProductCategoryMenuGroupId'] = previousProductCategoryMenuGroupId;
            }

            if (nextProductCategoryMenuGroupId !== undefined) {
                localVarQueryParameter['nextProductCategoryMenuGroupId'] = nextProductCategoryMenuGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductCategoryMenuGroupsIdDeleteRequest} [adminProductCategoryMenuGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdPut: async (id: string, adminProductCategoryMenuGroupsIdDeleteRequest?: AdminProductCategoryMenuGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductCategoryMenuGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/ProductCategoryMenuGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductCategoryMenuGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductCategoryMenuGroupsGetRequest} [adminProductCategoryMenuGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsPost: async (adminProductCategoryMenuGroupsGetRequest?: AdminProductCategoryMenuGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductCategoryMenuGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductCategoryMenuGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductCategoryMenuGroupsApi - functional programming interface
 * @export
 */
export const AdminProductCategoryMenuGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductCategoryMenuGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMenuGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMenuGroupsGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMenuGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMenuGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMenuGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryMenuGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMenuGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousProductCategoryMenuGroupId] 
         * @param {string} [nextProductCategoryMenuGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMenuGroupsIdPatch(id: string, previousProductCategoryMenuGroupId?: string, nextProductCategoryMenuGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMenuGroupsIdPatch(id, previousProductCategoryMenuGroupId, nextProductCategoryMenuGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductCategoryMenuGroupsIdDeleteRequest} [adminProductCategoryMenuGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMenuGroupsIdPut(id: string, adminProductCategoryMenuGroupsIdDeleteRequest?: AdminProductCategoryMenuGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryMenuGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMenuGroupsIdPut(id, adminProductCategoryMenuGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductCategoryMenuGroupsGetRequest} [adminProductCategoryMenuGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductCategoryMenuGroupsPost(adminProductCategoryMenuGroupsGetRequest?: AdminProductCategoryMenuGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryMenuGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductCategoryMenuGroupsPost(adminProductCategoryMenuGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductCategoryMenuGroupsApi - factory interface
 * @export
 */
export const AdminProductCategoryMenuGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductCategoryMenuGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<ProductCategoryMenuGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductCategoryMenuGroupsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductCategoryMenuGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdGet(id: string, options?: any): AxiosPromise<ProductCategoryMenuGroupAdminViewModelApplicationResult> {
            return localVarFp.adminProductCategoryMenuGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousProductCategoryMenuGroupId] 
         * @param {string} [nextProductCategoryMenuGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdPatch(id: string, previousProductCategoryMenuGroupId?: string, nextProductCategoryMenuGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductCategoryMenuGroupsIdPatch(id, previousProductCategoryMenuGroupId, nextProductCategoryMenuGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductCategoryMenuGroupsIdDeleteRequest} [adminProductCategoryMenuGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsIdPut(id: string, adminProductCategoryMenuGroupsIdDeleteRequest?: AdminProductCategoryMenuGroupsIdDeleteRequest, options?: any): AxiosPromise<ProductCategoryMenuGroupAdminViewModelApplicationResult> {
            return localVarFp.adminProductCategoryMenuGroupsIdPut(id, adminProductCategoryMenuGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductCategoryMenuGroupsGetRequest} [adminProductCategoryMenuGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductCategoryMenuGroupsPost(adminProductCategoryMenuGroupsGetRequest?: AdminProductCategoryMenuGroupsGetRequest, options?: any): AxiosPromise<ProductCategoryMenuGroupAdminViewModelApplicationResult> {
            return localVarFp.adminProductCategoryMenuGroupsPost(adminProductCategoryMenuGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductCategoryMenuGroupsApi - object-oriented interface
 * @export
 * @class AdminProductCategoryMenuGroupsApi
 * @extends {BaseAPI}
 */
export class AdminProductCategoryMenuGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductCategoryMenuGroupsApi
     */
    public adminProductCategoryMenuGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminProductCategoryMenuGroupsApiFp(this.configuration).adminProductCategoryMenuGroupsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductCategoryMenuGroupsApi
     */
    public adminProductCategoryMenuGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductCategoryMenuGroupsApiFp(this.configuration).adminProductCategoryMenuGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductCategoryMenuGroupsApi
     */
    public adminProductCategoryMenuGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductCategoryMenuGroupsApiFp(this.configuration).adminProductCategoryMenuGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousProductCategoryMenuGroupId] 
     * @param {string} [nextProductCategoryMenuGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductCategoryMenuGroupsApi
     */
    public adminProductCategoryMenuGroupsIdPatch(id: string, previousProductCategoryMenuGroupId?: string, nextProductCategoryMenuGroupId?: string, options?: AxiosRequestConfig) {
        return AdminProductCategoryMenuGroupsApiFp(this.configuration).adminProductCategoryMenuGroupsIdPatch(id, previousProductCategoryMenuGroupId, nextProductCategoryMenuGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductCategoryMenuGroupsIdDeleteRequest} [adminProductCategoryMenuGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductCategoryMenuGroupsApi
     */
    public adminProductCategoryMenuGroupsIdPut(id: string, adminProductCategoryMenuGroupsIdDeleteRequest?: AdminProductCategoryMenuGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductCategoryMenuGroupsApiFp(this.configuration).adminProductCategoryMenuGroupsIdPut(id, adminProductCategoryMenuGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductCategoryMenuGroupsGetRequest} [adminProductCategoryMenuGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductCategoryMenuGroupsApi
     */
    public adminProductCategoryMenuGroupsPost(adminProductCategoryMenuGroupsGetRequest?: AdminProductCategoryMenuGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductCategoryMenuGroupsApiFp(this.configuration).adminProductCategoryMenuGroupsPost(adminProductCategoryMenuGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductPricesApi - axios parameter creator
 * @export
 */
export const AdminProductPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductPricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductPricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminProductPricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/ProductPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductPricesIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductPricesIdGet', 'id', id)
            const localVarPath = `/Admin/ProductPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductPricesIdPut', 'id', id)
            const localVarPath = `/Admin/ProductPrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductPricesApi - functional programming interface
 * @export
 */
export const AdminProductPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductPricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductPricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductPricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductPricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductPricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductPricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductPricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductPricesApi - factory interface
 * @export
 */
export const AdminProductPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductPricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductPricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminProductPricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminProductPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminProductPricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductPricesApi - object-oriented interface
 * @export
 * @class AdminProductPricesApi
 * @extends {BaseAPI}
 */
export class AdminProductPricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductPricesApi
     */
    public adminProductPricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminProductPricesApiFp(this.configuration).adminProductPricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductPricesApi
     */
    public adminProductPricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductPricesApiFp(this.configuration).adminProductPricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductPricesApi
     */
    public adminProductPricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductPricesApiFp(this.configuration).adminProductPricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductPricesApi
     */
    public adminProductPricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductPricesApiFp(this.configuration).adminProductPricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductPricesApi
     */
    public adminProductPricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminProductPricesApiFp(this.configuration).adminProductPricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductProductionSpeedsApi - axios parameter creator
 * @export
 */
export const AdminProductProductionSpeedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} productId 
         * @param {DeliveryType} [deliveryTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsGet: async (pageIndex: number, pageSize: number, productId: string, deliveryTypeId?: DeliveryType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductProductionSpeedsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductProductionSpeedsGet', 'pageSize', pageSize)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductProductionSpeedsGet', 'productId', productId)
            const localVarPath = `/Admin/ProductProductionSpeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (deliveryTypeId !== undefined) {
                localVarQueryParameter['deliveryTypeId'] = deliveryTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductProductionSpeedsIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductProductionSpeedsIdGet', 'id', id)
            const localVarPath = `/Admin/ProductProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductProductionSpeedsIdDeleteRequest} [adminProductProductionSpeedsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsIdPut: async (id: string, adminProductProductionSpeedsIdDeleteRequest?: AdminProductProductionSpeedsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductProductionSpeedsIdPut', 'id', id)
            const localVarPath = `/Admin/ProductProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductProductionSpeedsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductProductionSpeedsGetRequest} [adminProductProductionSpeedsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsPost: async (adminProductProductionSpeedsGetRequest?: AdminProductProductionSpeedsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductProductionSpeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductProductionSpeedsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductProductionSpeedsApi - functional programming interface
 * @export
 */
export const AdminProductProductionSpeedsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductProductionSpeedsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} productId 
         * @param {DeliveryType} [deliveryTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductProductionSpeedsGet(pageIndex: number, pageSize: number, productId: string, deliveryTypeId?: DeliveryType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductProductionSpeedsGet(pageIndex, pageSize, productId, deliveryTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductProductionSpeedsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductProductionSpeedsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductProductionSpeedsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductProductionSpeedAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductProductionSpeedsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductProductionSpeedsIdDeleteRequest} [adminProductProductionSpeedsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductProductionSpeedsIdPut(id: string, adminProductProductionSpeedsIdDeleteRequest?: AdminProductProductionSpeedsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductProductionSpeedAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductProductionSpeedsIdPut(id, adminProductProductionSpeedsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductProductionSpeedsGetRequest} [adminProductProductionSpeedsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductProductionSpeedsPost(adminProductProductionSpeedsGetRequest?: AdminProductProductionSpeedsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductProductionSpeedAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductProductionSpeedsPost(adminProductProductionSpeedsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductProductionSpeedsApi - factory interface
 * @export
 */
export const AdminProductProductionSpeedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductProductionSpeedsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} productId 
         * @param {DeliveryType} [deliveryTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsGet(pageIndex: number, pageSize: number, productId: string, deliveryTypeId?: DeliveryType, options?: any): AxiosPromise<ProductProductionSpeedAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductProductionSpeedsGet(pageIndex, pageSize, productId, deliveryTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductProductionSpeedsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsIdGet(id: string, options?: any): AxiosPromise<ProductProductionSpeedAdminViewModelApplicationResult> {
            return localVarFp.adminProductProductionSpeedsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductProductionSpeedsIdDeleteRequest} [adminProductProductionSpeedsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsIdPut(id: string, adminProductProductionSpeedsIdDeleteRequest?: AdminProductProductionSpeedsIdDeleteRequest, options?: any): AxiosPromise<ProductProductionSpeedAdminViewModelApplicationResult> {
            return localVarFp.adminProductProductionSpeedsIdPut(id, adminProductProductionSpeedsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductProductionSpeedsGetRequest} [adminProductProductionSpeedsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductProductionSpeedsPost(adminProductProductionSpeedsGetRequest?: AdminProductProductionSpeedsGetRequest, options?: any): AxiosPromise<ProductProductionSpeedAdminViewModelApplicationResult> {
            return localVarFp.adminProductProductionSpeedsPost(adminProductProductionSpeedsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductProductionSpeedsApi - object-oriented interface
 * @export
 * @class AdminProductProductionSpeedsApi
 * @extends {BaseAPI}
 */
export class AdminProductProductionSpeedsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} productId 
     * @param {DeliveryType} [deliveryTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductProductionSpeedsApi
     */
    public adminProductProductionSpeedsGet(pageIndex: number, pageSize: number, productId: string, deliveryTypeId?: DeliveryType, options?: AxiosRequestConfig) {
        return AdminProductProductionSpeedsApiFp(this.configuration).adminProductProductionSpeedsGet(pageIndex, pageSize, productId, deliveryTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductProductionSpeedsApi
     */
    public adminProductProductionSpeedsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductProductionSpeedsApiFp(this.configuration).adminProductProductionSpeedsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductProductionSpeedsApi
     */
    public adminProductProductionSpeedsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductProductionSpeedsApiFp(this.configuration).adminProductProductionSpeedsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductProductionSpeedsIdDeleteRequest} [adminProductProductionSpeedsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductProductionSpeedsApi
     */
    public adminProductProductionSpeedsIdPut(id: string, adminProductProductionSpeedsIdDeleteRequest?: AdminProductProductionSpeedsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductProductionSpeedsApiFp(this.configuration).adminProductProductionSpeedsIdPut(id, adminProductProductionSpeedsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductProductionSpeedsGetRequest} [adminProductProductionSpeedsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductProductionSpeedsApi
     */
    public adminProductProductionSpeedsPost(adminProductProductionSpeedsGetRequest?: AdminProductProductionSpeedsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductProductionSpeedsApiFp(this.configuration).adminProductProductionSpeedsPost(adminProductProductionSpeedsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductTagsApi - axios parameter creator
 * @export
 */
export const AdminProductTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductTagsIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductTags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductTagsIdGet', 'id', id)
            const localVarPath = `/Admin/ProductTags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSiblingProductTagId] 
         * @param {string} [nextSiblingProductTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdPatch: async (id: string, previousSiblingProductTagId?: string, nextSiblingProductTagId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductTagsIdPatch', 'id', id)
            const localVarPath = `/Admin/ProductTags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousSiblingProductTagId !== undefined) {
                localVarQueryParameter['previousSiblingProductTagId'] = previousSiblingProductTagId;
            }

            if (nextSiblingProductTagId !== undefined) {
                localVarQueryParameter['nextSiblingProductTagId'] = nextSiblingProductTagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductTagsIdDeleteRequest} [adminProductTagsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdPut: async (id: string, adminProductTagsIdDeleteRequest?: AdminProductTagsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductTagsIdPut', 'id', id)
            const localVarPath = `/Admin/ProductTags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductTagsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductTagsGetRequest} [adminProductTagsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsPost: async (adminProductTagsGetRequest?: AdminProductTagsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductTagsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductTagsApi - functional programming interface
 * @export
 */
export const AdminProductTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductTagsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTagViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductTagsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductTagsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductTagsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductTagsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTagViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductTagsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSiblingProductTagId] 
         * @param {string} [nextSiblingProductTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductTagsIdPatch(id: string, previousSiblingProductTagId?: string, nextSiblingProductTagId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductTagsIdPatch(id, previousSiblingProductTagId, nextSiblingProductTagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductTagsIdDeleteRequest} [adminProductTagsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductTagsIdPut(id: string, adminProductTagsIdDeleteRequest?: AdminProductTagsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTagViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductTagsIdPut(id, adminProductTagsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductTagsGetRequest} [adminProductTagsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductTagsPost(adminProductTagsGetRequest?: AdminProductTagsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTagViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductTagsPost(adminProductTagsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductTagsApi - factory interface
 * @export
 */
export const AdminProductTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductTagsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsGet(options?: any): AxiosPromise<ProductTagViewModelListApplicationResult> {
            return localVarFp.adminProductTagsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductTagsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdGet(id: string, options?: any): AxiosPromise<ProductTagViewModelApplicationResult> {
            return localVarFp.adminProductTagsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSiblingProductTagId] 
         * @param {string} [nextSiblingProductTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdPatch(id: string, previousSiblingProductTagId?: string, nextSiblingProductTagId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductTagsIdPatch(id, previousSiblingProductTagId, nextSiblingProductTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductTagsIdDeleteRequest} [adminProductTagsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsIdPut(id: string, adminProductTagsIdDeleteRequest?: AdminProductTagsIdDeleteRequest, options?: any): AxiosPromise<ProductTagViewModelApplicationResult> {
            return localVarFp.adminProductTagsIdPut(id, adminProductTagsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductTagsGetRequest} [adminProductTagsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductTagsPost(adminProductTagsGetRequest?: AdminProductTagsGetRequest, options?: any): AxiosPromise<ProductTagViewModelApplicationResult> {
            return localVarFp.adminProductTagsPost(adminProductTagsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductTagsApi - object-oriented interface
 * @export
 * @class AdminProductTagsApi
 * @extends {BaseAPI}
 */
export class AdminProductTagsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductTagsApi
     */
    public adminProductTagsGet(options?: AxiosRequestConfig) {
        return AdminProductTagsApiFp(this.configuration).adminProductTagsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductTagsApi
     */
    public adminProductTagsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductTagsApiFp(this.configuration).adminProductTagsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductTagsApi
     */
    public adminProductTagsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductTagsApiFp(this.configuration).adminProductTagsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousSiblingProductTagId] 
     * @param {string} [nextSiblingProductTagId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductTagsApi
     */
    public adminProductTagsIdPatch(id: string, previousSiblingProductTagId?: string, nextSiblingProductTagId?: string, options?: AxiosRequestConfig) {
        return AdminProductTagsApiFp(this.configuration).adminProductTagsIdPatch(id, previousSiblingProductTagId, nextSiblingProductTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductTagsIdDeleteRequest} [adminProductTagsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductTagsApi
     */
    public adminProductTagsIdPut(id: string, adminProductTagsIdDeleteRequest?: AdminProductTagsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductTagsApiFp(this.configuration).adminProductTagsIdPut(id, adminProductTagsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductTagsGetRequest} [adminProductTagsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductTagsApi
     */
    public adminProductTagsPost(adminProductTagsGetRequest?: AdminProductTagsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductTagsApiFp(this.configuration).adminProductTagsPost(adminProductTagsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductionSpeedAdjustmentsApi - axios parameter creator
 * @export
 */
export const AdminProductionSpeedAdjustmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsExportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductionSpeedAdjustments/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductionSpeedAdjustmentsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductionSpeedAdjustmentsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ProductionSpeedAdjustments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedAdjustmentsIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductionSpeedAdjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedAdjustmentsIdGet', 'id', id)
            const localVarPath = `/Admin/ProductionSpeedAdjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsIdPut: async (id: string, adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedAdjustmentsIdPut', 'id', id)
            const localVarPath = `/Admin/ProductionSpeedAdjustments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductionSpeedAdjustmentsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} relativeFileUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsImportPost: async (relativeFileUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativeFileUrl' is not null or undefined
            assertParamExists('adminProductionSpeedAdjustmentsImportPost', 'relativeFileUrl', relativeFileUrl)
            const localVarPath = `/Admin/ProductionSpeedAdjustments/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (relativeFileUrl !== undefined) {
                localVarQueryParameter['relativeFileUrl'] = relativeFileUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsPost: async (adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductionSpeedAdjustments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductionSpeedAdjustmentsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductionSpeedAdjustmentsApi - functional programming interface
 * @export
 */
export const AdminProductionSpeedAdjustmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductionSpeedAdjustmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsExportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsExportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsGet(pageIndex: number, pageSize: number, searchTerm?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsGet(pageIndex, pageSize, searchTerm, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdjustmentViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsIdPut(id: string, adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdjustmentViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsIdPut(id, adminProductionSpeedAdjustmentsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} relativeFileUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsImportPost(relativeFileUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsImportPost(relativeFileUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedAdjustmentsPost(adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdjustmentViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedAdjustmentsPost(adminProductionSpeedAdjustmentsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductionSpeedAdjustmentsApi - factory interface
 * @export
 */
export const AdminProductionSpeedAdjustmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductionSpeedAdjustmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsExportGet(options?: any): AxiosPromise<string> {
            return localVarFp.adminProductionSpeedAdjustmentsExportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsGet(pageIndex: number, pageSize: number, searchTerm?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<ProductionSpeedAdjustmentViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductionSpeedAdjustmentsGet(pageIndex, pageSize, searchTerm, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductionSpeedAdjustmentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsIdGet(id: string, options?: any): AxiosPromise<ProductionSpeedAdjustmentViewModelApplicationResult> {
            return localVarFp.adminProductionSpeedAdjustmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsIdPut(id: string, adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options?: any): AxiosPromise<ProductionSpeedAdjustmentViewModelApplicationResult> {
            return localVarFp.adminProductionSpeedAdjustmentsIdPut(id, adminProductionSpeedAdjustmentsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} relativeFileUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsImportPost(relativeFileUrl: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductionSpeedAdjustmentsImportPost(relativeFileUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedAdjustmentsPost(adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options?: any): AxiosPromise<ProductionSpeedAdjustmentViewModelApplicationResult> {
            return localVarFp.adminProductionSpeedAdjustmentsPost(adminProductionSpeedAdjustmentsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductionSpeedAdjustmentsApi - object-oriented interface
 * @export
 * @class AdminProductionSpeedAdjustmentsApi
 * @extends {BaseAPI}
 */
export class AdminProductionSpeedAdjustmentsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsExportGet(options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsExportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsGet(pageIndex: number, pageSize: number, searchTerm?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsGet(pageIndex, pageSize, searchTerm, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsIdPut(id: string, adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsIdPut(id, adminProductionSpeedAdjustmentsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} relativeFileUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsImportPost(relativeFileUrl: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsImportPost(relativeFileUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductionSpeedAdjustmentsGetRequest} [adminProductionSpeedAdjustmentsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedAdjustmentsApi
     */
    public adminProductionSpeedAdjustmentsPost(adminProductionSpeedAdjustmentsGetRequest?: AdminProductionSpeedAdjustmentsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductionSpeedAdjustmentsApiFp(this.configuration).adminProductionSpeedAdjustmentsPost(adminProductionSpeedAdjustmentsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductionSpeedsApi - axios parameter creator
 * @export
 */
export const AdminProductionSpeedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {DeliveryType} [deliveryTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, deliveryTypeId?: DeliveryType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductionSpeedsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductionSpeedsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/ProductionSpeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (deliveryTypeId !== undefined) {
                localVarQueryParameter['deliveryTypeId'] = deliveryTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedsIdDelete', 'id', id)
            const localVarPath = `/Admin/ProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedsIdGet', 'id', id)
            const localVarPath = `/Admin/ProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousProductionSpeedId] 
         * @param {string} [nextProductionSpeedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdPatch: async (id: string, previousProductionSpeedId?: string, nextProductionSpeedId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedsIdPatch', 'id', id)
            const localVarPath = `/Admin/ProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousProductionSpeedId !== undefined) {
                localVarQueryParameter['previousProductionSpeedId'] = previousProductionSpeedId;
            }

            if (nextProductionSpeedId !== undefined) {
                localVarQueryParameter['nextProductionSpeedId'] = nextProductionSpeedId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductionSpeedsIdDeleteRequest} [adminProductionSpeedsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdPut: async (id: string, adminProductionSpeedsIdDeleteRequest?: AdminProductionSpeedsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductionSpeedsIdPut', 'id', id)
            const localVarPath = `/Admin/ProductionSpeeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductionSpeedsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductionSpeedsGetRequest} [adminProductionSpeedsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsPost: async (adminProductionSpeedsGetRequest?: AdminProductionSpeedsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/ProductionSpeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductionSpeedsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductionSpeedsApi - functional programming interface
 * @export
 */
export const AdminProductionSpeedsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductionSpeedsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {DeliveryType} [deliveryTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedsGet(pageIndex: number, pageSize: number, searchTerm?: string, deliveryTypeId?: DeliveryType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedsGet(pageIndex, pageSize, searchTerm, deliveryTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousProductionSpeedId] 
         * @param {string} [nextProductionSpeedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedsIdPatch(id: string, previousProductionSpeedId?: string, nextProductionSpeedId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedsIdPatch(id, previousProductionSpeedId, nextProductionSpeedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductionSpeedsIdDeleteRequest} [adminProductionSpeedsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedsIdPut(id: string, adminProductionSpeedsIdDeleteRequest?: AdminProductionSpeedsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedsIdPut(id, adminProductionSpeedsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductionSpeedsGetRequest} [adminProductionSpeedsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductionSpeedsPost(adminProductionSpeedsGetRequest?: AdminProductionSpeedsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionSpeedAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductionSpeedsPost(adminProductionSpeedsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductionSpeedsApi - factory interface
 * @export
 */
export const AdminProductionSpeedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductionSpeedsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {DeliveryType} [deliveryTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsGet(pageIndex: number, pageSize: number, searchTerm?: string, deliveryTypeId?: DeliveryType, options?: any): AxiosPromise<ProductionSpeedAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductionSpeedsGet(pageIndex, pageSize, searchTerm, deliveryTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductionSpeedsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdGet(id: string, options?: any): AxiosPromise<ProductionSpeedAdminViewModelApplicationResult> {
            return localVarFp.adminProductionSpeedsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousProductionSpeedId] 
         * @param {string} [nextProductionSpeedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdPatch(id: string, previousProductionSpeedId?: string, nextProductionSpeedId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductionSpeedsIdPatch(id, previousProductionSpeedId, nextProductionSpeedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductionSpeedsIdDeleteRequest} [adminProductionSpeedsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsIdPut(id: string, adminProductionSpeedsIdDeleteRequest?: AdminProductionSpeedsIdDeleteRequest, options?: any): AxiosPromise<ProductionSpeedAdminViewModelApplicationResult> {
            return localVarFp.adminProductionSpeedsIdPut(id, adminProductionSpeedsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductionSpeedsGetRequest} [adminProductionSpeedsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductionSpeedsPost(adminProductionSpeedsGetRequest?: AdminProductionSpeedsGetRequest, options?: any): AxiosPromise<ProductionSpeedAdminViewModelApplicationResult> {
            return localVarFp.adminProductionSpeedsPost(adminProductionSpeedsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductionSpeedsApi - object-oriented interface
 * @export
 * @class AdminProductionSpeedsApi
 * @extends {BaseAPI}
 */
export class AdminProductionSpeedsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {DeliveryType} [deliveryTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedsApi
     */
    public adminProductionSpeedsGet(pageIndex: number, pageSize: number, searchTerm?: string, deliveryTypeId?: DeliveryType, options?: AxiosRequestConfig) {
        return AdminProductionSpeedsApiFp(this.configuration).adminProductionSpeedsGet(pageIndex, pageSize, searchTerm, deliveryTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedsApi
     */
    public adminProductionSpeedsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedsApiFp(this.configuration).adminProductionSpeedsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedsApi
     */
    public adminProductionSpeedsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedsApiFp(this.configuration).adminProductionSpeedsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousProductionSpeedId] 
     * @param {string} [nextProductionSpeedId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedsApi
     */
    public adminProductionSpeedsIdPatch(id: string, previousProductionSpeedId?: string, nextProductionSpeedId?: string, options?: AxiosRequestConfig) {
        return AdminProductionSpeedsApiFp(this.configuration).adminProductionSpeedsIdPatch(id, previousProductionSpeedId, nextProductionSpeedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductionSpeedsIdDeleteRequest} [adminProductionSpeedsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedsApi
     */
    public adminProductionSpeedsIdPut(id: string, adminProductionSpeedsIdDeleteRequest?: AdminProductionSpeedsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductionSpeedsApiFp(this.configuration).adminProductionSpeedsIdPut(id, adminProductionSpeedsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductionSpeedsGetRequest} [adminProductionSpeedsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductionSpeedsApi
     */
    public adminProductionSpeedsPost(adminProductionSpeedsGetRequest?: AdminProductionSpeedsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductionSpeedsApiFp(this.configuration).adminProductionSpeedsPost(adminProductionSpeedsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductsApi - axios parameter creator
 * @export
 */
export const AdminProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {boolean} regenerateAllProducts 
         * @param {boolean} ignoreRegenerationDelay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGenerateOptionsPost: async (regenerateAllProducts: boolean, ignoreRegenerationDelay: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regenerateAllProducts' is not null or undefined
            assertParamExists('adminProductsGenerateOptionsPost', 'regenerateAllProducts', regenerateAllProducts)
            // verify required parameter 'ignoreRegenerationDelay' is not null or undefined
            assertParamExists('adminProductsGenerateOptionsPost', 'ignoreRegenerationDelay', ignoreRegenerationDelay)
            const localVarPath = `/Admin/Products/GenerateOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (regenerateAllProducts !== undefined) {
                localVarQueryParameter['regenerateAllProducts'] = regenerateAllProducts;
            }

            if (ignoreRegenerationDelay !== undefined) {
                localVarQueryParameter['ignoreRegenerationDelay'] = ignoreRegenerationDelay;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productName] 
         * @param {string} [productCode] 
         * @param {Array<string>} [productCategoryIds] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGet: async (pageIndex: number, pageSize: number, productName?: string, productCode?: string, productCategoryIds?: Array<string>, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminProductsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productName !== undefined) {
                localVarQueryParameter['productName'] = productName;
            }

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (productCategoryIds) {
                localVarQueryParameter['productCategoryIds'] = productCategoryIds;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (hasProductBuilderOptionsGenerationLogs !== undefined) {
                localVarQueryParameter['hasProductBuilderOptionsGenerationLogs'] = hasProductBuilderOptionsGenerationLogs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductsIdDelete', 'id', id)
            const localVarPath = `/Admin/Products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductsIdGet', 'id', id)
            const localVarPath = `/Admin/Products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder a product in reference to other products (Auth policies: ManageContent)
         * @param {string} id The id of the product being reordered
         * @param {string} [previousProductId] The id of the product that should appear before the target product
         * @param {string} [nextProductId] The id of the product that should appear after the target product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdPatch: async (id: string, previousProductId?: string, nextProductId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductsIdPatch', 'id', id)
            const localVarPath = `/Admin/Products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousProductId !== undefined) {
                localVarQueryParameter['previousProductId'] = previousProductId;
            }

            if (nextProductId !== undefined) {
                localVarQueryParameter['nextProductId'] = nextProductId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductsIdDeleteRequest} [adminProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdPut: async (id: string, adminProductsIdDeleteRequest?: AdminProductsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminProductsIdPut', 'id', id)
            const localVarPath = `/Admin/Products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductsGetRequest} [adminProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsPost: async (adminProductsGetRequest?: AdminProductsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminProductsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductsApi - functional programming interface
 * @export
 */
export const AdminProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {boolean} regenerateAllProducts 
         * @param {boolean} ignoreRegenerationDelay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsGenerateOptionsPost(regenerateAllProducts: boolean, ignoreRegenerationDelay: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsGenerateOptionsPost(regenerateAllProducts, ignoreRegenerationDelay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productName] 
         * @param {string} [productCode] 
         * @param {Array<string>} [productCategoryIds] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsGet(pageIndex: number, pageSize: number, productName?: string, productCode?: string, productCategoryIds?: Array<string>, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsGet(pageIndex, pageSize, productName, productCode, productCategoryIds, isDisabled, hasProductBuilderOptionsGenerationLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder a product in reference to other products (Auth policies: ManageContent)
         * @param {string} id The id of the product being reordered
         * @param {string} [previousProductId] The id of the product that should appear before the target product
         * @param {string} [nextProductId] The id of the product that should appear after the target product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsIdPatch(id: string, previousProductId?: string, nextProductId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsIdPatch(id, previousProductId, nextProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductsIdDeleteRequest} [adminProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsIdPut(id: string, adminProductsIdDeleteRequest?: AdminProductsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsIdPut(id, adminProductsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductsGetRequest} [adminProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsPost(adminProductsGetRequest?: AdminProductsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsPost(adminProductsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductsApi - factory interface
 * @export
 */
export const AdminProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {boolean} regenerateAllProducts 
         * @param {boolean} ignoreRegenerationDelay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGenerateOptionsPost(regenerateAllProducts: boolean, ignoreRegenerationDelay: boolean, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductsGenerateOptionsPost(regenerateAllProducts, ignoreRegenerationDelay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [productName] 
         * @param {string} [productCode] 
         * @param {Array<string>} [productCategoryIds] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGet(pageIndex: number, pageSize: number, productName?: string, productCode?: string, productCategoryIds?: Array<string>, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: any): AxiosPromise<ProductAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminProductsGet(pageIndex, pageSize, productName, productCode, productCategoryIds, isDisabled, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdGet(id: string, options?: any): AxiosPromise<ProductAdminViewModelApplicationResult> {
            return localVarFp.adminProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder a product in reference to other products (Auth policies: ManageContent)
         * @param {string} id The id of the product being reordered
         * @param {string} [previousProductId] The id of the product that should appear before the target product
         * @param {string} [nextProductId] The id of the product that should appear after the target product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdPatch(id: string, previousProductId?: string, nextProductId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminProductsIdPatch(id, previousProductId, nextProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminProductsIdDeleteRequest} [adminProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsIdPut(id: string, adminProductsIdDeleteRequest?: AdminProductsIdDeleteRequest, options?: any): AxiosPromise<ProductAdminViewModelApplicationResult> {
            return localVarFp.adminProductsIdPut(id, adminProductsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminProductsGetRequest} [adminProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsPost(adminProductsGetRequest?: AdminProductsGetRequest, options?: any): AxiosPromise<ProductAdminViewModelApplicationResult> {
            return localVarFp.adminProductsPost(adminProductsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductsApi - object-oriented interface
 * @export
 * @class AdminProductsApi
 * @extends {BaseAPI}
 */
export class AdminProductsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {boolean} regenerateAllProducts 
     * @param {boolean} ignoreRegenerationDelay 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsGenerateOptionsPost(regenerateAllProducts: boolean, ignoreRegenerationDelay: boolean, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsGenerateOptionsPost(regenerateAllProducts, ignoreRegenerationDelay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [productName] 
     * @param {string} [productCode] 
     * @param {Array<string>} [productCategoryIds] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsGet(pageIndex: number, pageSize: number, productName?: string, productCode?: string, productCategoryIds?: Array<string>, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsGet(pageIndex, pageSize, productName, productCode, productCategoryIds, isDisabled, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder a product in reference to other products (Auth policies: ManageContent)
     * @param {string} id The id of the product being reordered
     * @param {string} [previousProductId] The id of the product that should appear before the target product
     * @param {string} [nextProductId] The id of the product that should appear after the target product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsIdPatch(id: string, previousProductId?: string, nextProductId?: string, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsIdPatch(id, previousProductId, nextProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminProductsIdDeleteRequest} [adminProductsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsIdPut(id: string, adminProductsIdDeleteRequest?: AdminProductsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsIdPut(id, adminProductsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminProductsGetRequest} [adminProductsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductsApi
     */
    public adminProductsPost(adminProductsGetRequest?: AdminProductsGetRequest, options?: AxiosRequestConfig) {
        return AdminProductsApiFp(this.configuration).adminProductsPost(adminProductsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminRolesApi - axios parameter creator
 * @export
 */
export const AdminRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRolesApi - functional programming interface
 * @export
 */
export const AdminRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRolesApi - factory interface
 * @export
 */
export const AdminRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRolesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRolesGet(options?: any): AxiosPromise<StringListApplicationResult> {
            return localVarFp.adminRolesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminRolesApi - object-oriented interface
 * @export
 * @class AdminRolesApi
 * @extends {BaseAPI}
 */
export class AdminRolesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageUsers)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRolesApi
     */
    public adminRolesGet(options?: AxiosRequestConfig) {
        return AdminRolesApiFp(this.configuration).adminRolesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSizeGroupAttributeSizeValuesApi - axios parameter creator
 * @export
 */
export const AdminSizeGroupAttributeSizeValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} sizeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesGet: async (pageIndex: number, pageSize: number, sizeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesGet', 'pageSize', pageSize)
            // verify required parameter 'sizeId' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesGet', 'sizeId', sizeId)
            const localVarPath = `/Admin/SizeGroupAttributeSizeValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sizeId !== undefined) {
                localVarQueryParameter['sizeId'] = sizeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} sizeId 
         * @param {string} [sizeGroupAttributeSizeValueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet: async (sizeId: string, sizeGroupAttributeSizeValueId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sizeId' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet', 'sizeId', sizeId)
            const localVarPath = `/Admin/SizeGroupAttributeSizeValues/GetSizeGroupAttributesForSize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sizeId !== undefined) {
                localVarQueryParameter['sizeId'] = sizeId;
            }

            if (sizeGroupAttributeSizeValueId !== undefined) {
                localVarQueryParameter['sizeGroupAttributeSizeValueId'] = sizeGroupAttributeSizeValueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesIdDelete', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributeSizeValues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesIdGet', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributeSizeValues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupAttributeSizeValuesIdDeleteRequest} [adminSizeGroupAttributeSizeValuesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesIdPut: async (id: string, adminSizeGroupAttributeSizeValuesIdDeleteRequest?: AdminSizeGroupAttributeSizeValuesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributeSizeValuesIdPut', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributeSizeValues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizeGroupAttributeSizeValuesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupAttributeSizeValuesGetRequest} [adminSizeGroupAttributeSizeValuesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesPost: async (adminSizeGroupAttributeSizeValuesGetRequest?: AdminSizeGroupAttributeSizeValuesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SizeGroupAttributeSizeValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizeGroupAttributeSizeValuesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSizeGroupAttributeSizeValuesApi - functional programming interface
 * @export
 */
export const AdminSizeGroupAttributeSizeValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSizeGroupAttributeSizeValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} sizeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributeSizeValuesGet(pageIndex: number, pageSize: number, sizeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributeSizeValuesGet(pageIndex, pageSize, sizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} sizeId 
         * @param {string} [sizeGroupAttributeSizeValueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(sizeId: string, sizeGroupAttributeSizeValueId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(sizeId, sizeGroupAttributeSizeValueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributeSizeValuesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributeSizeValuesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributeSizeValuesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeSizeValueAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributeSizeValuesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupAttributeSizeValuesIdDeleteRequest} [adminSizeGroupAttributeSizeValuesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributeSizeValuesIdPut(id: string, adminSizeGroupAttributeSizeValuesIdDeleteRequest?: AdminSizeGroupAttributeSizeValuesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeSizeValueAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributeSizeValuesIdPut(id, adminSizeGroupAttributeSizeValuesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupAttributeSizeValuesGetRequest} [adminSizeGroupAttributeSizeValuesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributeSizeValuesPost(adminSizeGroupAttributeSizeValuesGetRequest?: AdminSizeGroupAttributeSizeValuesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeSizeValueAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributeSizeValuesPost(adminSizeGroupAttributeSizeValuesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSizeGroupAttributeSizeValuesApi - factory interface
 * @export
 */
export const AdminSizeGroupAttributeSizeValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSizeGroupAttributeSizeValuesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} sizeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesGet(pageIndex: number, pageSize: number, sizeId: string, options?: any): AxiosPromise<SizeGroupAttributeSizeValueAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminSizeGroupAttributeSizeValuesGet(pageIndex, pageSize, sizeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} sizeId 
         * @param {string} [sizeGroupAttributeSizeValueId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(sizeId: string, sizeGroupAttributeSizeValueId?: string, options?: any): AxiosPromise<SizeGroupAttributeAdminListViewModelListApplicationResult> {
            return localVarFp.adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(sizeId, sizeGroupAttributeSizeValueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizeGroupAttributeSizeValuesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesIdGet(id: string, options?: any): AxiosPromise<SizeGroupAttributeSizeValueAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupAttributeSizeValuesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupAttributeSizeValuesIdDeleteRequest} [adminSizeGroupAttributeSizeValuesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesIdPut(id: string, adminSizeGroupAttributeSizeValuesIdDeleteRequest?: AdminSizeGroupAttributeSizeValuesIdDeleteRequest, options?: any): AxiosPromise<SizeGroupAttributeSizeValueAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupAttributeSizeValuesIdPut(id, adminSizeGroupAttributeSizeValuesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupAttributeSizeValuesGetRequest} [adminSizeGroupAttributeSizeValuesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributeSizeValuesPost(adminSizeGroupAttributeSizeValuesGetRequest?: AdminSizeGroupAttributeSizeValuesGetRequest, options?: any): AxiosPromise<SizeGroupAttributeSizeValueAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupAttributeSizeValuesPost(adminSizeGroupAttributeSizeValuesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSizeGroupAttributeSizeValuesApi - object-oriented interface
 * @export
 * @class AdminSizeGroupAttributeSizeValuesApi
 * @extends {BaseAPI}
 */
export class AdminSizeGroupAttributeSizeValuesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} sizeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributeSizeValuesApi
     */
    public adminSizeGroupAttributeSizeValuesGet(pageIndex: number, pageSize: number, sizeId: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributeSizeValuesApiFp(this.configuration).adminSizeGroupAttributeSizeValuesGet(pageIndex, pageSize, sizeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} sizeId 
     * @param {string} [sizeGroupAttributeSizeValueId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributeSizeValuesApi
     */
    public adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(sizeId: string, sizeGroupAttributeSizeValueId?: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributeSizeValuesApiFp(this.configuration).adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(sizeId, sizeGroupAttributeSizeValueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributeSizeValuesApi
     */
    public adminSizeGroupAttributeSizeValuesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributeSizeValuesApiFp(this.configuration).adminSizeGroupAttributeSizeValuesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributeSizeValuesApi
     */
    public adminSizeGroupAttributeSizeValuesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributeSizeValuesApiFp(this.configuration).adminSizeGroupAttributeSizeValuesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminSizeGroupAttributeSizeValuesIdDeleteRequest} [adminSizeGroupAttributeSizeValuesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributeSizeValuesApi
     */
    public adminSizeGroupAttributeSizeValuesIdPut(id: string, adminSizeGroupAttributeSizeValuesIdDeleteRequest?: AdminSizeGroupAttributeSizeValuesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributeSizeValuesApiFp(this.configuration).adminSizeGroupAttributeSizeValuesIdPut(id, adminSizeGroupAttributeSizeValuesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminSizeGroupAttributeSizeValuesGetRequest} [adminSizeGroupAttributeSizeValuesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributeSizeValuesApi
     */
    public adminSizeGroupAttributeSizeValuesPost(adminSizeGroupAttributeSizeValuesGetRequest?: AdminSizeGroupAttributeSizeValuesGetRequest, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributeSizeValuesApiFp(this.configuration).adminSizeGroupAttributeSizeValuesPost(adminSizeGroupAttributeSizeValuesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSizeGroupAttributesApi - axios parameter creator
 * @export
 */
export const AdminSizeGroupAttributesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} sizeGroupId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesGet: async (pageIndex: number, pageSize: number, sizeGroupId: string, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminSizeGroupAttributesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminSizeGroupAttributesGet', 'pageSize', pageSize)
            // verify required parameter 'sizeGroupId' is not null or undefined
            assertParamExists('adminSizeGroupAttributesGet', 'sizeGroupId', sizeGroupId)
            const localVarPath = `/Admin/SizeGroupAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (sizeGroupId !== undefined) {
                localVarQueryParameter['sizeGroupId'] = sizeGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributesIdDelete', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributesIdGet', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSizeGroupAttributeId] 
         * @param {string} [nextSizeGroupAttributeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdPatch: async (id: string, previousSizeGroupAttributeId?: string, nextSizeGroupAttributeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributesIdPatch', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousSizeGroupAttributeId !== undefined) {
                localVarQueryParameter['previousSizeGroupAttributeId'] = previousSizeGroupAttributeId;
            }

            if (nextSizeGroupAttributeId !== undefined) {
                localVarQueryParameter['nextSizeGroupAttributeId'] = nextSizeGroupAttributeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupAttributesIdDeleteRequest} [adminSizeGroupAttributesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdPut: async (id: string, adminSizeGroupAttributesIdDeleteRequest?: AdminSizeGroupAttributesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupAttributesIdPut', 'id', id)
            const localVarPath = `/Admin/SizeGroupAttributes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizeGroupAttributesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupAttributesGetRequest} [adminSizeGroupAttributesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesPost: async (adminSizeGroupAttributesGetRequest?: AdminSizeGroupAttributesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SizeGroupAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizeGroupAttributesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSizeGroupAttributesApi - functional programming interface
 * @export
 */
export const AdminSizeGroupAttributesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSizeGroupAttributesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} sizeGroupId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributesGet(pageIndex: number, pageSize: number, sizeGroupId: string, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributesGet(pageIndex, pageSize, sizeGroupId, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSizeGroupAttributeId] 
         * @param {string} [nextSizeGroupAttributeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributesIdPatch(id: string, previousSizeGroupAttributeId?: string, nextSizeGroupAttributeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributesIdPatch(id, previousSizeGroupAttributeId, nextSizeGroupAttributeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupAttributesIdDeleteRequest} [adminSizeGroupAttributesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributesIdPut(id: string, adminSizeGroupAttributesIdDeleteRequest?: AdminSizeGroupAttributesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributesIdPut(id, adminSizeGroupAttributesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupAttributesGetRequest} [adminSizeGroupAttributesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupAttributesPost(adminSizeGroupAttributesGetRequest?: AdminSizeGroupAttributesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAttributeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupAttributesPost(adminSizeGroupAttributesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSizeGroupAttributesApi - factory interface
 * @export
 */
export const AdminSizeGroupAttributesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSizeGroupAttributesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} sizeGroupId 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesGet(pageIndex: number, pageSize: number, sizeGroupId: string, searchTerm?: string, options?: any): AxiosPromise<SizeGroupAttributeAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminSizeGroupAttributesGet(pageIndex, pageSize, sizeGroupId, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizeGroupAttributesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdGet(id: string, options?: any): AxiosPromise<SizeGroupAttributeAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupAttributesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSizeGroupAttributeId] 
         * @param {string} [nextSizeGroupAttributeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdPatch(id: string, previousSizeGroupAttributeId?: string, nextSizeGroupAttributeId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizeGroupAttributesIdPatch(id, previousSizeGroupAttributeId, nextSizeGroupAttributeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupAttributesIdDeleteRequest} [adminSizeGroupAttributesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesIdPut(id: string, adminSizeGroupAttributesIdDeleteRequest?: AdminSizeGroupAttributesIdDeleteRequest, options?: any): AxiosPromise<SizeGroupAttributeAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupAttributesIdPut(id, adminSizeGroupAttributesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupAttributesGetRequest} [adminSizeGroupAttributesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupAttributesPost(adminSizeGroupAttributesGetRequest?: AdminSizeGroupAttributesGetRequest, options?: any): AxiosPromise<SizeGroupAttributeAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupAttributesPost(adminSizeGroupAttributesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSizeGroupAttributesApi - object-oriented interface
 * @export
 * @class AdminSizeGroupAttributesApi
 * @extends {BaseAPI}
 */
export class AdminSizeGroupAttributesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} sizeGroupId 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributesApi
     */
    public adminSizeGroupAttributesGet(pageIndex: number, pageSize: number, sizeGroupId: string, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributesApiFp(this.configuration).adminSizeGroupAttributesGet(pageIndex, pageSize, sizeGroupId, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributesApi
     */
    public adminSizeGroupAttributesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributesApiFp(this.configuration).adminSizeGroupAttributesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributesApi
     */
    public adminSizeGroupAttributesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributesApiFp(this.configuration).adminSizeGroupAttributesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousSizeGroupAttributeId] 
     * @param {string} [nextSizeGroupAttributeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributesApi
     */
    public adminSizeGroupAttributesIdPatch(id: string, previousSizeGroupAttributeId?: string, nextSizeGroupAttributeId?: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributesApiFp(this.configuration).adminSizeGroupAttributesIdPatch(id, previousSizeGroupAttributeId, nextSizeGroupAttributeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminSizeGroupAttributesIdDeleteRequest} [adminSizeGroupAttributesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributesApi
     */
    public adminSizeGroupAttributesIdPut(id: string, adminSizeGroupAttributesIdDeleteRequest?: AdminSizeGroupAttributesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributesApiFp(this.configuration).adminSizeGroupAttributesIdPut(id, adminSizeGroupAttributesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminSizeGroupAttributesGetRequest} [adminSizeGroupAttributesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupAttributesApi
     */
    public adminSizeGroupAttributesPost(adminSizeGroupAttributesGetRequest?: AdminSizeGroupAttributesGetRequest, options?: AxiosRequestConfig) {
        return AdminSizeGroupAttributesApiFp(this.configuration).adminSizeGroupAttributesPost(adminSizeGroupAttributesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSizeGroupsApi - axios parameter creator
 * @export
 */
export const AdminSizeGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminSizeGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminSizeGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/SizeGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/SizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/SizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSizeGroupId] 
         * @param {string} [nextSizeGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdPatch: async (id: string, previousSizeGroupId?: string, nextSizeGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/SizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousSizeGroupId !== undefined) {
                localVarQueryParameter['previousSizeGroupId'] = previousSizeGroupId;
            }

            if (nextSizeGroupId !== undefined) {
                localVarQueryParameter['nextSizeGroupId'] = nextSizeGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupsIdDeleteRequest} [adminSizeGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdPut: async (id: string, adminSizeGroupsIdDeleteRequest?: AdminSizeGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizeGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/SizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizeGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupsGetRequest} [adminSizeGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsPost: async (adminSizeGroupsGetRequest?: AdminSizeGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/SizeGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizeGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSizeGroupsApi - functional programming interface
 * @export
 */
export const AdminSizeGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSizeGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupsGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSizeGroupId] 
         * @param {string} [nextSizeGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupsIdPatch(id: string, previousSizeGroupId?: string, nextSizeGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupsIdPatch(id, previousSizeGroupId, nextSizeGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupsIdDeleteRequest} [adminSizeGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupsIdPut(id: string, adminSizeGroupsIdDeleteRequest?: AdminSizeGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupsIdPut(id, adminSizeGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupsGetRequest} [adminSizeGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizeGroupsPost(adminSizeGroupsGetRequest?: AdminSizeGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizeGroupsPost(adminSizeGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSizeGroupsApi - factory interface
 * @export
 */
export const AdminSizeGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSizeGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<SizeGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminSizeGroupsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizeGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdGet(id: string, options?: any): AxiosPromise<SizeGroupAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousSizeGroupId] 
         * @param {string} [nextSizeGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdPatch(id: string, previousSizeGroupId?: string, nextSizeGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizeGroupsIdPatch(id, previousSizeGroupId, nextSizeGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizeGroupsIdDeleteRequest} [adminSizeGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsIdPut(id: string, adminSizeGroupsIdDeleteRequest?: AdminSizeGroupsIdDeleteRequest, options?: any): AxiosPromise<SizeGroupAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupsIdPut(id, adminSizeGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizeGroupsGetRequest} [adminSizeGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizeGroupsPost(adminSizeGroupsGetRequest?: AdminSizeGroupsGetRequest, options?: any): AxiosPromise<SizeGroupAdminViewModelApplicationResult> {
            return localVarFp.adminSizeGroupsPost(adminSizeGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSizeGroupsApi - object-oriented interface
 * @export
 * @class AdminSizeGroupsApi
 * @extends {BaseAPI}
 */
export class AdminSizeGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupsApi
     */
    public adminSizeGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupsApiFp(this.configuration).adminSizeGroupsGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupsApi
     */
    public adminSizeGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupsApiFp(this.configuration).adminSizeGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupsApi
     */
    public adminSizeGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupsApiFp(this.configuration).adminSizeGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousSizeGroupId] 
     * @param {string} [nextSizeGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupsApi
     */
    public adminSizeGroupsIdPatch(id: string, previousSizeGroupId?: string, nextSizeGroupId?: string, options?: AxiosRequestConfig) {
        return AdminSizeGroupsApiFp(this.configuration).adminSizeGroupsIdPatch(id, previousSizeGroupId, nextSizeGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminSizeGroupsIdDeleteRequest} [adminSizeGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupsApi
     */
    public adminSizeGroupsIdPut(id: string, adminSizeGroupsIdDeleteRequest?: AdminSizeGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminSizeGroupsApiFp(this.configuration).adminSizeGroupsIdPut(id, adminSizeGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminSizeGroupsGetRequest} [adminSizeGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizeGroupsApi
     */
    public adminSizeGroupsPost(adminSizeGroupsGetRequest?: AdminSizeGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminSizeGroupsApiFp(this.configuration).adminSizeGroupsPost(adminSizeGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSizesApi - axios parameter creator
 * @export
 */
export const AdminSizesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {Array<string>} [sizeGroupIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, sizeGroupIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminSizesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminSizesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Sizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (sizeGroupIds) {
                localVarQueryParameter['sizeGroupIds'] = sizeGroupIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizesIdDelete', 'id', id)
            const localVarPath = `/Admin/Sizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizesIdGet', 'id', id)
            const localVarPath = `/Admin/Sizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousId] 
         * @param {string} [nextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdPatch: async (id: string, previousId?: string, nextId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizesIdPatch', 'id', id)
            const localVarPath = `/Admin/Sizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousId !== undefined) {
                localVarQueryParameter['previousId'] = previousId;
            }

            if (nextId !== undefined) {
                localVarQueryParameter['nextId'] = nextId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizesIdDeleteRequest} [adminSizesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdPut: async (id: string, adminSizesIdDeleteRequest?: AdminSizesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminSizesIdPut', 'id', id)
            const localVarPath = `/Admin/Sizes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizesGetRequest} [adminSizesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesPost: async (adminSizesGetRequest?: AdminSizesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Sizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSizesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSizesApi - functional programming interface
 * @export
 */
export const AdminSizesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSizesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {Array<string>} [sizeGroupIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizesGet(pageIndex: number, pageSize: number, searchTerm?: string, sizeGroupIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizesGet(pageIndex, pageSize, searchTerm, sizeGroupIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousId] 
         * @param {string} [nextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizesIdPatch(id: string, previousId?: string, nextId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizesIdPatch(id, previousId, nextId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizesIdDeleteRequest} [adminSizesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizesIdPut(id: string, adminSizesIdDeleteRequest?: AdminSizesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizesIdPut(id, adminSizesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizesGetRequest} [adminSizesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSizesPost(adminSizesGetRequest?: AdminSizesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSizesPost(adminSizesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSizesApi - factory interface
 * @export
 */
export const AdminSizesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSizesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {Array<string>} [sizeGroupIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesGet(pageIndex: number, pageSize: number, searchTerm?: string, sizeGroupIds?: Array<string>, options?: any): AxiosPromise<SizeAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminSizesGet(pageIndex, pageSize, searchTerm, sizeGroupIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdGet(id: string, options?: any): AxiosPromise<SizeAdminViewModelApplicationResult> {
            return localVarFp.adminSizesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousId] 
         * @param {string} [nextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdPatch(id: string, previousId?: string, nextId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminSizesIdPatch(id, previousId, nextId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminSizesIdDeleteRequest} [adminSizesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesIdPut(id: string, adminSizesIdDeleteRequest?: AdminSizesIdDeleteRequest, options?: any): AxiosPromise<SizeAdminViewModelApplicationResult> {
            return localVarFp.adminSizesIdPut(id, adminSizesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminSizesGetRequest} [adminSizesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSizesPost(adminSizesGetRequest?: AdminSizesGetRequest, options?: any): AxiosPromise<SizeAdminViewModelApplicationResult> {
            return localVarFp.adminSizesPost(adminSizesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSizesApi - object-oriented interface
 * @export
 * @class AdminSizesApi
 * @extends {BaseAPI}
 */
export class AdminSizesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {Array<string>} [sizeGroupIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizesApi
     */
    public adminSizesGet(pageIndex: number, pageSize: number, searchTerm?: string, sizeGroupIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminSizesApiFp(this.configuration).adminSizesGet(pageIndex, pageSize, searchTerm, sizeGroupIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizesApi
     */
    public adminSizesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminSizesApiFp(this.configuration).adminSizesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizesApi
     */
    public adminSizesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminSizesApiFp(this.configuration).adminSizesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousId] 
     * @param {string} [nextId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizesApi
     */
    public adminSizesIdPatch(id: string, previousId?: string, nextId?: string, options?: AxiosRequestConfig) {
        return AdminSizesApiFp(this.configuration).adminSizesIdPatch(id, previousId, nextId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminSizesIdDeleteRequest} [adminSizesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizesApi
     */
    public adminSizesIdPut(id: string, adminSizesIdDeleteRequest?: AdminSizesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminSizesApiFp(this.configuration).adminSizesIdPut(id, adminSizesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminSizesGetRequest} [adminSizesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSizesApi
     */
    public adminSizesPost(adminSizesGetRequest?: AdminSizesGetRequest, options?: AxiosRequestConfig) {
        return AdminSizesApiFp(this.configuration).adminSizesPost(adminSizesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStoreCustomizedProductsApi - axios parameter creator
 * @export
 */
export const AdminStoreCustomizedProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoreCustomizedProductsIdPut: async (id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoreCustomizedProductsIdPut', 'id', id)
            const localVarPath = `/Admin/StoreCustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCustomizedProductsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoreCustomizedProductsStoreIdGet: async (pageIndex: number, pageSize: number, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStoreCustomizedProductsStoreIdGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStoreCustomizedProductsStoreIdGet', 'pageSize', pageSize)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('adminStoreCustomizedProductsStoreIdGet', 'storeId', storeId)
            const localVarPath = `/Admin/StoreCustomizedProducts/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStoreCustomizedProductsApi - functional programming interface
 * @export
 */
export const AdminStoreCustomizedProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStoreCustomizedProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoreCustomizedProductsIdPut(id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoreCustomizedProductsIdPut(id, storeCustomizedProductsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoreCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoreCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStoreCustomizedProductsApi - factory interface
 * @export
 */
export const AdminStoreCustomizedProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStoreCustomizedProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoreCustomizedProductsIdPut(id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options?: any): AxiosPromise<StoreCustomizedProductViewModelApplicationResult> {
            return localVarFp.adminStoreCustomizedProductsIdPut(id, storeCustomizedProductsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoreCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: any): AxiosPromise<StoreCustomizedProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStoreCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStoreCustomizedProductsApi - object-oriented interface
 * @export
 * @class AdminStoreCustomizedProductsApi
 * @extends {BaseAPI}
 */
export class AdminStoreCustomizedProductsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoreCustomizedProductsApi
     */
    public adminStoreCustomizedProductsIdPut(id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStoreCustomizedProductsApiFp(this.configuration).adminStoreCustomizedProductsIdPut(id, storeCustomizedProductsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoreCustomizedProductsApi
     */
    public adminStoreCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: AxiosRequestConfig) {
        return AdminStoreCustomizedProductsApiFp(this.configuration).adminStoreCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStoresApi - axios parameter creator
 * @export
 */
export const AdminStoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {StoreStatus} [storeStatusId] 
         * @param {string} [referenceNumber] 
         * @param {boolean} [onlyActiveStores] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {boolean} [hasCheckedOut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresGet: async (pageIndex: number, pageSize: number, searchTerm?: string, storeStatusId?: StoreStatus, referenceNumber?: string, onlyActiveStores?: boolean, orderManagerId?: string, salesManagerId?: string, hasCheckedOut?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStoresGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStoresGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (storeStatusId !== undefined) {
                localVarQueryParameter['storeStatusId'] = storeStatusId;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }

            if (onlyActiveStores !== undefined) {
                localVarQueryParameter['onlyActiveStores'] = onlyActiveStores;
            }

            if (orderManagerId !== undefined) {
                localVarQueryParameter['orderManagerId'] = orderManagerId;
            }

            if (salesManagerId !== undefined) {
                localVarQueryParameter['salesManagerId'] = salesManagerId;
            }

            if (hasCheckedOut !== undefined) {
                localVarQueryParameter['hasCheckedOut'] = hasCheckedOut;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresGetAvailableStoreStatusesForStoreStoreIdGet: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('adminStoresGetAvailableStoreStatusesForStoreStoreIdGet', 'storeId', storeId)
            const localVarPath = `/Admin/Stores/GetAvailableStoreStatusesForStore/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresGetStoreStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Stores/GetStoreStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: DeleteStore, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoresIdDelete', 'id', id)
            const localVarPath = `/Admin/Stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoresIdGet', 'id', id)
            const localVarPath = `/Admin/Stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminStoresIdDeleteRequest} [adminStoresIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresIdPut: async (id: string, adminStoresIdDeleteRequest?: AdminStoresIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStoresIdPut', 'id', id)
            const localVarPath = `/Admin/Stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoresIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStoresApi - functional programming interface
 * @export
 */
export const AdminStoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {StoreStatus} [storeStatusId] 
         * @param {string} [referenceNumber] 
         * @param {boolean} [onlyActiveStores] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {boolean} [hasCheckedOut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoresGet(pageIndex: number, pageSize: number, searchTerm?: string, storeStatusId?: StoreStatus, referenceNumber?: string, onlyActiveStores?: boolean, orderManagerId?: string, salesManagerId?: string, hasCheckedOut?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoresGet(pageIndex, pageSize, searchTerm, storeStatusId, referenceNumber, onlyActiveStores, orderManagerId, salesManagerId, hasCheckedOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStatusViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoresGetStoreStatusesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStatusViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoresGetStoreStatusesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: DeleteStore, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoresIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoresIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoresIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoresIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminStoresIdDeleteRequest} [adminStoresIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStoresIdPut(id: string, adminStoresIdDeleteRequest?: AdminStoresIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStoresIdPut(id, adminStoresIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStoresApi - factory interface
 * @export
 */
export const AdminStoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStoresApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {StoreStatus} [storeStatusId] 
         * @param {string} [referenceNumber] 
         * @param {boolean} [onlyActiveStores] 
         * @param {string} [orderManagerId] 
         * @param {string} [salesManagerId] 
         * @param {boolean} [hasCheckedOut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresGet(pageIndex: number, pageSize: number, searchTerm?: string, storeStatusId?: StoreStatus, referenceNumber?: string, onlyActiveStores?: boolean, orderManagerId?: string, salesManagerId?: string, hasCheckedOut?: boolean, options?: any): AxiosPromise<StoreAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStoresGet(pageIndex, pageSize, searchTerm, storeStatusId, referenceNumber, onlyActiveStores, orderManagerId, salesManagerId, hasCheckedOut, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(storeId: string, options?: any): AxiosPromise<StoreStatusViewModelListApplicationResult> {
            return localVarFp.adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresGetStoreStatusesGet(options?: any): AxiosPromise<StoreStatusViewModelListApplicationResult> {
            return localVarFp.adminStoresGetStoreStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: DeleteStore, ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStoresIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresIdGet(id: string, options?: any): AxiosPromise<StoreAdminViewModelApplicationResult> {
            return localVarFp.adminStoresIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminStoresIdDeleteRequest} [adminStoresIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStoresIdPut(id: string, adminStoresIdDeleteRequest?: AdminStoresIdDeleteRequest, options?: any): AxiosPromise<StoreAdminViewModelApplicationResult> {
            return localVarFp.adminStoresIdPut(id, adminStoresIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStoresApi - object-oriented interface
 * @export
 * @class AdminStoresApi
 * @extends {BaseAPI}
 */
export class AdminStoresApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {StoreStatus} [storeStatusId] 
     * @param {string} [referenceNumber] 
     * @param {boolean} [onlyActiveStores] 
     * @param {string} [orderManagerId] 
     * @param {string} [salesManagerId] 
     * @param {boolean} [hasCheckedOut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoresApi
     */
    public adminStoresGet(pageIndex: number, pageSize: number, searchTerm?: string, storeStatusId?: StoreStatus, referenceNumber?: string, onlyActiveStores?: boolean, orderManagerId?: string, salesManagerId?: string, hasCheckedOut?: boolean, options?: AxiosRequestConfig) {
        return AdminStoresApiFp(this.configuration).adminStoresGet(pageIndex, pageSize, searchTerm, storeStatusId, referenceNumber, onlyActiveStores, orderManagerId, salesManagerId, hasCheckedOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoresApi
     */
    public adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(storeId: string, options?: AxiosRequestConfig) {
        return AdminStoresApiFp(this.configuration).adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoresApi
     */
    public adminStoresGetStoreStatusesGet(options?: AxiosRequestConfig) {
        return AdminStoresApiFp(this.configuration).adminStoresGetStoreStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: DeleteStore, ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoresApi
     */
    public adminStoresIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminStoresApiFp(this.configuration).adminStoresIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoresApi
     */
    public adminStoresIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminStoresApiFp(this.configuration).adminStoresIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {AdminStoresIdDeleteRequest} [adminStoresIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStoresApi
     */
    public adminStoresIdPut(id: string, adminStoresIdDeleteRequest?: AdminStoresIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStoresApiFp(this.configuration).adminStoresIdPut(id, adminStoresIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStyleAdjustmentTypesApi - axios parameter creator
 * @export
 */
export const AdminStyleAdjustmentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageAdjustment 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesGet: async (pageIndex: number, pageAdjustment: number, styleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageAdjustment' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesGet', 'pageAdjustment', pageAdjustment)
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesGet', 'styleId', styleId)
            const localVarPath = `/Admin/StyleAdjustmentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageAdjustment !== undefined) {
                localVarQueryParameter['pageAdjustment'] = pageAdjustment;
            }

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} styleId 
         * @param {string} [styleAdjustmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet: async (styleId: string, styleAdjustmentTypeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet', 'styleId', styleId)
            const localVarPath = `/Admin/StyleAdjustmentTypes/GetAdjustmentTypesForStyle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }

            if (styleAdjustmentTypeId !== undefined) {
                localVarQueryParameter['styleAdjustmentTypeId'] = styleAdjustmentTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} adjustmentTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet: async (adjustmentTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adjustmentTypeId' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet', 'adjustmentTypeId', adjustmentTypeId)
            const localVarPath = `/Admin/StyleAdjustmentTypes/GetAdjustmentsForAdjustmentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (adjustmentTypeId !== undefined) {
                localVarQueryParameter['adjustmentTypeId'] = adjustmentTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesIdDelete', 'id', id)
            const localVarPath = `/Admin/StyleAdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesIdGet', 'id', id)
            const localVarPath = `/Admin/StyleAdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleAdjustmentTypesIdDeleteRequest} [adminStyleAdjustmentTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesIdPut: async (id: string, adminStyleAdjustmentTypesIdDeleteRequest?: AdminStyleAdjustmentTypesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleAdjustmentTypesIdPut', 'id', id)
            const localVarPath = `/Admin/StyleAdjustmentTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStyleAdjustmentTypesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleAdjustmentTypesGetRequest} [adminStyleAdjustmentTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesPost: async (adminStyleAdjustmentTypesGetRequest?: AdminStyleAdjustmentTypesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/StyleAdjustmentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStyleAdjustmentTypesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStyleAdjustmentTypesApi - functional programming interface
 * @export
 */
export const AdminStyleAdjustmentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStyleAdjustmentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageAdjustment 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesGet(pageIndex: number, pageAdjustment: number, styleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesGet(pageIndex, pageAdjustment, styleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} styleId 
         * @param {string} [styleAdjustmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(styleId: string, styleAdjustmentTypeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentTypeAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(styleId, styleAdjustmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} adjustmentTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(adjustmentTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdjustmentAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(adjustmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdjustmentTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleAdjustmentTypesIdDeleteRequest} [adminStyleAdjustmentTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesIdPut(id: string, adminStyleAdjustmentTypesIdDeleteRequest?: AdminStyleAdjustmentTypesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdjustmentTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesIdPut(id, adminStyleAdjustmentTypesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleAdjustmentTypesGetRequest} [adminStyleAdjustmentTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleAdjustmentTypesPost(adminStyleAdjustmentTypesGetRequest?: AdminStyleAdjustmentTypesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdjustmentTypeAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleAdjustmentTypesPost(adminStyleAdjustmentTypesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStyleAdjustmentTypesApi - factory interface
 * @export
 */
export const AdminStyleAdjustmentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStyleAdjustmentTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageAdjustment 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesGet(pageIndex: number, pageAdjustment: number, styleId: string, options?: any): AxiosPromise<StyleAdjustmentTypeAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesGet(pageIndex, pageAdjustment, styleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} styleId 
         * @param {string} [styleAdjustmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(styleId: string, styleAdjustmentTypeId?: string, options?: any): AxiosPromise<AdjustmentTypeAdminListViewModelListApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(styleId, styleAdjustmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} adjustmentTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(adjustmentTypeId: string, options?: any): AxiosPromise<AdjustmentAdminListViewModelListApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(adjustmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesIdGet(id: string, options?: any): AxiosPromise<StyleAdjustmentTypeAdminViewModelApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleAdjustmentTypesIdDeleteRequest} [adminStyleAdjustmentTypesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesIdPut(id: string, adminStyleAdjustmentTypesIdDeleteRequest?: AdminStyleAdjustmentTypesIdDeleteRequest, options?: any): AxiosPromise<StyleAdjustmentTypeAdminViewModelApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesIdPut(id, adminStyleAdjustmentTypesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleAdjustmentTypesGetRequest} [adminStyleAdjustmentTypesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleAdjustmentTypesPost(adminStyleAdjustmentTypesGetRequest?: AdminStyleAdjustmentTypesGetRequest, options?: any): AxiosPromise<StyleAdjustmentTypeAdminViewModelApplicationResult> {
            return localVarFp.adminStyleAdjustmentTypesPost(adminStyleAdjustmentTypesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStyleAdjustmentTypesApi - object-oriented interface
 * @export
 * @class AdminStyleAdjustmentTypesApi
 * @extends {BaseAPI}
 */
export class AdminStyleAdjustmentTypesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageAdjustment 
     * @param {string} styleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesGet(pageIndex: number, pageAdjustment: number, styleId: string, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesGet(pageIndex, pageAdjustment, styleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} styleId 
     * @param {string} [styleAdjustmentTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(styleId: string, styleAdjustmentTypeId?: string, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(styleId, styleAdjustmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} adjustmentTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(adjustmentTypeId: string, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(adjustmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminStyleAdjustmentTypesIdDeleteRequest} [adminStyleAdjustmentTypesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesIdPut(id: string, adminStyleAdjustmentTypesIdDeleteRequest?: AdminStyleAdjustmentTypesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesIdPut(id, adminStyleAdjustmentTypesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminStyleAdjustmentTypesGetRequest} [adminStyleAdjustmentTypesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleAdjustmentTypesApi
     */
    public adminStyleAdjustmentTypesPost(adminStyleAdjustmentTypesGetRequest?: AdminStyleAdjustmentTypesGetRequest, options?: AxiosRequestConfig) {
        return AdminStyleAdjustmentTypesApiFp(this.configuration).adminStyleAdjustmentTypesPost(adminStyleAdjustmentTypesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStyleGroupsApi - axios parameter creator
 * @export
 */
export const AdminStyleGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStyleGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStyleGroupsGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/StyleGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (isSystemManaged !== undefined) {
                localVarQueryParameter['isSystemManaged'] = isSystemManaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/StyleGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/StyleGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousStyleGroupId] 
         * @param {string} [nextStyleGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdPatch: async (id: string, previousStyleGroupId?: string, nextStyleGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleGroupsIdPatch', 'id', id)
            const localVarPath = `/Admin/StyleGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousStyleGroupId !== undefined) {
                localVarQueryParameter['previousStyleGroupId'] = previousStyleGroupId;
            }

            if (nextStyleGroupId !== undefined) {
                localVarQueryParameter['nextStyleGroupId'] = nextStyleGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleGroupsIdDeleteRequest} [adminStyleGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdPut: async (id: string, adminStyleGroupsIdDeleteRequest?: AdminStyleGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/StyleGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStyleGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleGroupsGetRequest} [adminStyleGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsPost: async (adminStyleGroupsGetRequest?: AdminStyleGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/StyleGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStyleGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStyleGroupsApi - functional programming interface
 * @export
 */
export const AdminStyleGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStyleGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousStyleGroupId] 
         * @param {string} [nextStyleGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleGroupsIdPatch(id: string, previousStyleGroupId?: string, nextStyleGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleGroupsIdPatch(id, previousStyleGroupId, nextStyleGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleGroupsIdDeleteRequest} [adminStyleGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleGroupsIdPut(id: string, adminStyleGroupsIdDeleteRequest?: AdminStyleGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleGroupsIdPut(id, adminStyleGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleGroupsGetRequest} [adminStyleGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleGroupsPost(adminStyleGroupsGetRequest?: AdminStyleGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleGroupsPost(adminStyleGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStyleGroupsApi - factory interface
 * @export
 */
export const AdminStyleGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStyleGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [isSystemManaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: any): AxiosPromise<StyleGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStyleGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStyleGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdGet(id: string, options?: any): AxiosPromise<StyleGroupAdminViewModelApplicationResult> {
            return localVarFp.adminStyleGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousStyleGroupId] 
         * @param {string} [nextStyleGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdPatch(id: string, previousStyleGroupId?: string, nextStyleGroupId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStyleGroupsIdPatch(id, previousStyleGroupId, nextStyleGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleGroupsIdDeleteRequest} [adminStyleGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsIdPut(id: string, adminStyleGroupsIdDeleteRequest?: AdminStyleGroupsIdDeleteRequest, options?: any): AxiosPromise<StyleGroupAdminViewModelApplicationResult> {
            return localVarFp.adminStyleGroupsIdPut(id, adminStyleGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleGroupsGetRequest} [adminStyleGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleGroupsPost(adminStyleGroupsGetRequest?: AdminStyleGroupsGetRequest, options?: any): AxiosPromise<StyleGroupAdminViewModelApplicationResult> {
            return localVarFp.adminStyleGroupsPost(adminStyleGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStyleGroupsApi - object-oriented interface
 * @export
 * @class AdminStyleGroupsApi
 * @extends {BaseAPI}
 */
export class AdminStyleGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [isSystemManaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleGroupsApi
     */
    public adminStyleGroupsGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, isSystemManaged?: boolean, options?: AxiosRequestConfig) {
        return AdminStyleGroupsApiFp(this.configuration).adminStyleGroupsGet(pageIndex, pageSize, searchTerm, isDisabled, isSystemManaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleGroupsApi
     */
    public adminStyleGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminStyleGroupsApiFp(this.configuration).adminStyleGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleGroupsApi
     */
    public adminStyleGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminStyleGroupsApiFp(this.configuration).adminStyleGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousStyleGroupId] 
     * @param {string} [nextStyleGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleGroupsApi
     */
    public adminStyleGroupsIdPatch(id: string, previousStyleGroupId?: string, nextStyleGroupId?: string, options?: AxiosRequestConfig) {
        return AdminStyleGroupsApiFp(this.configuration).adminStyleGroupsIdPatch(id, previousStyleGroupId, nextStyleGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminStyleGroupsIdDeleteRequest} [adminStyleGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleGroupsApi
     */
    public adminStyleGroupsIdPut(id: string, adminStyleGroupsIdDeleteRequest?: AdminStyleGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStyleGroupsApiFp(this.configuration).adminStyleGroupsIdPut(id, adminStyleGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminStyleGroupsGetRequest} [adminStyleGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleGroupsApi
     */
    public adminStyleGroupsPost(adminStyleGroupsGetRequest?: AdminStyleGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminStyleGroupsApiFp(this.configuration).adminStyleGroupsPost(adminStyleGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStylePricesApi - axios parameter creator
 * @export
 */
export const AdminStylePricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesGet: async (pageIndex: number, pageSize: number, linkedEntityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStylePricesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStylePricesGet', 'pageSize', pageSize)
            // verify required parameter 'linkedEntityId' is not null or undefined
            assertParamExists('adminStylePricesGet', 'linkedEntityId', linkedEntityId)
            const localVarPath = `/Admin/StylePrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (linkedEntityId !== undefined) {
                localVarQueryParameter['linkedEntityId'] = linkedEntityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylePricesIdDelete', 'id', id)
            const localVarPath = `/Admin/StylePrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylePricesIdGet', 'id', id)
            const localVarPath = `/Admin/StylePrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesIdPut: async (id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylePricesIdPut', 'id', id)
            const localVarPath = `/Admin/StylePrices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesPost: async (adminColourPricesGetRequest?: AdminColourPricesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/StylePrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminColourPricesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStylePricesApi - functional programming interface
 * @export
 */
export const AdminStylePricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStylePricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylePricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylePricesGet(pageIndex, pageSize, linkedEntityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylePricesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylePricesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylePricesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylePricesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylePricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylePricesIdPut(id, adminColourPricesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylePricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylePricesPost(adminColourPricesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStylePricesApi - factory interface
 * @export
 */
export const AdminStylePricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStylePricesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} linkedEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: any): AxiosPromise<PriceAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStylePricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStylePricesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesIdGet(id: string, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminStylePricesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminStylePricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylePricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: any): AxiosPromise<PriceAdminViewModelApplicationResult> {
            return localVarFp.adminStylePricesPost(adminColourPricesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStylePricesApi - object-oriented interface
 * @export
 * @class AdminStylePricesApi
 * @extends {BaseAPI}
 */
export class AdminStylePricesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} linkedEntityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylePricesApi
     */
    public adminStylePricesGet(pageIndex: number, pageSize: number, linkedEntityId: string, options?: AxiosRequestConfig) {
        return AdminStylePricesApiFp(this.configuration).adminStylePricesGet(pageIndex, pageSize, linkedEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylePricesApi
     */
    public adminStylePricesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminStylePricesApiFp(this.configuration).adminStylePricesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylePricesApi
     */
    public adminStylePricesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminStylePricesApiFp(this.configuration).adminStylePricesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminColourPricesIdDeleteRequest} [adminColourPricesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylePricesApi
     */
    public adminStylePricesIdPut(id: string, adminColourPricesIdDeleteRequest?: AdminColourPricesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStylePricesApiFp(this.configuration).adminStylePricesIdPut(id, adminColourPricesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminColourPricesGetRequest} [adminColourPricesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylePricesApi
     */
    public adminStylePricesPost(adminColourPricesGetRequest?: AdminColourPricesGetRequest, options?: AxiosRequestConfig) {
        return AdminStylePricesApiFp(this.configuration).adminStylePricesPost(adminColourPricesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStyleSizeGroupsApi - axios parameter creator
 * @export
 */
export const AdminStyleSizeGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsGet: async (pageIndex: number, pageSize: number, styleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStyleSizeGroupsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStyleSizeGroupsGet', 'pageSize', pageSize)
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('adminStyleSizeGroupsGet', 'styleId', styleId)
            const localVarPath = `/Admin/StyleSizeGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} styleId 
         * @param {string} [sizeGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsGetSizeGroupsForStyleGet: async (styleId: string, sizeGroupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('adminStyleSizeGroupsGetSizeGroupsForStyleGet', 'styleId', styleId)
            const localVarPath = `/Admin/StyleSizeGroups/GetSizeGroupsForStyle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }

            if (sizeGroupId !== undefined) {
                localVarQueryParameter['sizeGroupId'] = sizeGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} sizeGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsGetSizesForSizeGroupGet: async (sizeGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sizeGroupId' is not null or undefined
            assertParamExists('adminStyleSizeGroupsGetSizesForSizeGroupGet', 'sizeGroupId', sizeGroupId)
            const localVarPath = `/Admin/StyleSizeGroups/GetSizesForSizeGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sizeGroupId !== undefined) {
                localVarQueryParameter['sizeGroupId'] = sizeGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleSizeGroupsIdDelete', 'id', id)
            const localVarPath = `/Admin/StyleSizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleSizeGroupsIdGet', 'id', id)
            const localVarPath = `/Admin/StyleSizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleSizeGroupsIdDeleteRequest} [adminStyleSizeGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsIdPut: async (id: string, adminStyleSizeGroupsIdDeleteRequest?: AdminStyleSizeGroupsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStyleSizeGroupsIdPut', 'id', id)
            const localVarPath = `/Admin/StyleSizeGroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStyleSizeGroupsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleSizeGroupsGetRequest} [adminStyleSizeGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsPost: async (adminStyleSizeGroupsGetRequest?: AdminStyleSizeGroupsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/StyleSizeGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStyleSizeGroupsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStyleSizeGroupsApi - functional programming interface
 * @export
 */
export const AdminStyleSizeGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStyleSizeGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsGet(pageIndex: number, pageSize: number, styleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleSizeGroupAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsGet(pageIndex, pageSize, styleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} styleId 
         * @param {string} [sizeGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsGetSizeGroupsForStyleGet(styleId: string, sizeGroupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeGroupAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsGetSizeGroupsForStyleGet(styleId, sizeGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} sizeGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsGetSizesForSizeGroupGet(sizeGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SizeAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsGetSizesForSizeGroupGet(sizeGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleSizeGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleSizeGroupsIdDeleteRequest} [adminStyleSizeGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsIdPut(id: string, adminStyleSizeGroupsIdDeleteRequest?: AdminStyleSizeGroupsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleSizeGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsIdPut(id, adminStyleSizeGroupsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleSizeGroupsGetRequest} [adminStyleSizeGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeGroupsPost(adminStyleSizeGroupsGetRequest?: AdminStyleSizeGroupsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleSizeGroupAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeGroupsPost(adminStyleSizeGroupsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStyleSizeGroupsApi - factory interface
 * @export
 */
export const AdminStyleSizeGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStyleSizeGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsGet(pageIndex: number, pageSize: number, styleId: string, options?: any): AxiosPromise<StyleSizeGroupAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStyleSizeGroupsGet(pageIndex, pageSize, styleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} styleId 
         * @param {string} [sizeGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsGetSizeGroupsForStyleGet(styleId: string, sizeGroupId?: string, options?: any): AxiosPromise<SizeGroupAdminListViewModelListApplicationResult> {
            return localVarFp.adminStyleSizeGroupsGetSizeGroupsForStyleGet(styleId, sizeGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} sizeGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsGetSizesForSizeGroupGet(sizeGroupId: string, options?: any): AxiosPromise<SizeAdminListViewModelListApplicationResult> {
            return localVarFp.adminStyleSizeGroupsGetSizesForSizeGroupGet(sizeGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStyleSizeGroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsIdGet(id: string, options?: any): AxiosPromise<StyleSizeGroupAdminViewModelApplicationResult> {
            return localVarFp.adminStyleSizeGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStyleSizeGroupsIdDeleteRequest} [adminStyleSizeGroupsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsIdPut(id: string, adminStyleSizeGroupsIdDeleteRequest?: AdminStyleSizeGroupsIdDeleteRequest, options?: any): AxiosPromise<StyleSizeGroupAdminViewModelApplicationResult> {
            return localVarFp.adminStyleSizeGroupsIdPut(id, adminStyleSizeGroupsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStyleSizeGroupsGetRequest} [adminStyleSizeGroupsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeGroupsPost(adminStyleSizeGroupsGetRequest?: AdminStyleSizeGroupsGetRequest, options?: any): AxiosPromise<StyleSizeGroupAdminViewModelApplicationResult> {
            return localVarFp.adminStyleSizeGroupsPost(adminStyleSizeGroupsGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStyleSizeGroupsApi - object-oriented interface
 * @export
 * @class AdminStyleSizeGroupsApi
 * @extends {BaseAPI}
 */
export class AdminStyleSizeGroupsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} styleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsGet(pageIndex: number, pageSize: number, styleId: string, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsGet(pageIndex, pageSize, styleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} styleId 
     * @param {string} [sizeGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsGetSizeGroupsForStyleGet(styleId: string, sizeGroupId?: string, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsGetSizeGroupsForStyleGet(styleId, sizeGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} sizeGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsGetSizesForSizeGroupGet(sizeGroupId: string, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsGetSizesForSizeGroupGet(sizeGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminStyleSizeGroupsIdDeleteRequest} [adminStyleSizeGroupsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsIdPut(id: string, adminStyleSizeGroupsIdDeleteRequest?: AdminStyleSizeGroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsIdPut(id, adminStyleSizeGroupsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminStyleSizeGroupsGetRequest} [adminStyleSizeGroupsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeGroupsApi
     */
    public adminStyleSizeGroupsPost(adminStyleSizeGroupsGetRequest?: AdminStyleSizeGroupsGetRequest, options?: AxiosRequestConfig) {
        return AdminStyleSizeGroupsApiFp(this.configuration).adminStyleSizeGroupsPost(adminStyleSizeGroupsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStyleSizeInconsistenciesApi - axios parameter creator
 * @export
 */
export const AdminStyleSizeInconsistenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {Array<string>} [styleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeInconsistenciesGet: async (pageIndex: number, pageSize: number, styleIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStyleSizeInconsistenciesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStyleSizeInconsistenciesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/StyleSizeInconsistencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (styleIds) {
                localVarQueryParameter['styleIds'] = styleIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<StyleSizeAdminUpdateModel>} [styleSizeAdminUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeInconsistenciesPut: async (styleSizeAdminUpdateModel?: Array<StyleSizeAdminUpdateModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/StyleSizeInconsistencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(styleSizeAdminUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStyleSizeInconsistenciesApi - functional programming interface
 * @export
 */
export const AdminStyleSizeInconsistenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStyleSizeInconsistenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {Array<string>} [styleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeInconsistenciesGet(pageIndex: number, pageSize: number, styleIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeInconsistenciesGet(pageIndex, pageSize, styleIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<StyleSizeAdminUpdateModel>} [styleSizeAdminUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStyleSizeInconsistenciesPut(styleSizeAdminUpdateModel?: Array<StyleSizeAdminUpdateModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStyleSizeInconsistenciesPut(styleSizeAdminUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStyleSizeInconsistenciesApi - factory interface
 * @export
 */
export const AdminStyleSizeInconsistenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStyleSizeInconsistenciesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {Array<string>} [styleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeInconsistenciesGet(pageIndex: number, pageSize: number, styleIds?: Array<string>, options?: any): AxiosPromise<StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStyleSizeInconsistenciesGet(pageIndex, pageSize, styleIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {Array<StyleSizeAdminUpdateModel>} [styleSizeAdminUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStyleSizeInconsistenciesPut(styleSizeAdminUpdateModel?: Array<StyleSizeAdminUpdateModel>, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStyleSizeInconsistenciesPut(styleSizeAdminUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStyleSizeInconsistenciesApi - object-oriented interface
 * @export
 * @class AdminStyleSizeInconsistenciesApi
 * @extends {BaseAPI}
 */
export class AdminStyleSizeInconsistenciesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {Array<string>} [styleIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeInconsistenciesApi
     */
    public adminStyleSizeInconsistenciesGet(pageIndex: number, pageSize: number, styleIds?: Array<string>, options?: AxiosRequestConfig) {
        return AdminStyleSizeInconsistenciesApiFp(this.configuration).adminStyleSizeInconsistenciesGet(pageIndex, pageSize, styleIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {Array<StyleSizeAdminUpdateModel>} [styleSizeAdminUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStyleSizeInconsistenciesApi
     */
    public adminStyleSizeInconsistenciesPut(styleSizeAdminUpdateModel?: Array<StyleSizeAdminUpdateModel>, options?: AxiosRequestConfig) {
        return AdminStyleSizeInconsistenciesApiFp(this.configuration).adminStyleSizeInconsistenciesPut(styleSizeAdminUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminStylesApi - axios parameter creator
 * @export
 */
export const AdminStylesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminStylesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminStylesGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Styles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }

            if (hasProductBuilderOptionsGenerationLogs !== undefined) {
                localVarQueryParameter['hasProductBuilderOptionsGenerationLogs'] = hasProductBuilderOptionsGenerationLogs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylesIdDelete', 'id', id)
            const localVarPath = `/Admin/Styles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylesIdGet', 'id', id)
            const localVarPath = `/Admin/Styles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousStyleId] 
         * @param {string} [nextStyleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdPatch: async (id: string, previousStyleId?: string, nextStyleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylesIdPatch', 'id', id)
            const localVarPath = `/Admin/Styles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (previousStyleId !== undefined) {
                localVarQueryParameter['previousStyleId'] = previousStyleId;
            }

            if (nextStyleId !== undefined) {
                localVarQueryParameter['nextStyleId'] = nextStyleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStylesIdDeleteRequest} [adminStylesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdPut: async (id: string, adminStylesIdDeleteRequest?: AdminStylesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminStylesIdPut', 'id', id)
            const localVarPath = `/Admin/Styles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStylesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStylesGetRequest} [adminStylesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesPost: async (adminStylesGetRequest?: AdminStylesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Styles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStylesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminStylesApi - functional programming interface
 * @export
 */
export const AdminStylesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminStylesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylesGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylesGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousStyleId] 
         * @param {string} [nextStyleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylesIdPatch(id: string, previousStyleId?: string, nextStyleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylesIdPatch(id, previousStyleId, nextStyleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStylesIdDeleteRequest} [adminStylesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylesIdPut(id: string, adminStylesIdDeleteRequest?: AdminStylesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylesIdPut(id, adminStylesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStylesGetRequest} [adminStylesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminStylesPost(adminStylesGetRequest?: AdminStylesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminStylesPost(adminStylesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminStylesApi - factory interface
 * @export
 */
export const AdminStylesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminStylesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {boolean} [isDisabled] 
         * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: any): AxiosPromise<StyleAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminStylesGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStylesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdGet(id: string, options?: any): AxiosPromise<StyleAdminViewModelApplicationResult> {
            return localVarFp.adminStylesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {string} [previousStyleId] 
         * @param {string} [nextStyleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdPatch(id: string, previousStyleId?: string, nextStyleId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminStylesIdPatch(id, previousStyleId, nextStyleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminStylesIdDeleteRequest} [adminStylesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesIdPut(id: string, adminStylesIdDeleteRequest?: AdminStylesIdDeleteRequest, options?: any): AxiosPromise<StyleAdminViewModelApplicationResult> {
            return localVarFp.adminStylesIdPut(id, adminStylesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminStylesGetRequest} [adminStylesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminStylesPost(adminStylesGetRequest?: AdminStylesGetRequest, options?: any): AxiosPromise<StyleAdminViewModelApplicationResult> {
            return localVarFp.adminStylesPost(adminStylesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminStylesApi - object-oriented interface
 * @export
 * @class AdminStylesApi
 * @extends {BaseAPI}
 */
export class AdminStylesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {boolean} [isDisabled] 
     * @param {boolean} [hasProductBuilderOptionsGenerationLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylesApi
     */
    public adminStylesGet(pageIndex: number, pageSize: number, searchTerm?: string, isDisabled?: boolean, hasProductBuilderOptionsGenerationLogs?: boolean, options?: AxiosRequestConfig) {
        return AdminStylesApiFp(this.configuration).adminStylesGet(pageIndex, pageSize, searchTerm, isDisabled, hasProductBuilderOptionsGenerationLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylesApi
     */
    public adminStylesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminStylesApiFp(this.configuration).adminStylesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylesApi
     */
    public adminStylesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminStylesApiFp(this.configuration).adminStylesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {string} [previousStyleId] 
     * @param {string} [nextStyleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylesApi
     */
    public adminStylesIdPatch(id: string, previousStyleId?: string, nextStyleId?: string, options?: AxiosRequestConfig) {
        return AdminStylesApiFp(this.configuration).adminStylesIdPatch(id, previousStyleId, nextStyleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminStylesIdDeleteRequest} [adminStylesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylesApi
     */
    public adminStylesIdPut(id: string, adminStylesIdDeleteRequest?: AdminStylesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminStylesApiFp(this.configuration).adminStylesIdPut(id, adminStylesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminStylesGetRequest} [adminStylesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminStylesApi
     */
    public adminStylesPost(adminStylesGetRequest?: AdminStylesGetRequest, options?: AxiosRequestConfig) {
        return AdminStylesApiFp(this.configuration).adminStylesPost(adminStylesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTaskMessagesApi - axios parameter creator
 * @export
 */
export const AdminTaskMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesGet: async (pageIndex: number, pageSize: number, taskId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminTaskMessagesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminTaskMessagesGet', 'pageSize', pageSize)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('adminTaskMessagesGet', 'taskId', taskId)
            const localVarPath = `/Admin/TaskMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminTaskMessagesIdDelete', 'id', id)
            const localVarPath = `/Admin/TaskMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminTaskMessagesIdGet', 'id', id)
            const localVarPath = `/Admin/TaskMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminTaskMessagesIdDeleteRequest} [adminTaskMessagesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesIdPut: async (id: string, adminTaskMessagesIdDeleteRequest?: AdminTaskMessagesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminTaskMessagesIdPut', 'id', id)
            const localVarPath = `/Admin/TaskMessages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTaskMessagesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminTaskMessagesGetRequest} [adminTaskMessagesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesPost: async (adminTaskMessagesGetRequest?: AdminTaskMessagesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/TaskMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTaskMessagesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTaskMessagesApi - functional programming interface
 * @export
 */
export const AdminTaskMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTaskMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTaskMessagesGet(pageIndex: number, pageSize: number, taskId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskMessageAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTaskMessagesGet(pageIndex, pageSize, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTaskMessagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTaskMessagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTaskMessagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskMessageAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTaskMessagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminTaskMessagesIdDeleteRequest} [adminTaskMessagesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTaskMessagesIdPut(id: string, adminTaskMessagesIdDeleteRequest?: AdminTaskMessagesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskMessageAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTaskMessagesIdPut(id, adminTaskMessagesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminTaskMessagesGetRequest} [adminTaskMessagesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTaskMessagesPost(adminTaskMessagesGetRequest?: AdminTaskMessagesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskMessageAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTaskMessagesPost(adminTaskMessagesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTaskMessagesApi - factory interface
 * @export
 */
export const AdminTaskMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTaskMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesGet(pageIndex: number, pageSize: number, taskId: string, options?: any): AxiosPromise<TaskMessageAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminTaskMessagesGet(pageIndex, pageSize, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminTaskMessagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesIdGet(id: string, options?: any): AxiosPromise<TaskMessageAdminViewModelApplicationResult> {
            return localVarFp.adminTaskMessagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminTaskMessagesIdDeleteRequest} [adminTaskMessagesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesIdPut(id: string, adminTaskMessagesIdDeleteRequest?: AdminTaskMessagesIdDeleteRequest, options?: any): AxiosPromise<TaskMessageAdminViewModelApplicationResult> {
            return localVarFp.adminTaskMessagesIdPut(id, adminTaskMessagesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminTaskMessagesGetRequest} [adminTaskMessagesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaskMessagesPost(adminTaskMessagesGetRequest?: AdminTaskMessagesGetRequest, options?: any): AxiosPromise<TaskMessageAdminViewModelApplicationResult> {
            return localVarFp.adminTaskMessagesPost(adminTaskMessagesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTaskMessagesApi - object-oriented interface
 * @export
 * @class AdminTaskMessagesApi
 * @extends {BaseAPI}
 */
export class AdminTaskMessagesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTaskMessagesApi
     */
    public adminTaskMessagesGet(pageIndex: number, pageSize: number, taskId: string, options?: AxiosRequestConfig) {
        return AdminTaskMessagesApiFp(this.configuration).adminTaskMessagesGet(pageIndex, pageSize, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTaskMessagesApi
     */
    public adminTaskMessagesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminTaskMessagesApiFp(this.configuration).adminTaskMessagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTaskMessagesApi
     */
    public adminTaskMessagesIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminTaskMessagesApiFp(this.configuration).adminTaskMessagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {AdminTaskMessagesIdDeleteRequest} [adminTaskMessagesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTaskMessagesApi
     */
    public adminTaskMessagesIdPut(id: string, adminTaskMessagesIdDeleteRequest?: AdminTaskMessagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminTaskMessagesApiFp(this.configuration).adminTaskMessagesIdPut(id, adminTaskMessagesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {AdminTaskMessagesGetRequest} [adminTaskMessagesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTaskMessagesApi
     */
    public adminTaskMessagesPost(adminTaskMessagesGetRequest?: AdminTaskMessagesGetRequest, options?: AxiosRequestConfig) {
        return AdminTaskMessagesApiFp(this.configuration).adminTaskMessagesPost(adminTaskMessagesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTasksApi - axios parameter creator
 * @export
 */
export const AdminTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} isAssignedToMe 
         * @param {string} [orderId] 
         * @param {string} [enquiryId] 
         * @param {string} [fromAspNetUserId] 
         * @param {CliftonTaskStatus} [taskStatusId] 
         * @param {CliftonTaskPriority} [taskPriorityId] 
         * @param {TaskType} [taskType] 
         * @param {string} [searchTerm] 
         * @param {string} [assignedToUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksGet: async (pageIndex: number, pageSize: number, isAssignedToMe: boolean, orderId?: string, enquiryId?: string, fromAspNetUserId?: string, taskStatusId?: CliftonTaskStatus, taskPriorityId?: CliftonTaskPriority, taskType?: TaskType, searchTerm?: string, assignedToUserId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminTasksGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminTasksGet', 'pageSize', pageSize)
            // verify required parameter 'isAssignedToMe' is not null or undefined
            assertParamExists('adminTasksGet', 'isAssignedToMe', isAssignedToMe)
            const localVarPath = `/Admin/Tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (enquiryId !== undefined) {
                localVarQueryParameter['enquiryId'] = enquiryId;
            }

            if (fromAspNetUserId !== undefined) {
                localVarQueryParameter['fromAspNetUserId'] = fromAspNetUserId;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['isAssignedToMe'] = isAssignedToMe;
            }

            if (taskStatusId !== undefined) {
                localVarQueryParameter['taskStatusId'] = taskStatusId;
            }

            if (taskPriorityId !== undefined) {
                localVarQueryParameter['taskPriorityId'] = taskPriorityId;
            }

            if (taskType !== undefined) {
                localVarQueryParameter['taskType'] = taskType;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (assignedToUserId !== undefined) {
                localVarQueryParameter['assignedToUserId'] = assignedToUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminTasksIdDelete', 'id', id)
            const localVarPath = `/Admin/Tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminTasksIdGet', 'id', id)
            const localVarPath = `/Admin/Tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminTasksIdDeleteRequest} [adminTasksIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksIdPut: async (id: string, adminTasksIdDeleteRequest?: AdminTasksIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminTasksIdPut', 'id', id)
            const localVarPath = `/Admin/Tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTasksIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminTasksGetRequest} [adminTasksGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksPost: async (adminTasksGetRequest?: AdminTasksGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTasksGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTasksApi - functional programming interface
 * @export
 */
export const AdminTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} isAssignedToMe 
         * @param {string} [orderId] 
         * @param {string} [enquiryId] 
         * @param {string} [fromAspNetUserId] 
         * @param {CliftonTaskStatus} [taskStatusId] 
         * @param {CliftonTaskPriority} [taskPriorityId] 
         * @param {TaskType} [taskType] 
         * @param {string} [searchTerm] 
         * @param {string} [assignedToUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTasksGet(pageIndex: number, pageSize: number, isAssignedToMe: boolean, orderId?: string, enquiryId?: string, fromAspNetUserId?: string, taskStatusId?: CliftonTaskStatus, taskPriorityId?: CliftonTaskPriority, taskType?: TaskType, searchTerm?: string, assignedToUserId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTasksGet(pageIndex, pageSize, isAssignedToMe, orderId, enquiryId, fromAspNetUserId, taskStatusId, taskPriorityId, taskType, searchTerm, assignedToUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTasksIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTasksIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTasksIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTasksIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminTasksIdDeleteRequest} [adminTasksIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTasksIdPut(id: string, adminTasksIdDeleteRequest?: AdminTasksIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTasksIdPut(id, adminTasksIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminTasksGetRequest} [adminTasksGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTasksPost(adminTasksGetRequest?: AdminTasksGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTasksPost(adminTasksGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTasksApi - factory interface
 * @export
 */
export const AdminTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTasksApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} isAssignedToMe 
         * @param {string} [orderId] 
         * @param {string} [enquiryId] 
         * @param {string} [fromAspNetUserId] 
         * @param {CliftonTaskStatus} [taskStatusId] 
         * @param {CliftonTaskPriority} [taskPriorityId] 
         * @param {TaskType} [taskType] 
         * @param {string} [searchTerm] 
         * @param {string} [assignedToUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksGet(pageIndex: number, pageSize: number, isAssignedToMe: boolean, orderId?: string, enquiryId?: string, fromAspNetUserId?: string, taskStatusId?: CliftonTaskStatus, taskPriorityId?: CliftonTaskPriority, taskType?: TaskType, searchTerm?: string, assignedToUserId?: string, options?: any): AxiosPromise<TaskAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminTasksGet(pageIndex, pageSize, isAssignedToMe, orderId, enquiryId, fromAspNetUserId, taskStatusId, taskPriorityId, taskType, searchTerm, assignedToUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminTasksIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksIdGet(id: string, options?: any): AxiosPromise<TaskAdminViewModelApplicationResult> {
            return localVarFp.adminTasksIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {string} id 
         * @param {AdminTasksIdDeleteRequest} [adminTasksIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksIdPut(id: string, adminTasksIdDeleteRequest?: AdminTasksIdDeleteRequest, options?: any): AxiosPromise<TaskAdminViewModelApplicationResult> {
            return localVarFp.adminTasksIdPut(id, adminTasksIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {AdminTasksGetRequest} [adminTasksGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTasksPost(adminTasksGetRequest?: AdminTasksGetRequest, options?: any): AxiosPromise<TaskAdminViewModelApplicationResult> {
            return localVarFp.adminTasksPost(adminTasksGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTasksApi - object-oriented interface
 * @export
 * @class AdminTasksApi
 * @extends {BaseAPI}
 */
export class AdminTasksApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {boolean} isAssignedToMe 
     * @param {string} [orderId] 
     * @param {string} [enquiryId] 
     * @param {string} [fromAspNetUserId] 
     * @param {CliftonTaskStatus} [taskStatusId] 
     * @param {CliftonTaskPriority} [taskPriorityId] 
     * @param {TaskType} [taskType] 
     * @param {string} [searchTerm] 
     * @param {string} [assignedToUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public adminTasksGet(pageIndex: number, pageSize: number, isAssignedToMe: boolean, orderId?: string, enquiryId?: string, fromAspNetUserId?: string, taskStatusId?: CliftonTaskStatus, taskPriorityId?: CliftonTaskPriority, taskType?: TaskType, searchTerm?: string, assignedToUserId?: string, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).adminTasksGet(pageIndex, pageSize, isAssignedToMe, orderId, enquiryId, fromAspNetUserId, taskStatusId, taskPriorityId, taskType, searchTerm, assignedToUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public adminTasksIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).adminTasksIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public adminTasksIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).adminTasksIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {string} id 
     * @param {AdminTasksIdDeleteRequest} [adminTasksIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public adminTasksIdPut(id: string, adminTasksIdDeleteRequest?: AdminTasksIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).adminTasksIdPut(id, adminTasksIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {AdminTasksGetRequest} [adminTasksGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTasksApi
     */
    public adminTasksPost(adminTasksGetRequest?: AdminTasksGetRequest, options?: AxiosRequestConfig) {
        return AdminTasksApiFp(this.configuration).adminTasksPost(adminTasksGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminUsersApi - axios parameter creator
 * @export
 */
export const AdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {Array<string>} [roles] 
         * @param {boolean} [isDefaultEnquiryOrderManager] 
         * @param {boolean} [isDefaultEnquirySalesManager] 
         * @param {boolean} [isDefaultStripeOrderManager] 
         * @param {boolean} [isDefaultStripeSalesManager] 
         * @param {boolean} [isDefaultBankTransferOrderManager] 
         * @param {boolean} [isDefaultBankTransferSalesManager] 
         * @param {boolean} [isDefaultStoreOrderManager] 
         * @param {boolean} [isDefaultStoreSalesManager] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGet: async (pageIndex: number, pageSize: number, username?: string, email?: string, roles?: Array<string>, isDefaultEnquiryOrderManager?: boolean, isDefaultEnquirySalesManager?: boolean, isDefaultStripeOrderManager?: boolean, isDefaultStripeSalesManager?: boolean, isDefaultBankTransferOrderManager?: boolean, isDefaultBankTransferSalesManager?: boolean, isDefaultStoreOrderManager?: boolean, isDefaultStoreSalesManager?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminUsersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminUsersGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (isDefaultEnquiryOrderManager !== undefined) {
                localVarQueryParameter['isDefaultEnquiryOrderManager'] = isDefaultEnquiryOrderManager;
            }

            if (isDefaultEnquirySalesManager !== undefined) {
                localVarQueryParameter['isDefaultEnquirySalesManager'] = isDefaultEnquirySalesManager;
            }

            if (isDefaultStripeOrderManager !== undefined) {
                localVarQueryParameter['isDefaultStripeOrderManager'] = isDefaultStripeOrderManager;
            }

            if (isDefaultStripeSalesManager !== undefined) {
                localVarQueryParameter['isDefaultStripeSalesManager'] = isDefaultStripeSalesManager;
            }

            if (isDefaultBankTransferOrderManager !== undefined) {
                localVarQueryParameter['isDefaultBankTransferOrderManager'] = isDefaultBankTransferOrderManager;
            }

            if (isDefaultBankTransferSalesManager !== undefined) {
                localVarQueryParameter['isDefaultBankTransferSalesManager'] = isDefaultBankTransferSalesManager;
            }

            if (isDefaultStoreOrderManager !== undefined) {
                localVarQueryParameter['isDefaultStoreOrderManager'] = isDefaultStoreOrderManager;
            }

            if (isDefaultStoreSalesManager !== undefined) {
                localVarQueryParameter['isDefaultStoreSalesManager'] = isDefaultStoreSalesManager;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ViewOrderManagers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGetOrderManagersGet: async (pageIndex: number, pageSize: number, username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminUsersGetOrderManagersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminUsersGetOrderManagersGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Users/GetOrderManagers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGetUsersForOrderGet: async (pageIndex: number, pageSize: number, username?: string, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminUsersGetUsersForOrderGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminUsersGetUsersForOrderGet', 'pageSize', pageSize)
            const localVarPath = `/Admin/Users/GetUsersForOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUsersIdGet', 'id', id)
            const localVarPath = `/Admin/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {string} id 
         * @param {AdminUsersIdGetRequest} [adminUsersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersIdPut: async (id: string, adminUsersIdGetRequest?: AdminUsersIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUsersIdPut', 'id', id)
            const localVarPath = `/Admin/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUsersIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {AdminUsersGetRequest} [adminUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersPost: async (adminUsersGetRequest?: AdminUsersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUsersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUsersApi - functional programming interface
 * @export
 */
export const AdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {Array<string>} [roles] 
         * @param {boolean} [isDefaultEnquiryOrderManager] 
         * @param {boolean} [isDefaultEnquirySalesManager] 
         * @param {boolean} [isDefaultStripeOrderManager] 
         * @param {boolean} [isDefaultStripeSalesManager] 
         * @param {boolean} [isDefaultBankTransferOrderManager] 
         * @param {boolean} [isDefaultBankTransferSalesManager] 
         * @param {boolean} [isDefaultStoreOrderManager] 
         * @param {boolean} [isDefaultStoreSalesManager] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersGet(pageIndex: number, pageSize: number, username?: string, email?: string, roles?: Array<string>, isDefaultEnquiryOrderManager?: boolean, isDefaultEnquirySalesManager?: boolean, isDefaultStripeOrderManager?: boolean, isDefaultStripeSalesManager?: boolean, isDefaultBankTransferOrderManager?: boolean, isDefaultBankTransferSalesManager?: boolean, isDefaultStoreOrderManager?: boolean, isDefaultStoreSalesManager?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersGet(pageIndex, pageSize, username, email, roles, isDefaultEnquiryOrderManager, isDefaultEnquirySalesManager, isDefaultStripeOrderManager, isDefaultStripeSalesManager, isDefaultBankTransferOrderManager, isDefaultBankTransferSalesManager, isDefaultStoreOrderManager, isDefaultStoreSalesManager, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ViewOrderManagers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersGetOrderManagersGet(pageIndex: number, pageSize: number, username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSelectModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersGetOrderManagersGet(pageIndex, pageSize, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersGetUsersForOrderGet(pageIndex: number, pageSize: number, username?: string, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersGetUsersForOrderGet(pageIndex, pageSize, username, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {string} id 
         * @param {AdminUsersIdGetRequest} [adminUsersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersIdPut(id: string, adminUsersIdGetRequest?: AdminUsersIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersIdPut(id, adminUsersIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {AdminUsersGetRequest} [adminUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersPost(adminUsersGetRequest?: AdminUsersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersPost(adminUsersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUsersApi - factory interface
 * @export
 */
export const AdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {Array<string>} [roles] 
         * @param {boolean} [isDefaultEnquiryOrderManager] 
         * @param {boolean} [isDefaultEnquirySalesManager] 
         * @param {boolean} [isDefaultStripeOrderManager] 
         * @param {boolean} [isDefaultStripeSalesManager] 
         * @param {boolean} [isDefaultBankTransferOrderManager] 
         * @param {boolean} [isDefaultBankTransferSalesManager] 
         * @param {boolean} [isDefaultStoreOrderManager] 
         * @param {boolean} [isDefaultStoreSalesManager] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGet(pageIndex: number, pageSize: number, username?: string, email?: string, roles?: Array<string>, isDefaultEnquiryOrderManager?: boolean, isDefaultEnquirySalesManager?: boolean, isDefaultStripeOrderManager?: boolean, isDefaultStripeSalesManager?: boolean, isDefaultBankTransferOrderManager?: boolean, isDefaultBankTransferSalesManager?: boolean, isDefaultStoreOrderManager?: boolean, isDefaultStoreSalesManager?: boolean, options?: any): AxiosPromise<UserAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminUsersGet(pageIndex, pageSize, username, email, roles, isDefaultEnquiryOrderManager, isDefaultEnquirySalesManager, isDefaultStripeOrderManager, isDefaultStripeSalesManager, isDefaultBankTransferOrderManager, isDefaultBankTransferSalesManager, isDefaultStoreOrderManager, isDefaultStoreSalesManager, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ViewOrderManagers)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGetOrderManagersGet(pageIndex: number, pageSize: number, username?: string, options?: any): AxiosPromise<UserSelectModelPaginatedListApplicationResult> {
            return localVarFp.adminUsersGetOrderManagersGet(pageIndex, pageSize, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageOrders)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGetUsersForOrderGet(pageIndex: number, pageSize: number, username?: string, email?: string, options?: any): AxiosPromise<UserAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminUsersGetUsersForOrderGet(pageIndex, pageSize, username, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersIdGet(id: string, options?: any): AxiosPromise<UserAdminViewModelApplicationResult> {
            return localVarFp.adminUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {string} id 
         * @param {AdminUsersIdGetRequest} [adminUsersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersIdPut(id: string, adminUsersIdGetRequest?: AdminUsersIdGetRequest, options?: any): AxiosPromise<UserAdminViewModelApplicationResult> {
            return localVarFp.adminUsersIdPut(id, adminUsersIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageUsers)
         * @param {AdminUsersGetRequest} [adminUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersPost(adminUsersGetRequest?: AdminUsersGetRequest, options?: any): AxiosPromise<UserAdminViewModelApplicationResult> {
            return localVarFp.adminUsersPost(adminUsersGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUsersApi - object-oriented interface
 * @export
 * @class AdminUsersApi
 * @extends {BaseAPI}
 */
export class AdminUsersApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageUsers)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [username] 
     * @param {string} [email] 
     * @param {Array<string>} [roles] 
     * @param {boolean} [isDefaultEnquiryOrderManager] 
     * @param {boolean} [isDefaultEnquirySalesManager] 
     * @param {boolean} [isDefaultStripeOrderManager] 
     * @param {boolean} [isDefaultStripeSalesManager] 
     * @param {boolean} [isDefaultBankTransferOrderManager] 
     * @param {boolean} [isDefaultBankTransferSalesManager] 
     * @param {boolean} [isDefaultStoreOrderManager] 
     * @param {boolean} [isDefaultStoreSalesManager] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUsersGet(pageIndex: number, pageSize: number, username?: string, email?: string, roles?: Array<string>, isDefaultEnquiryOrderManager?: boolean, isDefaultEnquirySalesManager?: boolean, isDefaultStripeOrderManager?: boolean, isDefaultStripeSalesManager?: boolean, isDefaultBankTransferOrderManager?: boolean, isDefaultBankTransferSalesManager?: boolean, isDefaultStoreOrderManager?: boolean, isDefaultStoreSalesManager?: boolean, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUsersGet(pageIndex, pageSize, username, email, roles, isDefaultEnquiryOrderManager, isDefaultEnquirySalesManager, isDefaultStripeOrderManager, isDefaultStripeSalesManager, isDefaultBankTransferOrderManager, isDefaultBankTransferSalesManager, isDefaultStoreOrderManager, isDefaultStoreSalesManager, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ViewOrderManagers)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [username] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUsersGetOrderManagersGet(pageIndex: number, pageSize: number, username?: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUsersGetOrderManagersGet(pageIndex, pageSize, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageOrders)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [username] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUsersGetUsersForOrderGet(pageIndex: number, pageSize: number, username?: string, email?: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUsersGetUsersForOrderGet(pageIndex, pageSize, username, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageUsers)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUsersIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageUsers)
     * @param {string} id 
     * @param {AdminUsersIdGetRequest} [adminUsersIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUsersIdPut(id: string, adminUsersIdGetRequest?: AdminUsersIdGetRequest, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUsersIdPut(id, adminUsersIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageUsers)
     * @param {AdminUsersGetRequest} [adminUsersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public adminUsersPost(adminUsersGetRequest?: AdminUsersGetRequest, options?: AxiosRequestConfig) {
        return AdminUsersApiFp(this.configuration).adminUsersPost(adminUsersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminVouchersApi - axios parameter creator
 * @export
 */
export const AdminVouchersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} onlyValidVouchers 
         * @param {VoucherType} [voucherType] 
         * @param {VoucherApplyToType} [voucherApplyToType] 
         * @param {string} [searchTerm] 
         * @param {string} [currentVoucherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersGet: async (pageIndex: number, pageSize: number, onlyValidVouchers: boolean, voucherType?: VoucherType, voucherApplyToType?: VoucherApplyToType, searchTerm?: string, currentVoucherId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('adminVouchersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminVouchersGet', 'pageSize', pageSize)
            // verify required parameter 'onlyValidVouchers' is not null or undefined
            assertParamExists('adminVouchersGet', 'onlyValidVouchers', onlyValidVouchers)
            const localVarPath = `/Admin/Vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (voucherType !== undefined) {
                localVarQueryParameter['voucherType'] = voucherType;
            }

            if (voucherApplyToType !== undefined) {
                localVarQueryParameter['voucherApplyToType'] = voucherApplyToType;
            }

            if (onlyValidVouchers !== undefined) {
                localVarQueryParameter['onlyValidVouchers'] = onlyValidVouchers;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (currentVoucherId !== undefined) {
                localVarQueryParameter['currentVoucherId'] = currentVoucherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminVouchersIdDelete', 'id', id)
            const localVarPath = `/Admin/Vouchers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminVouchersIdGet', 'id', id)
            const localVarPath = `/Admin/Vouchers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminVouchersIdDeleteRequest} [adminVouchersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersIdPut: async (id: string, adminVouchersIdDeleteRequest?: AdminVouchersIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminVouchersIdPut', 'id', id)
            const localVarPath = `/Admin/Vouchers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVouchersIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminVouchersGetRequest} [adminVouchersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersPost: async (adminVouchersGetRequest?: AdminVouchersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/Vouchers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminVouchersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVouchersApi - functional programming interface
 * @export
 */
export const AdminVouchersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVouchersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} onlyValidVouchers 
         * @param {VoucherType} [voucherType] 
         * @param {VoucherApplyToType} [voucherApplyToType] 
         * @param {string} [searchTerm] 
         * @param {string} [currentVoucherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVouchersGet(pageIndex: number, pageSize: number, onlyValidVouchers: boolean, voucherType?: VoucherType, voucherApplyToType?: VoucherApplyToType, searchTerm?: string, currentVoucherId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVouchersGet(pageIndex, pageSize, onlyValidVouchers, voucherType, voucherApplyToType, searchTerm, currentVoucherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVouchersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVouchersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVouchersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVouchersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminVouchersIdDeleteRequest} [adminVouchersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVouchersIdPut(id: string, adminVouchersIdDeleteRequest?: AdminVouchersIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVouchersIdPut(id, adminVouchersIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminVouchersGetRequest} [adminVouchersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVouchersPost(adminVouchersGetRequest?: AdminVouchersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherAdminViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVouchersPost(adminVouchersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminVouchersApi - factory interface
 * @export
 */
export const AdminVouchersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVouchersApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} onlyValidVouchers 
         * @param {VoucherType} [voucherType] 
         * @param {VoucherApplyToType} [voucherApplyToType] 
         * @param {string} [searchTerm] 
         * @param {string} [currentVoucherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersGet(pageIndex: number, pageSize: number, onlyValidVouchers: boolean, voucherType?: VoucherType, voucherApplyToType?: VoucherApplyToType, searchTerm?: string, currentVoucherId?: string, options?: any): AxiosPromise<VoucherAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.adminVouchersGet(pageIndex, pageSize, onlyValidVouchers, voucherType, voucherApplyToType, searchTerm, currentVoucherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.adminVouchersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersIdGet(id: string, options?: any): AxiosPromise<VoucherAdminViewModelApplicationResult> {
            return localVarFp.adminVouchersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {string} id 
         * @param {AdminVouchersIdDeleteRequest} [adminVouchersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersIdPut(id: string, adminVouchersIdDeleteRequest?: AdminVouchersIdDeleteRequest, options?: any): AxiosPromise<VoucherAdminViewModelApplicationResult> {
            return localVarFp.adminVouchersIdPut(id, adminVouchersIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth policies: ManageContent)
         * @param {AdminVouchersGetRequest} [adminVouchersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVouchersPost(adminVouchersGetRequest?: AdminVouchersGetRequest, options?: any): AxiosPromise<VoucherAdminViewModelApplicationResult> {
            return localVarFp.adminVouchersPost(adminVouchersGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminVouchersApi - object-oriented interface
 * @export
 * @class AdminVouchersApi
 * @extends {BaseAPI}
 */
export class AdminVouchersApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {boolean} onlyValidVouchers 
     * @param {VoucherType} [voucherType] 
     * @param {VoucherApplyToType} [voucherApplyToType] 
     * @param {string} [searchTerm] 
     * @param {string} [currentVoucherId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVouchersApi
     */
    public adminVouchersGet(pageIndex: number, pageSize: number, onlyValidVouchers: boolean, voucherType?: VoucherType, voucherApplyToType?: VoucherApplyToType, searchTerm?: string, currentVoucherId?: string, options?: AxiosRequestConfig) {
        return AdminVouchersApiFp(this.configuration).adminVouchersGet(pageIndex, pageSize, onlyValidVouchers, voucherType, voucherApplyToType, searchTerm, currentVoucherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVouchersApi
     */
    public adminVouchersIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminVouchersApiFp(this.configuration).adminVouchersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVouchersApi
     */
    public adminVouchersIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminVouchersApiFp(this.configuration).adminVouchersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {string} id 
     * @param {AdminVouchersIdDeleteRequest} [adminVouchersIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVouchersApi
     */
    public adminVouchersIdPut(id: string, adminVouchersIdDeleteRequest?: AdminVouchersIdDeleteRequest, options?: AxiosRequestConfig) {
        return AdminVouchersApiFp(this.configuration).adminVouchersIdPut(id, adminVouchersIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth policies: ManageContent)
     * @param {AdminVouchersGetRequest} [adminVouchersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVouchersApi
     */
    public adminVouchersPost(adminVouchersGetRequest?: AdminVouchersGetRequest, options?: AxiosRequestConfig) {
        return AdminVouchersApiFp(this.configuration).adminVouchersPost(adminVouchersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannerItemsApi - axios parameter creator
 * @export
 */
export const BannerItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerItemsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/BannerItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerItemsApi - functional programming interface
 * @export
 */
export const BannerItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerItemsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerItemListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerItemsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerItemsApi - factory interface
 * @export
 */
export const BannerItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerItemsGet(options?: any): AxiosPromise<BannerItemListViewModelListApplicationResult> {
            return localVarFp.bannerItemsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerItemsApi - object-oriented interface
 * @export
 * @class BannerItemsApi
 * @extends {BaseAPI}
 */
export class BannerItemsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerItemsApi
     */
    public bannerItemsGet(options?: AxiosRequestConfig) {
        return BannerItemsApiFp(this.configuration).bannerItemsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannerMessagesApi - axios parameter creator
 * @export
 */
export const BannerMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerMessagesGet: async (countryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('bannerMessagesGet', 'countryId', countryId)
            const localVarPath = `/BannerMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerMessagesApi - functional programming interface
 * @export
 */
export const BannerMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerMessagesGet(countryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerMessageAdminListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerMessagesGet(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerMessagesApi - factory interface
 * @export
 */
export const BannerMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerMessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerMessagesGet(countryId: string, options?: any): AxiosPromise<BannerMessageAdminListViewModelListApplicationResult> {
            return localVarFp.bannerMessagesGet(countryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerMessagesApi - object-oriented interface
 * @export
 * @class BannerMessagesApi
 * @extends {BaseAPI}
 */
export class BannerMessagesApi extends BaseAPI {
    /**
     * 
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerMessagesApi
     */
    public bannerMessagesGet(countryId: string, options?: AxiosRequestConfig) {
        return BannerMessagesApiFp(this.configuration).bannerMessagesGet(countryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlogCategoriesApi - axios parameter creator
 * @export
 */
export const BlogCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/BlogCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogCategoriesApi - functional programming interface
 * @export
 */
export const BlogCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogCategoryListViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlogCategoriesApi - factory interface
 * @export
 */
export const BlogCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogCategoriesGet(options?: any): AxiosPromise<BlogCategoryListViewModelListApplicationResult> {
            return localVarFp.blogCategoriesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlogCategoriesApi - object-oriented interface
 * @export
 * @class BlogCategoriesApi
 * @extends {BaseAPI}
 */
export class BlogCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogCategoriesApi
     */
    public blogCategoriesGet(options?: AxiosRequestConfig) {
        return BlogCategoriesApiFp(this.configuration).blogCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlogsApi - axios parameter creator
 * @export
 */
export const BlogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} [isFeatured] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsGet: async (pageIndex: number, pageSize: number, isFeatured?: boolean, categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('blogsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('blogsGet', 'pageSize', pageSize)
            const localVarPath = `/Blogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (isFeatured !== undefined) {
                localVarQueryParameter['isFeatured'] = isFeatured;
            }

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blogSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsSingleBlogSlugGet: async (blogSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blogSlug' is not null or undefined
            assertParamExists('blogsSingleBlogSlugGet', 'blogSlug', blogSlug)
            const localVarPath = `/Blogs/single/{blogSlug}`
                .replace(`{${"blogSlug"}}`, encodeURIComponent(String(blogSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogsApi - functional programming interface
 * @export
 */
export const BlogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} [isFeatured] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsGet(pageIndex: number, pageSize: number, isFeatured?: boolean, categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsGet(pageIndex, pageSize, isFeatured, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} blogSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blogsSingleBlogSlugGet(blogSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blogsSingleBlogSlugGet(blogSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlogsApi - factory interface
 * @export
 */
export const BlogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {boolean} [isFeatured] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsGet(pageIndex: number, pageSize: number, isFeatured?: boolean, categoryIds?: Array<string>, options?: any): AxiosPromise<BlogListViewModelPaginatedListApplicationResult> {
            return localVarFp.blogsGet(pageIndex, pageSize, isFeatured, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} blogSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blogsSingleBlogSlugGet(blogSlug: string, options?: any): AxiosPromise<BlogViewModelApplicationResult> {
            return localVarFp.blogsSingleBlogSlugGet(blogSlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlogsApi - object-oriented interface
 * @export
 * @class BlogsApi
 * @extends {BaseAPI}
 */
export class BlogsApi extends BaseAPI {
    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {boolean} [isFeatured] 
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsGet(pageIndex: number, pageSize: number, isFeatured?: boolean, categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return BlogsApiFp(this.configuration).blogsGet(pageIndex, pageSize, isFeatured, categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} blogSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogsApi
     */
    public blogsSingleBlogSlugGet(blogSlug: string, options?: AxiosRequestConfig) {
        return BlogsApiFp(this.configuration).blogsSingleBlogSlugGet(blogSlug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} campaignSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsSingleCampaignSlugGet: async (campaignSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignSlug' is not null or undefined
            assertParamExists('campaignsSingleCampaignSlugGet', 'campaignSlug', campaignSlug)
            const localVarPath = `/Campaigns/single/{campaignSlug}`
                .replace(`{${"campaignSlug"}}`, encodeURIComponent(String(campaignSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} campaignSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignsSingleCampaignSlugGet(campaignSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignsSingleCampaignSlugGet(campaignSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} campaignSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignsSingleCampaignSlugGet(campaignSlug: string, options?: any): AxiosPromise<CampaignViewModelApplicationResult> {
            return localVarFp.campaignsSingleCampaignSlugGet(campaignSlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * 
     * @param {string} campaignSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public campaignsSingleCampaignSlugGet(campaignSlug: string, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).campaignsSingleCampaignSlugGet(campaignSlug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarouselItemsApi - axios parameter creator
 * @export
 */
export const CarouselItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carouselItemsGet: async (pageIndex: number, pageSize: number, section?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('carouselItemsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('carouselItemsGet', 'pageSize', pageSize)
            const localVarPath = `/CarouselItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarouselItemsApi - functional programming interface
 * @export
 */
export const CarouselItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarouselItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carouselItemsGet(pageIndex: number, pageSize: number, section?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarouselItemAdminListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carouselItemsGet(pageIndex, pageSize, section, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarouselItemsApi - factory interface
 * @export
 */
export const CarouselItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarouselItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carouselItemsGet(pageIndex: number, pageSize: number, section?: string, options?: any): AxiosPromise<CarouselItemAdminListViewModelPaginatedListApplicationResult> {
            return localVarFp.carouselItemsGet(pageIndex, pageSize, section, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarouselItemsApi - object-oriented interface
 * @export
 * @class CarouselItemsApi
 * @extends {BaseAPI}
 */
export class CarouselItemsApi extends BaseAPI {
    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [section] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarouselItemsApi
     */
    public carouselItemsGet(pageIndex: number, pageSize: number, section?: string, options?: AxiosRequestConfig) {
        return CarouselItemsApiFp(this.configuration).carouselItemsGet(pageIndex, pageSize, section, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CartItemsApi - axios parameter creator
 * @export
 */
export const CartItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {boolean} returnTotalPrice 
         * @param {Array<CustomizedProductQuantityModel>} [customizedProductQuantityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsCalculatePriceForCustomizedProductsPost: async (countryId: string, returnTotalPrice: boolean, customizedProductQuantityModel?: Array<CustomizedProductQuantityModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('cartItemsCalculatePriceForCustomizedProductsPost', 'countryId', countryId)
            // verify required parameter 'returnTotalPrice' is not null or undefined
            assertParamExists('cartItemsCalculatePriceForCustomizedProductsPost', 'returnTotalPrice', returnTotalPrice)
            const localVarPath = `/CartItems/CalculatePriceForCustomizedProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }

            if (returnTotalPrice !== undefined) {
                localVarQueryParameter['returnTotalPrice'] = returnTotalPrice;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customizedProductQuantityModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accessKey] 
         * @param {CartItemsCreateCartItemPostRequest} [cartItemsCreateCartItemPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsCreateCartItemPost: async (accessKey?: string, cartItemsCreateCartItemPostRequest?: CartItemsCreateCartItemPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CartItems/CreateCartItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartItemsCreateCartItemPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cartId 
         * @param {CartItemsCreateStoreCartItemCartIdPostRequest} [cartItemsCreateStoreCartItemCartIdPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsCreateStoreCartItemCartIdPost: async (cartId: string, cartItemsCreateStoreCartItemCartIdPostRequest?: CartItemsCreateStoreCartItemCartIdPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartItemsCreateStoreCartItemCartIdPost', 'cartId', cartId)
            const localVarPath = `/CartItems/CreateStoreCartItem/{cartId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartItemsCreateStoreCartItemCartIdPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsDeleteStoreCartItemCartIdCartItemIdDelete: async (cartId: string, cartItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartItemsDeleteStoreCartItemCartIdCartItemIdDelete', 'cartId', cartId)
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('cartItemsDeleteStoreCartItemCartIdCartItemIdDelete', 'cartItemId', cartItemId)
            const localVarPath = `/CartItems/DeleteStoreCartItem/{cartId}/{cartItemId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"cartItemId"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cartItemId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsGetCartItemCartItemIdGet: async (cartItemId: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('cartItemsGetCartItemCartItemIdGet', 'cartItemId', cartItemId)
            const localVarPath = `/CartItems/GetCartItem/{cartItemId}`
                .replace(`{${"cartItemId"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsGetStoreCartItemCartIdCartItemIdGet: async (cartId: string, cartItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartItemsGetStoreCartItemCartIdCartItemIdGet', 'cartId', cartId)
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('cartItemsGetStoreCartItemCartIdCartItemIdGet', 'cartItemId', cartItemId)
            const localVarPath = `/CartItems/GetStoreCartItem/{cartId}/{cartItemId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"cartItemId"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsIdDelete: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartItemsIdDelete', 'id', id)
            const localVarPath = `/CartItems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {CartItemsUpdateCartItemIdPutRequest} [cartItemsUpdateCartItemIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsUpdateCartItemIdPut: async (id: string, accessKey?: string, cartItemsUpdateCartItemIdPutRequest?: CartItemsUpdateCartItemIdPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartItemsUpdateCartItemIdPut', 'id', id)
            const localVarPath = `/CartItems/UpdateCartItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartItemsUpdateCartItemIdPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest} [cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsUpdateStoreCartItemCartIdCartItemIdPut: async (cartId: string, cartItemId: string, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest?: CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartItemsUpdateStoreCartItemCartIdCartItemIdPut', 'cartId', cartId)
            // verify required parameter 'cartItemId' is not null or undefined
            assertParamExists('cartItemsUpdateStoreCartItemCartIdCartItemIdPut', 'cartItemId', cartItemId)
            const localVarPath = `/CartItems/UpdateStoreCartItem/{cartId}/{cartItemId}`
                .replace(`{${"cartId"}}`, encodeURIComponent(String(cartId)))
                .replace(`{${"cartItemId"}}`, encodeURIComponent(String(cartItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartItemsApi - functional programming interface
 * @export
 */
export const CartItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {boolean} returnTotalPrice 
         * @param {Array<CustomizedProductQuantityModel>} [customizedProductQuantityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsCalculatePriceForCustomizedProductsPost(countryId: string, returnTotalPrice: boolean, customizedProductQuantityModel?: Array<CustomizedProductQuantityModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizedProductPriceViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsCalculatePriceForCustomizedProductsPost(countryId, returnTotalPrice, customizedProductQuantityModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [accessKey] 
         * @param {CartItemsCreateCartItemPostRequest} [cartItemsCreateCartItemPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsCreateCartItemPost(accessKey?: string, cartItemsCreateCartItemPostRequest?: CartItemsCreateCartItemPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartItemViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsCreateCartItemPost(accessKey, cartItemsCreateCartItemPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cartId 
         * @param {CartItemsCreateStoreCartItemCartIdPostRequest} [cartItemsCreateStoreCartItemCartIdPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsCreateStoreCartItemCartIdPost(cartId: string, cartItemsCreateStoreCartItemCartIdPostRequest?: CartItemsCreateStoreCartItemCartIdPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreOrderCartItemViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsCreateStoreCartItemCartIdPost(cartId, cartItemsCreateStoreCartItemCartIdPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(cartId: string, cartItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(cartId, cartItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cartItemId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsGetCartItemCartItemIdGet(cartItemId: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartItemViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsGetCartItemCartItemIdGet(cartItemId, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsGetStoreCartItemCartIdCartItemIdGet(cartId: string, cartItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreOrderCartItemViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsGetStoreCartItemCartIdCartItemIdGet(cartId, cartItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsIdDelete(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsIdDelete(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {CartItemsUpdateCartItemIdPutRequest} [cartItemsUpdateCartItemIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsUpdateCartItemIdPut(id: string, accessKey?: string, cartItemsUpdateCartItemIdPutRequest?: CartItemsUpdateCartItemIdPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartItemViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsUpdateCartItemIdPut(id, accessKey, cartItemsUpdateCartItemIdPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest} [cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartItemsUpdateStoreCartItemCartIdCartItemIdPut(cartId: string, cartItemId: string, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest?: CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreOrderCartItemViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartItemsUpdateStoreCartItemCartIdCartItemIdPut(cartId, cartItemId, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartItemsApi - factory interface
 * @export
 */
export const CartItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {boolean} returnTotalPrice 
         * @param {Array<CustomizedProductQuantityModel>} [customizedProductQuantityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsCalculatePriceForCustomizedProductsPost(countryId: string, returnTotalPrice: boolean, customizedProductQuantityModel?: Array<CustomizedProductQuantityModel>, options?: any): AxiosPromise<CustomizedProductPriceViewModelApplicationResult> {
            return localVarFp.cartItemsCalculatePriceForCustomizedProductsPost(countryId, returnTotalPrice, customizedProductQuantityModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accessKey] 
         * @param {CartItemsCreateCartItemPostRequest} [cartItemsCreateCartItemPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsCreateCartItemPost(accessKey?: string, cartItemsCreateCartItemPostRequest?: CartItemsCreateCartItemPostRequest, options?: any): AxiosPromise<OrderCartItemViewModelApplicationResult> {
            return localVarFp.cartItemsCreateCartItemPost(accessKey, cartItemsCreateCartItemPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cartId 
         * @param {CartItemsCreateStoreCartItemCartIdPostRequest} [cartItemsCreateStoreCartItemCartIdPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsCreateStoreCartItemCartIdPost(cartId: string, cartItemsCreateStoreCartItemCartIdPostRequest?: CartItemsCreateStoreCartItemCartIdPostRequest, options?: any): AxiosPromise<StoreOrderCartItemViewModelApplicationResult> {
            return localVarFp.cartItemsCreateStoreCartItemCartIdPost(cartId, cartItemsCreateStoreCartItemCartIdPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(cartId: string, cartItemId: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(cartId, cartItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cartItemId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsGetCartItemCartItemIdGet(cartItemId: string, accessKey?: string, options?: any): AxiosPromise<OrderCartItemViewModelApplicationResult> {
            return localVarFp.cartItemsGetCartItemCartItemIdGet(cartItemId, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsGetStoreCartItemCartIdCartItemIdGet(cartId: string, cartItemId: string, options?: any): AxiosPromise<StoreOrderCartItemViewModelApplicationResult> {
            return localVarFp.cartItemsGetStoreCartItemCartIdCartItemIdGet(cartId, cartItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsIdDelete(id: string, accessKey?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.cartItemsIdDelete(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {CartItemsUpdateCartItemIdPutRequest} [cartItemsUpdateCartItemIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsUpdateCartItemIdPut(id: string, accessKey?: string, cartItemsUpdateCartItemIdPutRequest?: CartItemsUpdateCartItemIdPutRequest, options?: any): AxiosPromise<OrderCartItemViewModelApplicationResult> {
            return localVarFp.cartItemsUpdateCartItemIdPut(id, accessKey, cartItemsUpdateCartItemIdPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cartId 
         * @param {string} cartItemId 
         * @param {CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest} [cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartItemsUpdateStoreCartItemCartIdCartItemIdPut(cartId: string, cartItemId: string, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest?: CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options?: any): AxiosPromise<StoreOrderCartItemViewModelApplicationResult> {
            return localVarFp.cartItemsUpdateStoreCartItemCartIdCartItemIdPut(cartId, cartItemId, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartItemsApi - object-oriented interface
 * @export
 * @class CartItemsApi
 * @extends {BaseAPI}
 */
export class CartItemsApi extends BaseAPI {
    /**
     * 
     * @param {string} countryId 
     * @param {boolean} returnTotalPrice 
     * @param {Array<CustomizedProductQuantityModel>} [customizedProductQuantityModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsCalculatePriceForCustomizedProductsPost(countryId: string, returnTotalPrice: boolean, customizedProductQuantityModel?: Array<CustomizedProductQuantityModel>, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsCalculatePriceForCustomizedProductsPost(countryId, returnTotalPrice, customizedProductQuantityModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accessKey] 
     * @param {CartItemsCreateCartItemPostRequest} [cartItemsCreateCartItemPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsCreateCartItemPost(accessKey?: string, cartItemsCreateCartItemPostRequest?: CartItemsCreateCartItemPostRequest, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsCreateCartItemPost(accessKey, cartItemsCreateCartItemPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cartId 
     * @param {CartItemsCreateStoreCartItemCartIdPostRequest} [cartItemsCreateStoreCartItemCartIdPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsCreateStoreCartItemCartIdPost(cartId: string, cartItemsCreateStoreCartItemCartIdPostRequest?: CartItemsCreateStoreCartItemCartIdPostRequest, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsCreateStoreCartItemCartIdPost(cartId, cartItemsCreateStoreCartItemCartIdPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cartId 
     * @param {string} cartItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(cartId: string, cartItemId: string, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsDeleteStoreCartItemCartIdCartItemIdDelete(cartId, cartItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cartItemId 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsGetCartItemCartItemIdGet(cartItemId: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsGetCartItemCartItemIdGet(cartItemId, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cartId 
     * @param {string} cartItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsGetStoreCartItemCartIdCartItemIdGet(cartId: string, cartItemId: string, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsGetStoreCartItemCartIdCartItemIdGet(cartId, cartItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsIdDelete(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsIdDelete(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {CartItemsUpdateCartItemIdPutRequest} [cartItemsUpdateCartItemIdPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsUpdateCartItemIdPut(id: string, accessKey?: string, cartItemsUpdateCartItemIdPutRequest?: CartItemsUpdateCartItemIdPutRequest, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsUpdateCartItemIdPut(id, accessKey, cartItemsUpdateCartItemIdPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cartId 
     * @param {string} cartItemId 
     * @param {CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest} [cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartItemsApi
     */
    public cartItemsUpdateStoreCartItemCartIdCartItemIdPut(cartId: string, cartItemId: string, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest?: CartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options?: AxiosRequestConfig) {
        return CartItemsApiFp(this.configuration).cartItemsUpdateStoreCartItemCartIdCartItemIdPut(cartId, cartItemId, cartItemsUpdateStoreCartItemCartIdCartItemIdPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CartsApi - axios parameter creator
 * @export
 */
export const CartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cartId 
         * @param {string} voucherRedemptionCode 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsApplyVoucherToCartPut: async (cartId: string, voucherRedemptionCode: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartsApplyVoucherToCartPut', 'cartId', cartId)
            // verify required parameter 'voucherRedemptionCode' is not null or undefined
            assertParamExists('cartsApplyVoucherToCartPut', 'voucherRedemptionCode', voucherRedemptionCode)
            const localVarPath = `/Carts/ApplyVoucherToCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cartId !== undefined) {
                localVarQueryParameter['cartId'] = cartId;
            }

            if (voucherRedemptionCode !== undefined) {
                localVarQueryParameter['voucherRedemptionCode'] = voucherRedemptionCode;
            }

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsCheckoutIdPut: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsCheckoutIdPut', 'id', id)
            const localVarPath = `/Carts/Checkout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CartsCreateCartPostRequest} [cartsCreateCartPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsCreateCartPost: async (cartsCreateCartPostRequest?: CartsCreateCartPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Carts/CreateCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartsCreateCartPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CartsCreateStoreCartPostRequest} [cartsCreateStoreCartPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsCreateStoreCartPost: async (cartsCreateStoreCartPostRequest?: CartsCreateStoreCartPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Carts/CreateStoreCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartsCreateStoreCartPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [countryId] 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetCartDeliveryOptionsIdGet: async (id: string, countryId?: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsGetCartDeliveryOptionsIdGet', 'id', id)
            const localVarPath = `/Carts/GetCartDeliveryOptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetCartGet: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsGetCartGet', 'id', id)
            const localVarPath = `/Carts/GetCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetCartIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Carts/GetCartId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetPaymentProvidersForOrderIdGet: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsGetPaymentProvidersForOrderIdGet', 'id', id)
            const localVarPath = `/Carts/GetPaymentProvidersForOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetPaymentProvidersForStoreOrderIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsGetPaymentProvidersForStoreOrderIdGet', 'id', id)
            const localVarPath = `/Carts/GetPaymentProvidersForStoreOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetStoreCartGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsGetStoreCartGet', 'id', id)
            const localVarPath = `/Carts/GetStoreCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsIdDelete', 'id', id)
            const localVarPath = `/Carts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderQuoteId 
         * @param {boolean} quoteAccepted 
         * @param {string} [amendmentsRequested] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsRespondToOrderQuotePut: async (orderQuoteId: string, quoteAccepted: boolean, amendmentsRequested?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderQuoteId' is not null or undefined
            assertParamExists('cartsRespondToOrderQuotePut', 'orderQuoteId', orderQuoteId)
            // verify required parameter 'quoteAccepted' is not null or undefined
            assertParamExists('cartsRespondToOrderQuotePut', 'quoteAccepted', quoteAccepted)
            const localVarPath = `/Carts/RespondToOrderQuote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderQuoteId !== undefined) {
                localVarQueryParameter['orderQuoteId'] = orderQuoteId;
            }

            if (quoteAccepted !== undefined) {
                localVarQueryParameter['quoteAccepted'] = quoteAccepted;
            }

            if (amendmentsRequested !== undefined) {
                localVarQueryParameter['amendmentsRequested'] = amendmentsRequested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {CartsUpdateCartIdPutRequest} [cartsUpdateCartIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsUpdateCartIdPut: async (id: string, accessKey?: string, cartsUpdateCartIdPutRequest?: CartsUpdateCartIdPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsUpdateCartIdPut', 'id', id)
            const localVarPath = `/Carts/UpdateCart/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartsUpdateCartIdPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CartsUpdateStoreCartIdPutRequest} [cartsUpdateStoreCartIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsUpdateStoreCartIdPut: async (id: string, cartsUpdateStoreCartIdPutRequest?: CartsUpdateStoreCartIdPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartsUpdateStoreCartIdPut', 'id', id)
            const localVarPath = `/Carts/UpdateStoreCart/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartsUpdateStoreCartIdPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartsApi - functional programming interface
 * @export
 */
export const CartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} cartId 
         * @param {string} voucherRedemptionCode 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsApplyVoucherToCartPut(cartId: string, voucherRedemptionCode: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoucherCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsApplyVoucherToCartPut(cartId, voucherRedemptionCode, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsCheckoutIdPut(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsCheckoutIdPut(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CartsCreateCartPostRequest} [cartsCreateCartPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsCreateCartPost(cartsCreateCartPostRequest?: CartsCreateCartPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsCreateCartPost(cartsCreateCartPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CartsCreateStoreCartPostRequest} [cartsCreateStoreCartPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsCreateStoreCartPost(cartsCreateStoreCartPostRequest?: CartsCreateStoreCartPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreOrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsCreateStoreCartPost(cartsCreateStoreCartPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [countryId] 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsGetCartDeliveryOptionsIdGet(id: string, countryId?: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartDeliveryOptionViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsGetCartDeliveryOptionsIdGet(id, countryId, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsGetCartGet(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsGetCartGet(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsGetCartIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidNullableApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsGetCartIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsGetPaymentProvidersForOrderIdGet(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProviderListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsGetPaymentProvidersForOrderIdGet(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsGetPaymentProvidersForStoreOrderIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProviderListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsGetPaymentProvidersForStoreOrderIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsGetStoreCartGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreOrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsGetStoreCartGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderQuoteId 
         * @param {boolean} quoteAccepted 
         * @param {string} [amendmentsRequested] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsRespondToOrderQuotePut(orderQuoteId: string, quoteAccepted: boolean, amendmentsRequested?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsRespondToOrderQuotePut(orderQuoteId, quoteAccepted, amendmentsRequested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {CartsUpdateCartIdPutRequest} [cartsUpdateCartIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsUpdateCartIdPut(id: string, accessKey?: string, cartsUpdateCartIdPutRequest?: CartsUpdateCartIdPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsUpdateCartIdPut(id, accessKey, cartsUpdateCartIdPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CartsUpdateStoreCartIdPutRequest} [cartsUpdateStoreCartIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartsUpdateStoreCartIdPut(id: string, cartsUpdateStoreCartIdPutRequest?: CartsUpdateStoreCartIdPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreOrderCartViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartsUpdateStoreCartIdPut(id, cartsUpdateStoreCartIdPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartsApi - factory interface
 * @export
 */
export const CartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} cartId 
         * @param {string} voucherRedemptionCode 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsApplyVoucherToCartPut(cartId: string, voucherRedemptionCode: string, accessKey?: string, options?: any): AxiosPromise<VoucherCartViewModelApplicationResult> {
            return localVarFp.cartsApplyVoucherToCartPut(cartId, voucherRedemptionCode, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsCheckoutIdPut(id: string, accessKey?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.cartsCheckoutIdPut(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartsCreateCartPostRequest} [cartsCreateCartPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsCreateCartPost(cartsCreateCartPostRequest?: CartsCreateCartPostRequest, options?: any): AxiosPromise<OrderCartViewModelApplicationResult> {
            return localVarFp.cartsCreateCartPost(cartsCreateCartPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartsCreateStoreCartPostRequest} [cartsCreateStoreCartPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsCreateStoreCartPost(cartsCreateStoreCartPostRequest?: CartsCreateStoreCartPostRequest, options?: any): AxiosPromise<StoreOrderCartViewModelApplicationResult> {
            return localVarFp.cartsCreateStoreCartPost(cartsCreateStoreCartPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [countryId] 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetCartDeliveryOptionsIdGet(id: string, countryId?: string, accessKey?: string, options?: any): AxiosPromise<OrderCartDeliveryOptionViewModelListApplicationResult> {
            return localVarFp.cartsGetCartDeliveryOptionsIdGet(id, countryId, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetCartGet(id: string, accessKey?: string, options?: any): AxiosPromise<OrderCartViewModelApplicationResult> {
            return localVarFp.cartsGetCartGet(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetCartIdGet(options?: any): AxiosPromise<GuidNullableApplicationResult> {
            return localVarFp.cartsGetCartIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetPaymentProvidersForOrderIdGet(id: string, accessKey?: string, options?: any): AxiosPromise<PaymentProviderListApplicationResult> {
            return localVarFp.cartsGetPaymentProvidersForOrderIdGet(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetPaymentProvidersForStoreOrderIdGet(id: string, options?: any): AxiosPromise<PaymentProviderListApplicationResult> {
            return localVarFp.cartsGetPaymentProvidersForStoreOrderIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsGetStoreCartGet(id: string, options?: any): AxiosPromise<StoreOrderCartViewModelApplicationResult> {
            return localVarFp.cartsGetStoreCartGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.cartsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderQuoteId 
         * @param {boolean} quoteAccepted 
         * @param {string} [amendmentsRequested] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsRespondToOrderQuotePut(orderQuoteId: string, quoteAccepted: boolean, amendmentsRequested?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.cartsRespondToOrderQuotePut(orderQuoteId, quoteAccepted, amendmentsRequested, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {CartsUpdateCartIdPutRequest} [cartsUpdateCartIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsUpdateCartIdPut(id: string, accessKey?: string, cartsUpdateCartIdPutRequest?: CartsUpdateCartIdPutRequest, options?: any): AxiosPromise<OrderCartViewModelApplicationResult> {
            return localVarFp.cartsUpdateCartIdPut(id, accessKey, cartsUpdateCartIdPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CartsUpdateStoreCartIdPutRequest} [cartsUpdateStoreCartIdPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartsUpdateStoreCartIdPut(id: string, cartsUpdateStoreCartIdPutRequest?: CartsUpdateStoreCartIdPutRequest, options?: any): AxiosPromise<StoreOrderCartViewModelApplicationResult> {
            return localVarFp.cartsUpdateStoreCartIdPut(id, cartsUpdateStoreCartIdPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartsApi - object-oriented interface
 * @export
 * @class CartsApi
 * @extends {BaseAPI}
 */
export class CartsApi extends BaseAPI {
    /**
     * 
     * @param {string} cartId 
     * @param {string} voucherRedemptionCode 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsApplyVoucherToCartPut(cartId: string, voucherRedemptionCode: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsApplyVoucherToCartPut(cartId, voucherRedemptionCode, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsCheckoutIdPut(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsCheckoutIdPut(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartsCreateCartPostRequest} [cartsCreateCartPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsCreateCartPost(cartsCreateCartPostRequest?: CartsCreateCartPostRequest, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsCreateCartPost(cartsCreateCartPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartsCreateStoreCartPostRequest} [cartsCreateStoreCartPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsCreateStoreCartPost(cartsCreateStoreCartPostRequest?: CartsCreateStoreCartPostRequest, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsCreateStoreCartPost(cartsCreateStoreCartPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [countryId] 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsGetCartDeliveryOptionsIdGet(id: string, countryId?: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsGetCartDeliveryOptionsIdGet(id, countryId, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsGetCartGet(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsGetCartGet(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsGetCartIdGet(options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsGetCartIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsGetPaymentProvidersForOrderIdGet(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsGetPaymentProvidersForOrderIdGet(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsGetPaymentProvidersForStoreOrderIdGet(id: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsGetPaymentProvidersForStoreOrderIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsGetStoreCartGet(id: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsGetStoreCartGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsIdDelete(id: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderQuoteId 
     * @param {boolean} quoteAccepted 
     * @param {string} [amendmentsRequested] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsRespondToOrderQuotePut(orderQuoteId: string, quoteAccepted: boolean, amendmentsRequested?: string, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsRespondToOrderQuotePut(orderQuoteId, quoteAccepted, amendmentsRequested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {CartsUpdateCartIdPutRequest} [cartsUpdateCartIdPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsUpdateCartIdPut(id: string, accessKey?: string, cartsUpdateCartIdPutRequest?: CartsUpdateCartIdPutRequest, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsUpdateCartIdPut(id, accessKey, cartsUpdateCartIdPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CartsUpdateStoreCartIdPutRequest} [cartsUpdateStoreCartIdPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartsApi
     */
    public cartsUpdateStoreCartIdPut(id: string, cartsUpdateStoreCartIdPutRequest?: CartsUpdateStoreCartIdPutRequest, options?: AxiosRequestConfig) {
        return CartsApiFp(this.configuration).cartsUpdateStoreCartIdPut(id, cartsUpdateStoreCartIdPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesFeaturedGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('categoriesFeaturedGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('categoriesFeaturedGet', 'pageSize', pageSize)
            const localVarPath = `/Categories/featured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the categories at the root of the hierarchy
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('categoriesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('categoriesGet', 'pageSize', pageSize)
            const localVarPath = `/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetProductCategoryMenuGroupsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Categories/GetProductCategoryMenuGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesParentCategorySlugGet: async (pageIndex: number, pageSize: number, parentCategorySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('categoriesParentCategorySlugGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('categoriesParentCategorySlugGet', 'pageSize', pageSize)
            // verify required parameter 'parentCategorySlug' is not null or undefined
            assertParamExists('categoriesParentCategorySlugGet', 'parentCategorySlug', parentCategorySlug)
            const localVarPath = `/Categories/{parentCategorySlug}`
                .replace(`{${"parentCategorySlug"}}`, encodeURIComponent(String(parentCategorySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesSingleCategorySlugGet: async (categorySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorySlug' is not null or undefined
            assertParamExists('categoriesSingleCategorySlugGet', 'categorySlug', categorySlug)
            const localVarPath = `/Categories/single/{categorySlug}`
                .replace(`{${"categorySlug"}}`, encodeURIComponent(String(categorySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesFeaturedGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesFeaturedGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the categories at the root of the hierarchy
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGetProductCategoryMenuGroupsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryMenuGroupViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGetProductCategoryMenuGroupsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesSingleCategorySlugGet(categorySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSingleCategorySlugGet(categorySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesFeaturedGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<CategoryListViewModelPaginatedListApplicationResult> {
            return localVarFp.categoriesFeaturedGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the categories at the root of the hierarchy
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<CategoryListViewModelPaginatedListApplicationResult> {
            return localVarFp.categoriesGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetProductCategoryMenuGroupsGet(options?: any): AxiosPromise<ProductCategoryMenuGroupViewModelListApplicationResult> {
            return localVarFp.categoriesGetProductCategoryMenuGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, options?: any): AxiosPromise<CategoryListViewModelPaginatedListApplicationResult> {
            return localVarFp.categoriesParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesSingleCategorySlugGet(categorySlug: string, options?: any): AxiosPromise<CategoryViewModelApplicationResult> {
            return localVarFp.categoriesSingleCategorySlugGet(categorySlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesFeaturedGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesFeaturedGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the categories at the root of the hierarchy
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGetProductCategoryMenuGroupsGet(options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesGetProductCategoryMenuGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} parentCategorySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categorySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesSingleCategorySlugGet(categorySlug: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesSingleCategorySlugGet(categorySlug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet(options?: any): AxiosPromise<ClientViewModelListApplicationResult> {
            return localVarFp.clientsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsGet(options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetCountryByIpGeolocationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Countries/GetCountryByIpGeolocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetLastSelectedCountryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Countries/GetLastSelectedCountry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesSetLastSelectedCountryPost: async (countryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('countriesSetLastSelectedCountryPost', 'countryId', countryId)
            const localVarPath = `/Countries/SetLastSelectedCountry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesGetCountryByIpGeolocationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGetCountryByIpGeolocationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesGetLastSelectedCountryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGetLastSelectedCountryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesSetLastSelectedCountryPost(countryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesSetLastSelectedCountryPost(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGet(options?: any): AxiosPromise<CountryViewModelListApplicationResult> {
            return localVarFp.countriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetCountryByIpGeolocationGet(options?: any): AxiosPromise<CountryViewModelApplicationResult> {
            return localVarFp.countriesGetCountryByIpGeolocationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetLastSelectedCountryGet(options?: any): AxiosPromise<CountryViewModelApplicationResult> {
            return localVarFp.countriesGetLastSelectedCountryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesSetLastSelectedCountryPost(countryId: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.countriesSetLastSelectedCountryPost(countryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesGet(options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesGetCountryByIpGeolocationGet(options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesGetCountryByIpGeolocationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesGetLastSelectedCountryGet(options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesGetLastSelectedCountryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesSetLastSelectedCountryPost(countryId: string, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesSetLastSelectedCountryPost(countryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomizedProductsApi - axios parameter creator
 * @export
 */
export const CustomizedProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsClaimPut: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('customizedProductsClaimPut', 'ids', ids)
            const localVarPath = `/CustomizedProducts/claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customizedProductId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet: async (customizedProductId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customizedProductId' is not null or undefined
            assertParamExists('customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet', 'customizedProductId', customizedProductId)
            const localVarPath = `/CustomizedProducts/GetCustomizedProductSizeOptions/{customizedProductId}`
                .replace(`{${"customizedProductId"}}`, encodeURIComponent(String(customizedProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsGetCustomizedProductsGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('customizedProductsGetCustomizedProductsGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('customizedProductsGetCustomizedProductsGet', 'pageSize', pageSize)
            const localVarPath = `/CustomizedProducts/GetCustomizedProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customizedProductsIdDelete', 'id', id)
            const localVarPath = `/CustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customizedProductsIdGet', 'id', id)
            const localVarPath = `/CustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsIdPut: async (id: string, customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customizedProductsIdPut', 'id', id)
            const localVarPath = `/CustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customizedProductsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsPost: async (customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CustomizedProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customizedProductsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomizedProductsApi - functional programming interface
 * @export
 */
export const CustomizedProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomizedProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsClaimPut(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsClaimPut(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customizedProductId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(customizedProductId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizedProductSizeOptionsViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(customizedProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsGetCustomizedProductsGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizedProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsGetCustomizedProductsGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomizedProductViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsIdPut(id: string, customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsIdPut(id, customizedProductsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customizedProductsPost(customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customizedProductsPost(customizedProductsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomizedProductsApi - factory interface
 * @export
 */
export const CustomizedProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomizedProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsClaimPut(ids: Array<string>, options?: any): AxiosPromise<GuidApplicationResult> {
            return localVarFp.customizedProductsClaimPut(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customizedProductId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(customizedProductId: string, options?: any): AxiosPromise<CustomizedProductSizeOptionsViewModelApplicationResult> {
            return localVarFp.customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(customizedProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsGetCustomizedProductsGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<CustomizedProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.customizedProductsGetCustomizedProductsGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.customizedProductsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsIdGet(id: string, options?: any): AxiosPromise<CustomizedProductViewModelApplicationResult> {
            return localVarFp.customizedProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsIdPut(id: string, customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options?: any): AxiosPromise<GuidApplicationResult> {
            return localVarFp.customizedProductsIdPut(id, customizedProductsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customizedProductsPost(customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options?: any): AxiosPromise<GuidApplicationResult> {
            return localVarFp.customizedProductsPost(customizedProductsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomizedProductsApi - object-oriented interface
 * @export
 * @class CustomizedProductsApi
 * @extends {BaseAPI}
 */
export class CustomizedProductsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsClaimPut(ids: Array<string>, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsClaimPut(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customizedProductId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(customizedProductId: string, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(customizedProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsGetCustomizedProductsGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsGetCustomizedProductsGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsIdDelete(id: string, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsIdGet(id: string, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsIdPut(id: string, customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsIdPut(id, customizedProductsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomizedProductsIdDeleteRequest} [customizedProductsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomizedProductsApi
     */
    public customizedProductsPost(customizedProductsIdDeleteRequest?: CustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig) {
        return CustomizedProductsApiFp(this.configuration).customizedProductsPost(customizedProductsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnquiriesApi - axios parameter creator
 * @export
 */
export const EnquiriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enquiriesCreateEnquiryPost: async (enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Enquiries/CreateEnquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enquiriesCreateEnquiryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnquiriesApi - functional programming interface
 * @export
 */
export const EnquiriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnquiriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enquiriesCreateEnquiryPost(enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enquiriesCreateEnquiryPost(enquiriesCreateEnquiryPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnquiriesApi - factory interface
 * @export
 */
export const EnquiriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnquiriesApiFp(configuration)
    return {
        /**
         * 
         * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enquiriesCreateEnquiryPost(enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.enquiriesCreateEnquiryPost(enquiriesCreateEnquiryPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnquiriesApi - object-oriented interface
 * @export
 * @class EnquiriesApi
 * @extends {BaseAPI}
 */
export class EnquiriesApi extends BaseAPI {
    /**
     * 
     * @param {EnquiriesCreateEnquiryPostRequest} [enquiriesCreateEnquiryPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnquiriesApi
     */
    public enquiriesCreateEnquiryPost(enquiriesCreateEnquiryPostRequest?: EnquiriesCreateEnquiryPostRequest, options?: AxiosRequestConfig) {
        return EnquiriesApiFp(this.configuration).enquiriesCreateEnquiryPost(enquiriesCreateEnquiryPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FacebookConversionsApi - axios parameter creator
 * @export
 */
export const FacebookConversionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FacebookConversionsEventTypes} eventType 
         * @param {string} [userEmailAddress] 
         * @param {string} [currency] 
         * @param {number} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookConversionsPostEventPost: async (eventType: FacebookConversionsEventTypes, userEmailAddress?: string, currency?: string, value?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('facebookConversionsPostEventPost', 'eventType', eventType)
            const localVarPath = `/FacebookConversions/PostEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (userEmailAddress !== undefined) {
                localVarQueryParameter['userEmailAddress'] = userEmailAddress;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacebookConversionsApi - functional programming interface
 * @export
 */
export const FacebookConversionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacebookConversionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FacebookConversionsEventTypes} eventType 
         * @param {string} [userEmailAddress] 
         * @param {string} [currency] 
         * @param {number} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facebookConversionsPostEventPost(eventType: FacebookConversionsEventTypes, userEmailAddress?: string, currency?: string, value?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facebookConversionsPostEventPost(eventType, userEmailAddress, currency, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacebookConversionsApi - factory interface
 * @export
 */
export const FacebookConversionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacebookConversionsApiFp(configuration)
    return {
        /**
         * 
         * @param {FacebookConversionsEventTypes} eventType 
         * @param {string} [userEmailAddress] 
         * @param {string} [currency] 
         * @param {number} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookConversionsPostEventPost(eventType: FacebookConversionsEventTypes, userEmailAddress?: string, currency?: string, value?: number, options?: any): AxiosPromise<StringApplicationResult> {
            return localVarFp.facebookConversionsPostEventPost(eventType, userEmailAddress, currency, value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacebookConversionsApi - object-oriented interface
 * @export
 * @class FacebookConversionsApi
 * @extends {BaseAPI}
 */
export class FacebookConversionsApi extends BaseAPI {
    /**
     * 
     * @param {FacebookConversionsEventTypes} eventType 
     * @param {string} [userEmailAddress] 
     * @param {string} [currency] 
     * @param {number} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookConversionsApi
     */
    public facebookConversionsPostEventPost(eventType: FacebookConversionsEventTypes, userEmailAddress?: string, currency?: string, value?: number, options?: AxiosRequestConfig) {
        return FacebookConversionsApiFp(this.configuration).facebookConversionsPostEventPost(eventType, userEmailAddress, currency, value, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet: async (relativePath: string, width: number, height: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'relativePath', relativePath)
            // verify required parameter 'width' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'width', width)
            // verify required parameter 'height' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'height', height)
            const localVarPath = `/files/{width}/{height}/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)))
                .replace(`{${"width"}}`, encodeURIComponent(String(width)))
                .replace(`{${"height"}}`, encodeURIComponent(String(height)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesWidthHeightRelativePathGet(relativePath, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost(file?: File, options?: any): AxiosPromise<FilePath> {
            return localVarFp.filesPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: any): AxiosPromise<string> {
            return localVarFp.filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPost(file?: File, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralSettingsApi - axios parameter creator
 * @export
 */
export const GeneralSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalSettingsGetGeneralSettingsGet: async (keys: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('generalSettingsGetGeneralSettingsGet', 'keys', keys)
            const localVarPath = `/GeneralSettings/GetGeneralSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalSettingsKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('generalSettingsKeyGet', 'key', key)
            const localVarPath = `/GeneralSettings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralSettingsApi - functional programming interface
 * @export
 */
export const GeneralSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalSettingsGetGeneralSettingsGet(keys: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringStringDictionaryApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalSettingsGetGeneralSettingsGet(keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalSettingsKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalSettingsKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralSettingsApi - factory interface
 * @export
 */
export const GeneralSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} keys 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalSettingsGetGeneralSettingsGet(keys: Array<string>, options?: any): AxiosPromise<StringStringDictionaryApplicationResult> {
            return localVarFp.generalSettingsGetGeneralSettingsGet(keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalSettingsKeyGet(key: string, options?: any): AxiosPromise<StringApplicationResult> {
            return localVarFp.generalSettingsKeyGet(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralSettingsApi - object-oriented interface
 * @export
 * @class GeneralSettingsApi
 * @extends {BaseAPI}
 */
export class GeneralSettingsApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} keys 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralSettingsApi
     */
    public generalSettingsGetGeneralSettingsGet(keys: Array<string>, options?: AxiosRequestConfig) {
        return GeneralSettingsApiFp(this.configuration).generalSettingsGetGeneralSettingsGet(keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralSettingsApi
     */
    public generalSettingsKeyGet(key: string, options?: AxiosRequestConfig) {
        return GeneralSettingsApiFp(this.configuration).generalSettingsKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentityAuthenticationApi - axios parameter creator
 * @export
 */
export const IdentityAuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationConfirmEmailAddressPost: async (emailAddress: string, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('identityAuthenticationConfirmEmailAddressPost', 'emailAddress', emailAddress)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('identityAuthenticationConfirmEmailAddressPost', 'token', token)
            const localVarPath = `/Identity/Authentication/ConfirmEmailAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationForgotPasswordPost: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('identityAuthenticationForgotPasswordPost', 'emailAddress', emailAddress)
            const localVarPath = `/Identity/Authentication/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationInvalidateAllRefreshTokensPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/InvalidateAllRefreshTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationInvalidateRefreshTokenPost: async (identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/InvalidateRefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityAuthenticationRefreshTokenPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginFacebookLoginFacebookPostRequest} [identityAuthenticationLoginFacebookLoginFacebookPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationLoginFacebookLoginFacebookPost: async (identityAuthenticationLoginFacebookLoginFacebookPostRequest?: IdentityAuthenticationLoginFacebookLoginFacebookPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/LoginFacebook/LoginFacebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityAuthenticationLoginFacebookLoginFacebookPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginGoogleLoginGooglePostRequest} [identityAuthenticationLoginGoogleLoginGooglePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationLoginGoogleLoginGooglePost: async (identityAuthenticationLoginGoogleLoginGooglePostRequest?: IdentityAuthenticationLoginGoogleLoginGooglePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/LoginGoogle/LoginGoogle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityAuthenticationLoginGoogleLoginGooglePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginPostRequest} [identityAuthenticationLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationLoginPost: async (identityAuthenticationLoginPostRequest?: IdentityAuthenticationLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityAuthenticationLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationRefreshTokenPost: async (identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/RefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityAuthenticationRefreshTokenPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityAuthenticationRegisterPostRequest} [identityAuthenticationRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationRegisterPost: async (identityAuthenticationRegisterPostRequest?: IdentityAuthenticationRegisterPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Authentication/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityAuthenticationRegisterPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationResendEmailConfirmationPost: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('identityAuthenticationResendEmailConfirmationPost', 'emailAddress', emailAddress)
            const localVarPath = `/Identity/Authentication/ResendEmailConfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} token 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationResetForgotPasswordPost: async (emailAddress: string, token: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('identityAuthenticationResetForgotPasswordPost', 'emailAddress', emailAddress)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('identityAuthenticationResetForgotPasswordPost', 'token', token)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('identityAuthenticationResetForgotPasswordPost', 'newPassword', newPassword)
            const localVarPath = `/Identity/Authentication/ResetForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityAuthenticationApi - functional programming interface
 * @export
 */
export const IdentityAuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentityAuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationConfirmEmailAddressPost(emailAddress: string, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationConfirmEmailAddressPost(emailAddress, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationForgotPasswordPost(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationForgotPasswordPost(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationInvalidateAllRefreshTokensPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationInvalidateAllRefreshTokensPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationInvalidateRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationInvalidateRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginFacebookLoginFacebookPostRequest} [identityAuthenticationLoginFacebookLoginFacebookPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationLoginFacebookLoginFacebookPost(identityAuthenticationLoginFacebookLoginFacebookPostRequest?: IdentityAuthenticationLoginFacebookLoginFacebookPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResultApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationLoginFacebookLoginFacebookPost(identityAuthenticationLoginFacebookLoginFacebookPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginGoogleLoginGooglePostRequest} [identityAuthenticationLoginGoogleLoginGooglePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationLoginGoogleLoginGooglePost(identityAuthenticationLoginGoogleLoginGooglePostRequest?: IdentityAuthenticationLoginGoogleLoginGooglePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResultApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationLoginGoogleLoginGooglePost(identityAuthenticationLoginGoogleLoginGooglePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginPostRequest} [identityAuthenticationLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationLoginPost(identityAuthenticationLoginPostRequest?: IdentityAuthenticationLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResultApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationLoginPost(identityAuthenticationLoginPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResultApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IdentityAuthenticationRegisterPostRequest} [identityAuthenticationRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationRegisterPost(identityAuthenticationRegisterPostRequest?: IdentityAuthenticationRegisterPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationRegisterPost(identityAuthenticationRegisterPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationResendEmailConfirmationPost(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationResendEmailConfirmationPost(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} token 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAuthenticationResetForgotPasswordPost(emailAddress: string, token: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAuthenticationResetForgotPasswordPost(emailAddress, token, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentityAuthenticationApi - factory interface
 * @export
 */
export const IdentityAuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentityAuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationConfirmEmailAddressPost(emailAddress: string, token: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.identityAuthenticationConfirmEmailAddressPost(emailAddress, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationForgotPasswordPost(emailAddress: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.identityAuthenticationForgotPasswordPost(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationInvalidateAllRefreshTokensPost(options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.identityAuthenticationInvalidateAllRefreshTokensPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationInvalidateRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.identityAuthenticationInvalidateRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginFacebookLoginFacebookPostRequest} [identityAuthenticationLoginFacebookLoginFacebookPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationLoginFacebookLoginFacebookPost(identityAuthenticationLoginFacebookLoginFacebookPostRequest?: IdentityAuthenticationLoginFacebookLoginFacebookPostRequest, options?: any): AxiosPromise<AuthenticationResultApplicationResult> {
            return localVarFp.identityAuthenticationLoginFacebookLoginFacebookPost(identityAuthenticationLoginFacebookLoginFacebookPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginGoogleLoginGooglePostRequest} [identityAuthenticationLoginGoogleLoginGooglePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationLoginGoogleLoginGooglePost(identityAuthenticationLoginGoogleLoginGooglePostRequest?: IdentityAuthenticationLoginGoogleLoginGooglePostRequest, options?: any): AxiosPromise<AuthenticationResultApplicationResult> {
            return localVarFp.identityAuthenticationLoginGoogleLoginGooglePost(identityAuthenticationLoginGoogleLoginGooglePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityAuthenticationLoginPostRequest} [identityAuthenticationLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationLoginPost(identityAuthenticationLoginPostRequest?: IdentityAuthenticationLoginPostRequest, options?: any): AxiosPromise<AuthenticationResultApplicationResult> {
            return localVarFp.identityAuthenticationLoginPost(identityAuthenticationLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options?: any): AxiosPromise<AuthenticationResultApplicationResult> {
            return localVarFp.identityAuthenticationRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityAuthenticationRegisterPostRequest} [identityAuthenticationRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationRegisterPost(identityAuthenticationRegisterPostRequest?: IdentityAuthenticationRegisterPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.identityAuthenticationRegisterPost(identityAuthenticationRegisterPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationResendEmailConfirmationPost(emailAddress: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.identityAuthenticationResendEmailConfirmationPost(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} token 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAuthenticationResetForgotPasswordPost(emailAddress: string, token: string, newPassword: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.identityAuthenticationResetForgotPasswordPost(emailAddress, token, newPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentityAuthenticationApi - object-oriented interface
 * @export
 * @class IdentityAuthenticationApi
 * @extends {BaseAPI}
 */
export class IdentityAuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} emailAddress 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationConfirmEmailAddressPost(emailAddress: string, token: string, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationConfirmEmailAddressPost(emailAddress, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationForgotPasswordPost(emailAddress: string, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationForgotPasswordPost(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationInvalidateAllRefreshTokensPost(options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationInvalidateAllRefreshTokensPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationInvalidateRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationInvalidateRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityAuthenticationLoginFacebookLoginFacebookPostRequest} [identityAuthenticationLoginFacebookLoginFacebookPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationLoginFacebookLoginFacebookPost(identityAuthenticationLoginFacebookLoginFacebookPostRequest?: IdentityAuthenticationLoginFacebookLoginFacebookPostRequest, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationLoginFacebookLoginFacebookPost(identityAuthenticationLoginFacebookLoginFacebookPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityAuthenticationLoginGoogleLoginGooglePostRequest} [identityAuthenticationLoginGoogleLoginGooglePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationLoginGoogleLoginGooglePost(identityAuthenticationLoginGoogleLoginGooglePostRequest?: IdentityAuthenticationLoginGoogleLoginGooglePostRequest, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationLoginGoogleLoginGooglePost(identityAuthenticationLoginGoogleLoginGooglePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityAuthenticationLoginPostRequest} [identityAuthenticationLoginPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationLoginPost(identityAuthenticationLoginPostRequest?: IdentityAuthenticationLoginPostRequest, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationLoginPost(identityAuthenticationLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityAuthenticationRefreshTokenPostRequest} [identityAuthenticationRefreshTokenPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest?: IdentityAuthenticationRefreshTokenPostRequest, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationRefreshTokenPost(identityAuthenticationRefreshTokenPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityAuthenticationRegisterPostRequest} [identityAuthenticationRegisterPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationRegisterPost(identityAuthenticationRegisterPostRequest?: IdentityAuthenticationRegisterPostRequest, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationRegisterPost(identityAuthenticationRegisterPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationResendEmailConfirmationPost(emailAddress: string, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationResendEmailConfirmationPost(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {string} token 
     * @param {string} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityAuthenticationApi
     */
    public identityAuthenticationResetForgotPasswordPost(emailAddress: string, token: string, newPassword: string, options?: AxiosRequestConfig) {
        return IdentityAuthenticationApiFp(this.configuration).identityAuthenticationResetForgotPasswordPost(emailAddress, token, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreateQuoteInvoiceOrderIdPost: async (orderId: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersCreateQuoteInvoiceOrderIdPost', 'orderId', orderId)
            const localVarPath = `/Orders/CreateQuoteInvoice/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} billingPostalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderByReferenceNumberReferenceNumberGet: async (referenceNumber: string, billingPostalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceNumber' is not null or undefined
            assertParamExists('ordersGetOrderByReferenceNumberReferenceNumberGet', 'referenceNumber', referenceNumber)
            // verify required parameter 'billingPostalCode' is not null or undefined
            assertParamExists('ordersGetOrderByReferenceNumberReferenceNumberGet', 'billingPostalCode', billingPostalCode)
            const localVarPath = `/Orders/GetOrderByReferenceNumber/{referenceNumber}`
                .replace(`{${"referenceNumber"}}`, encodeURIComponent(String(referenceNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (billingPostalCode !== undefined) {
                localVarQueryParameter['billingPostalCode'] = billingPostalCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderInvoiceId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderInvoiceDataOrderInvoiceIdGet: async (orderInvoiceId: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderInvoiceId' is not null or undefined
            assertParamExists('ordersGetOrderInvoiceDataOrderInvoiceIdGet', 'orderInvoiceId', orderInvoiceId)
            const localVarPath = `/Orders/GetOrderInvoiceData/{orderInvoiceId}`
                .replace(`{${"orderInvoiceId"}}`, encodeURIComponent(String(orderInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderItemAssetsForResuplyOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersGetOrderItemAssetsForResuplyOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Orders/GetOrderItemAssetsForResuply/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderOrderIdGet: async (orderId: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersGetOrderOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Orders/GetOrder/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderOutstandingPaymentOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersGetOrderOutstandingPaymentOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Orders/GetOrderOutstandingPayment/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderPackOrderPackIdGet: async (orderPackId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPackId' is not null or undefined
            assertParamExists('ordersGetOrderPackOrderPackIdGet', 'orderPackId', orderPackId)
            const localVarPath = `/Orders/GetOrderPack/{orderPackId}`
                .replace(`{${"orderPackId"}}`, encodeURIComponent(String(orderPackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [orderPlacementStartDate] 
         * @param {string} [orderPlacementEndDate] 
         * @param {OrderPublicStatus} [orderPublicStatusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrdersGet: async (pageIndex: number, pageSize: number, orderPlacementStartDate?: string, orderPlacementEndDate?: string, orderPublicStatusId?: OrderPublicStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('ordersGetOrdersGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('ordersGetOrdersGet', 'pageSize', pageSize)
            const localVarPath = `/Orders/GetOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderPlacementStartDate !== undefined) {
                localVarQueryParameter['orderPlacementStartDate'] = (orderPlacementStartDate as any instanceof Date) ?
                    (orderPlacementStartDate as any).toISOString() :
                    orderPlacementStartDate;
            }

            if (orderPlacementEndDate !== undefined) {
                localVarQueryParameter['orderPlacementEndDate'] = (orderPlacementEndDate as any instanceof Date) ?
                    (orderPlacementEndDate as any).toISOString() :
                    orderPlacementEndDate;
            }

            if (orderPublicStatusId !== undefined) {
                localVarQueryParameter['orderPublicStatusId'] = orderPublicStatusId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPackId 
         * @param {OrdersRespondToOrderPackOrderPackIdPostRequest} [ordersRespondToOrderPackOrderPackIdPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRespondToOrderPackOrderPackIdPost: async (orderPackId: string, ordersRespondToOrderPackOrderPackIdPostRequest?: OrdersRespondToOrderPackOrderPackIdPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPackId' is not null or undefined
            assertParamExists('ordersRespondToOrderPackOrderPackIdPost', 'orderPackId', orderPackId)
            const localVarPath = `/Orders/RespondToOrderPack/{orderPackId}`
                .replace(`{${"orderPackId"}}`, encodeURIComponent(String(orderPackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ordersRespondToOrderPackOrderPackIdPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {Array<OrderItemAssetResupplyUpdateModel>} [orderItemAssetResupplyUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersResupplyOrderItemAssetsOrderIdPost: async (orderId: string, orderItemAssetResupplyUpdateModel?: Array<OrderItemAssetResupplyUpdateModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersResupplyOrderItemAssetsOrderIdPost', 'orderId', orderId)
            const localVarPath = `/Orders/ResupplyOrderItemAssets/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderItemAssetResupplyUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} orderId 
         * @param {boolean} [marketingOptIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersUpdateMarketingFlagPut: async (orderId: string, marketingOptIn?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersUpdateMarketingFlagPut', 'orderId', orderId)
            const localVarPath = `/Orders/UpdateMarketingFlag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (marketingOptIn !== undefined) {
                localVarQueryParameter['marketingOptIn'] = marketingOptIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersCreateQuoteInvoiceOrderIdPost(orderId: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersCreateQuoteInvoiceOrderIdPost(orderId, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} billingPostalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderByReferenceNumberReferenceNumberGet(referenceNumber: string, billingPostalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderByReferenceNumberReferenceNumberGet(referenceNumber, billingPostalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderInvoiceId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderInvoiceDataOrderInvoiceIdGet(orderInvoiceId: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderInvoiceDataOrderInvoiceIdGet(orderInvoiceId, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderItemAssetsForResuplyOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemAssetResupplyViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderItemAssetsForResuplyOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderOrderIdGet(orderId: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderOrderIdGet(orderId, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderOutstandingPaymentOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOutstandingPaymentViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderOutstandingPaymentOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderPackOrderPackIdGet(orderPackId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPackApprovalViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderPackOrderPackIdGet(orderPackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [orderPlacementStartDate] 
         * @param {string} [orderPlacementEndDate] 
         * @param {OrderPublicStatus} [orderPublicStatusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrdersGet(pageIndex: number, pageSize: number, orderPlacementStartDate?: string, orderPlacementEndDate?: string, orderPublicStatusId?: OrderPublicStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderHistoryViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrdersGet(pageIndex, pageSize, orderPlacementStartDate, orderPlacementEndDate, orderPublicStatusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPackId 
         * @param {OrdersRespondToOrderPackOrderPackIdPostRequest} [ordersRespondToOrderPackOrderPackIdPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersRespondToOrderPackOrderPackIdPost(orderPackId: string, ordersRespondToOrderPackOrderPackIdPostRequest?: OrdersRespondToOrderPackOrderPackIdPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersRespondToOrderPackOrderPackIdPost(orderPackId, ordersRespondToOrderPackOrderPackIdPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {Array<OrderItemAssetResupplyUpdateModel>} [orderItemAssetResupplyUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersResupplyOrderItemAssetsOrderIdPost(orderId: string, orderItemAssetResupplyUpdateModel?: Array<OrderItemAssetResupplyUpdateModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersResupplyOrderItemAssetsOrderIdPost(orderId, orderItemAssetResupplyUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} orderId 
         * @param {boolean} [marketingOptIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersUpdateMarketingFlagPut(orderId: string, marketingOptIn?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersUpdateMarketingFlagPut(orderId, marketingOptIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreateQuoteInvoiceOrderIdPost(orderId: string, accessKey?: string, options?: any): AxiosPromise<OrderInvoiceViewModelApplicationResult> {
            return localVarFp.ordersCreateQuoteInvoiceOrderIdPost(orderId, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} referenceNumber 
         * @param {string} billingPostalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderByReferenceNumberReferenceNumberGet(referenceNumber: string, billingPostalCode: string, options?: any): AxiosPromise<OrderHistoryViewModelApplicationResult> {
            return localVarFp.ordersGetOrderByReferenceNumberReferenceNumberGet(referenceNumber, billingPostalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderInvoiceId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderInvoiceDataOrderInvoiceIdGet(orderInvoiceId: string, accessKey?: string, options?: any): AxiosPromise<OrderInvoiceViewModelApplicationResult> {
            return localVarFp.ordersGetOrderInvoiceDataOrderInvoiceIdGet(orderInvoiceId, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderItemAssetsForResuplyOrderIdGet(orderId: string, options?: any): AxiosPromise<OrderItemAssetResupplyViewModelListApplicationResult> {
            return localVarFp.ordersGetOrderItemAssetsForResuplyOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderOrderIdGet(orderId: string, accessKey?: string, options?: any): AxiosPromise<OrderHistoryViewModelApplicationResult> {
            return localVarFp.ordersGetOrderOrderIdGet(orderId, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderOutstandingPaymentOrderIdGet(orderId: string, options?: any): AxiosPromise<OrderOutstandingPaymentViewModelApplicationResult> {
            return localVarFp.ordersGetOrderOutstandingPaymentOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderPackOrderPackIdGet(orderPackId: string, options?: any): AxiosPromise<OrderPackApprovalViewModelApplicationResult> {
            return localVarFp.ordersGetOrderPackOrderPackIdGet(orderPackId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [orderPlacementStartDate] 
         * @param {string} [orderPlacementEndDate] 
         * @param {OrderPublicStatus} [orderPublicStatusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrdersGet(pageIndex: number, pageSize: number, orderPlacementStartDate?: string, orderPlacementEndDate?: string, orderPublicStatusId?: OrderPublicStatus, options?: any): AxiosPromise<OrderHistoryViewModelPaginatedListApplicationResult> {
            return localVarFp.ordersGetOrdersGet(pageIndex, pageSize, orderPlacementStartDate, orderPlacementEndDate, orderPublicStatusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPackId 
         * @param {OrdersRespondToOrderPackOrderPackIdPostRequest} [ordersRespondToOrderPackOrderPackIdPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersRespondToOrderPackOrderPackIdPost(orderPackId: string, ordersRespondToOrderPackOrderPackIdPostRequest?: OrdersRespondToOrderPackOrderPackIdPostRequest, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.ordersRespondToOrderPackOrderPackIdPost(orderPackId, ordersRespondToOrderPackOrderPackIdPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {Array<OrderItemAssetResupplyUpdateModel>} [orderItemAssetResupplyUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersResupplyOrderItemAssetsOrderIdPost(orderId: string, orderItemAssetResupplyUpdateModel?: Array<OrderItemAssetResupplyUpdateModel>, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.ordersResupplyOrderItemAssetsOrderIdPost(orderId, orderItemAssetResupplyUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} orderId 
         * @param {boolean} [marketingOptIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersUpdateMarketingFlagPut(orderId: string, marketingOptIn?: boolean, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.ordersUpdateMarketingFlagPut(orderId, marketingOptIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersCreateQuoteInvoiceOrderIdPost(orderId: string, accessKey?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersCreateQuoteInvoiceOrderIdPost(orderId, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} referenceNumber 
     * @param {string} billingPostalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderByReferenceNumberReferenceNumberGet(referenceNumber: string, billingPostalCode: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderByReferenceNumberReferenceNumberGet(referenceNumber, billingPostalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderInvoiceId 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderInvoiceDataOrderInvoiceIdGet(orderInvoiceId: string, accessKey?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderInvoiceDataOrderInvoiceIdGet(orderInvoiceId, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderItemAssetsForResuplyOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderItemAssetsForResuplyOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderOrderIdGet(orderId: string, accessKey?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderOrderIdGet(orderId, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderOutstandingPaymentOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderOutstandingPaymentOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPackId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderPackOrderPackIdGet(orderPackId: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderPackOrderPackIdGet(orderPackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [orderPlacementStartDate] 
     * @param {string} [orderPlacementEndDate] 
     * @param {OrderPublicStatus} [orderPublicStatusId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrdersGet(pageIndex: number, pageSize: number, orderPlacementStartDate?: string, orderPlacementEndDate?: string, orderPublicStatusId?: OrderPublicStatus, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrdersGet(pageIndex, pageSize, orderPlacementStartDate, orderPlacementEndDate, orderPublicStatusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPackId 
     * @param {OrdersRespondToOrderPackOrderPackIdPostRequest} [ordersRespondToOrderPackOrderPackIdPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersRespondToOrderPackOrderPackIdPost(orderPackId: string, ordersRespondToOrderPackOrderPackIdPostRequest?: OrdersRespondToOrderPackOrderPackIdPostRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersRespondToOrderPackOrderPackIdPost(orderPackId, ordersRespondToOrderPackOrderPackIdPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {Array<OrderItemAssetResupplyUpdateModel>} [orderItemAssetResupplyUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersResupplyOrderItemAssetsOrderIdPost(orderId: string, orderItemAssetResupplyUpdateModel?: Array<OrderItemAssetResupplyUpdateModel>, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersResupplyOrderItemAssetsOrderIdPost(orderId, orderItemAssetResupplyUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} orderId 
     * @param {boolean} [marketingOptIn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersUpdateMarketingFlagPut(orderId: string, marketingOptIn?: boolean, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersUpdateMarketingFlagPut(orderId, marketingOptIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageSeoSettingsApi - axios parameter creator
 * @export
 */
export const PageSeoSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSeoSettingsGet: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('pageSeoSettingsGet', 'url', url)
            const localVarPath = `/PageSeoSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageSeoSettingsApi - functional programming interface
 * @export
 */
export const PageSeoSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageSeoSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageSeoSettingsGet(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeoSettingViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageSeoSettingsGet(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageSeoSettingsApi - factory interface
 * @export
 */
export const PageSeoSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageSeoSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageSeoSettingsGet(url: string, options?: any): AxiosPromise<PageSeoSettingViewModelApplicationResult> {
            return localVarFp.pageSeoSettingsGet(url, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageSeoSettingsApi - object-oriented interface
 * @export
 * @class PageSeoSettingsApi
 * @extends {BaseAPI}
 */
export class PageSeoSettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageSeoSettingsApi
     */
    public pageSeoSettingsGet(url: string, options?: AxiosRequestConfig) {
        return PageSeoSettingsApiFp(this.configuration).pageSeoSettingsGet(url, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCompleteCheckoutEftIdPut: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsCompleteCheckoutEftIdPut', 'id', id)
            const localVarPath = `/Payments/CompleteCheckoutEft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCompleteCheckoutNoOutstandingPaymentIdPut: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsCompleteCheckoutNoOutstandingPaymentIdPut', 'id', id)
            const localVarPath = `/Payments/CompleteCheckoutNoOutstandingPayment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetEftBankDetailsIdGet: async (id: string, accessKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsGetEftBankDetailsIdGet', 'id', id)
            const localVarPath = `/Payments/GetEftBankDetails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetStripePaymentDetailsForStoreOrderIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsGetStripePaymentDetailsForStoreOrderIdGet', 'id', id)
            const localVarPath = `/Payments/GetStripePaymentDetailsForStoreOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetStripePaymentDetailsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsGetStripePaymentDetailsIdGet', 'id', id)
            const localVarPath = `/Payments/GetStripePaymentDetails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsStripeWebhookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/StripeWebhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCompleteCheckoutEftIdPut(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EftBankDetailsApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCompleteCheckoutEftIdPut(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCompleteCheckoutNoOutstandingPaymentIdPut(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCompleteCheckoutNoOutstandingPaymentIdPut(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsGetEftBankDetailsIdGet(id: string, accessKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EftBankDetailsApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsGetEftBankDetailsIdGet(id, accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsGetStripePaymentDetailsForStoreOrderIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntentViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsGetStripePaymentDetailsForStoreOrderIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsGetStripePaymentDetailsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIntentViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsGetStripePaymentDetailsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsStripeWebhookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsStripeWebhookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCompleteCheckoutEftIdPut(id: string, accessKey?: string, options?: any): AxiosPromise<EftBankDetailsApplicationResult> {
            return localVarFp.paymentsCompleteCheckoutEftIdPut(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCompleteCheckoutNoOutstandingPaymentIdPut(id: string, accessKey?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.paymentsCompleteCheckoutNoOutstandingPaymentIdPut(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [accessKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetEftBankDetailsIdGet(id: string, accessKey?: string, options?: any): AxiosPromise<EftBankDetailsApplicationResult> {
            return localVarFp.paymentsGetEftBankDetailsIdGet(id, accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetStripePaymentDetailsForStoreOrderIdGet(id: string, options?: any): AxiosPromise<PaymentIntentViewModelApplicationResult> {
            return localVarFp.paymentsGetStripePaymentDetailsForStoreOrderIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetStripePaymentDetailsIdGet(id: string, options?: any): AxiosPromise<PaymentIntentViewModelApplicationResult> {
            return localVarFp.paymentsGetStripePaymentDetailsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsStripeWebhookPost(options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.paymentsStripeWebhookPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCompleteCheckoutEftIdPut(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsCompleteCheckoutEftIdPut(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCompleteCheckoutNoOutstandingPaymentIdPut(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsCompleteCheckoutNoOutstandingPaymentIdPut(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [accessKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsGetEftBankDetailsIdGet(id: string, accessKey?: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsGetEftBankDetailsIdGet(id, accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsGetStripePaymentDetailsForStoreOrderIdGet(id: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsGetStripePaymentDetailsForStoreOrderIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsGetStripePaymentDetailsIdGet(id: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsGetStripePaymentDetailsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsStripeWebhookPost(options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsStripeWebhookPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductBuilderApi - axios parameter creator
 * @export
 */
export const ProductBuilderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBuilderProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productBuilderProductIdGet', 'productId', productId)
            const localVarPath = `/ProductBuilder/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductBuilderApi - functional programming interface
 * @export
 */
export const ProductBuilderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductBuilderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBuilderProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBuilderOptionsViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBuilderProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductBuilderApi - factory interface
 * @export
 */
export const ProductBuilderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductBuilderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBuilderProductIdGet(productId: string, options?: any): AxiosPromise<ProductBuilderOptionsViewModelApplicationResult> {
            return localVarFp.productBuilderProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductBuilderApi - object-oriented interface
 * @export
 * @class ProductBuilderApi
 * @extends {BaseAPI}
 */
export class ProductBuilderApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBuilderApi
     */
    public productBuilderProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return ProductBuilderApiFp(this.configuration).productBuilderProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductBundlesApi - axios parameter creator
 * @export
 */
export const ProductBundlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesFeaturedGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('productBundlesFeaturedGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('productBundlesFeaturedGet', 'pageSize', pageSize)
            const localVarPath = `/ProductBundles/featured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {Array<string>} [productBundleTags] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesParentCategorySlugGet: async (pageIndex: number, pageSize: number, parentCategorySlug: string, productBundleTags?: Array<string>, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('productBundlesParentCategorySlugGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('productBundlesParentCategorySlugGet', 'pageSize', pageSize)
            // verify required parameter 'parentCategorySlug' is not null or undefined
            assertParamExists('productBundlesParentCategorySlugGet', 'parentCategorySlug', parentCategorySlug)
            const localVarPath = `/ProductBundles/{parentCategorySlug}`
                .replace(`{${"parentCategorySlug"}}`, encodeURIComponent(String(parentCategorySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productBundleTags) {
                localVarQueryParameter['productBundleTags'] = productBundleTags;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesSearchGet: async (pageIndex: number, pageSize: number, searchTerm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('productBundlesSearchGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('productBundlesSearchGet', 'pageSize', pageSize)
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('productBundlesSearchGet', 'searchTerm', searchTerm)
            const localVarPath = `/ProductBundles/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesSingleProductBundleSlugGet: async (productBundleSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleSlug' is not null or undefined
            assertParamExists('productBundlesSingleProductBundleSlugGet', 'productBundleSlug', productBundleSlug)
            const localVarPath = `/ProductBundles/single/{productBundleSlug}`
                .replace(`{${"productBundleSlug"}}`, encodeURIComponent(String(productBundleSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductBundlesApi - functional programming interface
 * @export
 */
export const ProductBundlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductBundlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesFeaturedGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesFeaturedGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {Array<string>} [productBundleTags] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, productBundleTags?: Array<string>, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, productBundleTags, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesSearchGet(pageIndex: number, pageSize: number, searchTerm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesSearchGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesSingleProductBundleSlugGet(productBundleSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesSingleProductBundleSlugGet(productBundleSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductBundlesApi - factory interface
 * @export
 */
export const ProductBundlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductBundlesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesFeaturedGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<ProductBundleListViewModelPaginatedListApplicationResult> {
            return localVarFp.productBundlesFeaturedGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {Array<string>} [productBundleTags] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, productBundleTags?: Array<string>, searchTerm?: string, options?: any): AxiosPromise<ProductBundleListViewModelPaginatedListApplicationResult> {
            return localVarFp.productBundlesParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, productBundleTags, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesSearchGet(pageIndex: number, pageSize: number, searchTerm: string, options?: any): AxiosPromise<ProductBundleListViewModelPaginatedListApplicationResult> {
            return localVarFp.productBundlesSearchGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesSingleProductBundleSlugGet(productBundleSlug: string, options?: any): AxiosPromise<ProductBundleViewModelApplicationResult> {
            return localVarFp.productBundlesSingleProductBundleSlugGet(productBundleSlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductBundlesApi - object-oriented interface
 * @export
 * @class ProductBundlesApi
 * @extends {BaseAPI}
 */
export class ProductBundlesApi extends BaseAPI {
    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesFeaturedGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesFeaturedGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} parentCategorySlug 
     * @param {Array<string>} [productBundleTags] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, productBundleTags?: Array<string>, searchTerm?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, productBundleTags, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesSearchGet(pageIndex: number, pageSize: number, searchTerm: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesSearchGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesSingleProductBundleSlugGet(productBundleSlug: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesSingleProductBundleSlugGet(productBundleSlug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductTagsApi - axios parameter creator
 * @export
 */
export const ProductTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} parentCategorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productTagsParentCategorySlugGet: async (parentCategorySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentCategorySlug' is not null or undefined
            assertParamExists('productTagsParentCategorySlugGet', 'parentCategorySlug', parentCategorySlug)
            const localVarPath = `/ProductTags/{parentCategorySlug}`
                .replace(`{${"parentCategorySlug"}}`, encodeURIComponent(String(parentCategorySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductTagsApi - functional programming interface
 * @export
 */
export const ProductTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} parentCategorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productTagsParentCategorySlugGet(parentCategorySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTagViewModelListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productTagsParentCategorySlugGet(parentCategorySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductTagsApi - factory interface
 * @export
 */
export const ProductTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductTagsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} parentCategorySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productTagsParentCategorySlugGet(parentCategorySlug: string, options?: any): AxiosPromise<ProductTagViewModelListApplicationResult> {
            return localVarFp.productTagsParentCategorySlugGet(parentCategorySlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductTagsApi - object-oriented interface
 * @export
 * @class ProductTagsApi
 * @extends {BaseAPI}
 */
export class ProductTagsApi extends BaseAPI {
    /**
     * 
     * @param {string} parentCategorySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductTagsApi
     */
    public productTagsParentCategorySlugGet(parentCategorySlug: string, options?: AxiosRequestConfig) {
        return ProductTagsApiFp(this.configuration).productTagsParentCategorySlugGet(parentCategorySlug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsFeaturedGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('productsFeaturedGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('productsFeaturedGet', 'pageSize', pageSize)
            const localVarPath = `/Products/featured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetSizingForStyleGet: async (productId: string, styleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsGetSizingForStyleGet', 'productId', productId)
            // verify required parameter 'styleId' is not null or undefined
            assertParamExists('productsGetSizingForStyleGet', 'styleId', styleId)
            const localVarPath = `/Products/GetSizingForStyle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (styleId !== undefined) {
                localVarQueryParameter['styleId'] = styleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {Array<string>} [productTags] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsParentCategorySlugGet: async (pageIndex: number, pageSize: number, parentCategorySlug: string, productTags?: Array<string>, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('productsParentCategorySlugGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('productsParentCategorySlugGet', 'pageSize', pageSize)
            // verify required parameter 'parentCategorySlug' is not null or undefined
            assertParamExists('productsParentCategorySlugGet', 'parentCategorySlug', parentCategorySlug)
            const localVarPath = `/Products/{parentCategorySlug}`
                .replace(`{${"parentCategorySlug"}}`, encodeURIComponent(String(parentCategorySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productTags) {
                localVarQueryParameter['productTags'] = productTags;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductFeedCsvGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Products/product_feed_csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSearchGet: async (pageIndex: number, pageSize: number, searchTerm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('productsSearchGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('productsSearchGet', 'pageSize', pageSize)
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('productsSearchGet', 'searchTerm', searchTerm)
            const localVarPath = `/Products/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSingleProductSlugGet: async (productSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productSlug' is not null or undefined
            assertParamExists('productsSingleProductSlugGet', 'productSlug', productSlug)
            const localVarPath = `/Products/single/{productSlug}`
                .replace(`{${"productSlug"}}`, encodeURIComponent(String(productSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsFeaturedGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsFeaturedGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetSizingForStyleGet(productId: string, styleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStyleViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetSizingForStyleGet(productId, styleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {Array<string>} [productTags] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, productTags?: Array<string>, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, productTags, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductFeedCsvGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStyleViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductFeedCsvGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsSearchGet(pageIndex: number, pageSize: number, searchTerm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsSearchGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsSingleProductSlugGet(productSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsSingleProductSlugGet(productSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsFeaturedGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<ProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.productsFeaturedGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} styleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetSizingForStyleGet(productId: string, styleId: string, options?: any): AxiosPromise<ProductStyleViewModelApplicationResult> {
            return localVarFp.productsGetSizingForStyleGet(productId, styleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} parentCategorySlug 
         * @param {Array<string>} [productTags] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, productTags?: Array<string>, searchTerm?: string, options?: any): AxiosPromise<ProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.productsParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, productTags, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductFeedCsvGet(options?: any): AxiosPromise<ProductStyleViewModelApplicationResult> {
            return localVarFp.productsProductFeedCsvGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSearchGet(pageIndex: number, pageSize: number, searchTerm: string, options?: any): AxiosPromise<ProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.productsSearchGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSingleProductSlugGet(productSlug: string, options?: any): AxiosPromise<ProductViewModelApplicationResult> {
            return localVarFp.productsSingleProductSlugGet(productSlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsFeaturedGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsFeaturedGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} styleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetSizingForStyleGet(productId: string, styleId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetSizingForStyleGet(productId, styleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} parentCategorySlug 
     * @param {Array<string>} [productTags] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsParentCategorySlugGet(pageIndex: number, pageSize: number, parentCategorySlug: string, productTags?: Array<string>, searchTerm?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsParentCategorySlugGet(pageIndex, pageSize, parentCategorySlug, productTags, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductFeedCsvGet(options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductFeedCsvGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsSearchGet(pageIndex: number, pageSize: number, searchTerm: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsSearchGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsSingleProductSlugGet(productSlug: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsSingleProductSlugGet(productSlug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreCustomizedProductsApi - axios parameter creator
 * @export
 */
export const StoreCustomizedProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet: async (pageIndex: number, pageSize: number, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet', 'pageSize', pageSize)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet', 'storeId', storeId)
            const localVarPath = `/StoreCustomizedProducts/GetActiveStoreCustomizedProducts/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsGetStoreCustomizedProductIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeCustomizedProductsGetStoreCustomizedProductIdGet', 'id', id)
            const localVarPath = `/StoreCustomizedProducts/GetStoreCustomizedProduct/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeCustomizedProductsIdDelete', 'id', id)
            const localVarPath = `/StoreCustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [previousStoreCustomizedProductId] 
         * @param {string} [nextStoreCustomizedProductId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsIdPatch: async (id: string, previousStoreCustomizedProductId?: string, nextStoreCustomizedProductId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeCustomizedProductsIdPatch', 'id', id)
            const localVarPath = `/StoreCustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (previousStoreCustomizedProductId !== undefined) {
                localVarQueryParameter['previousStoreCustomizedProductId'] = previousStoreCustomizedProductId;
            }

            if (nextStoreCustomizedProductId !== undefined) {
                localVarQueryParameter['nextStoreCustomizedProductId'] = nextStoreCustomizedProductId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsIdPut: async (id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeCustomizedProductsIdPut', 'id', id)
            const localVarPath = `/StoreCustomizedProducts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCustomizedProductsIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {StoreCustomizedProductsPostRequest} [storeCustomizedProductsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsPost: async (storeCustomizedProductsPostRequest?: StoreCustomizedProductsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/StoreCustomizedProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeCustomizedProductsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsStoreIdGet: async (pageIndex: number, pageSize: number, storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('storeCustomizedProductsStoreIdGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('storeCustomizedProductsStoreIdGet', 'pageSize', pageSize)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeCustomizedProductsStoreIdGet', 'storeId', storeId)
            const localVarPath = `/StoreCustomizedProducts/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreCustomizedProductsApi - functional programming interface
 * @export
 */
export const StoreCustomizedProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreCustomizedProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsGetStoreCustomizedProductIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsGetStoreCustomizedProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [previousStoreCustomizedProductId] 
         * @param {string} [nextStoreCustomizedProductId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsIdPatch(id: string, previousStoreCustomizedProductId?: string, nextStoreCustomizedProductId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsIdPatch(id, previousStoreCustomizedProductId, nextStoreCustomizedProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsIdPut(id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsIdPut(id, storeCustomizedProductsIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {StoreCustomizedProductsPostRequest} [storeCustomizedProductsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsPost(storeCustomizedProductsPostRequest?: StoreCustomizedProductsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsPost(storeCustomizedProductsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCustomizedProductListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreCustomizedProductsApi - factory interface
 * @export
 */
export const StoreCustomizedProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreCustomizedProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: any): AxiosPromise<StoreCustomizedProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsGetStoreCustomizedProductIdGet(id: string, options?: any): AxiosPromise<StoreCustomizedProductViewModelApplicationResult> {
            return localVarFp.storeCustomizedProductsGetStoreCustomizedProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.storeCustomizedProductsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [previousStoreCustomizedProductId] 
         * @param {string} [nextStoreCustomizedProductId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsIdPatch(id: string, previousStoreCustomizedProductId?: string, nextStoreCustomizedProductId?: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.storeCustomizedProductsIdPatch(id, previousStoreCustomizedProductId, nextStoreCustomizedProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsIdPut(id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options?: any): AxiosPromise<StoreCustomizedProductViewModelApplicationResult> {
            return localVarFp.storeCustomizedProductsIdPut(id, storeCustomizedProductsIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {StoreCustomizedProductsPostRequest} [storeCustomizedProductsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsPost(storeCustomizedProductsPostRequest?: StoreCustomizedProductsPostRequest, options?: any): AxiosPromise<StoreCustomizedProductViewModelApplicationResult> {
            return localVarFp.storeCustomizedProductsPost(storeCustomizedProductsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: any): AxiosPromise<StoreCustomizedProductListViewModelPaginatedListApplicationResult> {
            return localVarFp.storeCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreCustomizedProductsApi - object-oriented interface
 * @export
 * @class StoreCustomizedProductsApi
 * @extends {BaseAPI}
 */
export class StoreCustomizedProductsApi extends BaseAPI {
    /**
     * 
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsGetActiveStoreCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsGetStoreCustomizedProductIdGet(id: string, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsGetStoreCustomizedProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [previousStoreCustomizedProductId] 
     * @param {string} [nextStoreCustomizedProductId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsIdPatch(id: string, previousStoreCustomizedProductId?: string, nextStoreCustomizedProductId?: string, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsIdPatch(id, previousStoreCustomizedProductId, nextStoreCustomizedProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {StoreCustomizedProductsIdDeleteRequest} [storeCustomizedProductsIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsIdPut(id: string, storeCustomizedProductsIdDeleteRequest?: StoreCustomizedProductsIdDeleteRequest, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsIdPut(id, storeCustomizedProductsIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {StoreCustomizedProductsPostRequest} [storeCustomizedProductsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsPost(storeCustomizedProductsPostRequest?: StoreCustomizedProductsPostRequest, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsPost(storeCustomizedProductsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreCustomizedProductsApi
     */
    public storeCustomizedProductsStoreIdGet(pageIndex: number, pageSize: number, storeId: string, options?: AxiosRequestConfig) {
        return StoreCustomizedProductsApiFp(this.configuration).storeCustomizedProductsStoreIdGet(pageIndex, pageSize, storeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet: async (pageIndex: number, pageSize: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('storesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('storesGet', 'pageSize', pageSize)
            const localVarPath = `/Stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetActiveStoreIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesGetActiveStoreIdGet', 'id', id)
            const localVarPath = `/Stores/GetActiveStore/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesIdDelete', 'id', id)
            const localVarPath = `/Stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesIdGet', 'id', id)
            const localVarPath = `/Stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {StoresIdDeleteRequest} [storesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesIdPut: async (id: string, storesIdDeleteRequest?: StoresIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesIdPut', 'id', id)
            const localVarPath = `/Stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {StoresGetRequest} [storesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesPost: async (storesGetRequest?: StoresGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesSubmitStoreForApprovalIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesSubmitStoreForApprovalIdPut', 'id', id)
            const localVarPath = `/Stores/SubmitStoreForApproval/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGet(pageIndex, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetActiveStoreIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetActiveStoreIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {StoresIdDeleteRequest} [storesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesIdPut(id: string, storesIdDeleteRequest?: StoresIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesIdPut(id, storesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {StoresGetRequest} [storesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesPost(storesGetRequest?: StoresGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesPost(storesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesSubmitStoreForApprovalIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesSubmitStoreForApprovalIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: any): AxiosPromise<StoreListViewModelPaginatedListApplicationResult> {
            return localVarFp.storesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetActiveStoreIdGet(id: string, options?: any): AxiosPromise<StoreViewModelApplicationResult> {
            return localVarFp.storesGetActiveStoreIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.storesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesIdGet(id: string, options?: any): AxiosPromise<StoreViewModelApplicationResult> {
            return localVarFp.storesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {StoresIdDeleteRequest} [storesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesIdPut(id: string, storesIdDeleteRequest?: StoresIdDeleteRequest, options?: any): AxiosPromise<StoreViewModelApplicationResult> {
            return localVarFp.storesIdPut(id, storesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {StoresGetRequest} [storesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesPost(storesGetRequest?: StoresGetRequest, options?: any): AxiosPromise<StoreViewModelApplicationResult> {
            return localVarFp.storesPost(storesGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesSubmitStoreForApprovalIdPut(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.storesSubmitStoreForApprovalIdPut(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGet(pageIndex: number, pageSize: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGet(pageIndex, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetActiveStoreIdGet(id: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetActiveStoreIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesIdGet(id: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {StoresIdDeleteRequest} [storesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesIdPut(id: string, storesIdDeleteRequest?: StoresIdDeleteRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesIdPut(id, storesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {StoresGetRequest} [storesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesPost(storesGetRequest?: StoresGetRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesPost(storesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesSubmitStoreForApprovalIdPut(id: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesSubmitStoreForApprovalIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAddressesApi - axios parameter creator
 * @export
 */
export const UserAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesGet: async (pageIndex: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageIndex' is not null or undefined
            assertParamExists('userAddressesGet', 'pageIndex', pageIndex)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('userAddressesGet', 'pageSize', pageSize)
            const localVarPath = `/UserAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['pageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userAddressesIdDelete', 'id', id)
            const localVarPath = `/UserAddresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userAddressesIdGet', 'id', id)
            const localVarPath = `/UserAddresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {UserAddressesIdDeleteRequest} [userAddressesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesIdPut: async (id: string, userAddressesIdDeleteRequest?: UserAddressesIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userAddressesIdPut', 'id', id)
            const localVarPath = `/UserAddresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAddressesIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UserAddressesGetRequest} [userAddressesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesPost: async (userAddressesGetRequest?: UserAddressesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAddressesGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAddressesApi - functional programming interface
 * @export
 */
export const UserAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAddressListViewModelPaginatedListApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesGet(pageIndex, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAddressViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {UserAddressesIdDeleteRequest} [userAddressesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesIdPut(id: string, userAddressesIdDeleteRequest?: UserAddressesIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAddressViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesIdPut(id, userAddressesIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UserAddressesGetRequest} [userAddressesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAddressesPost(userAddressesGetRequest?: UserAddressesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAddressViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAddressesPost(userAddressesGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAddressesApi - factory interface
 * @export
 */
export const UserAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAddressesApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} pageIndex 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesGet(pageIndex: number, pageSize: number, options?: any): AxiosPromise<UserAddressListViewModelPaginatedListApplicationResult> {
            return localVarFp.userAddressesGet(pageIndex, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesIdDelete(id: string, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.userAddressesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesIdGet(id: string, options?: any): AxiosPromise<UserAddressViewModelApplicationResult> {
            return localVarFp.userAddressesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} id 
         * @param {UserAddressesIdDeleteRequest} [userAddressesIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesIdPut(id: string, userAddressesIdDeleteRequest?: UserAddressesIdDeleteRequest, options?: any): AxiosPromise<UserAddressViewModelApplicationResult> {
            return localVarFp.userAddressesIdPut(id, userAddressesIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UserAddressesGetRequest} [userAddressesGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAddressesPost(userAddressesGetRequest?: UserAddressesGetRequest, options?: any): AxiosPromise<UserAddressViewModelApplicationResult> {
            return localVarFp.userAddressesPost(userAddressesGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAddressesApi - object-oriented interface
 * @export
 * @class UserAddressesApi
 * @extends {BaseAPI}
 */
export class UserAddressesApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {number} pageIndex 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressesApi
     */
    public userAddressesGet(pageIndex: number, pageSize: number, options?: AxiosRequestConfig) {
        return UserAddressesApiFp(this.configuration).userAddressesGet(pageIndex, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressesApi
     */
    public userAddressesIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserAddressesApiFp(this.configuration).userAddressesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressesApi
     */
    public userAddressesIdGet(id: string, options?: AxiosRequestConfig) {
        return UserAddressesApiFp(this.configuration).userAddressesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} id 
     * @param {UserAddressesIdDeleteRequest} [userAddressesIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressesApi
     */
    public userAddressesIdPut(id: string, userAddressesIdDeleteRequest?: UserAddressesIdDeleteRequest, options?: AxiosRequestConfig) {
        return UserAddressesApiFp(this.configuration).userAddressesIdPut(id, userAddressesIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {UserAddressesGetRequest} [userAddressesGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressesApi
     */
    public userAddressesPost(userAddressesGetRequest?: UserAddressesGetRequest, options?: AxiosRequestConfig) {
        return UserAddressesApiFp(this.configuration).userAddressesPost(userAddressesGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/GetUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UsersUpdateUserPasswordPutRequest} [usersUpdateUserPasswordPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserPasswordPut: async (usersUpdateUserPasswordPutRequest?: UsersUpdateUserPasswordPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/UpdateUserPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersUpdateUserPasswordPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UsersUpdateUserPutRequest} [usersUpdateUserPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserPut: async (usersUpdateUserPutRequest?: UsersUpdateUserPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersUpdateUserPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UsersUpdateUserPasswordPutRequest} [usersUpdateUserPasswordPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserPasswordPut(usersUpdateUserPasswordPutRequest?: UsersUpdateUserPasswordPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserPasswordPut(usersUpdateUserPasswordPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UsersUpdateUserPutRequest} [usersUpdateUserPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserPut(usersUpdateUserPutRequest?: UsersUpdateUserPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserViewModelApplicationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserPut(usersUpdateUserPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserGet(options?: any): AxiosPromise<UserViewModelApplicationResult> {
            return localVarFp.usersGetUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UsersUpdateUserPasswordPutRequest} [usersUpdateUserPasswordPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserPasswordPut(usersUpdateUserPasswordPutRequest?: UsersUpdateUserPasswordPutRequest, options?: any): AxiosPromise<ApplicationResult> {
            return localVarFp.usersUpdateUserPasswordPut(usersUpdateUserPasswordPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {UsersUpdateUserPutRequest} [usersUpdateUserPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserPut(usersUpdateUserPutRequest?: UsersUpdateUserPutRequest, options?: any): AxiosPromise<UserViewModelApplicationResult> {
            return localVarFp.usersUpdateUserPut(usersUpdateUserPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGetUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {UsersUpdateUserPasswordPutRequest} [usersUpdateUserPasswordPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserPasswordPut(usersUpdateUserPasswordPutRequest?: UsersUpdateUserPasswordPutRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateUserPasswordPut(usersUpdateUserPasswordPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {UsersUpdateUserPutRequest} [usersUpdateUserPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserPut(usersUpdateUserPutRequest?: UsersUpdateUserPutRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateUserPut(usersUpdateUserPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


